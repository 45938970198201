import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from "react";
import { ApiProject, JenkinsJob, MapJob } from "../../../interfaces/commonInterfaces";
import { User } from "../../../interfaces/interfaceUser";
import { useCreateTilesMutation, useDeployTilesMutation, useLazyCreateTilesStatusGlobalQuery, useLazyCreateTilesStatusQuery, useLazyGetUserQuery, useLazyGetJobsQuery, useLazyGetMapQuery } from "../../slices/apiSlice";
import { setCurrentGuiController_mapsRefetch } from "../../slices/guiControllerSlice"
import Backdrop from "@mui/material/Backdrop";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from '@mui/icons-material/Close';
import { LayerFile, LayerNames, MapData } from "../../../interfaces/interfaceGuiController";
import LinearProgress from '@mui/material/LinearProgress';
import 'dayjs/locale/de';
import dayjs, { Dayjs } from "dayjs";
import { useAppDispatch } from "../../hooks";
import ModalIframe from "../projectComponents/modal_iFrame"


interface IDeploymentItemProps {
    buttonTitle: string;
    buttonTarget: string;
    date: string;
    linkTarget: string;
    user: string;
    mainColor: string;
    apiProject: ApiProject;
    layers: LayerNames[];
    layerFiles: { [key: string]: LayerFile };
    mapUuid: string;
}

const CssGreenButton = styled(Button)({
    color: '#092935',
    backgroundColor: '#E1E77B',
    fontWeight: '800',
    fontSize: '0.9em',
    '&:hover': {
        color: '#092935',
        backgroundColor: '#a8ad4b',
    },
});

const CssBlueButton = styled(Button)({
    color: '#092935',
    backgroundColor: '#24A3D3',
    fontWeight: '800',
    fontSize: '0.9em',
    '&:hover': {
        color: '#092935',
    },
});

const CssRedButton = styled(Button)({
    color: '#092935',
    backgroundColor: '#E77B7B',
    fontWeight: '800',
    fontSize: '0.9em',
    '&:hover': {
        color: '#092935',
        backgroundColor: '#E77B7B',
    },
});

const getBaseUrl = () => {
    let url;
    switch (process.env.REACT_APP_PATH_TO_API) {
        case 'production ':
            url = 'https://creator.guide3d.com/';
            break;
        case 'development ':
        default:
            url = 'http://api.creator.local/';
    }

    return url;
}

function DeploymentItem(props: React.PropsWithChildren<IDeploymentItemProps>) {
    const dispatch = useAppDispatch();
    const [openLayerChoice, setOpenLayerChoice] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [viewTilesGenLoader, setViewTilesGenLoader] = useState<boolean>(false);
    const [bFilesPresent, setBFilesPresent] = useState<boolean>(false);
    /* const [tileProgressWatcher, setTileProgressWatcher] = useState<boolean>(false); */
    const [viewTilesBetaDeployLoader, setViewTilesBetaDeployLoader] = useState<boolean>(false);
    const [viewTilesLiveDeployLoader, setViewTilesLiveDeployLoader] = useState<boolean>(false);
    
    /* const [tileIdsToGenerate, setTileIdsToGenerate] = useState<string[]>([]); */
    const [bMtilesPresent, setbMtilesPresent] = useState<boolean>(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [tileGenDate, setTileGenDate] = useState<string>();
    const [tileBetaDate, setTileBetaDate] = useState<string>();
    const [tileGenUser, setTileGenUser] = useState<string>();
    const [tileGenProgress, setTileGenProgress] = useState<number>(0);
    const [tileBetaUser, setTileBetaUser] = useState<string>("-");
    const [readyLiveDeploy, setReadyLiveDeploy] = useState<boolean>(false);
    const [allMbTilesPresent, setAllMbTilesPresent] = useState<boolean>(false);
    const [showLivePreview, setShowLivePreview] = useState<boolean>(false);
    const [livePreviewUrl, setLivePreviewUrl] = useState<string>();
    const [showBetaPreview, setShowBetaPreview] = useState<boolean>(false);    
    const [betaPreviewUrl, setBetaPreviewUrl] = useState<string>();
    const [showTilesPreview, setShowTilesPreview] = useState<boolean>(false);
    const [tilesPreviewUrl, setTilesPreviewUrl] = useState<string>();
    const [mapLayerFiles, setMapLayerFiles] = useState<{ [key: string]: LayerFile }>();
    /* const [mapID, setMapID] = useState<string>(); */
    const { t } = useTranslation();
    const [getMapCall] = useLazyGetMapQuery();
    const [createTiles] = useCreateTilesMutation();
    const [deployTiles] = useDeployTilesMutation();
    const [getCreateTilesStatus] = useLazyCreateTilesStatusQuery();
    const [getCreateTilesStatusGlobal] = useLazyCreateTilesStatusGlobalQuery();
    const [getUser] = useLazyGetUserQuery();
    const [getJobs] = useLazyGetJobsQuery();

    /* let viewTilesGenLoader: boolean = false; */

    const apiUrl: string = getBaseUrl(); // http://api.creator.local/ | https://creator.guide3d.com/

    useEffect(() => {
        const interval = setInterval(() => {
            if (props.mainColor === 'green') {
                if (props.mapUuid !== null && props.mapUuid !== undefined) {
                    queryTileProgressGlobal();
                }
            }
        }, 6000)

        // Clean up the interval when the component unmounts
        return () => clearInterval(interval)
    }, [props.mapUuid]);

    useEffect(() => {
        // setAllMbTilesPresent
        let bMbTiles: boolean = false;
        let bFiles: boolean = false;
        let mbTilesPresent: boolean = false;
        let mbTilesPresentArray: boolean[] = [];
        const layerFiles: { [key: string]: LayerFile } = props.layerFiles;
        /* console.log("layerFiles: ", layerFiles); */
        if (layerFiles !== null && layerFiles !== undefined) {
            /* console.log("useEffect layerFiles: ", layerFiles); */
            const mbGenDates: string[] = [];
            const mbGenUsers: string[] = [];
            Object.keys(layerFiles).forEach(key => {
                if (layerFiles[key].mbtiles === null) {
                    bMbTiles = false;
                    mbTilesPresentArray.push(false);
                }
                else {
                    // mach nix
                    mbTilesPresentArray.push(true);
                    bMbTiles = true;
                    if (layerFiles[key].mbtiles.modifiedAt !== undefined) {
                        mbGenDates.push(layerFiles[key].mbtiles.modifiedAt as string);
                        mbGenUsers.push(layerFiles[key].mbtiles.modifiedBy as string);
                    }
                }
                if (layerFiles[key].pgw === null) {
                    bFiles = false;
                }
                else {
                    bFiles = true;
                }
            });
            if (mbGenDates.length > 0) {
                constructLatestEditor(mbGenDates, mbGenUsers);
            }
            else {
                setTileGenDate("-");
                setTileGenUser("");
            }
        }
        else {
            setTileGenDate("-");
            setTileGenUser("");
        }
        /* console.log("bMbTiles: ", bMbTiles); */

        if (mbTilesPresentArray.indexOf(false) >= 0 || mbTilesPresentArray.length === 0) {
            mbTilesPresent = false;
        }
        else {
            mbTilesPresent = true;
            queryJobs(props.apiProject.projectId);
        }
        setAllMbTilesPresent(mbTilesPresent);
        setbMtilesPresent(bMbTiles);
        setBFilesPresent(bFiles);
    }, [props.layerFiles]);

    useEffect(() => {
        // setAllMbTilesPresent
        let bMbTiles: boolean = false;
        let bFiles: boolean = false;
        let mbTilesPresent: boolean = false;
        let mbTilesPresentArray: boolean[] = [];
        const layerFiles: { [key: string]: LayerFile } = mapLayerFiles as { [key: string]: LayerFile };
        /* console.log("layerFiles: ", layerFiles); */
        if (layerFiles !== null && layerFiles !== undefined) {
            /* console.log("useEffect layerFiles: ", layerFiles); */
            const mbGenDates: string[] = [];
            const mbGenUsers: string[] = [];
            Object.keys(layerFiles).forEach(key => {
                if (layerFiles[key].mbtiles === null) {
                    bMbTiles = false;
                    mbTilesPresentArray.push(false);
                }
                else {
                    // mach nix
                    mbTilesPresentArray.push(true);
                    bMbTiles = true;
                    if (layerFiles[key].mbtiles.modifiedAt !== undefined) {
                        mbGenDates.push(layerFiles[key].mbtiles.modifiedAt as string);
                        mbGenUsers.push(layerFiles[key].mbtiles.modifiedBy as string);
                    }
                }
                if (layerFiles[key].pgw === null) {
                    bFiles = false;
                }
                else {
                    bFiles = true;
                }
            });
            if (mbGenDates.length > 0) {
                constructLatestEditor(mbGenDates, mbGenUsers);
            }
            else {
                setTileGenDate("-");
                setTileGenUser("");
            }
        }
        else {
            setTileGenDate("-");
            setTileGenUser("");
        }
        /* console.log("bMbTiles: ", bMbTiles); */

        if (mbTilesPresentArray.indexOf(false) >= 0 || mbTilesPresentArray.length === 0) {
            mbTilesPresent = false;
        }
        else {
            mbTilesPresent = true;
            queryJobs(props.apiProject.projectId);
        }
        setAllMbTilesPresent(mbTilesPresent);
        setbMtilesPresent(bMbTiles);
        setBFilesPresent(bFiles);
    }, [mapLayerFiles]);

    useEffect(() => {
        queryTileProgressGlobal();
    }, [props.mapUuid]);

    useEffect(() => {
        if (tileGenDate !== undefined) {
            if (tileGenDate.length > 5) {
                dispatch(setCurrentGuiController_mapsRefetch(true));
            }
        }
    }, [tileGenDate]);


    const queryTileProgressGlobal = async () => {

        await getCreateTilesStatusGlobal("")
            .then((response: any) => {
                /* console.log("########################")
                console.log("response.data.data: ", response.data.data); */
                checkStatusForlastInfo(response.data.data);
            }
            );
    }

    const queryMapTileGen = async () => {

        await getMapCall(props.mapUuid)
            .then((response: any) => {

                let mapData: MapData = response.data.data.mapData as MapData;
                let filesData: { [key: string]: LayerFile } = response.data.data.files as { [key: string]: LayerFile };
                setMapLayerFiles(filesData);

            }
            );
    }

    const queryUser = async (userUuid: string) => {

        await getUser(userUuid)
            .then((response: any) => {
                /* console.log("########################")
                console.log("USER response.data.data: ", response.data.data);    */
                try {
                    setUserTileGenName(response.data.data);
                } catch (error) {
                    // mach nix
                }
            }
            );
    }

    const queryUserForBeta = async (userUuid: string) => {

        await getUser(userUuid)
            .then((response: any) => {
                /* console.log("########################")
                console.log("USER response.data.data: ", response.data.data);    */
                try {
                    setUserTileBetaName(response.data.data);
                } catch (error) {
                    // mach nix
                }
            }
            );
    }

    const queryJobs = async (projectId: number) => {

        await getJobs(projectId)
            .then((response: any) => {
                /* console.log("########################")
                console.log("Jobs response.data.data: ", response.data.data); */
                const jobs: { [key: string]: JenkinsJob } = response.data.data;
                const mbBetaDates: string[] = [];
                const mbBetaUsers: string[] = [];
                Object.keys(jobs).forEach(key => {
                    const date: string = jobs[key].createdAt;
                    let user: string = "";
                    if (jobs[key].userUuid !== undefined) {
                        user = jobs[key].userUuid as string;
                    }

                    if (date.length > 6) {
                        mbBetaDates.push(date);
                        mbBetaUsers.push(user);
                    }
                });
                if (mbBetaDates.length > 0) {
                    let latestDateIndex: number = 0;
                    let latestDate: dayjs.Dayjs;
                    mbBetaDates.forEach((date, index) => {
                        if (index === 0) {
                            latestDate = dayjs(mbBetaDates[0]);
                            latestDateIndex = 0;
                        }
                        else {
                            if (latestDate.isBefore(dayjs(mbBetaDates[index]))) {
                                latestDate = dayjs(mbBetaDates[index]);
                                latestDateIndex = index;
                            }
                        }
                    });
                    setTileBetaDate(mbBetaDates[latestDateIndex]);
                    if (mbBetaUsers[latestDateIndex] !== "") {
                        queryUserForBeta(mbBetaUsers[latestDateIndex]);
                    }
                }
                else {
                    setTileBetaDate("-");
                }

            });
    }

    const setUserTileGenName = (user: User) => {
        setTileGenUser(user.firstname + " " + user.lastname);
    }

    const setUserTileBetaName = (user: User) => {
        setTileBetaUser(user.firstname + " " + user.lastname);
    }

    const constructLatestEditor = (dates: string[], users: string[]) => {
        /* console.log("dates: ", dates);
        console.log("users: ", users); */
        let latestDateIndex: number = 0;
        let latestDate: dayjs.Dayjs;
        dates.forEach((date, index) => {
            if (index === 0) {
                // mach nix   
                latestDate = dayjs(dates[0]);
                latestDateIndex = 0;
            }
            else {
                if (latestDate.isBefore(dayjs(dates[index]))) {
                    latestDate = dayjs(dates[index]);
                    latestDateIndex = index;
                }
            }
        });
        setTileGenDate(dates[latestDateIndex]);
        // setTileGenUser(users[latestDateIndex]);
        queryUser(users[latestDateIndex]);
    }

    const getDateLayer = (str: string) => {
        let dateString: string = "nicht erstellt";

        const layerFiles: { [key: string]: LayerFile } = mapLayerFiles as { [key: string]: LayerFile };
        /* console.log("layerFiles: ", layerFiles); */
        try {
            if (layerFiles !== null && layerFiles !== undefined) {
                Object.keys(layerFiles).forEach(key => {
                    if (layerFiles[key].layer === str) {
                        if (layerFiles[key].mbtiles !== null) {
                            dateString = dayjs(layerFiles[key].mbtiles.modifiedAt as string).locale('de').format('LLL');
                        }
                    }
                });
            }
        } catch (error) {
            // mach nix
        }
        return dateString;
    }

    const checkStatusForlastInfo = (data: MapJob[]) => {
        const progressArray: number[] = [];
        const progressIndexArray: number[] = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.mapUuid === props.mapUuid) {
                progressArray.push(element.progress);
                progressIndexArray.push(index);
            }
        }
        /* if (progressArray.indexOf(100) >= 0) {
            // already processed in the past
            setTileGenDate(data[progressIndexArray[progressArray.lastIndexOf(100)]].finishedAt)
        } */
        let currentlyRunning: boolean = false
        for (let index = 0; index < progressArray.length; index++) {
            const progressValue = progressArray[index];
            if (progressValue < 100) {
                // Tiles process is running just now
                currentlyRunning = true;
                setTileGenProgress(progressValue);
            }
        }
        if (currentlyRunning === true) {
            /* console.log("checkStatusForlastInfo: currentlyRunning => ", currentlyRunning); */
            setViewTilesGenLoader(true);
            /* viewTilesGenLoader = true; */
        }
        else {
            /* console.log("checkStatusForlastInfo: currentlyRunning FALSE => ", currentlyRunning);
            console.log("checkStatusForlastInfo: viewTilesGenLoader => ", viewTilesGenLoader); */
            /* if(viewTilesGenLoader === true){ */
            /* console.log("checkStatusForlastInfo: btGenLoadActive: ", btGenLoadActive); */
            /* if(viewTilesGenLoader === true){ */
            setViewTilesGenLoader(false);
            /* viewTilesGenLoader = false; */
            queryMapTileGen();
            /* } */


        }


    }

    const queryTileProgress = async () => {

        await getCreateTilesStatus(props.mapUuid)
            .then((response: any) => {
                const tileGenRunning: boolean = response.data.data.createTilesRunning as boolean;
                /*  console.log("tileGenRunning: ", tileGenRunning); */
                if (tileGenRunning === true) {
                    setViewTilesGenLoader(true);
                    /* viewTilesGenLoader = true; */
                }
                else {
                    setViewTilesGenLoader(false);
                    /* viewTilesGenLoader = false; */
                }
            }
            );
    }

    const decideTileGenDisabled = () => {
        let bTileGenDisabled: boolean = false;
        /* console.log("###################################");
        console.log("bFilesPresent: ", bFilesPresent);
        console.log("viewTilesGenLoader: ", viewTilesGenLoader); */
        if (bFilesPresent === false) {
            bTileGenDisabled = true;
        }
        if (viewTilesGenLoader === true) {
            bTileGenDisabled = true;
        }
        /* console.log("bTileGenDisabled: ", bTileGenDisabled); */
        return bTileGenDisabled;
    }

    const decideBetaDisabled = () => {
        let bBetaDisabled: boolean = false;
        if (viewTilesBetaDeployLoader === true) {
            bBetaDisabled = true;
        }
        if (bMtilesPresent === false) {
            bBetaDisabled = true;
        }
        if (allMbTilesPresent === false) {
            bBetaDisabled = true;
        }
        return bBetaDisabled;
    }

    // tileBetaDate !== "-"

    const decideLiveDisabled = () => {
        let bLiveDisabled: boolean = true;
        if (tileBetaDate !== "-" && tileBetaDate !== undefined) {
            bLiveDisabled = false;
        }        
        return bLiveDisabled;
    }

    const handleAction = (buttonColor: string) => {
        /* console.log("deployBut hit: ", buttonColor);
        console.log("deployBut hit: ", props.linkTarget);
        console.log("mapsAssistant_layers: ", mapsAssistant_layers); */
        switch (buttonColor) {
            case 'green':
                handleOpenLayerChoice();
                break;
            case 'blue':
                handleBetaTilesDeploy();
                break;
            case 'red':
                handleLiveTilesDeploy();
                break;
            default:
                break;
        }

    }

    const handleMbTilesPreview = () => {
        /* 
        const [showBetaPreview, setShowBetaPreview] = useState<boolean>(false);
        const [betaPreviewUrl, setBetaPreviewUrl] = useState<string>();
        */
        setTilesPreviewUrl(('https://creator.guide3d.com/mbviewer/index.php?map_id=' + props.apiProject.mapUuid + '&project_id=' + props.apiProject.projectId + '&tilegen=true'));
        setShowTilesPreview(true);
        // window.open(('https://creator.guide3d.com/mbtiles_viewer/index.php?map_id=' + props.apiProject.mapUuid + '&project_id=' + props.apiProject.projectId), '_blank');
    }

    const handleBetaMbTilesPreview = () => {
        /* 
        const [showBetaPreview, setShowBetaPreview] = useState<boolean>(false);
        const [betaPreviewUrl, setBetaPreviewUrl] = useState<string>();
        */
        /* setBetaPreviewUrl(('https://creator.guide3d.com/mbtiles_viewer/index.php?map_id=' + props.apiProject.mapUuid + '&project_id=' + props.apiProject.projectId)); */
        setBetaPreviewUrl(('https://creator.guide3d.com/mbviewer/index.php?map_id=' + props.apiProject.mapUuid + '&project_id=' + props.apiProject.projectId));
        setShowBetaPreview(true);
        // window.open(('https://creator.guide3d.com/mbtiles_viewer/index.php?map_id=' + props.apiProject.mapUuid + '&project_id=' + props.apiProject.projectId), '_blank');
    }

    const handleLiveMbTilesPreview = () => {
        /* 
        const [showBetaPreview, setShowBetaPreview] = useState<boolean>(false);
        const [betaPreviewUrl, setBetaPreviewUrl] = useState<string>();
        */
        /* setBetaPreviewUrl(('https://creator.guide3d.com/mbtiles_viewer/index.php?map_id=' + props.apiProject.mapUuid + '&project_id=' + props.apiProject.projectId)); */
        setLivePreviewUrl(('https://creator.guide3d.com/mbviewer/index.php?map_id=' + props.apiProject.mapUuid + '&project_id=' + props.apiProject.projectId + '&live=true'));
        setShowLivePreview(true);
        // window.open(('https://creator.guide3d.com/mbtiles_viewer/index.php?map_id=' + props.apiProject.mapUuid + '&project_id=' + props.apiProject.projectId), '_blank');
    }

    const handleCloseLayerChoice = () => {
        setOpenLayerChoice(false);
    };
    const handleOpenLayerChoice = () => {
        setOpenLayerChoice(true);
    };

    /* const handleChangeTilesToGenerate = (layer: string) => {
        console.log("checkbox layer: ", layer);
        console.log("checkbox tileIdsToGenerate: ", tileIdsToGenerate);
        let currentTilesList: string[] = tileIdsToGenerate;
        if (currentTilesList.indexOf(layer) >= 0) {
            currentTilesList.splice(currentTilesList.indexOf(layer), 1);
            setTileIdsToGenerate(currentTilesList);
            console.log("" + layer + " removed tilesList");
        }
        else {
            currentTilesList.push(layer);
            setTileIdsToGenerate(currentTilesList);
            console.log("" + layer + " added to tilesList");
        }
    } */

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const checkedId = event.target.value;
        if (event.target.checked) {
            setSelectedIds([...selectedIds, checkedId])
        } else {
            setSelectedIds(selectedIds.filter(id => id !== checkedId))
        }
    }

    const handleSelectAllOrNone = () => {
        if (props.layers !== undefined) {
            if (selectAll === false) {
                // selectall
                let allLayers: string[] = [];
                props.layers.forEach((element: LayerNames) => {
                    allLayers.push(element.layerId);
                });
                setSelectAll(true);
                setSelectedIds(allLayers);
            }
            else {
                // deselectall
                let allLayers: string[] = [];
                setSelectAll(false);
                setSelectedIds(allLayers);
            }
        }
    }

    const handleApproveTileGen = () => {
        console.log("approve selectedIds: ", selectedIds);
        setTileGenProgress(0);
        callCreateTiles(selectedIds, true);
        /* selectedIds.forEach(layerId => {
            delayedCallCreateTiles(layerId, selectedIds);
            // callCreateTiles([layerId], true);
        }); */
    }

    /* const delayedCallCreateTiles = (layerId: string, allIds: string[]) => {
        const index: number = allIds.indexOf(layerId);
        setTimeout(() => {
            callCreateTiles([layerId], true);
        }, ((index * 30000) + 1000));
    } */

    const callCreateTiles = async (layerArray: string[], bCircleCut: boolean) => {
        const data: Object = { mapUuid: props.apiProject.mapUuid, bodydata: { "layers": layerArray, "circlecut": bCircleCut } }
        /* console.log("createTiles data: ", data);
        console.log("createTiles props: ", props); */
        handleCloseLayerChoice();
        setViewTilesGenLoader(true);
        /* viewTilesGenLoader = true; */
        await createTiles(data)
            .then((response: any) => {
                console.log("createTiles response: ", response);
                /* if (response.data.success === true) {
                    setViewTilesGenLoader(false);
                } */
            }
            );
    }

    const handleBetaTilesDeploy = async () => {
        setViewTilesBetaDeployLoader(true);
        console.log("Beta Tiles Deploy for project ", props.apiProject.projectId);
        const layerFiles: { [key: string]: LayerFile } = props.layerFiles;
        console.log("layerFiles: ", layerFiles);
        let mbTilesUrls: string[] = [];

        Object.keys(layerFiles).forEach(key => {
            if (layerFiles[key].mbtiles === null) {
                // mach nix
            }
            else {
                console.log(layerFiles[key])
                mbTilesUrls.push(apiUrl + layerFiles[key].mbtiles.url);
            }
        });

        const data: Object = { projectId: props.apiProject.projectId, bodydata: { "env": "beta", "mbtilesUrls": mbTilesUrls } }
        /* setViewTilesBetaDeployLoader(true); */
        await deployTiles(data)
            .then((response: any) => {
                console.log("deployBetaTiles response: ", response);
                if (response.data.success === true) {
                    setTimeout(() => {
                        setViewTilesBetaDeployLoader(false);
                        queryJobs(props.apiProject.projectId);
                    }, 8000);

                }
            }
            );
    }


    const handleLiveTilesDeploy = async () => {
        setViewTilesLiveDeployLoader(true);
        console.log("Live Tiles Deploy for project ", props.apiProject.projectId);
        const layerFiles: { [key: string]: LayerFile } = props.layerFiles;
        console.log("layerFiles: ", layerFiles);
        let mbTilesUrls: string[] = [];

        Object.keys(layerFiles).forEach(key => {
            if (layerFiles[key].mbtiles === null) {
                // mach nix
            }
            else {
                console.log(layerFiles[key])
                mbTilesUrls.push(apiUrl + layerFiles[key].mbtiles.url);
            }
        });

        const data: Object = { projectId: props.apiProject.projectId, bodydata: { "env": "live", "mbtilesUrls": mbTilesUrls } }
        /* setViewTilesBetaDeployLoader(true); */
        await deployTiles(data)
            .then((response: any) => {
                console.log("deployBetaTiles response: ", response);
                if (response.data.success === true) {
                    setTimeout(() => {
                        setViewTilesBetaDeployLoader(false);
                        queryJobs(props.apiProject.projectId);
                    }, 8000);

                }
            }
            );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: '4vh' }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLayerChoice}
            >
                <Box sx={{ backgroundColor: 'primary.dark', padding: '1rem', boxShadow: 3 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                        <CloseIcon cursor='pointer' sx={{ fontSize: '1.8rem', top: '1em', right: '1em' }} onClick={handleCloseLayerChoice}></CloseIcon>
                    </Box>
                    <Typography variant="subtitle1" gutterBottom align='left' sx={{
                        fontWeight: '400'
                    }}>Welche Tiles sollen generiert werden?</Typography>
                    <Button variant="contained" size="small" color="primary" sx={{ marginTop: '0.5rem' }} onClick={() => handleSelectAllOrNone()} >{'Alle / keine selektieren'}</Button>
                    {props.layers !== undefined ?
                        props.layers.map((layer, index) => (
                            <Box key={"layer_" + Math.random() + "_" + index}>
                                <FormControlLabel labelPlacement="start" control={
                                    <Checkbox color='default' aria-label='active'
                                        value={layer.layerId}
                                        checked={selectedIds.includes(layer.layerId)}
                                        onChange={(event) => { handleCheckboxChange(event) }}
                                    />
                                } label={layer.layerId + " - " + layer.shortName[0] + " (" + getDateLayer(layer.layerId) + ")"} />
                            </Box>
                        ))
                        : null
                    }
                    <Button variant="contained" /* size="small" */ color="secondary" sx={{ width: '100%', marginTop: '1rem' }} disabled={selectedIds.length > 0 ? false : true} onClick={() => handleApproveTileGen()} >{'Tiles für ausgewählte Layer generieren'}</Button>
                    <Button variant="contained" /* size="small" */ color="primary" sx={{ width: '100%', marginTop: '0.5rem' }} onClick={() => handleCloseLayerChoice()} >{'Abbrechen'}</Button>
                </Box>
            </Backdrop>
            {props.mainColor === 'green' ?
                <CssGreenButton variant="contained" disabled={decideTileGenDisabled()} size="small" color="success" sx={{ width: '100%' }} onClick={() => handleAction(props.mainColor)} >{(viewTilesGenLoader as boolean) === true ? 'Tiles werden generiert...' : props.buttonTitle}</CssGreenButton>
                : null
            }
            {props.mainColor === 'blue' ?
                <CssBlueButton variant="contained" disabled={decideBetaDisabled()} size="small" color="success" sx={{ width: '100%' }} onClick={() => handleAction(props.mainColor)} >{viewTilesBetaDeployLoader === true ? 'Beta Tiles werden deployt...' : props.buttonTitle}</CssBlueButton>
                : null
            }
            {props.mainColor === 'red' ?
                <CssRedButton variant="contained" disabled={decideLiveDisabled()} size="small" color="success" sx={{ width: '100%' }} onClick={() => handleAction(props.mainColor)} >{props.buttonTitle}</CssRedButton>
                : null
            }
            {props.mainColor === 'green' ?
                <Box sx={{ width: '100%' }}>
                    {(viewTilesGenLoader as boolean) === true ?
                        <Box>
                            <LinearProgress color="secondary" sx={{ width: '100%', height: '6px' }} />
                            <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                fontWeight: '400'
                            }}>{tileGenProgress + "%"}</Typography>
                        </Box>
                        : null
                    }
                </Box>
                : null
            }
            {props.mainColor === 'blue' ?
                <Box sx={{ width: '100%' }}>
                    {viewTilesBetaDeployLoader === true ?
                        <LinearProgress color="secondary" sx={{ width: '100%', height: '6px' }} />
                        : null
                    }
                </Box>
                : null
            }

            {props.mainColor === 'green' && tileGenDate !== "-" ?
                <Typography variant="body2" gutterBottom align='left' sx={{ mt: '1vh' }}>{t("tools.deployment.changed")} {dayjs(tileGenDate).locale('de').format('LLL')}<br></br>{"von " + tileGenUser}</Typography>
                : null
            }
            {props.mainColor === 'blue' && allMbTilesPresent === true && tileBetaDate !== "-" ?
                <Typography variant="body2" gutterBottom align='left' sx={{ mt: '1vh' }}>{t("tools.deployment.changed")} {dayjs(tileBetaDate).locale('de').format('LLL')}<br></br>{"von " + tileBetaUser}</Typography>
                : null
            }
            {props.mainColor === 'red' && readyLiveDeploy === true ?
                <Typography variant="body2" gutterBottom align='left' sx={{ mt: '1vh' }}>{t("tools.deployment.changed")} {props.date}<br></br>{props.user}</Typography>
                : null
            }

            {props.mainColor === 'green' && tileGenDate !== "-" ?
                <Button size="small" variant="contained" onClick={() => handleMbTilesPreview()}>{t('tools.deployment.open')}</Button>
                : null
            }
            {props.mainColor === 'blue' && allMbTilesPresent === true && tileBetaDate !== "-" ?
                <Button size="small" variant="contained" onClick={() => handleBetaMbTilesPreview()}>{t('tools.deployment.open')}</Button>
                : null
            }
            {props.mainColor === 'red'?
                <Button size="small" variant="contained" sx={{marginTop: '0.5em'}}onClick={() => handleLiveMbTilesPreview()}>{t('tools.deployment.open')}</Button>
                : null
            }
            {showTilesPreview === true ?
                <ModalIframe title={'Tiles Preview'} url={tilesPreviewUrl as string} hexBackgroundColor={"#e1e77b"} closeIframe={setShowTilesPreview}></ModalIframe>
                : null
            }
            {showBetaPreview === true ?
                <ModalIframe title={'Beta Tiles Preview'} url={betaPreviewUrl as string} hexBackgroundColor={"#24a3d3"} closeIframe={setShowBetaPreview}></ModalIframe>
                : null
            }
            {showLivePreview === true ?
                <ModalIframe title={'Live Tiles Preview'} url={livePreviewUrl as string} hexBackgroundColor={"#E77B7B"} closeIframe={setShowLivePreview}></ModalIframe>
                : null
            }
        </Box>
    );
}

export default DeploymentItem;