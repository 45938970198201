import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Backdrop from '@mui/material/Backdrop';
import { setCurrentGuiController_mapsAssistant, setCurrentGuiController_mapsManualSetup, setCurrentGuiController_mapsAssistant_layerImages_index, setCurrentGuiController_editedLayerNames, setCurrentGuiController_editedLayerIds, setCurrentGuiController_mapsStep, setCurrentGuiController_valueTabs, setCurrentGuiController_mapsFiles, setCurrentGuiController_mapsAssistant_uploads, setCurrentGuiController_mapsPixelCoordinates_A, setCurrentGuiController_mapsPixelCoordinates_B, setCurrentGuiController_mapsGeoCoordinates_A, setCurrentGuiController_mapsGeoCoordinates_B, setCurrentGuiController_mapsRotation, setCurrentGuiController_mapsPreviewRenew } from '../../slices/guiControllerSlice';
import { setCurrentMapDataController, setCurrentMapDataController_layerIds, setCurrentMapDataController_layerNames, setCurrentMapDataController_rotation } from '../../slices/MapDataSlice';
import Radio from '@mui/material/Radio';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MapsUploadElement from './maps_upload_element';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import LocatorMap from '../../smallComponents/locatorMap';
import ReferenceMap from '../../smallComponents/referenceMap';
import { Extent, boundingExtent, getCenter } from "ol/extent";
/* import PreviewMap from '../../smallComponents/previewMap'; */
import { transform } from "ol/proj";
import { LayerNames, MapData, UploadedFile, UploadedFiles } from '../../../interfaces/interfaceGuiController';
import RasterOverlay from "../../../raster.png";
import { useEditMapMutation } from '../../slices/apiSlice';
import { ApiProject, langObject } from '../../../interfaces/commonInterfaces';
import { Coordinate } from 'ol/coordinate';
import PrevMap from '../../smallComponents/prevMap';
import Checkbox from '@mui/material/Checkbox';



const CssTextField = styled(TextField)({
    /* width: '30%', */
    margin: 'unset',
    marginLeft: '2vh',
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
});

const AS2TextField = styled(TextField)({
    marginTop: '0.2em',
    backgroundColor: '#030E12',
    /* paddingLeft: '1em', */
    marginLeft: '1em',
    width: '15em',
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#fffffff',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#ffffff',
            borderWidth: '1px'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#ffffff',
            borderWidth: '2px'
        },
    },
});

const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
        color: '#ffffff',
    },
});

/* const coordsCss: Object = {
    backgroundColor: 'primary.dark',
    padding: '0.3rem',
    marginTop: 0,
    width: '30em',
    marginBottom: '0.5em'
} */

interface IManualProps {
    apiProject: ApiProject;
    mapData: MapData;
    mapFiles: UploadedFiles;
    setMainMapData: Dispatch<SetStateAction<MapData | undefined>>;
    setMainMapFiles: Dispatch<SetStateAction<UploadedFiles | undefined>>;
    requeryMap: () => Promise<void>;
}

export default function Maps_manualSetup(props: React.PropsWithChildren<IManualProps>) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    // const apiProject = props.apiProject;

    /* const [mapsStep, setMapsStep] = React.useState(6);
    const [valueTabs, setValueTabs] = React.useState(0); */
    const [layerNumber, setLayerNumber] = React.useState(1);
    const [layerArray, setLayerArray] = React.useState([0]);
    const [inputErrors] = React.useState([999]);
    // const [editedLayerNames, seteditedLayerNames] = React.useState([""]);
    const [newLayer, setNewLayer] = React.useState<boolean>(false);
    const [advancedPos, setAdvancedPos] = React.useState<boolean>(false);
    const [selectedValue, setSelectedValue] = React.useState('');
    const [aboveLayer, setAboveLayer] = React.useState('');
    const [belowLayer, setBelowLayer] = React.useState('');
    /* const [languageNamesTest, setlanguageNamesTest] = React.useState(["de", "en", "es"]); */
    const [languagesShort, setLanguagesShort] = React.useState([""]);
    const [languagesLong, setLanguagesLong] = React.useState([""]);
    const mapsStep = useAppSelector((state) => state.guicontroller.value.mapsStep);
    const valueTabs = useAppSelector((state) => state.guicontroller.value.valueTabs);
    const editedLayerNames = useAppSelector((state) => state.guicontroller.value.editedLayerNames);
    const editedLayerIds = useAppSelector((state) => state.guicontroller.value.editedLayerIds);
    const mapsAssistant_uploads = useAppSelector((state) => state.guicontroller.value.mapsAssistant_uploads);
    /* const mapsFiles = useAppSelector((state) => state.guicontroller.value.mapsFiles); */
    /* const mapsFiles = props.mapFiles; */
    const mapsPixelA = useAppSelector((state) => state.guicontroller.value.mapsPixelCoordinates_A);
    const mapsPixelB = useAppSelector((state) => state.guicontroller.value.mapsPixelCoordinates_B);
    const mapsGeoA = useAppSelector((state) => state.guicontroller.value.mapsGeoCoordinates_A);
    const mapsGeoB = useAppSelector((state) => state.guicontroller.value.mapsGeoCoordinates_B);
    const [refMapKey, setRefMapKey] = React.useState(0);
    const [locMapKey, setLocMapKey] = React.useState(0);
    const [mapsGeoALon, setMapsGeoALon] = React.useState<number>(-1);
    const [mapsGeoALat, setMapsGeoALat] = React.useState<number>(-1);
    const [mapsGeoBLon, setMapsGeoBLon] = React.useState<number>(-1);
    const [mapsGeoBLat, setMapsGeoBLat] = React.useState<number>(-1);
    const [mapsPixelA_x, setMapsPixelA_x] = React.useState<number>(-1);
    const [mapsPixelA_y, setMapsPixelA_y] = React.useState<number>(-1);
    const [mapsPixelB_x, setMapsPixelB_x] = React.useState<number>(-1);
    const [mapsPixelB_y, setMapsPixelB_y] = React.useState<number>(-1);
    const [mapsGeoAToSet, setMapsGeoAToSet] = React.useState<[number, number]>([-1, -1]);
    const [mapsGeoBToSet, setMapsGeoBToSet] = React.useState<[number, number]>([-1, -1]);
    const [mapsPixelAToSet, setMapsPixelAToSet] = React.useState<[number, number]>([-1, -1]);
    const [mapsPixelBToSet, setMapsPixelBToSet] = React.useState<[number, number]>([-1, -1]);
    const [tab0Passed, setTab0passed] = React.useState(false);
    const [tab1Passed, setTab1passed] = React.useState(false);
    const [tab2Passed, setTab2passed] = React.useState(false);
    const [tab3Passed, setTab3passed] = React.useState(false);
    /* const layerIds = useAppSelector((state) => state.mapDataController.value.layerIds); */
    /* console.log("props.mapData: ", props.mapData); */
    let layerIds: string[] = [];
    if (props.mapData.layerIds !== undefined) {
        layerIds = props.mapData.layerIds;
    }

    const layerNames = useAppSelector((state) => state.mapDataController.value.layerNames);
    /* const layerNames = props.mapData.layerNames; */
    const mapDataCreate = useAppSelector((state) => state.mapDataController.value);
    const mapData = props.mapData;
    const mapRotation_intern = useAppSelector((state) => state.mapDataController.value.rotation);
    const mapRotation_extern = props.mapData.rotation;
    /* const { data, error, isLoading } = useGetMapQuery(props.apiProject.mapUuid);
    const [getMapCall] = useLazyGetMapQuery(); */
    const [mapRotation, setMapRotation] = React.useState(0);
    const [rasterView, setRasterView] = React.useState(0);
    const [surroundingView, setSurroundingView] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selectedLayer, setselectedLayer] = React.useState("");
    const [customLayerIDStart, setCustomLayerIDStart] = React.useState("");
    const [selectedLayerIndex, setselectedLayerIndex] = React.useState(0);
    const [basicsDone, setBasicsDone] = React.useState(false);

    const [prevMapData, setprevMapData] = React.useState<MapData>();
    const [prevMapFiles, setprevMapFiles] = React.useState<UploadedFiles>();
    const [editMap] = useEditMapMutation();
    const previewRenew = useAppSelector((state) => state.guicontroller.value.mapsPreviewRenew);




    const marks = [
        {
            value: -360,
            label: '-360°',
        },
        {
            value: -270,
            label: '-270°',
        },
        {
            value: -180,
            label: '-180°',
        },
        {
            value: -90,
            label: '-90°',
        },
        {
            value: 0,
            label: '0°',
        },
        {
            value: 90,
            label: '90°',
        },
        {
            value: 180,
            label: '180°',
        },
        {
            value: 270,
            label: '270°',
        },
        {
            value: 360,
            label: '360°',
        },
    ];

    React.useEffect(() => {
        /* console.log("React.useEffect: setMapRotation(mapRotation_intern)"); */
        setMapRotation(mapRotation_intern as number);
    }, [mapRotation_intern]);

    React.useEffect(() => {
        /* console.log("React.useEffect: setMapRotation(mapRotation_extern)"); */
        setMapRotation(mapRotation_extern as number);
    }, [mapRotation_extern]);


    /* const nullCheck = (str: string | null): boolean => {
        let bNullValue = false;
        if (str === null) {
            bNullValue = true;
        }
        if (str === undefined) {
            bNullValue = true;
        }
        if (str === "") {
            bNullValue = true;
        }
        return bNullValue;
    } */

    /*  React.useEffect(() => {
         setRefMapKey(refMapKey + 1);
     }, [mapsPixelA, mapsPixelB])
 
     React.useEffect(() => {
         setLocMapKey(locMapKey + 1);
     }, [mapsGeoA, mapsGeoB]) */

    React.useEffect(() => {
        /* console.log("React.useEffect: A"); */
        if (basicsDone === true) {
            if (layerIds !== undefined && layerNames !== undefined) {
                if (layerIds.length > 0 && layerNames.length > 0) {
                    setBasicsDone(false);
                    /* saveChangesToMap(); */
                }
            }
        }
    }, [layerIds, layerNames]);

    const refreshLocMap = () => {
        setLocMapKey(locMapKey + 1);
    }

    const refreshRefMap = () => {
        setRefMapKey(refMapKey + 1);
    }

    const constructLayerIds = () => {
        const tmpLayerArray: number[] = [...layerArray];
        const layerIds: string[] = [];
        tmpLayerArray.reverse();
        tmpLayerArray.forEach(element => {
            const layerId: string = getLayerIdbyIndex(element);
            layerIds.push(layerId);
        });
        if (surroundingView === true) {
            if (layerIds.indexOf("L00") === -1) {
                layerIds.push("L00");
                let newArr = [...editedLayerNames];
                if (newArr.length < layerIds.length) {
                    newArr.push("Umgebung");
                    dispatch(setCurrentGuiController_editedLayerNames(newArr));
                }
            }
        }
        if (surroundingView === false) {
            let newArr = [...editedLayerNames];
            if (newArr.indexOf("Umgebung") >= 0) {
                const index = newArr.indexOf("Umgebung");
                newArr.splice(index, 1);
                dispatch(setCurrentGuiController_editedLayerNames(newArr));
            }
        }
        dispatch(setCurrentGuiController_editedLayerIds(layerIds));
        dispatch(setCurrentMapDataController_layerIds(layerIds));
        console.log("layerIds: ", layerIds);
        console.log("mapData: ", mapData);
        console.log("props.apiProject ", props.apiProject);
        console.log("props.apiProject.languages: ", props.apiProject.languages);



        constructLayerNamesObjects(layerIds);
        setTab0passed(true);
        dispatch(setCurrentGuiController_valueTabs(valueTabs + 1));
    }

    const constructLayerNamesObjects = (layerIdsArray: string[]) => {
        console.log("constructLayerNamesObjects, layerIdsArray: ", layerIdsArray);
        let layerNamesObjects: LayerNames[] = [];
        layerIdsArray.forEach((layer: string, index: number) => {
            let layerObject: LayerNames = {
                layerId: layer,
                longName: [],
                shortName: []
            };
            if (layer === "L00") {
                props.apiProject.languages.forEach((language: langObject) => {
                    layerObject.shortName.push("Out");
                    layerObject.longName.push("");
                });
            }
            else {
                props.apiProject.languages.forEach((language: langObject) => {
                    layerObject.shortName.push(editedLayerNames[index]);
                    layerObject.longName.push("");
                });
            }

            layerNamesObjects.push(layerObject);
        });

        let mapUpdateData: MapData = structuredClone(mapData);
        mapUpdateData.layerIds = layerIdsArray;
        mapUpdateData.layerNames = layerNamesObjects;
        saveUpdatesToMap(mapUpdateData);
        dispatch(setCurrentMapDataController_layerNames(layerNamesObjects));
        setBasicsDone(true);
    }

    const getLayerIdbyIndex = (index: number): string => {
        let id: string = "";
        let bCustomIdHandling: boolean = false
        if (customLayerIDStart !== "") {
            const layer_reg: RegExp = new RegExp(/L\d\d/, "i");
            if (layer_reg.test(customLayerIDStart)) {
                bCustomIdHandling = true;
                const layerNumber: number = Number(customLayerIDStart.substring(1, 3));
                index = index + layerNumber;
                if (index <= 9) {
                    id = "L0" + index;
                }
                else {
                    id = "L" + index;
                }
            }
        }
        if (bCustomIdHandling === false) {
            index = index + 2;
            if (index <= 9) {
                id = "L0" + index;
            }
            else {
                id = "L" + index;
            }
        }
        return id;
    }

    const saveChangesToMap = () => {
        /* console.log("mapData altered: ", mapData); */
        const mapDataJSON = JSON.stringify(mapData);
        callEditMapMutation(mapDataJSON);
    }

    const saveUpdatesToMap = (data: MapData) => {
        /* console.log("mapData altered: ", mapData); */
        const mapDataJSON = JSON.stringify(data);
        callEditMapMutation(mapDataJSON);
    }

    const callEditMapMutation = async (jsonString: string) => {
        const data: Object = { mapUuid: props.apiProject.mapUuid, bodydata: { "mapData": jsonString } }
        await editMap(data)
            .then((response: any) => {
                // console.log("updated server mapData: ", response);
                props.setMainMapData(response.data.data.mapData);
                if (response.data.data.files !== null) {
                    let uf: UploadedFiles = { files: [] };
                    response.data.data.mapData.layerIds.forEach((id: string) => {
                        let fileData: UploadedFile = response.data.data.files[id] as UploadedFile;
                        uf.files.push(fileData);
                    });
                    // console.log("setMainMapFiles: ", uf);
                    props.setMainMapFiles(uf);
                }
            }
            );
    }

    React.useEffect(() => {
        /* console.log("React.useEffect: B"); */
        /* console.log("mapData: ", mapData); */
        if (mapsPixelA[0] !== -1 && mapsPixelB[0] !== -1 && mapsGeoA[0] !== -1 && mapsGeoA[0] !== -1) {
            /* console.log("React.useEffect: B1"); */
            if (tab3Passed === false) {
                if (valueTabs !== 3) {
                    /* console.log("React.useEffect: B2"); */
                    if (mapData.layerPgws !== undefined) {
                        /* console.log("React.useEffect: B3"); */
                        if (props.mapFiles.files.length > 0) {
                            /* console.log("?????????????????????????? HERE WE GO !!!!!!!!!!!!!!!!!!!!"); */
                            setTab2passed(true);
                            setTab3passed(true);
                            dispatch(setCurrentGuiController_valueTabs(4));
                            dispatch(setCurrentGuiController_mapsStep(8));
                        }
                    }
                    else {
                        if (props.mapFiles.files.length > 0) {
                            /* console.log("?????????????????????????? HERE WE GO !!!!!!!!!!!!!!!!!!!!"); */
                            setTab2passed(true);
                            setTab3passed(true);
                            dispatch(setCurrentGuiController_valueTabs(4));
                            dispatch(setCurrentGuiController_mapsStep(8));
                        }
                    }
                }

            }
        }

    }, [mapsPixelA, mapsPixelB, mapsGeoA, mapsGeoB]);

    /* const queryMap = async () => {

        await getMapCall(props.mapUuid)
            .then((response: any) => {
                dispatch(setCurrentMapDataController(response.data.data.mapData as MapData));

                if (response.data.data.files !== null) {
                    let uf: UploadedFiles = { files: [] };
                    response.data.data.mapData.layerIds.forEach((id: string) => {
                        let fileData: UploadedFile = response.data.data.files[id] as UploadedFile;
                        uf.files.push(fileData);
                    });
                    dispatch(setCurrentGuiController_mapsAssistant_uploads(response.data.data.mapData.layerIds.length))
                    dispatch(setCurrentGuiController_mapsFiles(uf));
                }
                else {
                    dispatch(setCurrentGuiController_mapsAssistant_uploads(0))
                    dispatch(setCurrentGuiController_mapsFiles({
                        files: []
                    }));
                    dispatch(setCurrentGuiController_mapsGeoCoordinates_A([-1, -1]));
                    dispatch(setCurrentGuiController_mapsGeoCoordinates_B([-1, -1]));
                    dispatch(setCurrentGuiController_mapsPixelCoordinates_A([-1, -1]));
                    dispatch(setCurrentGuiController_mapsPixelCoordinates_B([-1, -1]));
                    dispatch(setCurrentGuiController_mapsRotation(0));
                }

            })
    } */

    React.useEffect(() => {

        if (props.mapData !== undefined) {
            /* console.log("####################### API useGetMapQuery LOADED ", data.data); */

            dispatch(setCurrentMapDataController(props.mapData as MapData));
            setprevMapData(props.mapData as MapData);


            if (props.mapFiles !== null && props.mapFiles !== undefined) {
                /* let uf: UploadedFiles = { files: [] };
                data.data.mapData.layerIds.forEach((id: string) => {
                    let fileData: UploadedFile = data.data.files[id] as UploadedFile;
                    uf.files.push(fileData);
                }); */
                dispatch(setCurrentGuiController_mapsAssistant_uploads(props.mapData.layerIds?.length as number))
                dispatch(setCurrentGuiController_mapsFiles(props.mapFiles));
                setprevMapFiles(props.mapFiles);
            }
            else {
                dispatch(setCurrentGuiController_mapsAssistant_uploads(0))
                dispatch(setCurrentGuiController_mapsFiles({
                    files: []
                }));
                dispatch(setCurrentGuiController_mapsGeoCoordinates_A([-1, -1]));
                dispatch(setCurrentGuiController_mapsGeoCoordinates_B([-1, -1]));
                dispatch(setCurrentGuiController_mapsPixelCoordinates_A([-1, -1]));
                dispatch(setCurrentGuiController_mapsPixelCoordinates_B([-1, -1]));
                dispatch(setCurrentGuiController_mapsRotation(0));
            }
        }
    }, [props.mapData]);


    React.useEffect(() => {
        console.log("layerNames changed! => ", layerNames);

    }, [layerNames]);

    const calculateY = (coord: number): number => {
        let num: number = 0;
        if (coord < (4096) && coord >= 0) {
            num = (4096) - coord
        }
        if (coord > (4096)) {
            num = (coord - 4096) * (-1);
        }

        return num;
    }



    const transformLatLonToMercator = (coord: number[]): number[] => {
        let mercCoord: number[] = [0, 0];
        const tempCoord: Coordinate = [coord[0], coord[1]];
        const tempCoord2: Coordinate = transform(tempCoord, 'EPSG:4326', 'EPSG:3857');
        /* console.log("tempCoord: ",tempCoord);
        console.log("tempCoord2: ",tempCoord2); */
        mercCoord[0] = tempCoord2[0];
        mercCoord[1] = tempCoord2[1];
        return mercCoord;
    };

    useEffect(() => {
        if (mapData.layerIds !== undefined) {
            if (mapData.layerNames !== undefined) {
                /* console.log("mapData: ", mapData);
                console.log("layerIds: ", layerIds); */
                if (mapData.layerNames.length > 0) {
                    setTab0passed(true);
                    let simpleLayerNames: string[] = [];
                    let arrayNums: number[] = [];
                    mapData.layerNames.forEach((element, index) => {
                        if (mapData.layerIds !== undefined) {
                            if (mapData.layerIds[index] === "L00" && editedLayerNames.indexOf("Out") === -1) {
                                simpleLayerNames.push("Out");
                                arrayNums.push(index);
                            }
                            else {
                                simpleLayerNames.push(element.shortName[0]);
                                arrayNums.push(index);
                            }
                        }
                    });
                    setLayerArray(arrayNums)
                    dispatch(setCurrentGuiController_editedLayerNames(simpleLayerNames));
                    dispatch(setCurrentGuiController_editedLayerIds(mapData.layerIds));
                    if (props.apiProject.languages.length !== languagesShort.length) {
                        const langShorts: string[] = [];
                        const langLongs: string[] = [];
                        props.apiProject.languages.forEach(element => {
                            langShorts.push(element.code);
                            langLongs.push(element.name);
                        });
                        setLanguagesShort(langShorts);
                        setLanguagesLong(langLongs);
                    }

                    /* mapData. */



                    // dispatch(setCurrentGuiController_mapsAssistant_uploads(3));                
                }
            }
            else {
                dispatch(setCurrentGuiController_editedLayerNames([]));
            }


            if (mapData.layerPgws !== undefined) {
                if (mapData.layerPgws.length > 0) {
                    setTab2passed(true);
                    // dispatch(setCurrentGuiController_mapsPixelCoordinates_A([(mapData.rawPoints?.pointA_imageX as number - (mapsFiles.files[0].png.width / 2)),(calculateY(mapData.rawPoints?.pointA_imageY as number))]));
                    // dispatch(setCurrentGuiController_mapsPixelCoordinates_B([(mapData.rawPoints?.pointB_imageX as number - (mapsFiles.files[0].png.width / 2)),(calculateY(mapData.rawPoints?.pointB_imageY as number))]));
                    dispatch(setCurrentGuiController_mapsPixelCoordinates_A([(mapData.rawPoints?.pointA_imageX as number), (mapData.rawPoints?.pointA_imageY as number)]));
                    dispatch(setCurrentGuiController_mapsPixelCoordinates_B([(mapData.rawPoints?.pointB_imageX as number), (mapData.rawPoints?.pointB_imageY as number)]));
                    const geoMercA: number[] = transformLatLonToMercator([mapData.rawPoints?.pointA_geoLongitude as number, mapData.rawPoints?.pointA_geoLatitude as number]);
                    const geoMercB: number[] = transformLatLonToMercator([mapData.rawPoints?.pointB_geoLongitude as number, mapData.rawPoints?.pointB_geoLatitude as number]);
                    /* console.log("SET mapsGeoA[0] / mapsGeoB[0] : " + geoMercA[0] + " / " + geoMercB[0]); */
                    dispatch(setCurrentGuiController_mapsGeoCoordinates_A([geoMercA[0], geoMercA[1]]));
                    dispatch(setCurrentGuiController_mapsGeoCoordinates_B([geoMercB[0], geoMercB[1]]));
                }
            }
            if (mapData.rotation !== undefined) {
                dispatch(setCurrentGuiController_mapsRotation(mapData.rotation));
            }
            if (mapData.layerNames !== undefined) {
                setselectedLayer((mapData.layerNames as LayerNames[])[0].shortName[0]);
            }
            if (mapData.rawPoints !== undefined) {
                setTab3passed(true);



                if (valueTabs !== 4) {
                    if (previewRenew === true) {
                        dispatch(setCurrentGuiController_valueTabs(4));
                        dispatch(setCurrentGuiController_mapsPreviewRenew(false));
                    }

                    /* setTimeout(() => {
                        dispatch(setCurrentGuiController_valueTabs(4));
                    }, 500); */
                }
            }
        }
        else {
            console.log("Empty MapData, resetting LayerIds / LayerNames");
            /*             const tempArray: string[] = [];
                        props.apiProject.languages.forEach((language: langObject) => {
                            tempArray.push("");
                        }); */

            dispatch(setCurrentGuiController_editedLayerNames([""]));
            dispatch(setCurrentGuiController_editedLayerIds([]));
        }
    }, [mapData]);

    /* useEffect(() => {
        console.log("????????????????? MAPSMANUAL STARTED!!!!!!!");
    }); */

    /* useEffect(() => {
        if (previewRenew === true) {
            dispatch(setCurrentGuiController_mapsPreviewRenew(false));
            dispatch(setCurrentGuiController_valueTabs(3));
            setTimeout(() => {
                dispatch(setCurrentGuiController_valueTabs(4));
            }, 250);
        }
        else {
            // mach nix
        }
    }, [previewRenew]); */


    useEffect(() => {
        /* console.log("mapsPixelA: ", mapsPixelA); 
        console.log("mapsPixelB: ", mapsPixelB); */
        if (mapData.rawPoints !== undefined) {
            dispatch(setCurrentGuiController_mapsStep(8));
        }
    }, [mapsPixelA, mapsPixelB]);

    useEffect(() => {
        if (layerIds !== undefined) {
            if (layerIds.indexOf("L00") >= 0) {
                setSurroundingView(true);
            }
            else {
                setSurroundingView(false);
            }
        }
    }, [layerIds]);

    useEffect(() => {
        setAboveLayer(editedLayerNames[0]);
        setBelowLayer(editedLayerNames[0]);
    }, [editedLayerNames]);

    useEffect(() => {
        if (mapsAssistant_uploads > 0 && mapsAssistant_uploads >= layerIds?.length) {
            setTab1passed(true);
        }
        else {
            setTab1passed(false);
        }
    }, [layerIds, mapsAssistant_uploads])

    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        /* if (newValue === 2) {
            apiProject.languages.forEach(element => {
                langShorts.push(element.code);
                langLongs.push(element.name);
            });
            setLanguagesShort(langShorts);
            setLanguagesLong(langLongs);
        } */
        dispatch(setCurrentGuiController_valueTabs(newValue));

        // setValueTabs(newValue);
        /* console.log("handleChangeTabs: ", newValue);
        console.log("layerArray: ", layerArray); */
        // constructLayerIds();
        /* console.log("editedLayerNames: ", editedLayerNames);
        console.log("editedLayerIds: ", editedLayerIds); */
    };

    const handleChangeFloorsAbove = (value: string) => {
        /* const floorNum: number = parseInt(value); */
        const floorNum: number = layerArray.length + 1;
        let arrayNums: number[] = [];
        console.log("editedLayerNames: ", editedLayerNames);
        console.log("aboveLayer: ", aboveLayer);
        console.log("layerArray: ", layerArray);
        console.log("floorNum: ", floorNum);
        setLayerNumber(floorNum);
        for (let index = 0; index < floorNum; index++) {
            arrayNums.push(index);
        }
        console.log("arrayNums: ", arrayNums);
        setLayerArray(arrayNums);
        editedLayerNames.forEach((floorName, index) => {
            if (floorName === aboveLayer) {
                /* if(index === 0){ */
                let newArr = [...editedLayerNames];
                newArr.splice(index, 0, "");
                dispatch(setCurrentGuiController_editedLayerNames(newArr));
                // seteditedLayerNames(newArr);
                /* } */
            }
        });
        console.log("layerArray: ", layerArray);
        console.log("editedLayerNames: ", editedLayerNames);
    }

    const handleChangeFloorsBelow = (value: string) => {
        /* const floorNum: number = parseInt(value); */
        const floorNum: number = layerArray.length + 1;
        let arrayNums: number[] = [];
        /* console.log("editedLayerNames: ", editedLayerNames);
        console.log("belowLayer: ", belowLayer);
        console.log("layerArray: ", layerArray); */
        setLayerNumber(floorNum);
        for (let index = 0; index < floorNum; index++) {
            arrayNums.push(index);
        }
        /* console.log("arrayNums: ", arrayNums); */
        setLayerArray(arrayNums);
        editedLayerNames.forEach((floorName, index) => {
            if (floorName === belowLayer) {
                /* console.log("WTF"); */
                let newArr = [...editedLayerNames];
                newArr.splice((index + 1), 0, "");
                /* console.log("newArr: ", newArr); */
                dispatch(setCurrentGuiController_editedLayerNames(newArr));
                // seteditedLayerNames(newArr);
            }
        });
        /* console.log("layerArray: ", layerArray);
        console.log("editedLayerNames: ", editedLayerNames); */
    }

    const indexhasErrors = (index: number): boolean | undefined => {
        if (inputErrors.indexOf(index) >= 0) {
            return true;
        }
        return undefined;
    }

    const handleChangeFloorname = (event: React.ChangeEvent<HTMLInputElement>, item: number) => {
        let newArr = [...editedLayerNames];
        if (newArr.length !== layerArray.length) {
            for (let index = 0; index < layerArray.length; index++) {
                newArr[index] = "";
            }
        }
        newArr[item] = event.target.value;
        dispatch(setCurrentGuiController_editedLayerNames(newArr));
        // seteditedLayerNames(newArr);
    };

    const handleChangeFloornameShort = (event: React.ChangeEvent<HTMLInputElement>, entryIndex: number, langIndex: number) => {
        console.log("handleChangeFloornameShort, event.target.value: ", event.target.value);
        if (layerNames !== undefined) {
            let newArr = [...layerNames as LayerNames[]];
            let ln1: LayerNames = newArr[entryIndex];
            let newShort: string[] = [];
            console.log("handleChange A");
            ln1.shortName.forEach((element, index) => {
                if (index !== langIndex) {
                    console.log("handleChange B");
                    newShort.push(element)
                }
                else {
                    console.log("handleChange C");
                    newShort.push(event.target.value);
                }
            });
            const ln = layerNames.map((entry, index) => {
                if (index !== entryIndex) {
                    console.log("handleChange D");
                    return entry;
                }
                else {
                    console.log("handleChange E");
                    return {
                        ...entry,
                        shortName: newShort,
                    }
                }
            });
            console.log("handleChange ln:", ln);
            dispatch(setCurrentMapDataController_layerNames(ln));
        }
    };

    const handleChangeFloornameLong = (event: React.ChangeEvent<HTMLInputElement>, entryIndex: number, langIndex: number) => {
        if (layerNames !== undefined) {
            let newArr = [...layerNames as LayerNames[]];
            let ln1: LayerNames = newArr[entryIndex];
            let newLong: string[] = [];
            ln1.longName.forEach((element, index) => {
                if (index !== langIndex) {
                    newLong.push(element)
                }
                else {
                    newLong.push(event.target.value);
                }
            });
            const ln = layerNames.map((entry, index) => {
                if (index !== entryIndex) {
                    return entry;
                }
                else {
                    return {
                        ...entry,
                        longName: newLong,
                    }
                }
            });
            dispatch(setCurrentMapDataController_layerNames(ln));
        }


        // dispatch(setCurrentGuiController_editedLayerNames(newArr));
        // seteditedLayerNames(newArr);
    };

    const getShortFloorName = (entryIndex: number, langIndex: number): string => {
        let shortName: string = "";
        if (layerNames !== undefined) {
            const layerNamesObject = layerNames as LayerNames[];
            shortName = layerNamesObject[entryIndex].shortName[langIndex]
        }

        // (mapData.layerNames[index] as LayerNames).shortName[indexLang]
        return shortName;
    }

    const getLongFloorName = (entryIndex: number, langIndex: number): string => {
        let shortName: string = "";
        if (layerNames !== undefined) {
            const layerNamesObject = layerNames as LayerNames[];
            shortName = layerNamesObject[entryIndex].longName[langIndex]
        }

        // (mapData.layerNames[index] as LayerNames).shortName[indexLang]
        return shortName;
    }





    const handleManualDeleteFloor = (item: number) => {
        console.log("handleManualDeleteFloor called");
        console.log("pre editedLayerNames", editedLayerNames);
        let backupArray: number[] = [...layerArray];
        let layerNames: string[] = [...editedLayerNames];
        /* for (let index = 0; index < layerArray.length; index++) {
            backupArray[index] = layerArray[index];
        } */
        const foundIndex = layerArray.indexOf(item);
        /*         console.log("item: ", item);
                console.log("backupArray: ", backupArray);
                console.log("index: ", foundIndex); */
        if (foundIndex > -1) { // only splice array when item is found
            backupArray.splice((backupArray.length - 1), 1); // 2nd parameter means remove one item only
            /* layerArray.splice(foundIndex, 1); */
            layerNames.splice(foundIndex, 1);
            dispatch(setCurrentGuiController_editedLayerNames(layerNames));
            // seteditedLayerNames(layerNames);
            setLayerArray(backupArray);
            console.log("layerArray: ", backupArray);
            console.log("editedLayerNames: ", layerNames);
        }
    }

    const determinAllNamed = (): boolean => {
        let allNamed: boolean = false;
        // console.log("editedLayerNames: ", editedLayerNames);
        if (editedLayerNames.length > 0) {
            editedLayerNames.forEach(element => {
                try {
                    if (element.length === 0) {
                        allNamed = true;
                    }
                } catch (error) {
                    // mach nix
                }
            });
        }
        return allNamed;
    }

    const handleAddNewFloor = () => {
        setNewLayer(true);
    }

    const handleManualQuit = () => {
        console.log("handleManualQuit called");
        dispatch(setCurrentGuiController_mapsAssistant(false));
        dispatch(setCurrentGuiController_mapsManualSetup(false));
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const handleChangeCustomLayerIDStart = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomLayerIDStart(event.target.value);
    }

    const handleAboveLayerChange = (event: SelectChangeEvent) => {
        setAboveLayer(event.target.value as string);
    }

    const handleBelowLayerChange = (event: SelectChangeEvent) => {
        setBelowLayer(event.target.value as string);
    }

    const handleCancelAddNewLayer = () => {
        setNewLayer(false);
    }

    const handleConfirmAddNewLayer = () => {
        if (selectedValue === "a") {
            // layerArray
            handleChangeFloorsAbove((layerNumber + 1).toString());
        }
        if (selectedValue === "b") {
            handleChangeFloorsBelow((layerNumber + 1).toString());
        }

        setNewLayer(false);
    }

    interface StyledTabProps {
        label: string;
        disabled?: boolean;
    }


    const determinDisabledSave = (): boolean => {
        let bDisabled: boolean = false;
        if (valueTabs === 1) {
            if (props.mapFiles !== undefined) {
                if (props.mapData.layerIds !== undefined) {
                    if (props.mapFiles.files.length > 0 && props.mapFiles.files.length === props.mapData.layerIds.length) {
                        bDisabled = false;
                    }
                    else {
                        bDisabled = true;
                    }
                }
                else {
                    bDisabled = true;
                }
            }
            else {
                bDisabled = true;
            }

        }
        return bDisabled
    }

    const handleManualNext = () => {
        console.log("handleManualNext called");
        console.log("valueTabs: ", valueTabs);
        console.log("mapsStep: ", mapsStep);
        console.log("mapData: ", mapData);
        if (valueTabs === 0) {
            // Etagen-Setup
            constructLayerIds();

        }
        if (valueTabs === 1) {
            // Karten Upload            
            dispatch(setCurrentGuiController_valueTabs(valueTabs + 1));
        }
        if (valueTabs === 2) {
            // Übersetzungen
            setTab2passed(true);
            // dispatch(setCurrentMapDataController_layerNames());
            dispatch(setCurrentGuiController_valueTabs(valueTabs + 1));

            let mapUpdateData: MapData = structuredClone(mapData);
            mapUpdateData.layerNames = layerNames;
            saveUpdatesToMap(mapUpdateData);
            /* saveChangesToMap(); */
        }
        if (valueTabs === 3 && mapsStep < 8) {
            // Georeferenzierung

            /* setMapsStep(counter + 1) */
            let mapUpdateData: MapData = structuredClone(mapData);
            mapUpdateData.rotation = mapRotation;
            saveUpdatesToMap(mapUpdateData);

            let counter: number = mapsStep;
            dispatch(setCurrentGuiController_mapsStep(counter + 1));
            /* saveChangesToMap(); */
        }
        if (valueTabs === 3 && mapsStep === 8) {
            // Georeferenzierung DONE
            /* saveChangesToMap(); */
            let mapUpdateData: MapData = structuredClone(mapData);
            mapUpdateData.rotation = mapRotation;
            saveUpdatesToMap(mapUpdateData);
            setTab3passed(true);
            dispatch(setCurrentGuiController_valueTabs(valueTabs + 1));
        }
        if (valueTabs === 4 && mapsStep === 8) {
            saveChangesToMap();
        }
    }

    const StyledTab = styled((props: StyledTabProps) => (
        <Tab {...props} />
    ))(({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 1.0)',
        '&.Mui-selected': {
            color: '#e1e77b',
        }
    }));

    const determinHeadline = (): string => {
        let headline: string = ""
        switch (valueTabs) {
            case 0:
                headline = t('tools.maps.floorsetup_1');
                break;
            case 1:
                headline = t('tools.maps.floorsetup_3');
                break;
            case 2:
                headline = t('tools.maps.floorsetup_4');
                break;
            case 3:
                headline = t('tools.maps.floorsetup_5');
                break;
            case 4:
                headline = t('tools.maps.floorsetup_9');
                break;
            default:
                headline = "No translation found";
                break;
        }
        return headline;
    }

    const handleRotationSliderChange = (event: Event, newValue: number | number[]) => {
        /* setRotationValue(newValue); */
        /* let mapUpdateData: MapData = structuredClone(mapData);
        mapUpdateData.rotation = Number(newValue);
        saveUpdatesToMap(mapUpdateData); */
        dispatch(setCurrentMapDataController_rotation(Number(newValue)));
    };

    const handleRotationInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setRotationValue(event.target.value === '' ? '' : Number(event.target.value));
        /* let mapUpdateData: MapData = structuredClone(mapData);
        mapUpdateData.rotation = Number(event.target.value);
        saveUpdatesToMap(mapUpdateData); */
        dispatch(setCurrentMapDataController_rotation(Number(event.target.value)));
    };

    const handleBlur = () => {
        if (Number(mapRotation) < -360) {
            /* setRotationValue(0); */
            dispatch(setCurrentMapDataController_rotation(-360))
        } else if (Number(mapRotation) > 360) {
            dispatch(setCurrentMapDataController_rotation(360))
        }
    };

    const handleRasterChange = () => {
        if (rasterView === 0) {
            setRasterView(1);
        }
        else {
            setRasterView(0);
        }
    }

    const handleSurroundingViewChange = () => {
        if (surroundingView === false) {
            setSurroundingView(true);
        }
        else {
            setSurroundingView(false);
        }
    }



    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (param: string) => {
        setAnchorEl(null);
    };

    const handleImageSelect = (name: string, index: number) => {
        setAnchorEl(null);
        /* console.log(name + " selected"); */
        /* setrastermapimage(imgsrc); */
        setselectedLayer(name);
        dispatch(setCurrentGuiController_mapsAssistant_layerImages_index(index));
        /* setMenuTitleProj(id + " - " + name);
        dispatch(setCurrentGuiController_currentProject(id)); */
    }

    const handlePreviewLayerChange = (index: number) => {
        /* console.log("props.mapData.layerNames: ", props.mapData.layerNames);
        console.log("props.apiProject.languages: ", props.apiProject.languages); */
        setselectedLayerIndex(index);
    }

    const getLayerShortName = (index: number): string => {
        let layerShortName: string = "";
        let germanIndexPosition: number = -1;
        let englishIndexPosition: number = -1;
        let selectedLanguageIndex: number = -1;
        if (props.mapData.layerNames !== undefined) {
            const langObjectList: langObject[] = props.apiProject.languages;
            const shortNameList: string[] = props.mapData.layerNames[index].shortName;
            langObjectList.forEach((element, indexPos) => {
                if (element.code === "de") {
                    germanIndexPosition = indexPos;
                }
                if (element.code === "en") {
                    englishIndexPosition = indexPos;
                }
            });
            if (germanIndexPosition !== -1) {
                selectedLanguageIndex = germanIndexPosition;
            }
            if (germanIndexPosition === -1 && englishIndexPosition !== -1) {
                selectedLanguageIndex = englishIndexPosition;
            }
            if (germanIndexPosition === -1 && englishIndexPosition === -1) {
                selectedLanguageIndex = 0;
            }
            layerShortName = shortNameList[selectedLanguageIndex]
        }
        return layerShortName;
    }

    /* const toGeoCords = (mercCords: [number, number]) => {
        let lonlat = transform(mercCords, 'EPSG:3857', 'EPSG:4326');
        let geoCords: [number, number] = [lonlat[0], lonlat[1]];
        return geoCords;
    } */


    const handleChangeSetGeoALon = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMapsGeoALon(parseFloat(event.target.value));
    }

    const handleChangeSetGeoALat = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMapsGeoALat(parseFloat(event.target.value));
    }

    const handleChangeSetGeoBLon = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMapsGeoBLon(parseFloat(event.target.value));
    }

    const handleChangeSetGeoBLat = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMapsGeoBLat(parseFloat(event.target.value));
    }

    const handleChangeSetPixelA_x = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMapsPixelA_x(parseFloat(event.target.value));
    }

    const handleChangeSetPixelA_y = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMapsPixelA_y(parseFloat(event.target.value));
    }

    const handleChangeSetPixelB_x = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMapsPixelB_x(parseFloat(event.target.value));
    }

    const handleChangeSetPixelB_y = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMapsPixelB_y(parseFloat(event.target.value));
    }

    React.useEffect(() => {
        const coords: [number, number] = [mapsPixelB_x, mapsPixelB_y];
        setMapsPixelBToSet(coords);
    }, [mapsPixelB_x, mapsPixelB_y]);

    React.useEffect(() => {
        const coords: [number, number] = [mapsPixelA_x, mapsPixelA_y];
        setMapsPixelAToSet(coords);
    }, [mapsPixelA_x, mapsPixelA_y]);

    React.useEffect(() => {
        const preCoords: number[] = transformLatLonToMercator([mapsGeoBLon, mapsGeoBLat]);
        setMapsGeoBToSet([preCoords[0], preCoords[1]]);
    }, [mapsGeoBLon, mapsGeoBLat]);

    React.useEffect(() => {
        const preCoords: number[] = transformLatLonToMercator([mapsGeoALon, mapsGeoALat]);
        setMapsGeoAToSet([preCoords[0], preCoords[1]]);
    }, [mapsGeoALon, mapsGeoALat]);



    /* const handleChangeSetGeo_x_a = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setCommentValue(event.target.value);
        const temp: number[] = event.target.value.split(",").map(Number);
        const coords: [number, number] = [temp[0], temp[1]];
        const preCoords: number[] = transformLatLonToMercator(coords);
        setMapsGeoAToSet([preCoords[0], preCoords[1]]);
    };

    const handleChangeSetGeo_x_b = (event: React.ChangeEvent<HTMLInputElement>) => {
        const temp: number[] = event.target.value.split(",").map(Number);
        const coords: [number, number] = [temp[0], temp[1]];
        const preCoords: number[] = transformLatLonToMercator(coords);
        setMapsGeoBToSet([preCoords[0], preCoords[1]]);
    };

    const setGeoA = () => {
        if (mapsGeoAToSet[0] !== -1 && mapsGeoBToSet[1] !== -1) {

            const pointsExtent: Extent = boundingExtent([mapsGeoAToSet, mapsGeoBToSet]);
            const pointsCenter: Coordinate = getCenter(pointsExtent);

            if (mapRotation) {
                const rotatedGeoA: [number, number] = rotateAroundCenter(mapsGeoAToSet, pointsCenter, (mapRotation * -1));
                const rotatedGeoB: [number, number] = rotateAroundCenter(mapsGeoBToSet, pointsCenter, (mapRotation * -1));
                dispatch(setCurrentGuiController_mapsGeoCoordinates_A(rotatedGeoA));
                dispatch(setCurrentGuiController_mapsGeoCoordinates_B(rotatedGeoB));
            }
        }
    } */



    const setGeoB = () => {
        console.log("mapsGeoAToSet: ", mapsGeoAToSet);
        console.log("mapsGeoBToSet: ", mapsGeoBToSet);
        if (mapsGeoAToSet[0] !== -1 && mapsGeoBToSet[1] !== -1) {
            const pointsExtent: Extent = boundingExtent([mapsGeoAToSet, mapsGeoBToSet]);
            const pointsCenter: Coordinate = getCenter(pointsExtent);

            if (mapRotation) {
                const rotatedGeoA: [number, number] = rotateAroundCenter(mapsGeoAToSet, pointsCenter, (mapRotation * -1));
                const rotatedGeoB: [number, number] = rotateAroundCenter(mapsGeoBToSet, pointsCenter, (mapRotation * -1));
                dispatch(setCurrentGuiController_mapsGeoCoordinates_A(rotatedGeoA));
                dispatch(setCurrentGuiController_mapsGeoCoordinates_B(rotatedGeoB));

                refreshLocMap();
            }
        }
    }

    /* const handleChangeSetPixel_x_a = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setCommentValue(event.target.value);
        const temp: number[] = event.target.value.split(",").map(Number);
        const coords: [number, number] = [temp[0], temp[1]];
        setMapsPixelAToSet(coords);
    };

    const setPixelA = () => {
        dispatch(setCurrentGuiController_mapsPixelCoordinates_A(mapsPixelAToSet));
    }

    const handleChangeSetPixel_x_b = (event: React.ChangeEvent<HTMLInputElement>) => {
        const temp: number[] = event.target.value.split(",").map(Number);
        const coords: [number, number] = [temp[0], temp[1]];
        setMapsPixelBToSet(coords);
    }; */

    const setPixelB = () => {
        console.log("mapsPixelAToSet: ", mapsPixelAToSet);
        console.log("mapsPixelBToSet: ", mapsPixelBToSet);
        dispatch(setCurrentGuiController_mapsPixelCoordinates_A(mapsPixelAToSet));
        dispatch(setCurrentGuiController_mapsPixelCoordinates_B(mapsPixelBToSet));
        refreshRefMap();
    }

    const handleChangeAdvanced = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdvancedPos(event.target.checked);
    };


    const rotateAroundCenter = (coord: [number, number], center: Coordinate, angle: number): [number, number] => {


        const piDiv180: number = Math.PI / 180;
        const r = angle * piDiv180;

        const x: number = coord[1];
        const y: number = coord[0];
        const mx: number = center[1]
        const my: number = center[0];

        const ox: number = x - mx;
        const oy: number = y - my;

        const cosr: number = Math.cos(r);
        const sinr: number = Math.sin(r);

        const dx = ox * cosr - oy * sinr;
        const dy = ox * sinr + oy * cosr;

        let rotatedCoord: [number, number] = [dy + my, dx + mx];

        return rotatedCoord
    }

    return (
        <Box>
            <Tabs
                value={valueTabs}
                onChange={handleChangeTabs}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="Maps menu"
                sx={{ ml: '2vh' }}
            >

                <StyledTab label={t('tools.maps.floorsetup_1')} />
                {tab0Passed === false ?
                    <StyledTab disabled label={t('tools.maps.floorsetup_3')} />
                    :
                    <StyledTab label={t('tools.maps.floorsetup_3')} />
                }
                {tab1Passed === false ?
                    <StyledTab disabled label={t('tools.maps.floorsetup_4')} />
                    :
                    <StyledTab label={t('tools.maps.floorsetup_4')} />
                }
                {tab2Passed === false ?
                    <StyledTab disabled label={t('tools.maps.floorsetup_5')} />
                    :
                    <StyledTab label={t('tools.maps.floorsetup_5')} />
                }
                {tab3Passed === false ?
                    <StyledTab disabled label={t('tools.maps.floorsetup_9')} />
                    :
                    <StyledTab label={t('tools.maps.floorsetup_9')} />
                }
            </Tabs>
            <Box sx={{ pl: '2vh', mt: '1vh', position: 'relative', maxWidth: '108rem', minHeight: '10rem', width: 'fit-content' }}>
                {valueTabs < 4
                    ?
                    <Paper elevation={6} sx={{ padding: '1.4em', minHeight: '10rem', minWidth: '75vh', backgroundImage: 'unset', display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '0.5vh', maxHeight: '60rem', alignItems: 'flex-start' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                    <Typography gutterBottom variant="h4" align="left" component="div" sx={{
                                        pl: '2vh', color: '#fff', mb: 0
                                    }}>
                                        {determinHeadline()}
                                    </Typography>
                                    <CloseIcon cursor='pointer' sx={{ fontSize: '1.8rem' }} onClick={handleManualQuit}></CloseIcon>
                                </Box>
                                <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                    pl: '2vh', opacity: '0.6'
                                }}>
                                    {valueTabs === 0 ? t('tools.maps.setLayerShorts') : null}
                                    {valueTabs === 2 ? null : null}
                                    {valueTabs === 3 ? null : null}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                                    mt: '0vh', minWidth: '75vh', maxHeight: '67vh', overflowY: 'auto',
                                    '&::-webkit-scrollbar': {
                                        width: '0.4em'
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        boxShadow: 'inset 0 0 6px rgba(255,255,255,0.64)',
                                        webkitBoxShadow: 'inset 0 0 6px rgba(255,255,255,0.64)'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: 'white',
                                        outline: '1px solid white'
                                    }
                                }}>
                                {valueTabs === 0
                                    ?
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Box sx={{ display: 'flex', width: '35vh', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                                pl: '2vh',
                                            }}>
                                                {t('tools.maps.floorname')}
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                                pl: '2vh',
                                            }}>
                                                {t('tools.maps.visible')}
                                            </Typography>
                                        </Box>
                                        {layerArray.map((item, index) => {
                                            return (
                                                /* (layerIds.length > 0) && (layerIds[index] !== "L00")
                                                    ? */
                                                <Box key={"floorListVlue_" + item} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {index === 0 && index !== (layerArray.length - 1)
                                                        ?
                                                        <CssTextField
                                                            error={indexhasErrors(item)}
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="floor_edit1"
                                                            label={t('tools.maps.topfloor')}
                                                            name="firstName"
                                                            autoComplete=""
                                                            variant="standard"
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFloorname(event, item) }}
                                                            value={editedLayerNames[item] === undefined ? "" : editedLayerNames[item]}
                                                        /* value={firstNameValue} */
                                                        />
                                                        : null
                                                    }
                                                    {index !== 0 && index !== (layerArray.length - 1)
                                                        ?
                                                        <CssTextField
                                                            /* {...indexhasErrors(item) && ({error})} */
                                                            error={indexhasErrors(item)}
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="floor_edit2"
                                                            label={t('tools.maps.floor')}
                                                            name="firstName"
                                                            autoComplete=""
                                                            variant="standard"
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFloorname(event, item) }}
                                                            value={editedLayerNames[item]}
                                                        /* value={firstNameValue} */
                                                        />
                                                        : null
                                                    }
                                                    {index === (layerArray.length - 1)
                                                        ?
                                                        <CssTextField
                                                            error={indexhasErrors(item)}
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="floor_edit3"
                                                            label={t('tools.maps.lowfloor')}
                                                            name="firstName"
                                                            autoComplete=""
                                                            variant="standard"
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFloorname(event, item) }}
                                                            value={editedLayerNames[item]}
                                                        /* value={firstNameValue} */
                                                        />
                                                        : null
                                                    }
                                                    <Switch color='success' sx={{
                                                        ml: '6vh',
                                                        mt: '2vh'
                                                    }} defaultChecked />
                                                    {layerArray.length > 1
                                                        ?
                                                        <DeleteIcon cursor='pointer' sx={{
                                                            ml: '7vh',
                                                            mt: '2vh'
                                                        }} onClick={() => { handleManualDeleteFloor(item) }}></DeleteIcon>
                                                        :
                                                        null
                                                    }

                                                </Box>
                                                /* :
                                                null */
                                            );
                                        })}
                                        <Button variant="outlined" color="secondary" startIcon={<AddIcon />} size="small"
                                            /* disabled={editedLayerNames[0].length === 0 ? true : false} */
                                            disabled={determinAllNamed()}
                                            sx={{
                                                /* backgroundColor: 'primary.contrastText', */
                                                ml: '2vh',
                                                mt: '1vh',
                                                color: 'primary.contrastText',
                                                width: 'fit-content',
                                                '&:hover': {
                                                    color: 'primary.contrastText',
                                                },
                                            }}
                                            onClick={handleAddNewFloor}>
                                            {t('tools.maps.floormanual_1')}
                                        </Button>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '4vh' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                                    pl: '2vh', opacity: '0.6'
                                                }}>
                                                    {t('tools.maps.surrounding')}
                                                </Typography>
                                                <InfoIcon sx={{ ml: '1vh', mt: '0.4vh', fontSize: '20px' }}></InfoIcon>
                                            </Box>
                                            <Switch color='success' sx={{
                                                ml: '1vh'
                                            }}
                                                checked={surroundingView}
                                                onChange={handleSurroundingViewChange}
                                            />
                                            <CssTextField
                                                /* error={indexhasErrors(item)} */
                                                margin="normal"
                                                fullWidth
                                                id="customID_edit1"
                                                label={t('tools.maps.customLayerId')}
                                                name="customLayerID"
                                                autoComplete=""
                                                variant="standard"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeCustomLayerIDStart(event) }}
                                                value={customLayerIDStart}
                                            /* value={firstNameValue} */
                                            />
                                        </Box>
                                        {newLayer === true
                                            ?
                                            <Backdrop
                                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}
                                                open
                                            >
                                                <Box sx={{ backgroundColor: 'black', padding: '4vh', display: 'flex', flexDirection: 'column' }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                                        <Typography gutterBottom variant="h5" align="left" component="div">
                                                            {t('tools.maps.floormanual_2a')}
                                                        </Typography>
                                                        <CloseIcon cursor='pointer' sx={{ fontSize: '1.8rem' }} onClick={handleCancelAddNewLayer}></CloseIcon>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '3vh' }}>
                                                        <Radio
                                                            checked={selectedValue === 'a'}
                                                            color='secondary'
                                                            onChange={handleRadioChange}
                                                            value="a"
                                                            name="radio-buttons"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            sx={{ mb: '0.5vh' }}
                                                        />
                                                        <Typography gutterBottom variant="h6" align="left" component="div" sx={{ lineHeight: '1.8vh' }}>
                                                            {t('tools.maps.floormanual_2b')}
                                                        </Typography>
                                                        <FormControl variant="standard" color='secondary' sx={{ minWidth: 120, ml: '2vh', mb: '2vh' }} size='small'>
                                                            <InputLabel id="demo-simple-select-standard-label">{t('tools.maps.floor')}</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={aboveLayer}
                                                                label={t('tools.maps.floor')}
                                                                onChange={handleAboveLayerChange}
                                                            >
                                                                {editedLayerNames.map((item, index) => {
                                                                    return (
                                                                        <MenuItem key={"layername_below_" + index} value={item}>{item}</MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                        <Typography gutterBottom variant="h6" align="left" component="div" sx={{ ml: '2vh' }}>
                                                            {t('tools.maps.floormanual_2d')}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '0vh' }}>
                                                        <Radio
                                                            checked={selectedValue === 'b'}
                                                            color='secondary'
                                                            onChange={handleRadioChange}
                                                            value="b"
                                                            name="radio-buttons"
                                                            inputProps={{ 'aria-label': 'B' }}
                                                            sx={{ mb: '0.5vh' }}
                                                        />
                                                        <Typography gutterBottom variant="h6" align="left" component="div" sx={{ lineHeight: '1.8vh' }}>
                                                            {t('tools.maps.floormanual_2c')}
                                                        </Typography>
                                                        <FormControl variant="standard" color='secondary' sx={{ minWidth: 120, ml: '2vh', mb: '2vh' }} size='small'>
                                                            <InputLabel id="demo-simple-select-standard-label">{t('tools.maps.floor')}</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={belowLayer}
                                                                label="Etage"
                                                                onChange={handleBelowLayerChange}
                                                            >

                                                                {editedLayerNames.map((item, index) => {
                                                                    return (
                                                                        <MenuItem key={"layername_below_" + index} value={item}>{item}</MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                        <Typography gutterBottom variant="h6" align="left" component="div" sx={{ ml: '2vh' }}>
                                                            {t('tools.maps.floormanual_2d')}
                                                        </Typography>
                                                    </Box>
                                                    <CssWhiteButton
                                                        type="submit"
                                                        /* fullWidth */
                                                        variant="contained"
                                                        onClick={handleConfirmAddNewLayer}
                                                        sx={{
                                                            /* position: 'absolute',
                                                            bottom: '4vh',
                                                            left: '7vh', */
                                                            mt: '2.5vh',
                                                            backgroundColor: 'primary.contrastText',
                                                            color: 'primary.main',
                                                            width: 'fit-content',
                                                            '&:hover': {
                                                                color: 'primary.contrastText',
                                                            },
                                                        }}
                                                    >
                                                        {valueTabs < 8 ? t('tools.maps.finished') : t('tools.maps.proceed')}
                                                        {valueTabs < 8
                                                            ?
                                                            <NavigateNextIcon sx={{
                                                                marginLeft: '6px'
                                                            }}></NavigateNextIcon>
                                                            :
                                                            <SaveIcon sx={{
                                                                marginLeft: '6px'
                                                            }}></SaveIcon>
                                                        }

                                                    </CssWhiteButton>
                                                </Box>
                                            </Backdrop>
                                            :
                                            null
                                        }
                                    </Box>
                                    :
                                    null
                                }
                                {valueTabs === 1
                                    ?
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        {mapData.layerNames !== undefined
                                            ?
                                            mapData.layerNames?.map((layername, index) => {
                                                return (
                                                    <Box key={"floorMapUploader_" + index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '0.5vh', justifyContent: 'center' }}>
                                                        <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                                            pl: '2vh', width: '20vh'
                                                        }}>
                                                            {layername.shortName[0]}
                                                        </Typography>
                                                        <MapsUploadElement uploadProp={{ layernameString: layername.shortName[0], layerId: editedLayerIds[index], layerNamesArray: editedLayerNames, mapUuid: props.apiProject.mapUuid as string, requeryMap: props.requeryMap, mapFiles: props.mapFiles }}></MapsUploadElement>
                                                    </Box>
                                                );
                                            })
                                            :
                                            mapDataCreate.layerNames?.map((layername, index) => {
                                                return (
                                                    <Box key={"floorMapUploader_" + index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '0.5vh', justifyContent: 'center' }}>
                                                        <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                                            pl: '2vh', width: '20vh'
                                                        }}>
                                                            {layername.shortName[0]}
                                                        </Typography>
                                                        <MapsUploadElement uploadProp={{ layernameString: layername.shortName[0], layerId: editedLayerIds[index], layerNamesArray: editedLayerNames, mapUuid: props.apiProject.mapUuid as string, requeryMap: props.requeryMap, mapFiles: props.mapFiles }}></MapsUploadElement>
                                                    </Box>
                                                );
                                            })
                                        }
                                    </Box>
                                    :
                                    null
                                }
                                {valueTabs === 2
                                    ?
                                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: '2.5vh', maxHeight: '35rem', maxWidth: '108rem', alignItems: 'flex-start' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', maxWidth: '108rem', mb: '2.5vh' }}>
                                            <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                                pl: '2vh', opacity: '0.6'
                                            }}>
                                                {t('tools.maps.floorsetup_4_heading')}
                                            </Typography>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', pl: '0vh', maxHeight: '30rem', maxWidth: '103rem', overflowY: 'auto', overflowX: 'auto',
                                            '&::-webkit-scrollbar': {
                                                width: '0.4em',
                                                height: '0.4em'
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                boxShadow: 'inset 0 0 0.4em rgba(255,255,255,0.64)',
                                                webkitBoxShadow: 'inset 0 0 0.4em rgba(255,255,255,0.64)'
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'white',
                                                /* outline: '1px solid white' */
                                            }
                                        }}>
                                            <Box sx={{ zIndex: '50', transform: 'translateY(-0.8vh) translateX(7vh)', position: 'sticky', top: 0, width: 0 }}>
                                                <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                                    pl: '2vh', fontWeight: 'bold'
                                                }}>
                                                    {t('tools.maps.floorsetup_4_short')}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ position: 'absolute', backgroundColor: '#092935', width: '7vh', height: '6vh', zIndex: '100' }}>
                                            </Box>
                                            <Box sx={{ position: 'sticky', left: 0, backgroundColor: '#092935', zIndex: 20, height: '100%' }}>
                                                {editedLayerNames.map((item, index) => {
                                                    return (
                                                        <Box key={item + "_" + index}>
                                                            {index === 0 ?
                                                                <Box sx={{ minWidth: '7vh', height: '4vh', mb: '1vh', mt: '5vh' }}>
                                                                    <Typography gutterBottom variant="subtitle1" component="div" sx={{
                                                                        fontWeight: 'bold', pt: '0.5vh', mb: '0'
                                                                    }}>
                                                                        {item}
                                                                    </Typography>
                                                                </Box>
                                                                :
                                                                <Box sx={{ minWidth: '7vh', height: '4vh', mb: '1vh' }}>
                                                                    <Typography gutterBottom variant="subtitle1" component="div" sx={{
                                                                        fontWeight: 'bold', pt: '0.5vh', mb: '0'
                                                                    }}>
                                                                        {item}
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                        </Box>
                                                    );
                                                })
                                                }
                                            </Box>
                                            {languagesShort.map((itemLang, indexLang) => {
                                                return (
                                                    <Box key={itemLang + "_" + indexLang} sx={{ ml: '1vh', minWidth: '7vh', display: 'flex', flexDirection: 'column' }}>
                                                        <Box sx={{ minWidth: '7.05vh', height: '4vh', mb: '1vh', position: 'sticky', top: 0, backgroundColor: '#092935', zIndex: 30 }}>
                                                            <Typography gutterBottom variant="subtitle2" component="div" align="left" sx={{
                                                                fontWeight: 'bold', pt: '0.5vh', pl: '1vh', mb: '0', opacity: 0.6, transform: 'translateY(1.2vh)'
                                                            }}>
                                                                {itemLang.toLocaleUpperCase()}
                                                            </Typography>
                                                        </Box>
                                                        {/* {editedLayerNames.map((item, index) => { */}
                                                        {editedLayerNames.map((item, index) => {
                                                            return (
                                                                <TextField key={item + "_names_" + index} id="filled-basic" hiddenLabel /* defaultValue={item} */ value={getShortFloorName(index, indexLang)} size="small" variant="filled" sx={{ mb: '1vh', minWidth: '7vh', maxWidth: '14vh', maxHeight: '4vh' }}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFloornameShort(event, index, indexLang) }}></TextField>
                                                            );
                                                        })
                                                        }
                                                    </Box>
                                                );
                                            })}
                                            <Box sx={{ minWidth: '7vh', height: '100%', backgroundColor: 'red' }}>
                                            </Box>
                                            <Box sx={{ zIndex: '50', transform: 'translateY(-0.8vh) translateX(-1vh)', position: 'sticky', top: 0, width: 0 }}>
                                                <Typography gutterBottom variant="subtitle1" align="left" component="div" sx={{
                                                    pl: '2vh', fontWeight: 'bold'
                                                }}>
                                                    {t('tools.maps.floorsetup_4_long')}
                                                </Typography>
                                            </Box>
                                            {languagesLong.map((languageString, indexLang) => {
                                                return (
                                                    <Box key={languageString + "_languageString_" + indexLang} sx={{ mr: '1vh', minWidth: '19vh', maxWidth: '19vh' }}>
                                                        <Box sx={{ minWidth: '19vh', maxWidth: '19vh', height: '4vh', mb: '1vh', position: 'sticky', top: 0, backgroundColor: '#092935', zIndex: 10 }}>
                                                            <Typography gutterBottom variant="subtitle2" component="div" align="left" sx={{
                                                                fontWeight: 'bold', pt: '0.5vh', pl: '1vh', mb: '0', opacity: 0.6, transform: 'translateY(1.2vh)'
                                                            }}>
                                                                {languageString.toLocaleUpperCase()}
                                                            </Typography>
                                                        </Box>
                                                        {editedLayerNames.map((item, index) => {
                                                            return (
                                                                <TextField key={item + "_layerNames_" + index} id="filled-basic" hiddenLabel /* defaultValue={item} */ value={getLongFloorName(index, indexLang)} size="small" variant="filled" sx={{ mb: '1vh', minWidth: '19vh', maxHeight: '4vh' }}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFloornameLong(event, index, indexLang) }}></TextField>
                                                            );
                                                        })
                                                        }
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                    : null
                                }
                                {valueTabs === 3
                                    ?
                                    <Box sx={{ width: 'auto', display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', top: '1vh', ml: '2vh' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', top: '1vh', ml: '2vh' }}>
                                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', ml: '0vh', mb: '0.5vh' }}>
                                                    <Slider sx={{ color: '#24a3d3', ml: '2vh', mr: '4vh', width: '40vh' }} step={1} onChange={handleRotationSliderChange} marks={marks} value={typeof mapRotation === 'number' ? mapRotation : 0} min={-360} max={360} aria-label="Default" valueLabelDisplay="auto" />
                                                    <Input
                                                        value={mapRotation}
                                                        size="small"
                                                        onChange={handleRotationInputChange}
                                                        onBlur={handleBlur}
                                                        inputProps={{
                                                            min: -360,
                                                            max: 360,
                                                            type: 'number',
                                                            'aria-labelledby': 'input-slider',
                                                        }}
                                                        sx={{ width: '10vh' }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                                <FormControlLabel
                                                    value="top"
                                                    control={<Switch
                                                        color='success'
                                                        onChange={handleRasterChange}
                                                    />}
                                                    label="Raster"
                                                    labelPlacement="start"
                                                />
                                                <Box>
                                                    <Button
                                                        id="basic-button"
                                                        aria-controls={open ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={handleClick}
                                                        sx={{
                                                            marginLeft: '0',
                                                            color: 'primary.contrastText',
                                                            /* backgroundColor: 'info.main', */
                                                            textTransform: 'unset !important',
                                                            fontSize: '1em',
                                                            fontWeight: 'normal',
                                                        }}
                                                    >
                                                        {/* {userStatus.firstName} */}
                                                        {selectedLayer}
                                                        {open ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
                                                    </Button>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                            sx: {
                                                                backgroundColor: 'info.main',
                                                                paddingBottom: '0px !important'
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            props.mapFiles.files.map((element: UploadedFile, index: number) => {
                                                                /* console.log("WTFdfgsdf", element); */
                                                                return <div key={(mapData.layerNames as LayerNames[])[index].shortName[0] + "_index"}>
                                                                    <Divider sx={{
                                                                        borderColor: 'primary.contrastText',
                                                                        marginTop: '0px !important',
                                                                        marginBottom: '0px !important',
                                                                    }}></Divider>
                                                                    <MenuItem onClick={() => handleImageSelect((mapData.layerNames as LayerNames[])[index].shortName[0], index)}>{(mapData.layerNames as LayerNames[])[index].shortName[0]}</MenuItem>
                                                                </div>

                                                            })
                                                        }
                                                    </Menu>
                                                </Box>

                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography gutterBottom align="left" component="div" sx={{
                                                fontSize: '16px',
                                                pb: '0.1em',
                                                pl: '0.5em',
                                                pr: '0.5em',
                                                ml: '268px',
                                                mt: '0.2em',
                                                transform: 'translateX(-50%)',
                                                position: 'absolute',
                                                zIndex: 1,
                                                backgroundColor: '#092935',
                                                borderRadius: '6px 6px 6px 6px'
                                            }}>
                                                OpenStreetMap
                                            </Typography>
                                            <LocatorMap key={"locmapkey_" + locMapKey} locatorProp={{ assistNumber: mapsStep, mapObject: mapData }}></LocatorMap>
                                            {rasterView === 1
                                                ?
                                                <img src={RasterOverlay} alt='previewRaster' height={"500px"} width={'auto'} style={{ position: 'absolute', pointerEvents: 'none', marginLeft: '2vh' }}></img>
                                                : null
                                            }
                                            <Box sx={{ ml: "1em", backgroundColor: "#bbbbbb" }}>
                                                {/* <img src={rastermapimage} alt='preview' height={"500px"} width={'auto'}></img> */}
                                                <Typography gutterBottom align="left" component="div" sx={{
                                                    fontSize: '16px',
                                                    pb: '0.1em',
                                                    pl: '0.5em',
                                                    pr: '0.5em',
                                                    ml: '250px',
                                                    mt: '0.2em',
                                                    transform: 'translateX(-50%)',
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    backgroundColor: '#092935',
                                                    borderRadius: '6px 6px 6px 6px'
                                                }}>
                                                    User map
                                                </Typography>
                                                <ReferenceMap key={"refmapkey_" + refMapKey} referenceProp={{ assistNumber: mapsStep, mapObject: props.mapData, mapFiles: props.mapFiles }}></ReferenceMap>
                                            </Box>
                                            {/* <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', pl: '0.5vw', width: '36vw', top: 0, right: '-44em', backgroundColor: '#092935', }}>
                                                <Typography gutterBottom align="left" component="div" sx={{
                                                    pl: '2vh',
                                                    pt: '0.5vh',
                                                    fontSize: '16px'
                                                }}>
                                                    AS 2 Values:
                                                </Typography>
                                                <Box sx={{ display: 'flex', width: '35vw', justifyContent: 'space-around' }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '10em' }}>
                                                        <AS2TextField
                                                            margin="normal"
                                                            label="point-a-image-x"
                                                            size="small"
                                                            defaultValue={0}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                        <AS2TextField
                                                            margin="normal"
                                                            label="point-a-image-y"
                                                            size="small"
                                                            defaultValue={0}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '10em' }}>
                                                        <AS2TextField
                                                            margin="normal"
                                                            label="point-a-geo-longitude"
                                                            size="small"
                                                            defaultValue={toGeoCords(mapsGeoA)}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                        <AS2TextField
                                                            margin="normal"
                                                            label="point-a-geo-latitude"
                                                            size="small"
                                                            defaultValue={toGeoCords(mapsGeoA)}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '10em' }}>
                                                        <AS2TextField
                                                            margin="normal"
                                                            label="point-b-image-x"
                                                            size="small"
                                                            defaultValue={8192}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                        <AS2TextField
                                                            margin="normal"
                                                            label="point-b-image-y"
                                                            size="small"
                                                            defaultValue={8192}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '10em' }}>
                                                        <AS2TextField
                                                            margin="normal"
                                                            label="point-b-geo-longitude"
                                                            size="small"
                                                            defaultValue={toGeoCords(mapsGeoA)}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                        <AS2TextField
                                                            margin="normal"
                                                            label="point-b-geo-latitude"
                                                            size="small"
                                                            defaultValue={toGeoCords(mapsGeoA)}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                    </Box>
                                                </Box>

                                            </Box> */}
                                            <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', pl: '0.5vw', width: '36vw', top: 0, right: '-44em', backgroundColor: '#092935', }}>
                                                <FormControlLabel labelPlacement="end" control={<Checkbox color='default' aria-label='active'
                                                    checked={advancedPos}
                                                    onChange={handleChangeAdvanced}
                                                    sx={{
                                                        color: 'rgba(255,255,255,1)'
                                                    }}
                                                ></Checkbox>} label={t("tools.maps.showAdvancedPos")} aria-required />
                                                {advancedPos === true ?
                                                    <Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                            <Box>
                                                                <Typography gutterBottom align="left" component="div" sx={{
                                                                    pl: '2vh', fontSize: '18px', mt: '1.3em', mb: '1em'
                                                                }}>
                                                                    {t("tools.maps.advGeo")}
                                                                </Typography>
                                                                <Typography gutterBottom align="left" component="div" sx={{
                                                                    pl: '2vh', fontSize: '16px'
                                                                }}>
                                                                    {t("tools.maps.setGeoA")}
                                                                </Typography>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '31em' }}>
                                                                    <AS2TextField
                                                                        margin="normal"
                                                                        label="point-a-geo-longitude"
                                                                        size="small"
                                                                        onChange={handleChangeSetGeoALon}
                                                                    />
                                                                    <AS2TextField
                                                                        margin="normal"
                                                                        label="point-a-geo-latitude"
                                                                        size="small"
                                                                        onChange={handleChangeSetGeoALat}
                                                                    />
                                                                </Box>
                                                                <Typography gutterBottom align="left" component="div" sx={{
                                                                    pl: '2vh', fontSize: '16px'
                                                                }}>
                                                                    {t("tools.maps.setGeoB")}
                                                                </Typography>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '31em' }}>
                                                                    <AS2TextField
                                                                        margin="normal"
                                                                        label="point-b-geo-longitude"
                                                                        size="small"
                                                                        onChange={handleChangeSetGeoBLon}
                                                                    />
                                                                    <AS2TextField
                                                                        margin="normal"
                                                                        label="point-b-geo-latitude"
                                                                        size="small"
                                                                        onChange={handleChangeSetGeoBLat}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ display: 'flex' }}>
                                                                <CssWhiteButton
                                                                    type="submit"
                                                                    /* fullWidth */
                                                                    size="small"
                                                                    variant="contained"
                                                                    onClick={setGeoB}
                                                                    sx={{
                                                                        height: '8.9em',
                                                                        ml: '0.5em',
                                                                        mt: '8.0em',
                                                                        backgroundColor: 'primary.contrastText',
                                                                        color: 'primary.main',
                                                                        width: '11.5em',
                                                                        '&:hover': {
                                                                            color: 'primary.contrastText',
                                                                        },
                                                                    }}
                                                                >
                                                                    {t("tools.maps.setGeoCoords")}
                                                                </CssWhiteButton>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: '2em' }}>
                                                            <Box>
                                                                <Typography gutterBottom align="left" component="div" sx={{
                                                                    pl: '2vh', fontSize: '18px', mb: '1em'
                                                                }}>
                                                                    {t("tools.maps.advPx")}
                                                                </Typography>
                                                                <Typography gutterBottom align="left" component="div" sx={{
                                                                    pl: '2vh', fontSize: '16px'
                                                                }}>
                                                                    {t("tools.maps.setPxA")}
                                                                </Typography>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '31em' }}>
                                                                    <AS2TextField
                                                                        margin="normal"
                                                                        label="point-a-image-x"
                                                                        size="small"
                                                                        onChange={handleChangeSetPixelA_x}
                                                                    />
                                                                    <AS2TextField
                                                                        margin="normal"
                                                                        label="point-a-image-y"
                                                                        size="small"
                                                                        onChange={handleChangeSetPixelA_y}
                                                                    />
                                                                </Box>
                                                                <Typography gutterBottom align="left" component="div" sx={{
                                                                    pl: '2vh', fontSize: '16px'
                                                                }}>
                                                                    {t("tools.maps.setPxB")}
                                                                </Typography>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '31em' }}>
                                                                    <AS2TextField
                                                                        margin="normal"
                                                                        label="point-b-image-x"
                                                                        size="small"
                                                                        onChange={handleChangeSetPixelB_x}
                                                                    />
                                                                    <AS2TextField
                                                                        margin="normal"
                                                                        label="point-b-image-y"
                                                                        size="small"
                                                                        onChange={handleChangeSetPixelB_y}
                                                                    />
                                                                </Box>
                                                            </Box>

                                                            <Box sx={{ display: 'flex' }}>
                                                                <CssWhiteButton
                                                                    type="submit"
                                                                    size="small"
                                                                    variant="contained"
                                                                    onClick={setPixelB}
                                                                    sx={{
                                                                        height: '8.9em',
                                                                        ml: '1.5em',
                                                                        mt: '6.2em',
                                                                        mb: '1em',
                                                                        backgroundColor: 'primary.contrastText',
                                                                        color: 'primary.main',
                                                                        width: '11.5em',
                                                                        '&:hover': {
                                                                            color: 'primary.contrastText',
                                                                        },
                                                                    }}
                                                                >
                                                                    {t("tools.maps.setPxCoords")}
                                                                </CssWhiteButton>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    : null
                                                }

                                            </Box>
                                        </Box>
                                    </Box>
                                    :
                                    null
                                }
                                {valueTabs === 3
                                    ?
                                    <Box sx={{ width: 'auto', display: 'flex', flexDirection: 'column' }}>
                                    </Box>
                                    :
                                    null
                                }
                                <CssWhiteButton
                                    type="submit"
                                    /* fullWidth */
                                    disabled={determinDisabledSave()}
                                    variant="contained"
                                    onClick={handleManualNext}
                                    sx={{
                                        /* position: 'absolute',
                                        bottom: '4vh',
                                        left: '7vh', */
                                        mt: '2.5vh',
                                        ml: '2vh',
                                        backgroundColor: 'primary.contrastText',
                                        color: 'primary.main',
                                        width: 'fit-content',
                                        '&:hover': {
                                            color: 'primary.contrastText',
                                        },
                                    }}
                                >

                                    {valueTabs < 3 ? t('tools.maps.save_proceed') : null}

                                    {valueTabs < 3
                                        ?
                                        <SaveIcon sx={{
                                            marginLeft: '6px'
                                        }}></SaveIcon>
                                        :
                                        null
                                    }
                                    {/* {valueTabs === 3 && mapsStep < 8 ? t('tools.maps.proceed') : null} */}
                                    {valueTabs === 3 && mapsStep === 6 ? t('tools.maps.place_A') : null}
                                    {valueTabs === 3 && mapsStep === 7 ? t('tools.maps.place_B') : null}
                                    {valueTabs === 3 && mapsStep < 8
                                        ?
                                        <AddLocationAltIcon sx={{
                                            marginLeft: '6px'
                                        }}></AddLocationAltIcon>
                                        :
                                        null
                                    }
                                    {valueTabs === 3 && mapsStep === 8 ? t('tools.maps.save_preview') : null}
                                    {valueTabs === 3 && mapsStep === 8
                                        ?
                                        <SaveIcon sx={{
                                            marginLeft: '6px'
                                        }}></SaveIcon>
                                        :
                                        null
                                    }
                                </CssWhiteButton>
                            </Box>
                        </Box>

                    </Paper>
                    :
                    <Box sx={{ width: '93vw', height: '100%', ml: 0 }}>
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 10, top: '50%', left: '2%', transform: 'translateY(-50%)' }}>
                                {editedLayerNames.map((item, index) => {
                                    return (
                                        <Button variant="contained" color={selectedLayerIndex === index ? 'secondary' : 'primary'} key={"buttonFloor_" + index}
                                            sx={{ mb: '0.5em', borderRadius: '100%', minWidth: '3em', width: '3.5em', height: '3.5em', padding: '0.5em' }}
                                            onClick={() => handlePreviewLayerChange(index)}>{getLayerShortName(index)}</Button>
                                    );
                                })}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '17em', position: 'absolute', zIndex: 10, backgroundColor: '#092935', bottom: '2.5em', left: '1.7em' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography variant="h5" gutterBottom align='left' sx={{
                                        pt: '0', pl: '1vh', pb: '0vh'
                                    }}>{getLayerShortName(selectedLayerIndex)}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                        pt: '0', pl: '1vh', pb: '0vh', pr: '3em'
                                    }}>{t("tools.maps.visible")}</Typography>
                                    <CheckIcon />
                                </Box>

                            </Box>
                            {/* <PreviewMap mapIndex={selectedLayerIndex} apiProject={apiProject}></PreviewMap> */}
                            <PrevMap mapIndex={selectedLayerIndex} apiProject={props.apiProject} mapData={prevMapData as MapData} mapFiles={prevMapFiles as UploadedFiles} setMainMapData={props.setMainMapData} requeryMap={props.requeryMap}></PrevMap>
                        </Box>

                    </Box>
                }
            </Box>
            {/* <Paper>
                <Typography gutterBottom variant="h5" align="left" component="div">
                    {t('tools.maps.floorsetup_1')}
                </Typography>
            </Paper> */}
        </Box>
    );
}