import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
/* import translation_en from './en/translation_en.json';
import translation_de from './de/translation_de.json'; */

export const resources = {
    en: {
        translation: {
            title: "EasyGuide Creator",
            sidebar: {
                entry1: "Maps",
                entry1_tooltip_heading: "Maps",
                entry1_tooltip_text: "Description in small font",
                entry2: "Routes",
                entry2_tooltip_heading: "Routes",
                entry2_tooltip_text: "Description in small font",
                entry3: "Areas",
                entry3_tooltip_heading: "Areas",
                entry3_tooltip_text: "Description in small font",
                entry4: "Destinations",
                entry4_tooltip_heading: "Destinations",
                entry4_tooltip_text: "Description in small font",
                entry5: "Signage",
                entry5_tooltip_heading: "Signage",
                entry5_tooltip_text: "Description in small font",
                entry6: "Home screen",
                entry6_tooltip_heading: "Home screen",
                entry6_tooltip_text: "Description in small font",
                entry7: "Style",
                entry7_tooltip_heading: "Style",
                entry7_tooltip_text: "Description in small font",
                entry8: "Deployment",
                entry8_tooltip_heading: "Deployment",
                entry8_tooltip_text: "Description in small font",
                admin_entry1: "Users",
                admin_entry1_tooltip_heading: "Users",
                admin_entry1_tooltip_text: "Description in small font",
                admin_entry2: "Projects",
                admin_entry2_tooltip_heading: "Projects",
                admin_entry2_tooltip_text: "Description in small font",
                admin_entry3: "Roles",
                admin_entry3_tooltip_heading: "Roles",
                admin_entry3_tooltip_text: "Description in small font",
                admin_entry4: "Groups",
                admin_entry4_tooltip_heading: "Groups",
                admin_entry4_tooltip_text: "Description in small font",
            },
            signin: {
                mail: "Email Ad­res­s",
                pw: "Password",
                forgot: "Forgot password?",
                login: "Login"
            },
            signout: {
                heading: "You were logged out successfully",
                subtitle: "Do you want to login again?"
            },
            drpdwnmn: {
                profile: "Profile",
                account: "My account",
                logout: "Logout",
                login: "Login"
            },
            drpdwnmnproj: {
                title: "Select project"
            },
            drpdwnmnmarket: {
                title: "Select industry"
            },
            dashboard: {
                greeting: "Hello",
                select: "Select a project to start.",
                changes: "these are the recent changes in the project:",
                changesAdmin: "these are the recent changes in the different projects:"
            },
            tools: {
                heading_1: "You are exiting the",
                heading_2: " tool",
                subtitle: "Unsaves changes will be lost! Continue?",
                save: "Yes",
                nosave: "No, discard changes",
                cancel: "Cancel",
                maps: {
                    setup_assistant: "Setup assistant",
                    stepbystep: "Set up maps step-by-step",
                    start: "Start",
                    wo_assistant: "Continue without assistant",
                    floorsetup_1: "Floor setup",
                    floorsetup_2: "Floor setup",
                    floorsetup_3: "Upload maps",
                    floorsetup_3_note_1: "Caution!",
                    floorsetup_3_note_2: "Maps have to be",
                    floorsetup_3_note_3: "- in png format",
                    floorsetup_3_note_4: "- square with a resolution of at least 8192x8192px or more",
                    floorsetup_3_note_5: "- contain the surroundings",
                    floorsetup_3_note_6: "- aligned on top of each other perfectly (escalators and stairs especially)",
                    floorsetup_3_note_7: "- destinations for routing have to be inside the inner circle",
                    floorsetup_3_note_8: "Do not show this again",
                    floorsetup_3_note_9: "understood",
                    floorsetup_3_note_image_1: "non visible area",
                    floorsetup_3_note_image_2: "surroundings",
                    floorsetup_3_note_image_3: "area relevant for routing",
                    floorsetup_3_note_image_4: "(buildings and POIs)",
                    floorsetup_4: "Translations",
                    floorsetup_4_heading: "Add labels & translations to the floors",
                    floorsetup_4_short: "short form",
                    floorsetup_4_long: "long form",
                    floorsetup_5: "Georeference",
                    floorsetup_5_heading: "Where is the building located?",
                    floorsetup_6: "Georeference",
                    floorsetup_6_heading: "Rotate the map to the same orientation",
                    floorsetup_7: "Georeference",
                    floorsetup_7_heading: "Move the A markers to the same position on both maps",
                    floorsetup_8: "Georeference",
                    floorsetup_8_heading: "Move the B markers to the same position on both maps",
                    floorsetup_9: "Preview",
                    floormanual_1: "Add new floor",
                    floormanual_2a: "Add new floor",
                    floormanual_2b: "above",
                    floormanual_2c: "below",
                    floormanual_2d: "",
                    howmanyfloors: "How many floors has the building?",
                    surrounding: "Show surrounding seperately?",
                    customLayerId: "custom Layer-Start ID:",
                    proceed: "Proceed",
                    finished: "Done",
                    setLayerShorts: "Set the short names of the floors",
                    floorname: "FLOORNAME",
                    visible: "VISIBLE",
                    topfloor: "Highest floor",
                    lowfloor: "Lowest floor",
                    floor: "Floor",
                    uploadmaps: "Upload the maps for all floors",
                    uploadmaps_pngerror: "Please select an png image file",
                    uploadmaps_sizeerror: "File size is too large (> 100MB)",
                    uploadmaps_reserror: "Wrong image resolution",
                    save_proceed: "Save and proceed",
                    place_A: "place A Marker",
                    place_B: "place B Marker",
                    save_preview: "Save and show preview",
                    save_geo: "Save Georeferencing",
                    as2Values: "AS2 values",
                    showAs2Values: "Show AS2 values",
                    showAdvancedPos: "Show advanced positioning",
                    advGeo: "Geo-coordinates in decimal longitude and latitude values",
                    setGeoA: "Set geo-Point A (longitude,latitude):",
                    setGeoB: "Set geo-Point B (longitude,latitude):",
                    setGeoCoords: "Set geo coordinates",
                    advPx: "Pixel-coordinates in integers (0 to 8192 allowed)",
                    setPxA: "Set Pixel-Point A (usually 0,0):",
                    setPxB: "Set Pixel-Point B (usually 8192,8192):",
                    setPxCoords: "Set pixel coordinates",
                },
                deployment: {
                    changed: "changed",
                    tiles_gen: "generate tiles",
                    beta_tiles: "update beta tiles",
                    live_tiles: "update live tiles",
                    open: "open"
                }
            },
            admin_users: {
                title: "Users",
                add_user: "add new user",
                delete_user: "delete user",
                delete_user_really: "Really delete user?",
                delete_user_really_text: "The deletion of users is irreversible!",
                first_name: "First name",
                surname: "surname",
                mail: "E-mail",
                role: "Role(s)",
                projects: "Project(s)",
                company: "Company",
                groups: "Group(s)",
                active: "Active",
                description: "description",
                language: "language",
                pw_repeat: "repeat password",
                save: "Save",
                cancel: "Cancel"
            },
            admin_projects: {
                title: "Projects",
                title_edit: "Edit Project",
                add_project: "add new project",
                projectId: "Projectno.",
                active: "Active",
                groups: "Group(s)",
                name: "Name",
                shortName: "Short name",
                description: "Description",
                comment: "Comment",
                contact: "Contact",
                market: "Industry",
                demogallery: "Demo-Gallery",
                demogallery_public: "Demo-Gallery public",
                technical_contact: "technical contakt",
                kiosk_retail: "easyGuide kiosk retail",
                kiosk_business: "easyGuide kiosk business",
                custom_development: "custom development",
                mobile: "easyGuide mobile",
                web: "easyGuide web",
                print: "easyGuide print",
                engine: "mapsEngine",
                carfinder: "carFinder",
                addons: "Addons",
                country: "Country",
                city: "City",
                hasService: "Service active",
                url: "Url",
                save: "save",
                cancel: "cancel",
                products: "Products",
                prod_active: "Active products",
                prod_inactive: "Inactive products",
                addons_active: "Active add-ons",
                addons_inactive: "Inactive add-ons",
                opening_time: "Opening time",
                closing_time: "Closing time",
                languages: "Languages",
                timezone: "Timezone",
            }
        }
    },
    de: {
        translation: {
            title: "EasyGuide Creator",
            sidebar: {
                entry1: "Karten",
                entry1_tooltip_heading: "Karten",
                entry1_tooltip_text: "Beschreibung in kleiner Schrift",
                entry2: "Wege",
                entry2_tooltip_heading: "Wege",
                entry2_tooltip_text: "Beschreibung in kleiner Schrift",
                entry3: "Flächen",
                entry3_tooltip_heading: "Flächen",
                entry3_tooltip_text: "Beschreibung in kleiner Schrift",
                entry4: "Ziele",
                entry4_tooltip_heading: "Ziele",
                entry4_tooltip_text: "Beschreibung in kleiner Schrift",
                entry5: "Beschilderung",
                entry5_tooltip_heading: "Beschilderung",
                entry5_tooltip_text: "Beschreibung in kleiner Schrift",
                entry6: "Startbildschirm",
                entry6_tooltip_heading: "Startbildschirm",
                entry6_tooltip_text: "Beschreibung in kleiner Schrift",
                entry7: "Style",
                entry7_tooltip_heading: "Style",
                entry7_tooltip_text: "Beschreibung in kleiner Schrift",
                entry8: "Veröffentlichung",
                entry8_tooltip_heading: "Veröffentlichung",
                entry8_tooltip_text: "Beschreibung in kleiner Schrift",
                admin_entry1: "Benutzer",
                admin_entry1_tooltip_heading: "Benutzer",
                admin_entry1_tooltip_text: "Beschreibung in kleiner Schrift",
                admin_entry2: "Projekte",
                admin_entry2_tooltip_heading: "Projekte",
                admin_entry2_tooltip_text: "Beschreibung in kleiner Schrift",
                admin_entry3: "Rollen",
                admin_entry3_tooltip_heading: "Rollen",
                admin_entry3_tooltip_text: "Beschreibung in kleiner Schrift",
                admin_entry4: "Gruppen",
                admin_entry4_tooltip_heading: "Gruppen",
                admin_entry4_tooltip_text: "Beschreibung in kleiner Schrift"
            },
            signin: {
                mail: "E-Mail-Ad­res­se",
                pw: "Passwort",
                forgot: "Passwort vergessen?",
                login: "Anmelden"
            },
            signout: {
                heading: "Du wurdest erfolgreich abgemeldet",
                subtitle: "Willst Du Dich wieder anmelden?",
                login: "Zur Anmeldung"
            },
            drpdwnmn: {
                profile: "Profil",
                account: "Mein Account",
                logout: "Abmelden"
            },
            drpdwnmnproj: {
                title: "Projekt auswählen"
            },
            drpdwnmnmarket: {
                title: "Branche auswählen"
            },
            dashboard: {
                greeting: "Hallo",
                select: "Wähle ein Projekt aus, um zu starten.",
                changes: "hier sind die letzen Änderungen im Projekt:",
                changesAdmin: "hier sind die letzen Änderungen in den Projekten:"
            },
            tools: {
                heading_1: "Du verlässt das",
                heading_2: "",
                subtitle: "Nicht gespeicherte Änderungen gehen verloren! Fortfahren?",
                save: "Fortfahren",
                nosave: "Nein, Verwerfen",
                cancel: "Abbrechen",
                maps: {
                    setup_assistant: "SETUP-ASSISTENT",
                    stepbystep: "Karten Schritt-für-Schritt aufsetzen",
                    start: "Starten",
                    wo_assistant: "Weiter ohne Assistenten",
                    floorsetup_1: "Etagen-Setup",
                    floorsetup_2: "Etagen-Setup",
                    floorsetup_3: "Karten-Upload",
                    floorsetup_3_note_1: "Achtung!",
                    floorsetup_3_note_2: "Karten müssen",
                    floorsetup_3_note_3: "- PNGs sein",
                    floorsetup_3_note_4: "- quadratisch und mindestens auf 8192x8192px oder mehr angelegt sein",
                    floorsetup_3_note_5: "- bereits die Umgebung enthalten",
                    floorsetup_3_note_6: "- perfekt übereinander liegen (besonders Aufzüge und Treppen)",
                    floorsetup_3_note_7: "- routingrelevante Ziele müssen sich im inneren Kreisausschnitt befinden",
                    floorsetup_3_note_8: "Nicht wieder anzeigen",
                    floorsetup_3_note_9: "verstanden",
                    floorsetup_3_note_image_1: "nicht sichtbarer Bereich",
                    floorsetup_3_note_image_2: "Umgebung",
                    floorsetup_3_note_image_3: "routingrelevanter Bereich",
                    floorsetup_3_note_image_4: "(Gebäude und POIs)",
                    floorsetup_4: "Übersetzungen",
                    floorsetup_4_heading: "Füge Bezeichnungen & Übersetzungen zu den Etagen hinzu",
                    floorsetup_4_short: "Kurzform",
                    floorsetup_4_long: "Langform",
                    floorsetup_5: "Georeferenz",
                    floorsetup_5_heading: "Wo befindet sich das Gebäude?",
                    floorsetup_6: "Georeferenz",
                    floorsetup_6_heading: "Drehe die Karte in die selbe Ausrichtung",
                    floorsetup_7: "Georeferenz",
                    floorsetup_7_heading: "Bringe die A-Marker auf die gleiche Position auf beiden Karten",
                    floorsetup_8: "Georeferenz",
                    floorsetup_8_heading: "Bringe die B-Marker auf die gleiche Position auf beiden Karten",
                    floorsetup_9: "Vorschau",
                    floormanual_1: "Neue Etage hinzufügen",
                    floormanual_2a: "Neue Etage soll",
                    floormanual_2b: "über",
                    floormanual_2c: "unter",
                    floormanual_2d: "eingefügt werden",
                    howmanyfloors: "Wie viele Etagen hat das Gebäude?",
                    surrounding: "Umgebung separat anzeigen?",
                    customLayerId: "eigene Layer-Start ID:",
                    proceed: "Weiter",
                    finished: "Fertig",
                    setLayerShorts: "Lege die Kurzbezeichnungen der Etagen fest",
                    floorname: "ETAGENNAME",
                    visible: "SICHTBAR",
                    topfloor: "Oberste Etage",
                    lowfloor: "Unterste Etage",
                    floor: "Etage",
                    uploadmaps: "Lade die Karten zu allen Etagen hoch",
                    uploadmaps_pngerror: "Bitte eine png-Datei auswählen",
                    uploadmaps_sizeerror: "Die Datei ist zu groß (> 100MB)",
                    uploadmaps_reserror: "Die Datei hat eine falsche Auflösung",
                    save_proceed: "Speichern und fortfahren",
                    place_A: "A-Marker platzieren",
                    place_B: "B-Marker platzieren",
                    save_preview: "Speichern und Vorschau ansehen",
                    save_geo: "Georeferenzierung speichern",
                    as2Values: "AS2 Werte",
                    showAs2Values: "AS2 Werte anzeigen",
                    showAdvancedPos: "Numerische Positionierung anzeigen",
                    advGeo: "Geo-Koordinaten in dezimalen Längen- und Breitengraden",
                    setGeoA: "Geo-Punkt A setzen (Längengrad,Breitengrad):",
                    setGeoB: "Geo-Punkt B setzen (Längengrad,Breitengrad):",
                    setGeoCoords: "Geo Koordinaten übernehmen",
                    advPx: "Pixel-Koordinaten in ganzen Zahlen (0 bis 8192)",
                    setPxA: "Pixel-Punkt A (meist 0,0):",
                    setPxB: "Pixel-Punkt B (meist 8192,8192):",
                    setPxCoords: "Pixel Koordinaten übernehmen"
                },
                deployment: {
                    changed: "zuletzt am",
                    tiles_gen: "Tiles generieren",
                    beta_tiles: "Beta Tiles aktualisieren",
                    live_tiles: "Live Tiles aktualisieren",
                    open: "Öffnen"
                }
            },
            admin_users: {
                title: "Benutzer",
                add_user: "Neuen Benutzer hinzufügen",
                delete_user: "Benutzer löschen",
                delete_user_really: "Benutzer wirklich löschen?",
                delete_user_really_text: "Das Löschen von Benutzern kann nicht rückgängig gemacht werden!",
                first_name: "Vorname",
                surname: "Nachname",
                mail: "E-mail",
                role: "Rolle(n)",
                projects: "Projekt(e)",
                company: "Unternehmen",
                groups: "Gruppe(n)",
                active: "Aktiv",
                description: "Beschreibung",
                language: "Sprache",
                pw_repeat: "Passwort wiederholen",
                save: "Speichern",
                cancel: "Abbrechen"
            },
            admin_projects: {
                title: "Projekte",
                title_edit: "Projekt editieren",
                add_project: "Neues Projekt hinzufügen",
                projectId: "Projektnr.",
                active: "aktiv",
                groups: "Gruppen",
                name: "Name",
                shortName: "Kurzname",
                description: "Beschreibung",
                comment: "Kommentar",
                contact: "Kontakt",
                market: "Branche",
                demogallery: "Demo-Gallery",
                demogallery_public: "Demo-Gallery public",
                technical_contact: "Technischer Kontakt",
                kiosk_retail: "easyGuide kiosk retail",
                kiosk_business: "easyGuide kiosk business",
                custom_development: "Kundenspezifische Entwicklung",
                mobile: "easyGuide mobile",
                web: "easyGuide web",
                print: "easyGuide print",
                engine: "mapsEngine",
                carfinder: "carFinder",
                addons: "Add-ons",
                country: "Land",
                city: "Stadt",
                hasService: "Service aktiv",
                url: "Url",
                save: "Speichern",
                cancel: "Abbrechen",
                products: "Produkte",
                prod_active: "Aktive Produkte",
                prod_inactive: "Inaktive Produkte",
                addons_active: "Aktive Add-ons",
                addons_inactive: "Inaktive Add-ons",
                opening_time: "Öffnungszeit",
                closing_time: "Schließzeit",
                languages: "Sprachen",
                timezone: "Zeitzone",
            }
        }
    }
};

let lang: string = (navigator.language).substring(0, 2);
if (lang !== "de") {
    lang = "en";
}

i18next.use(initReactI18next).init({
    /* lng: 'en', */ // if you're using a language detector, do not define the lng option
    lng: lang,
    debug: false,
    resources: resources,
    preload: ['de', 'en'],
    /* ns: ['translation_de', 'translation_en'] */
});