import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LayerNames, LayerPgw, MapData, RawPoints, SvgToGeo, X3dToSvg, transformNode } from '../../interfaces/interfaceGuiController'

// Define a type for the slice state
interface MapDataController {
    value: MapData
}

// Define the initial state using that type
const initialState: MapDataController = {
    value: {
        transform: {
            x3dToSvg: undefined,
            svgToGeo: undefined
        },
        rawPoints: undefined,
        layerIds: [],
        layerNames: undefined,
        layerPgws: undefined,
        rotation: undefined
    }
}


export const currentMapDataControllerSlice = createSlice({
    name: 'currentMapDataController',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setCurrentMapDataController: (state, action: PayloadAction<MapData>) => {
            state.value = action.payload;
        },
        setCurrentMapDataController_transformNode: (state, action: PayloadAction<transformNode>) => {
            state.value.transform = action.payload;
        },
        setCurrentMapDataController_x3dToSvg: (state, action: PayloadAction<X3dToSvg>) => {
            state.value.transform.x3dToSvg = action.payload;
        },
        setCurrentMapDataController_svgToGeo: (state, action: PayloadAction<SvgToGeo>) => {
            state.value.transform.svgToGeo = action.payload;
        },
        setCurrentMapDataController_layerIds: (state, action: PayloadAction<string[]>) => {
            state.value.layerIds = action.payload;
        },
        setCurrentMapDataController_layerNames: (state, action: PayloadAction<LayerNames[]>) => {
            state.value.layerNames = action.payload;
        },
        setCurrentMapDataController_layerPgws: (state, action: PayloadAction<LayerPgw[]>) => {
            state.value.layerPgws = action.payload;
        },
        setCurrentMapDataController_rawPoints: (state, action: PayloadAction<RawPoints>) => {
            state.value.rawPoints = action.payload;
        },
        setCurrentMapDataController_rotation: (state, action: PayloadAction<number>) => {
            state.value.rotation = action.payload;
        },
        resetCurrentMapDataController: () => initialState
    },
})

export const { setCurrentMapDataController } = currentMapDataControllerSlice.actions
export const { setCurrentMapDataController_transformNode } = currentMapDataControllerSlice.actions
export const { setCurrentMapDataController_x3dToSvg } = currentMapDataControllerSlice.actions
export const { setCurrentMapDataController_svgToGeo } = currentMapDataControllerSlice.actions
export const { setCurrentMapDataController_layerIds } = currentMapDataControllerSlice.actions
export const { setCurrentMapDataController_layerNames } = currentMapDataControllerSlice.actions
export const { setCurrentMapDataController_layerPgws } = currentMapDataControllerSlice.actions
export const { setCurrentMapDataController_rawPoints } = currentMapDataControllerSlice.actions
export const { setCurrentMapDataController_rotation } = currentMapDataControllerSlice.actions
export const { resetCurrentMapDataController } = currentMapDataControllerSlice.actions

// Other code such as selectors can use the imported `RootState` type

export default currentMapDataControllerSlice.reducer