import React from 'react'
/* import Avatar from '@mui/material/Avatar'; */
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
/* import LockOutlinedIcon from '@mui/icons-material/LockOutlined'; */
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import mainlogoLarge from '../res/svg/creatorMainLogo_large.svg';
import { useAppDispatch, useAppSelector } from './hooks';
// import { setGuiStatus } from './slices/guiStatusSlice';
// import { setCurrentUserStatus } from './slices/currentUserSlice';
/* import { setCurrentProject } from './slices/currentProjectSlice'; */
import { setCurrentGuiController_guistatus } from './slices/guiControllerSlice';
import { setCurrentGuiController_currentUser } from './slices/guiControllerSlice';
import { setCurrentGuiController_currentProject } from './slices/guiControllerSlice';
/* import { setCurrentRoleStatus } from './slices/currentRoleSlice'; */
import '../i18n/config';
import { useTranslation } from 'react-i18next';
import { User } from '../interfaces/interfaceUser';
/* import { ConvertRole, Role } from '../interfaces/interfaceRole'; */
import { useGetAuthCookieQuery, useGetLoginMutation, useLazyGetMeQuery } from './slices/apiSlice'




function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://3d-berlin.com/">
                3d-berlin vr solutions GmbH
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
});

const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
        color: '#ffffff',
    },
});




export default function SignIn() {
    // const guistatus = useAppSelector((state) => state.guistatus.value);
    /* const userStatus = useAppSelector((state) => state.userstatus.value); */
    const currentUser = useAppSelector((state) => state.guicontroller.value.currentUser);
    const guiController = useAppSelector((state) => state.guicontroller.value);
    const [loginSuccess, setloginSuccess] = React.useState(false);
    const [loginFail, setloginFail] = React.useState(false);
    const [mailValue, setMailValue] = React.useState("");
    const [pwValue, setPwValue] = React.useState("");
    const { error, isLoading, isSuccess } = useGetAuthCookieQuery('');
    /* const { data, fulfilledTimeStamp } = useGetMeQuery(''); */
    const [getLogin] = useGetLoginMutation();
    const [getMeCall] = useLazyGetMeQuery();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    /*     const [incrementAmount, setIncrementAmount] = useState('2');
        const incrementValue = Number(incrementAmount) || 0; */

    const handleChangeMail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailValue(event.target.value);
    };

    const handleChangePW = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPwValue(event.target.value);
    };

    React.useEffect(() => {
        /* console.log("UseEffect guistatus: " + guistatus); */
        if (guiController.guiStatus === "logout") {
            setloginSuccess(false);
            setloginFail(false);
            setMailValue("");
            setPwValue("");
            /* dispatch(setCurrentProject(0)); */
            dispatch(setCurrentGuiController_currentProject(0));
        }
    }, [guiController.guiStatus, dispatch]);

    React.useEffect(() => {
        const fetcher = () => {
            if (currentUser.uuid !== "0") {
                /* const userRole: string = currentUser.role;
                return fetch("./Role_" + userRole + ".json"
                    , {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    })
                    .then(async (response) => {
                        if (response.ok) {
                            const responseString: string = (await response.text());
                            const userRole: Role = ConvertRole.toRole(responseString);
                            dispatch(setCurrentRoleStatus(userRole));
                        } else {
                            console.log("Could not load role json!");
                        }
                    }) */
            }

        }
        fetcher();

        /* console.log("UseEffect userStatus: " + userStatus);
        checkRole(); */
    }, [currentUser, dispatch]);


    const handleSubmitLogout = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // dispatch(setGuiStatus("login"));
        dispatch(setCurrentGuiController_guistatus("login"));
    }

    const loadProfile = async () => {
        callMeInfo();
        /* fetch("./bg.json"
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(async (response) => {
                if (response.ok) {
                    const responseString: string = (await response.text());
                    const loggedUser: User = ConvertUser.toUser(responseString);
                    dispatch(setCurrentUserStatus(loggedUser));
                    dispatch(setCurrentGuiController_currentUser(loggedUser));
                } else {
                    console.log("Could not load user json!");
                }
            }) */
    }

    const callMeInfo = async () => {
        await getMeCall("")
            .then((response: any) => {
                /* console.log("getMeCall response: ", response); */
                if (response.isError === true) {
                    // mach nix
                }
                else {
                    const loggedUser: User = {
                        firstname: response.data.data.firstname,
                        lastname: response.data.data.lastname,
                        email: response.data.data.email,
                        description: response.data.data.description,
                        language: response.data.data.language,
                        uuid: response.data.data.uuid,
                        enabled: response.data.data.enabled
                    }
                    /* console.log("callMeInfo response: ", response);
                    console.log("callMeInfo loggedUser: ", loggedUser); */
                    setloginSuccess(true);
                    setloginFail(false);
                    /* dispatch(setGuiStatus("login_success")); */
                    dispatch(setCurrentGuiController_guistatus("login_success"));
                    dispatch(setCurrentGuiController_currentUser(loggedUser));
                }
            }
            );
    }

    React.useEffect(() => {
        /* console.log("####################### API getMarkets"); */
        /*         console.log("################### isLoading: ", isLoading);
                console.log("################### error: ", error);
                console.log("################### data: ", data);
                console.log("####################### API getMarkets"); */
        /*  handleApiProjectsList(); */
        if (isLoading === false && error === undefined) {
            /* console.log("####################### API getAuth LOADED"); */
            /* setTimeout(() => {
                console.log("####################### API getAuth LOADED2: ", data);
                testLogin();
            }, 3000); */
        }
        if (isSuccess === true) {
            /* console.log("####################### API getAuth Success"); */
            // callMeInfo();
        }

    }, [error, isLoading, isSuccess]);



    const apiLogin = async (email_string: string | undefined, password_string: string | undefined) => {
        if (email_string === undefined) {
            email_string = "";
        }
        if (password_string === undefined) {
            password_string = "";
        }
        let loginData = {
            email: email_string,
            password: password_string
        }


        try {
            await getLogin(loginData).unwrap().then((response: any) => {
                /* console.log("response: ", response); */
                if (response.success === true) {
                    setTimeout(() => {
                        loadProfile();
                    }, 250);
                    
                    /* setloginSuccess(true);
                    setloginFail(false);
                    dispatch(setCurrentGuiController_guistatus("login_success"));
                    fetch("./bg.json"
                        , {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        })
                        .then(async (response) => {
                            if (response.ok) {
                                const responseString: string = (await response.text());
                                const loggedUser: User = ConvertUser.toUser(responseString);
                                dispatch(setCurrentGuiController_currentUser(loggedUser));
                            } else {
                                console.log("Could not load user json!");
                            }
                        }) */
                }
            })
        } catch (error) {
            /* console.log("Authorization error: ", error); */
            setloginFail(true);
            // dispatch(setGuiStatus("login_fail"));
            // dispatch(setCurrentGuiController_guistatus("login_fail"));
            setPwValue("");
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const inputData = new FormData(event.currentTarget);

        apiLogin(inputData.get('email')?.toString(), inputData.get('password')?.toString());
        /* let validLogin: boolean = false;
        if (inputData.get('email') === "bjoern.gaede@3d-berlin.com" && inputData.get('password') === "okM8ZFRDJ87QIDhtP0Wf") {
            validLogin = true;
            setloginSuccess(true);
            setloginFail(false);
            dispatch(setCurrentGuiController_guistatus("login_success"));
            fetch("./bg.json"
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then(async (response) => {
                    if (response.ok) {
                        const responseString: string = (await response.text());
                        const loggedUser: User = ConvertUser.toUser(responseString);
                        dispatch(setCurrentGuiController_currentUser(loggedUser));
                    } else {
                        console.log("Could not load user json!");
                    }
                })
        }
        if (inputData.get('email') === "vivia.kusebauch@3d-berlin.com" && inputData.get('password') === "tQS4Ur4X0FiRmiDa51gM") {
            validLogin = true;
            setloginSuccess(true);
            setloginFail(false);
            dispatch(setCurrentGuiController_guistatus("login_success"));
            fetch("./vk.json"
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then(async (response) => {
                    if (response.ok) {
                        const responseString: string = (await response.text());
                        const loggedUser: User = ConvertUser.toUser(responseString);
                        dispatch(setCurrentGuiController_currentUser(loggedUser));
                    } else {
                        console.log("Could not load user json!");
                    }
                })
        }
        if (inputData.get('email') === "benjamin.wysk@3d-berlin.com" && inputData.get('password') === "tQYmwfnNImd4LzcvwwVk") {
            validLogin = true;
            setloginSuccess(true);
            setloginFail(false);
            dispatch(setCurrentGuiController_guistatus("login_success"));
            fetch("./bw.json"
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then(async (response) => {
                    if (response.ok) {
                        const responseString: string = (await response.text());
                        const loggedUser: User = ConvertUser.toUser(responseString);
                        dispatch(setCurrentGuiController_currentUser(loggedUser));
                    } else {
                        console.log("Could not load user json!");
                    }
                })
        }


        if (inputData.get('email') === "123" && inputData.get('password') === "qwe") {
            validLogin = true;
            setloginSuccess(true);
            setloginFail(false);
            dispatch(setCurrentGuiController_guistatus("login_success"));
            fetch("./bg.json"
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then(async (response) => {
                    if (response.ok) {
                        const responseString: string = (await response.text());
                        const loggedUser: User = ConvertUser.toUser(responseString);
                        dispatch(setCurrentGuiController_currentUser(loggedUser));
                    } else {
                        console.log("Could not load FloorMap.json, standard Floorslider used");
                    }
                })
        }
        if (validLogin === false) {
            setloginFail(true);
            dispatch(setCurrentGuiController_guistatus("login_fail"));
            setPwValue("");
        } */
    };



    /* const [pwValue, setpwValue] = React.useState(<String>); */

    return (
        <Container component="main" maxWidth="xs"
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                ...(loginSuccess && { display: 'none' }),
            }}>
            <CssBaseline />
            {isSuccess === true ?
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',

                    }}
                >
                    {React.createElement('img', { src: mainlogoLarge })}
                    {
                        guiController.guiStatus === "login"
                            ?
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{
                                mt: 1,
                                backgroundColor: 'primary.main',
                                padding: '5%',
                                marginTop: '10%',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}>
                                <CssTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={t('signin.mail')}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    variant="standard"
                                    onChange={handleChangeMail}
                                    value={mailValue}
                                />
                                <CssTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={t('signin.pw')}
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    variant="standard"
                                    onChange={handleChangePW}
                                    error={loginFail}
                                    value={pwValue}
                                />
                                <Link href="#" variant="body2" sx={{ textAlign: 'left', color: 'primary.contrastText' }} /* onClick={() => dispatch(increment())} */>
                                    {/* Forgot password?  */}{t('signin.forgot')}
                                </Link>
                                <CssWhiteButton
                                    type="submit"
                                    /* fullWidth */
                                    variant="contained"
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        backgroundColor: 'primary.contrastText',
                                        color: 'primary.main',
                                        width: 'fit-content',
                                        '&:hover': {
                                            color: 'primary.contrastText',
                                        },
                                    }}
                                >
                                    {t('signin.login')}
                                </CssWhiteButton>
                            </Box>
                            : null
                    }
                    {
                        guiController.guiStatus === "logout"
                            ?
                            <Box component="form" onSubmit={handleSubmitLogout} noValidate sx={{
                                mt: 1,
                                backgroundColor: 'info.main',
                                paddingLeft: '10%',
                                paddingRight: '10%',
                                paddingTop: '10%',
                                paddingBottom: '8%',
                                marginTop: '10%',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}>
                                <Typography variant="h6" gutterBottom align='left' sx={{
                                    color: 'success.main',
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem',
                                }}>
                                    {t('signout.heading')}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom align='left'>
                                    {t('signout.subtitle')}
                                </Typography>
                                <CssWhiteButton
                                    type="submit"
                                    /* fullWidth */
                                    variant="contained"
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        backgroundColor: 'primary.contrastText',
                                        color: 'primary.main',
                                        width: 'fit-content',
                                        '&:hover': {
                                            color: 'primary.contrastText',
                                        },
                                    }}
                                >
                                    <LogoutIcon sx={{
                                        marginRight: '6px'
                                    }}></LogoutIcon>
                                    {t('signin.login')}
                                </CssWhiteButton>
                            </Box>
                            : null
                    }
                </Box>
                : null
            }
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}