import React from 'react';
import './App.css';
import Drawer from './components/Drawer';
import SignIn from './components/SignIn';
import { store } from './components/store'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';


function startup() {
  console.log("EasyGuide Creator - ©3d-berlin vr solutions GmbH");
}

startup();

function App() {
  const mainTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#092935',
        light: '#092935',
        contrastText: '#ffffff',
        dark: '#030E12',
      },
      secondary: {
        main: '#e1e77b',
        light: '#ffffff',
        contrastText: '#092935',
      },
      info: {
        main: '#000000',
      },
      error: {
        main: '#E77B7B',
        contrastText: '#092935',
      },
      success: {
        main: '#24a3d3',
        contrastText: '#FFFFFF',
      },
      text: {
        primary: '#ffffff',
        secondary: 'rgba(255,255,255,0.64)',
        disabled: 'rgba(255,255,255,0.38)',
        /* hint: '#24A3D3', */
      },
      background: {
        default: '#0F4357',
        paper: '#092935',
      },
      warning: {
        main: '#e77b7b',
        contrastText: '#092935',
      },
      action: {
        active: '#ffffff',
        /* focus: '#ff0000', */
        focus: '#092935',
      },
      divider: '#092935',
    },
    shape: {
      borderRadius: 2
    },
    typography: {
      fontFamily: [
        'OPENSANS',
        'OPENSANSMEDIUM',
      ].join(','),
    }/* ,
    components: {
      MuiAutocomplete: {
        defaultProps: {
          slot
        }
      }
    } */
    /* components: {
      MuiList: {
        styleOverrides: {
          root: {
            backgroundColor: 'black',
            color: 'white',
          },
        },
      },
    }, */
  });

  return (
    <div className="App" >
      {<React.StrictMode>
        <ThemeProvider theme={mainTheme}>
          <Provider store={store}>
            <ThemeProvider theme={mainTheme}>
              <Drawer />
            </ThemeProvider>
            <SignIn />
          </Provider>
        </ThemeProvider>
      </React.StrictMode>}
    </div>
  );


}

export default App;
