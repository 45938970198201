import * as React from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { resetCurrentAdminEditUser } from '../../slices/currentAdminEditUserSlice';
import { User } from '../../../interfaces/interfaceUser';
import { setCurrentGuiController_modalLoading } from '../../slices/guiControllerSlice';
import { useEditUserMutation, useDeleteUserMutation } from '../../slices/apiSlice';
import ProjectsTransferList from '../../smallComponents/ProjectsTransferList';
import RolesTransferList from '../../smallComponents/RolesTransferlist';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CompanyTransferList from '../../smallComponents/CompanyTransferlist';

const CssTextField = styled(TextField)({
    /* width: '30%', */
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
});

const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
        color: '#ffffff',
    },
});

const CssWhiteInputLabel = styled(InputLabel)({
    '&.Mui-focused': {
        color: 'rgba(255,255,255,0.64)'
    }
});

const CssWhiteSelect = styled(Select)({
    color: '#ffffff',
    marginTop: '16px'
});



const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
}

/* const roleOptions = [
    { label: "Admin", value: "admin" },
    { label: "Poweruser", value: "poweruser" },
    { label: "User", value: "user" }
]; */

const languageOptions = [
    { label: "Deutsch", value: "de" },
    { label: "English", value: "en" }
];

export default function UserEdit() {
    const currentAdminEditUser = useAppSelector((state) => state.currentAdminEditUser.value);
    /* const [loginFail, setloginFail] = React.useState(false); */
    const [firstNameValue, setfirstNameValue] = React.useState(currentAdminEditUser.firstname);
    const [lastNameValue, setlastNameValue] = React.useState(currentAdminEditUser.lastname);
    const [mailValue, setMailValue] = React.useState(currentAdminEditUser.email);
    const [activeValue, setActiveValue] = React.useState(currentAdminEditUser.enabled);
    const [descriptionValue, setDescriptionValue] = React.useState(currentAdminEditUser.description);
    /* const [roleValue, setRoleValue] = React.useState(""); */
    const [languageValue, setLanguageValue] = React.useState(currentAdminEditUser.language);
    const [pwValue, setPwValue] = React.useState("");
    const [pwValueRepeat, setPwValueRepeat] = React.useState("");
    const [mounted, setMounted] = React.useState(false);
    const [saveChangesToApi, setSaveChangesToApi] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [editUser] = useEditUserMutation();
    const [deleteUser] = useDeleteUserMutation();
    let expectedCalls: number = 0;
    const [copyObject, setcopyObject] = React.useState<User>({
        firstname: "",
        lastname: "",
        email: "",
        description: "",
        enabled: true,
        language: "",
        uuid: "0"
    });

    React.useEffect(() => {
        if (mounted === false) {
            // setLanguageValue(currentAdminEditProject.language as string);
            /* console.log("Wann wirst Du gecalled???"); */
            setcopyObject(structuredClone(currentAdminEditUser));
            /* console.log("copyObject: ", copyObject); */
            setMounted(true);
        }
    }, [mounted, currentAdminEditUser, copyObject]);

    React.useEffect(() => {
        if (currentAdminEditUser.uuid !== "0") {
            setLanguageValue(currentAdminEditUser.language as string);
        }
    }, [currentAdminEditUser]);

    const handleSave = () => {
        setSaveChangesToApi(true);
        dispatch(setCurrentGuiController_modalLoading(false));
        setTimeout(() => {
            handleComparisons();
        }, 250);
        // dispatch(resetCurrentAdminEditUser());
    }

    const handleClose = () => {
        dispatch(resetCurrentAdminEditUser());
    }

    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setfirstNameValue(event.target.value);
    };

    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setlastNameValue(event.target.value);
    };

    const handleChangeMail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailValue(event.target.value);
    };

    const handleChangeActive = () => {
        setActiveValue(!activeValue);
    };

    const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescriptionValue(event.target.value);
    };

    /* const handleRoleSelect = (value: string) => {
        // setRoleValue(value);
    } */

    const handleLanguageSelect = (value: string) => {
        setLanguageValue(value);
    }

    const handleChangePW = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPwValue(event.target.value);
    };

    const handleChangePWrepeat = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPwValueRepeat(event.target.value);
    };

    const handleComparisons = () => {
        let dirtyMap = new Map<string, (number | string | boolean | undefined | null)>();

        if (copyObject.firstname !== firstNameValue) {
            dirtyMap.set("firstname", firstNameValue);
        }
        if (copyObject.lastname !== lastNameValue) {
            dirtyMap.set("lastname", lastNameValue);
        }
        if (copyObject.email !== mailValue) {
            dirtyMap.set("email", mailValue);
        }
        if (copyObject.description !== descriptionValue) {
            dirtyMap.set("description", descriptionValue);
        }
        if (copyObject.language !== languageValue) {
            dirtyMap.set("language", languageValue);
        }
        if (copyObject.enabled !== activeValue) {
            dirtyMap.set("enabled", activeValue);
        }
        if (pwValue !== "") {
            if (pwValue === pwValueRepeat) {
                dirtyMap.set("password", pwValue);
            }
        }
        onSavePostClicked(dirtyMap);
    }

    const onSavePostClicked = async (dirtyMap: Map<string, (number | string | boolean | undefined | null)>) => {
        expectedCalls = 0;
        dispatch(setCurrentGuiController_modalLoading(true));

        const constructedUser: Object = constructJsonUser(dirtyMap);


        if (JSON.stringify(constructedUser) !== "{}") {
            const data: Object = { userUuid: copyObject.uuid, bodydata: constructedUser }
            await editUser(data).unwrap()
                .then((response: any) => {
                    /* console.log("editUser response: ", response); */
                    checkExpectedCalls();
                });
        }
        else {
            dispatch(resetCurrentAdminEditUser());
            setTimeout(() => {
                dispatch(setCurrentGuiController_modalLoading(false));
            }, 250);
        }
    }


    const checkExpectedCalls = () => {
        if (expectedCalls === 0) {
            dispatch(resetCurrentAdminEditUser());
            setTimeout(() => {
                dispatch(setCurrentGuiController_modalLoading(false));
            }, 250);
        }
    }

    const constructJsonUser = (paramsMap: Map<string, (number | string | boolean | undefined | null)>) => {
        let user: { [key: string]: any } = {}

        for (let [key, value] of paramsMap) {
            user[key] = value;
        }
        /* console.log("user: ", user); */

        return user;
    }

    const handleDeleteUser = () => {
        handleClickOpenDialog();
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCloseDialogAgree = async () => {
        setOpenDialog(false);
        dispatch(setCurrentGuiController_modalLoading(true));
        await deleteUser(currentAdminEditUser.uuid)
            .then((response: any) => {
                /* console.log("deleteUser response: ", response);   */
                dispatch(resetCurrentAdminEditUser());
                setTimeout(() => {
                    dispatch(setCurrentGuiController_modalLoading(false));
                }, 250);
            });
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('admin_users.delete_user_really')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('admin_users.delete_user_really_text')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* variant="contained"
                        onClick={handleDeleteUser}
                        sx={{
                            mt: 3,
                            mb: 2,
                            ml: 4,
                            backgroundColor: 'primary.contrastText',
                            color: 'primary.main', */}
                    <CssWhiteButton variant="contained" onClick={handleCloseDialog} sx={{
                        backgroundColor: 'primary.contrastText',
                        color: 'primary.main',
                        '&:hover': {
                            color: 'primary.contrastText',
                        },
                    }}>
                        {t('admin_users.cancel')}
                    </CssWhiteButton>
                    <CssWhiteButton variant="contained" onClick={handleCloseDialogAgree} autoFocus sx={{
                        backgroundColor: 'primary.contrastText',
                        color: 'primary.main',
                        '&:hover': {
                            color: 'primary.contrastText',
                        },
                    }}>
                        {t('admin_users.delete_user')}
                    </CssWhiteButton>
                </DialogActions>
            </Dialog>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{
                display: currentAdminEditUser.uuid === "0" ? 'none' : 'flex',
                mt: 1,
                backgroundColor: 'primary.main',
                padding: '2%',
                marginTop: '0%',
                /* display: 'flex', */
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                width: '75%',
                height: '68%'
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '20em', mr: '3em' }}>
                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstName_edit"
                            label={t('admin_users.first_name')}
                            name="firstName"
                            autoComplete=""
                            variant="standard"
                            onChange={handleChangeFirstName}
                            value={firstNameValue}
                        />

                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="surname_edit"
                            label={t('admin_users.surname')}
                            name="email"
                            autoComplete="email"
                            variant="standard"
                            onChange={handleChangeLastName}
                            value={lastNameValue}
                        />
                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="email_edit"
                            label={t('signin.mail')}
                            name="email"
                            autoComplete="email"
                            variant="standard"
                            onChange={handleChangeMail}
                            value={mailValue}
                        />
                        <FormControlLabel control={<Checkbox color='default' aria-label='active'
                            checked={activeValue as boolean}
                            required={true}
                            onChange={handleChangeActive}
                        ></Checkbox>} label={t('admin_users.active')} aria-required />
                        <CssTextField
                            margin="normal"
                            required={false}
                            fullWidth
                            id="description_edit"
                            label={t('admin_users.description')}
                            name="description"
                            autoComplete=""
                            variant="standard"
                            onChange={handleChangeDescription}
                            value={descriptionValue}
                        />


                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '20em', mr: '3em' }}>
                        <FormControl variant="standard" sx={{ mt: '16px', mb: '8px', minWidth: 140 }}>
                            <CssWhiteInputLabel id="demo-simple-select-standard-label">{t('admin_users.groups')}</CssWhiteInputLabel>
                            <CssWhiteSelect
                                labelId="demo-simple-select-standard-label"
                                id="groups_edit"
                                label="Groups"
                                value=""
                                disabled
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                            </CssWhiteSelect>
                        </FormControl>
                        <FormControl variant="standard" sx={{ mt: '16px', mb: '8px', minWidth: 140 }}>
                            <CssWhiteInputLabel id="demo-simple-select-standard-label">{t('admin_users.language')}</CssWhiteInputLabel>
                            <CssWhiteSelect
                                labelId="demo-simple-select-standard-label"
                                id="language_edit"
                                value={languageValue}
                                /* onChange={handleChange} */
                                label="language"
                            >
                                {languageOptions.map((item) => {
                                    return (
                                        <MenuItem value={item.value} key={item.value} onClick={() => handleLanguageSelect(item.value)}>
                                            {item.label}
                                        </MenuItem>
                                    );
                                })}
                            </CssWhiteSelect>
                        </FormControl>
                        {/* <Autocomplete
                            freeSolo
                            id="free-solo-company-add"
                            disableClearable
                            options={allCompanies.map((option) => option.name)}
                            onChange = {
                                (event, newValue) => {                                  
                                  setCompanyValue(newValue);
                                  setBcompanyChanged(true);
                                }
                            }
                            renderInput={(params) => (
                                <CssTextField
                                    {...params}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="company_edit"
                                    label={t('admin_users.company')}
                                    name="firstName"
                                    autoComplete=""
                                    variant="standard"
                                    onChange={handleChangeCompany}
                                    value={companyValue}
                                    sx={{ width: '100%' }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        /> */}
                        <Box sx={{ ml: '0em' }}>
                            <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                paddingTop: '0.5rem'
                            }}>{t('admin_users.company') + ':'}</Typography>
                            <CompanyTransferList user_uuid={currentAdminEditUser.uuid} saveChanges={saveChangesToApi}></CompanyTransferList>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '20em', mr: '3em' }}>
                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('signin.pw')}
                            type="password"
                            id="password_edit"
                            autoComplete="current-password"
                            variant="standard"
                            onChange={handleChangePW}
                            /* error={loginFail} */
                            value={pwValue}
                        />
                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('admin_users.pw_repeat')}
                            type="password"
                            id="password_rp"
                            autoComplete="current-password"
                            variant="standard"
                            onChange={handleChangePWrepeat}
                            /* error={loginFail} */
                            value={pwValueRepeat}
                        />
                    </Box>
                </Box>

            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '65%', paddingLeft: '2%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mr: '3em', width: '100%' }}>
                    <Box sx={{ width: '32em' }}>
                        <Typography variant="subtitle1" gutterBottom align='left' sx={{
                            paddingTop: '0.5rem'
                        }}>{t('admin_users.projects') + ':'}</Typography>
                        <ProjectsTransferList user_uuid={currentAdminEditUser.uuid} saveChanges={saveChangesToApi}></ProjectsTransferList>
                    </Box>
                    <Box sx={{ width: '32em', ml: '2em' }}>
                        <Typography variant="subtitle1" gutterBottom align='left' sx={{
                            paddingTop: '0.5rem'
                        }}>{t('admin_users.role') + ':'}</Typography>
                        <RolesTransferList user_uuid={currentAdminEditUser.uuid} saveChanges={saveChangesToApi}></RolesTransferList>
                    </Box>
                </Box>
            </Box>


            <Box sx={{ display: 'flex', flexDirection: 'column', mr: '3em', paddingLeft: '2%' }}>
                <Container sx={{
                    /* width: '30%', */
                    padding: 'unset',
                    pl: 'unset !important',
                    pr: 'unset !important',
                    margin: 'unset !important',
                    display: 'flex',
                    /* justifyContent: 'space-between' */
                }}>
                    <CssWhiteButton
                        type="submit"
                        /* fullWidth */
                        variant="contained"
                        onClick={handleSave}
                        sx={{
                            mt: 3,
                            mr: 3,
                            mb: 2,
                            backgroundColor: 'primary.contrastText',
                            color: 'primary.main',
                            width: 'fit-content',
                            '&:hover': {
                                color: 'primary.contrastText',
                            },
                        }}
                    >
                        <CheckIcon sx={{
                            marginRight: '6px'
                        }}></CheckIcon>
                        {t('admin_users.save')}
                    </CssWhiteButton>
                    <CssWhiteButton
                        type="submit"
                        /* fullWidth */
                        variant="contained"
                        onClick={handleClose}
                        sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: 'primary.contrastText',
                            color: 'primary.main',
                            width: 'fit-content',
                            '&:hover': {
                                color: 'primary.contrastText',
                            },
                        }}
                    >
                        <CloseIcon sx={{
                            marginRight: '6px'
                        }}></CloseIcon>
                        {t('admin_users.cancel')}
                    </CssWhiteButton>
                    <CssWhiteButton
                        type="submit"
                        /* fullWidth */
                        variant="contained"
                        onClick={handleDeleteUser}
                        sx={{
                            mt: 3,
                            mb: 2,
                            ml: 4,
                            backgroundColor: 'primary.contrastText',
                            color: 'primary.main',
                            width: 'fit-content',
                            '&:hover': {
                                color: 'primary.contrastText',
                            },
                        }}
                    >
                        <DeleteIcon sx={{
                            marginRight: '6px'
                        }}></DeleteIcon>
                        {t('admin_users.delete_user')}
                    </CssWhiteButton>
                </Container>
            </Box>
        </Box>
    )
}