import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../interfaces/interfaceUser';

// Define a type for the slice state
interface CurrentAdminEditUser {
  value: User
}

// Define the initial state using that type
const initialState: CurrentAdminEditUser = {
  value: {
    uuid: "0",
    enabled: true,
    firstname: "",
    lastname: "",
    email: "",
    language: "",
    description: "",
  },
}

export const currentAdminEditUserSlice = createSlice({
  name: 'setCurrentAdminEditUser',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentAdminEditUser: (state, action: PayloadAction<User>) => {
      state.value = action.payload;
    },
    resetCurrentAdminEditUser: () => initialState
  },
})

export const { setCurrentAdminEditUser } = currentAdminEditUserSlice.actions
export const { resetCurrentAdminEditUser } = currentAdminEditUserSlice.actions

// Other code such as selectors can use the imported `RootState` type
/* export const selectCurrentProject = (state: RootState) => state.currentproject */


export default currentAdminEditUserSlice.reducer