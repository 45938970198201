/* import * as React from 'react'; */
import { useAppSelector, useAppDispatch } from '../../hooks';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
/* import { resetCurrentAdminEditUser } from '../../slices/currentAdminEditUserSlice'; */
import { resetCurrentAdminEditProject } from '../../slices/currentAdminEditProjectSlice';
import DropDownMarketsMenu from '../../DropDownMarketsMenu';
import ProductsTransferList from '../../smallComponents/ProductsTransferList';
import AddonsTransferList from '../../smallComponents/AddonsTransferList';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers';
import LanguageTransferList from '../../smallComponents/LanguageTransferList';
import { ApiProject } from '../../../interfaces/commonInterfaces';
import { useAddAddonToProjectMutation, useAddProductToProjectMutation, useEditProjectMutation, useRemoveAddonFromProjectMutation, useRemoveProductFromProjectMutation } from '../../slices/apiSlice'
import { useEffect, useState } from "react";
import { setCurrentGuiController_modalLoading } from '../../slices/guiControllerSlice'
/* import TimezonesTransferList from '../../smallComponents/TimezonesTransferList'; */
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import TimeZoneDropdown from '../../smallComponents/TimeZoneDropdown';

const CssTextField = styled(TextField)({
    /* width: '30%', */
    marginTop: 0,
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:hover': {
        borderBottomColor: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
});

const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
        color: '#ffffff',
    },
});

const CssWhiteInputLabel = styled(InputLabel)({
    '&.Mui-focused': {
        color: 'rgba(255,255,255,0.64)'
    }
});

const CssWhiteSelect = styled(Select)({
    color: '#ffffff',
    marginTop: '16px'
});



const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
}

/* const roleOptions = [
    { label: "Admin", value: "admin" },
    { label: "Poweruser", value: "poweruser" },
    { label: "User", value: "user" }
];

const languageOptions = [
    { label: "Deutsch", value: "de" },
    { label: "English", value: "en" }
]; */

export default function ProjectEdit() {
    const currentAdminEditProject = useAppSelector((state) => state.currentAdminEditProject.value);
    const currentAdminEditCopyProject = useAppSelector((state) => state.currentAdminEditCopyProject.value);

    const [nameValue, setNameValue] = useState(currentAdminEditProject.name);
    const [shortNameValue, setShortNameValue] = useState(currentAdminEditProject.shortName);
    const [countryValue, setCountryValue] = useState(currentAdminEditProject.country);
    const [cityValue, setCityValue] = useState(currentAdminEditProject.city);
    

    const [hasServiceValue, sethasServiceValue] = useState(currentAdminEditProject.hasService);
    const [projectIdValue] = useState(currentAdminEditProject.projectId);
    const [activeValue, setActiveValue] = useState(currentAdminEditProject.active);
    const [dgValue, setdgValue] = useState(currentAdminEditProject.demoGalleryEnabled);
    const [dgpValue, setdgpValue] = useState(currentAdminEditProject.demoGalleryPublic);
    const [timeOpenvalue, setTimeOpenValue] = useState<Dayjs | null>(dayjs('2022-04-17T' + currentAdminEditProject.openingTime));
    const [timeClosevalue, setTimeClosevalue] = useState<Dayjs | null>(dayjs('2022-04-17T' + currentAdminEditProject.closingTime));
    const [descriptionValue, setDescriptionValue] = useState(currentAdminEditProject.description);
    const [commentValue, setCommentValue] = useState(currentAdminEditProject.comment);
    const [copyObject, setcopyObject] = useState<ApiProject>({
        "projectId": 0,
        "name": "",
        "shortName": "",
        "description": "",
        "comment": "",
        "city": "",
        "openingTime": "10:00:00",
        "closingTime": "20:00:00",
        "country": "DE",
        "active": true,
        "hasService": false,
        "market": "",
        "tags": [],
        "links": [],
        "products": [],
        "addons": [],
        "demoGalleryEnabled": false,
        "demoGalleryPublic": false,
        "mapUuid": "",
        "languages": [
            {
                "code": "de",
                "name": "German"
            },
        ],
        "createdAt": "2023-08-12T15:29:39.000000Z",
        "modifiedAt": "2023-08-12T15:29:39.000000Z",
        "timezone": null
    })
    const [editProject] = useEditProjectMutation(); 
    const [addAddonToProject] = useAddAddonToProjectMutation();
    const [addProductToProject] = useAddProductToProjectMutation();
    const [removeAddonFromProject] = useRemoveAddonFromProjectMutation();
    const [removeProductFromProject] = useRemoveProductFromProjectMutation();
    const dispatch = useAppDispatch();
    const [mounted, setMounted] = useState(false);
    const { t } = useTranslation();

    let expectedCalls: number = 0;


    useEffect(() => {
        if (mounted === false) {
            // setLanguageValue(currentAdminEditProject.language as string);
            /* console.log("Wann wirst Du gecalled???"); */
            setcopyObject(structuredClone(currentAdminEditProject));
            /* console.log("copyObject: ", copyObject); */
            setMounted(true);
        }
    }, [mounted, currentAdminEditProject, copyObject]);


    /* useEffect(() => {
        if (currentAdminEditProject.projectId !== 0) {
            // setLanguageValue(currentAdminEditProject.language as string);
        }
    }, [currentAdminEditProject]); */

    interface MarketIdObject {
        id: number,
        name: string
    }

    const marketsObject: MarketIdObject[] = [
        {
            "id": 1,
            "name": "Corporate Offices & Organizations"
        },
        {
            "id": 2,
            "name": "Government Agencies & Administration"
        },
        {
            "id": 3,
            "name": "Convention Centers & Events"
        },
        {
            "id": 4,
            "name": "Hospitals & Healthcare"
        },
        {
            "id": 5,
            "name": "Universities & Education"
        },
        {
            "id": 6,
            "name": "Airports & Transportation"
        },
        {
            "id": 7,
            "name": "Shopping Malls & Retail"
        },
        {
            "id": 8,
            "name": "Theme parks & Entertainment"
        },
        {
            "id": 9,
            "name": "Stadiums & Sports"
        },
        {
            "id": 10,
            "name": "Hotels & Vacation"
        },
        {
            "id": 11,
            "name": "Museums & Culture"
        },
        {
            "id": 12,
            "name": "Cruise ships & Leisure"
        },
        {
            "id": 13,
            "name": "Parking garage & Logistics"
        },
        {
            "id": 14,
            "name": "Other"
        }
    ];

    const getMarketId = (): number | null => {
        let id: number | null = null;
        marketsObject.forEach((element: MarketIdObject) => {
            if (element.name === currentAdminEditProject.market) {
                id = element.id;
            }
        });
        return id;
    }

    interface langObject {
        code: string,
        name: string,
    }

    interface uuidObject {
        uuid: string,
        name: string,
    }

    const getLanguageShort = (languages: langObject[]): string[] => {
        let languagArray: string[] = [];
        languages.forEach((element: langObject) => {
            /* console.log("element: ", element); */
            languagArray.push(element.code)
        });
        return languagArray;
    }

    const getAddonsUuid = (addons: any[]): string[] => {
        let addonsArray: string[] = [];
        addons.forEach((element: uuidObject) => {
            /* console.log("element: ", element); */
            addonsArray.push(element.uuid)
        });
        return addonsArray;
    }

    const getProductsUuid = (addons: any[]): string[] => {
        let addonsArray: string[] = [];
        addons.forEach((element: uuidObject) => {
            /* console.log("element: ", element); */
            addonsArray.push(element.uuid)
        });
        return addonsArray;
    }

    const constructJsonProject = (paramsMap: Map<string, (number | string | boolean | undefined | null)>) => {
        let project: { [key: string]: any } = {}

        for (let [key, value] of paramsMap) {
            project[key] = value;
        }

        if (languagesAltered() === true) {
            project.languages = getLanguageShort(currentAdminEditProject.languages);
        }


        /* console.log("project: ", project); */

        return project;
    }

    const languagesAltered = (): boolean => {
        let languageChanged: boolean = false;
        const oldLanguageArray: string[] = getLanguageShort(copyObject.languages);
        const newLanguageArray: string[] = getLanguageShort(currentAdminEditProject.languages);
        if (oldLanguageArray.length !== newLanguageArray.length) {
            languageChanged = true
        }
        else {
            oldLanguageArray.forEach(element => {
                if (newLanguageArray.indexOf(element) === -1) {
                    languageChanged = true;
                }
            });
        }
        return languageChanged;
    }

    const addonsAltered = (): boolean => {
        let addonsChanged: boolean = false;
        const oldAddonsArray: string[] = getAddonsUuid(currentAdminEditCopyProject.addons);
        const newAddonsArray: string[] = getAddonsUuid(currentAdminEditProject.addons);
        if (oldAddonsArray.length !== newAddonsArray.length) {
            addonsChanged = true
        }
        else {
            oldAddonsArray.forEach(element => {
                if (newAddonsArray.indexOf(element) === -1) {
                    addonsChanged = true;
                }
            });
        }
        return addonsChanged;
    }

    const productsAltered = (): boolean => {
        let productsChanged: boolean = false;
        const oldProductsArray: string[] = getProductsUuid(currentAdminEditCopyProject.products);
        const newProductsArray: string[] = getProductsUuid(currentAdminEditProject.products);
        if (oldProductsArray.length !== newProductsArray.length) {
            productsChanged = true
        }
        else {
            oldProductsArray.forEach(element => {
                if (newProductsArray.indexOf(element) === -1) {
                    productsChanged = true;
                }
            });
        }
        return productsChanged;
    }

    const handleComparisons = () => {
        let dirtyMap = new Map<string, (number | string | boolean | undefined | null)>();
        /* console.log("copyObject: ", copyObject);
        console.log("currentAdminEditProject: ", currentAdminEditProject);
        console.log("currentAdminEditCopyProject: ", currentAdminEditCopyProject); */
        if (copyObject.active !== activeValue) {
            dirtyMap.set("active", activeValue);
        }
        /* if(copyObject.addons !== currentAdminEditProject.addons){
            dirtyArray.push("addons");
        } */
        if (copyObject.city !== cityValue) {
            dirtyMap.set("city", cityValue);
        }
        if (copyObject.closingTime !== timeClosevalue?.format('HH[:]mm[:]ss')) {
            if (timeClosevalue?.format('HH[:]mm[:]ss') === "Invalid Date") {
                dirtyMap.set("closingTime", "20:00:00");
            }
            else {
                dirtyMap.set("closingTime", timeClosevalue?.format('HH[:]mm[:]ss'));
            }
        }
        if (copyObject.comment !== commentValue) {
            dirtyMap.set("comment", commentValue);
        }
        if (copyObject.country !== countryValue) {
            dirtyMap.set("country", countryValue);
        }
        if (copyObject.demoGalleryEnabled !== dgValue) {
            dirtyMap.set("demoGalleryEnabled", dgValue);
        }
        if (copyObject.demoGalleryPublic !== dgpValue) {
            dirtyMap.set("demoGalleryPublic", dgpValue);
        }
        if (copyObject.description !== descriptionValue) {
            dirtyMap.set("description", descriptionValue);
        }
        if (copyObject.hasService !== hasServiceValue) {
            dirtyMap.set("hasService", hasServiceValue);
        }
        /* if(languagesAltered() === true){
            dirtyArray.push("languages");
        } */
        /* if(copyObject.links !== currentAdminEditProject.links){
            dirtyArray.push("links");
        } */
        if (copyObject.market !== currentAdminEditProject.market) {
            if (currentAdminEditProject.market !== "") {
                dirtyMap.set("marketId", getMarketId());
            }
        }
        if (copyObject.name !== nameValue) {
            dirtyMap.set("name", nameValue);
        }
        if (copyObject.openingTime !== timeOpenvalue?.format('HH[:]mm[:]ss')) {
            if (timeOpenvalue?.format('HH[:]mm[:]ss') === "Invalid Date") {
                dirtyMap.set("openingTime", "08:00:00");
            }
            else {
                dirtyMap.set("openingTime", timeOpenvalue?.format('HH[:]mm[:]ss'));
            }
        }
        if(copyObject.timezone !== currentAdminEditProject.timezone){
            dirtyMap.set("timezone",currentAdminEditProject.timezone);
        }
        if (copyObject.shortName !== shortNameValue) {
            dirtyMap.set("shortName", shortNameValue);
        }

        /*  const constructedApiProject: Object = constructJsonProject(dirtyMap);
 
         if (addonsAltered() === true) {
             console.log("addonsAltered!!! -> Trigger addons selective");
         }
         if (productsAltered() === true) {
             console.log("productsAltered!!! -> Trigger products selective");
         } */

        onSavePostClicked(dirtyMap);

    }

    const onSavePostClicked = async (dirtyMap: Map<string, (number | string | boolean | undefined | null)>) => {
        expectedCalls = 0;
        dispatch(setCurrentGuiController_modalLoading(true));

        const constructedApiProject: Object = constructJsonProject(dirtyMap);
        /* console.log("currentAdminEditProject: ", currentAdminEditProject);
        console.log("SAVE constructedApiProject: ", constructedApiProject); */
        try {
            if (JSON.stringify(constructedApiProject) !== "{}") {
                const projectId: string = projectIdValue.toString();
                const data: Object = { id: projectId, bodydata: constructedApiProject }
                await editProject(data).unwrap();
            }


            const bAddonsChanged: boolean = addonsAltered();
            const bProductsChanged: boolean = productsAltered();

            if (bAddonsChanged === false && bProductsChanged === false) {
                checkExpectedCalls();
            }
            else {
                if (bAddonsChanged === true) {
                    /* console.log("addonsAltered!!! -> Trigger addons selective"); */
                    const addAddonsList: string[] = createAddAddonsList();
                    const removeAddonsList: string[] = createRemoveAddonsList();
                    /* console.log("addAddonsList: ", addAddonsList);
                    console.log("removeAddonsList: ", removeAddonsList); */
                    if (addAddonsList.length > 0) {
                        expectedCalls += addAddonsList.length;
                        addAddonsList.forEach((addonsUuid: string) => {
                            onSaveAddAddon(addonsUuid, projectIdValue.toString());
                        });
                    }
                    if (removeAddonsList.length > 0) {
                        expectedCalls += removeAddonsList.length;
                        removeAddonsList.forEach((addonsUuid: string) => {
                            onSaveRemoveAddon(addonsUuid, projectIdValue.toString());
                        });
                    }
                }
                if (bProductsChanged === true) {
                    /* console.log("productsAltered!!! -> Trigger products selective"); */
                    const addProductsList: string[] = createAddProductsList();
                    const removeProductsList: string[] = createRemoveProductsList();
                    /* console.log("addProductsList: ", addProductsList);
                    console.log("removeProductsList: ", removeProductsList); */
                    if (addProductsList.length > 0) {
                        expectedCalls += addProductsList.length;
                        addProductsList.forEach((addonsUuid: string) => {
                            onSaveAddProduct(addonsUuid, projectIdValue.toString());
                        });
                    }
                    if (removeProductsList.length > 0) {
                        expectedCalls += removeProductsList.length;
                        removeProductsList.forEach((addonsUuid: string) => {
                            onSaveRemoveProduct(addonsUuid, projectIdValue.toString());
                        });
                    }
                }
            }

            // dispatch(resetCurrentAdminEditProject());
        } catch (err) {
            console.error('Failed to save the post: ', err)
        }
    }

    const createAddProductsList = (): string[] => {
        let addList: string[] = [];
        const oldProductsArray: string[] = getAddonsUuid(currentAdminEditCopyProject.products);
        const newProductsArray: string[] = getAddonsUuid(currentAdminEditProject.products);
        newProductsArray.forEach(element => {
            if (oldProductsArray.indexOf(element) === -1) {
                addList.push(element);
            }
        });
        return addList;
    }

    const createRemoveProductsList = (): string[] => {
        let removeList: string[] = [];
        const oldProductsArray: string[] = getAddonsUuid(currentAdminEditCopyProject.products);
        const newProductsArray: string[] = getAddonsUuid(currentAdminEditProject.products);
        oldProductsArray.forEach(element => {
            if (newProductsArray.indexOf(element) === -1) {
                removeList.push(element);
            }
        });
        return removeList;
    }

    const createAddAddonsList = (): string[] => {
        let addList: string[] = [];
        const oldAddonsArray: string[] = getAddonsUuid(currentAdminEditCopyProject.addons);
        const newAddonsArray: string[] = getAddonsUuid(currentAdminEditProject.addons);
        newAddonsArray.forEach(element => {
            if (oldAddonsArray.indexOf(element) === -1) {
                addList.push(element);
            }
        });
        return addList;
    }

    const createRemoveAddonsList = (): string[] => {
        let removeList: string[] = [];
        const oldAddonsArray: string[] = getAddonsUuid(currentAdminEditCopyProject.addons);
        const newAddonsArray: string[] = getAddonsUuid(currentAdminEditProject.addons);
        oldAddonsArray.forEach(element => {
            if (newAddonsArray.indexOf(element) === -1) {
                removeList.push(element);
            }
        });
        return removeList;
    }

    const onSaveAddProduct = async (productUuid: string, projectId: string) => {
        try {
            /* console.log("$$$$$$ addProductToProject Started!!! (" + productUuid + ")"); */
            await addProductToProject({ productUuid, projectId });
            expectedCalls -= 1;
            checkExpectedCalls();
            /* console.log("$$$$$$ addProductToProject Finished!!! (" + productUuid + ")"); */
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }

    const onSaveRemoveProduct = async (productUuid: string, projectId: string) => {
        try {
            /* console.log("$$$$$$ addProductToProject Started!!! (" + productUuid + ")"); */
            await removeProductFromProject({ productUuid, projectId });
            expectedCalls -= 1;
            checkExpectedCalls();
            /* console.log("$$$$$$ addProductToProject Finished!!! (" + productUuid + ")"); */
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }

    const onSaveAddAddon = async (productUuid: string, projectId: string) => {
        try {
            /* console.log("$$$$$$ addAddonToProject Started!!! (" + productUuid + ")"); */
            await addAddonToProject({ productUuid, projectId });
            expectedCalls -= 1;
            checkExpectedCalls();
            /* console.log("$$$$$$ addAddonToProject Finished!!! (" + productUuid + ")"); */
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }

    const onSaveRemoveAddon = async (productUuid: string, projectId: string) => {
        try {
            /* console.log("$$$$$$ addAddonToProject Started!!! (" + productUuid + ")"); */
            await removeAddonFromProject({ productUuid, projectId });
            expectedCalls -= 1;
            checkExpectedCalls();
            /* console.log("$$$$$$ addAddonToProject Finished!!! (" + productUuid + ")"); */
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }

    const checkExpectedCalls = () => {
        if (expectedCalls === 0) {
            dispatch(resetCurrentAdminEditProject());
            setTimeout(() => {
                dispatch(setCurrentGuiController_modalLoading(false));
            }, 250);
        }
    }

    const handleSave = () => {
        handleComparisons();
        // dispatch(resetCurrentAdminEditProject());
    }

    const handleClose = () => {
        dispatch(resetCurrentAdminEditProject());
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameValue(event.target.value);
    };

    const handleChangeShortName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShortNameValue(event.target.value);
    };

    const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCountryValue(event.target.value);
    };

    const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCityValue(event.target.value);
    };

    const handleChangehasService = () => {
        sethasServiceValue(!hasServiceValue);
    };

    const handleChangeActive = () => {
        setActiveValue(!activeValue);
    };

    const handleChangeDG = () => {
        setdgValue(!dgValue);
    };

    const handleChangeDGP = () => {
        setdgpValue(!dgpValue);
    };

    const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescriptionValue(event.target.value);
    };

    const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommentValue(event.target.value);
    };



    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{
            display: currentAdminEditProject.projectId === 0 ? 'none' : 'flex',
            mt: 1,
            /* backgroundColor: 'blue',  */// 'primary.main',
            padding: '0%',
            paddingTop: '0.5%',
            marginTop: '0%',
            /* display: 'flex', */
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            /* overflowY: 'auto', */
            width: '100%',
            height: '92%',
        }}>
            <div className="list_scroll_area_project">
                <PerfectScrollbar>
                    <div className="content">
                        <Box sx={{
                            display: currentAdminEditProject.projectId === 0 ? 'none' : 'flex',
                            mt: 1,
                            /* backgroundColor: 'red', */
                            padding: '0%',
                            marginTop: '0%',
                            /* display: 'flex', */
                            /* height: '42vh', */
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}>
                            <Box sx={{ width: '33%', height: '25rem', display: 'flex', flexDirection: 'column',  justifyContent: 'space-between' }}>
                                <CssTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name_edit"
                                    label={t('admin_projects.name')}
                                    name="name"
                                    autoComplete=""
                                    variant="standard"
                                    onChange={handleChangeName}
                                    value={nameValue}
                                    multiline
                                    sx={{
                                        backgroundColor: 'primary.dark',
                                        padding: '0.3rem',
                                        marginTop: 0,
                                        width: '100%'
                                    }}
                                />
                                <CssTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="shortName_edit"
                                    label={t('admin_projects.shortName')}
                                    name="name"
                                    autoComplete=""
                                    variant="standard"
                                    onChange={handleChangeShortName}
                                    value={shortNameValue === null ? "" : shortNameValue}
                                    multiline
                                    sx={{
                                        backgroundColor: 'primary.dark',
                                        padding: '0.3rem',
                                        marginTop: 0,
                                        width: '100%'
                                    }}
                                />
                                <CssTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="id_edit"
                                    label={t('admin_projects.projectId')}
                                    name="id"
                                    autoComplete="id"
                                    variant="standard"
                                    value={projectIdValue}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    sx={{
                                        backgroundColor: 'primary.dark',
                                        padding: '0.3rem',
                                        marginTop: 0,
                                        width: '100%'
                                    }}
                                />
                                <CssTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="country_edit"
                                    label={t('admin_projects.country')}
                                    name="country"
                                    autoComplete=""
                                    variant="standard"
                                    onChange={handleChangeCountry}
                                    value={countryValue === null ? "" : countryValue}
                                    sx={{
                                        backgroundColor: 'primary.dark',
                                        padding: '0.3rem',
                                        marginTop: 0,
                                        width: '100%'
                                    }}
                                />
                                <CssTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="city_edit"
                                    label={t('admin_projects.city')}
                                    name="city"
                                    autoComplete=""
                                    variant="standard"
                                    onChange={handleChangeCity}
                                    value={cityValue === null ? "" : cityValue}
                                    sx={{
                                        backgroundColor: 'primary.dark',
                                        padding: '0.3rem',
                                        marginTop: 0,
                                        width: '100%'
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: '32%', height: '80%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderLeft: '1px solid #FFFFFF;', borderRight: '1px solid #FFFFFF;', paddingRight: '1rem', paddingLeft: '1rem', paddingBottom: '2rem' }}>
                                <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                    paddingTop: '0.0rem'
                                }}>{t('admin_users.groups') + ':'}</Typography>
                                <FormControl variant="standard" sx={{ mt: '0', mb: '8px', minWidth: 140, lineHeight: '0.5rem', backgroundColor: 'primary.dark', width: '100%', padding: '0.3rem' }}>
                                    <CssWhiteInputLabel id="demo-simple-select-standard-label">{t('admin_users.groups')}</CssWhiteInputLabel>
                                    <CssWhiteSelect
                                        labelId="demo-simple-select-standard-label"
                                        id="groups_edit"
                                        label="Groups"
                                        value=""
                                        fullWidth
                                        sx={{ width: '100%' }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                    </CssWhiteSelect>
                                </FormControl>
                                <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                    paddingTop: '0.0rem'
                                }}>{t('admin_projects.market') + ':'}</Typography>
                                <DropDownMarketsMenu></DropDownMarketsMenu>
                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '0.0rem' }}>
                                    <FormControlLabel labelPlacement="start" control={<Checkbox color='default' aria-label='active'
                                        checked={activeValue as boolean}
                                        required={true}
                                        onChange={handleChangeActive}
                                        sx={{
                                            color: 'rgba(255,255,255,1)'
                                        }}
                                    ></Checkbox>} label={t('admin_projects.active')} aria-required />
                                    <FormControlLabel labelPlacement="start" control={<Checkbox color='default' aria-label='active'
                                        checked={hasServiceValue}
                                        required={true}
                                        onChange={handleChangehasService}
                                        sx={{
                                            color: 'rgba(255,255,255,1)'
                                        }}

                                    ></Checkbox>} label={t('admin_projects.hasService')} aria-required />
                                </Box>
                                {/*  Demo-Gallery: */}
                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '0.0rem' }}>
                                    <FormControlLabel labelPlacement="start" control={<Checkbox color='default' aria-label='active'
                                        checked={dgValue}
                                        required={true}
                                        onChange={handleChangeDG}
                                        sx={{
                                            color: 'rgba(255,255,255,1)'
                                        }}
                                    ></Checkbox>} label={t('admin_projects.demogallery')} aria-required />
                                    {/*  Demo-Gallery public: */}
                                    <FormControlLabel labelPlacement="start" control={<Checkbox color='default' aria-label='active'
                                        checked={dgpValue}
                                        required={true}
                                        onChange={handleChangeDGP}
                                        sx={{
                                            color: 'rgba(255,255,255,1)'
                                        }}

                                    ></Checkbox>} label={t('admin_projects.demogallery_public')} aria-required />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '0.2rem' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            ampm={false}
                                            label={t('admin_projects.opening_time') + ':'}
                                            value={timeOpenvalue}
                                            onChange={(newValueOpen) => setTimeOpenValue(newValueOpen)}
                                        />
                                        <TimePicker
                                            ampm={false}
                                            sx={{ marginLeft: '1rem' }}
                                            label={t('admin_projects.closing_time') + ':'}
                                            value={timeClosevalue}
                                            onChange={(newValueClose) => setTimeClosevalue(newValueClose)}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                    paddingTop: '0.5rem'
                                }}>{t('admin_projects.timezone') + ':'}</Typography>
                                {/* <TimezonesTransferList></TimezonesTransferList> */}
                                <TimeZoneDropdown></TimeZoneDropdown>
                            </Box>
                            <Box sx={{ width: '33%', height: '80%', display: 'flex', flexDirection: 'column' }}>
                                <Box height={'45%'}>
                                    <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                        paddingTop: '0.0rem'
                                    }}>{t('admin_users.description') + ':'}</Typography>
                                    <CssTextField
                                        margin="normal"
                                        required={false}
                                        fullWidth
                                        id="description_edit"
                                        /* label={t('admin_users.description')} */
                                        name="description"
                                        autoComplete=""
                                        variant="standard"
                                        onChange={handleChangeDescription}
                                        value={descriptionValue === null ? "" : descriptionValue}
                                        multiline
                                        minRows={5}
                                        sx={{
                                            backgroundColor: 'primary.dark',
                                            padding: '0.3rem',
                                            marginTop: 0,
                                            width: '100%'
                                        }}
                                    />
                                </Box>
                                <Box height={'45%'}>
                                    <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                        paddingTop: '0.0rem'
                                    }}>{t('admin_projects.comment') + ':'}</Typography>
                                    <CssTextField
                                        margin="normal"
                                        required={false}
                                        fullWidth
                                        id="comment_edit"
                                        /* label={t('admin_users.description')} */
                                        name="comment"
                                        autoComplete=""
                                        variant="standard"
                                        onChange={handleChangeComment}
                                        value={commentValue === null ? "" : commentValue}
                                        multiline
                                        minRows={5}
                                        sx={{
                                            backgroundColor: 'primary.dark',
                                            padding: '0.3rem',
                                            marginTop: 0,
                                            width: '100%'
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', borderTop: '1px solid #FFFFFF;', alignItems: 'flex-start', paddingTop: '1.5rem' }}>
                                <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                    paddingTop: '0.0rem'
                                }}>{t('admin_projects.languages') + ':'}</Typography>
                                <LanguageTransferList></LanguageTransferList>                                
                            </Box>
                        </Box>
                        <Box sx={{
                            display: currentAdminEditProject.projectId === 0 ? 'none' : 'flex',
                            mt: 1,
                            /* backgroundColor: 'green', */
                            padding: '0%',
                            marginTop: '2rem',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}>
                            <ProductsTransferList></ProductsTransferList>
                            <AddonsTransferList></AddonsTransferList>
                        </Box>

                        <Container sx={{
                            width: '30%',
                            padding: 'unset',
                            pl: 'unset !important',
                            pr: 'unset !important',
                            margin: 'unset !important',
                            display: 'flex'
                        }}>
                            <CssWhiteButton
                                type="button"
                                /* fullWidth */
                                variant="contained"
                                onClick={handleSave}
                                sx={{
                                    mt: 3,
                                    mr: 3,
                                    mb: 2,
                                    backgroundColor: 'primary.contrastText',
                                    color: 'primary.main',
                                    width: 'fit-content',
                                    '&:hover': {
                                        color: 'primary.contrastText',
                                    },
                                }}
                            >
                                <CheckIcon sx={{
                                    marginRight: '6px'
                                }}></CheckIcon>
                                {t('admin_users.save')}
                            </CssWhiteButton>
                            <CssWhiteButton
                                type="button"
                                /* fullWidth */
                                variant="contained"
                                onClick={handleClose}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: 'primary.contrastText',
                                    color: 'primary.main',
                                    width: 'fit-content',
                                    '&:hover': {
                                        color: 'primary.contrastText',
                                    },
                                }}
                            >
                                <CloseIcon sx={{
                                    marginRight: '6px'
                                }}></CloseIcon>
                                {t('admin_users.cancel')}
                            </CssWhiteButton>
                        </Container>
                    </div>
                </PerfectScrollbar>
            </div>

        </Box>


    )
}