
import OSM from "ol/source/OSM";
import Map from 'ol/Map';
import ExtentInteraction from 'ol/interaction/Extent';
import { shiftKeyOnly } from 'ol/events/condition';
import { getDistance } from 'ol/sphere'
/* import { generateWorldFile } from 'world-file'; */

/* import Image from 'ol/layer/Image' */
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Style } from "ol/style";
import { transform } from "ol/proj";
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke"
import Fill from "ol/style/Fill";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import TileLayer from "ol/layer/WebGLTile";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import React from "react";
import SearchNominatim from "ol-ext/control/SearchNominatim";
import { useAppDispatch, useAppSelector } from '../hooks';
/* import {defaults as defaultControls} from "ol/control/defaults" */
import Attribution from "ol/control/Attribution"
import Fullscreen from "ol/control/FullScreen"
import Zoom from "ol/control/Zoom"
import { defaults } from 'ol/interaction';
import { Extent, boundingExtent, getCenter } from "ol/extent";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ImageLayer from "ol/layer/Image";
import Polygon, { fromExtent } from "ol/geom/Polygon";
/* import GeoImageLayer from "ol-ext/layer/GeoImageLayer"; */
import GeoImageSource from "ol-ext/source/GeoImage";
import { HelmertTransformation } from "../../classes/helmert_transformation";
import { Coordinate } from "ol/coordinate";
import ImageSource from "ol/source/Image";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { toLonLat } from "ol/proj";
import { setCurrentMapDataController_layerPgws, setCurrentMapDataController_rawPoints, setCurrentMapDataController_rotation, setCurrentMapDataController_x3dToSvg, setCurrentMapDataController_svgToGeo, setCurrentMapDataController_transformNode } from "../slices/MapDataSlice";
import { LayerPgw, MapData, RawPoints, SvgToGeo, UploadedFiles, X3dToSvg, transformNode } from "../../interfaces/interfaceGuiController";
import { ApiProject } from "../../interfaces/commonInterfaces";
import { useEditMapMutation, useUploadPgwToLayerMutation } from "../slices/apiSlice";
import { ScaleLine } from "ol/control";
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { MappingCalculator } from "../../classes/MappingCalculator";
/* import { current } from "@reduxjs/toolkit"; */
import Collection from "ol/Collection";
import BaseLayer from "ol/layer/Base";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
/* import { setCurrentGuiController_mapsStep, setCurrentGuiController_valueTabs } from "../slices/guiControllerSlice"; */

const pi = Math.PI;

interface IPreviewProps {
    mapIndex: number;
    apiProject: ApiProject;
    mapData: MapData;
    mapFiles: UploadedFiles;
    setMainMapData: Dispatch<SetStateAction<MapData | undefined>>;
    requeryMap: () => Promise<void>;
}

const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
        color: '#ffffff',
    },
});

const getBaseUrl = () => {
    let url;
    switch (process.env.REACT_APP_PATH_TO_API) {
        case 'production ':
            url = 'https://creator.guide3d.com';
            break;
        case 'development ':
        default:
            url = 'http://api.creator.local';
    }

    return url;
}

const AS2TextField = styled(TextField)({
    /* width: '30%', */
    /* margin: 'unset',
    marginLeft: '2vh', */
    marginTop: 0,
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#fffffff',
    },
    '& .MuiOutlinedInput-root': {
        /* '& fieldset': {
          borderColor: '#ff0000',
        }, */
        '&:hover fieldset': {
            borderColor: '#ffffff',
            borderWidth: '1px'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#ffffff',
            borderWidth: '2px'
        },
    },
});

function PrevMap(props: React.PropsWithChildren<IPreviewProps>) {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    // pull refs
    /* const mapIndex = props.mapIndex; */
    const previewApiProject = props.apiProject;
    const mapFiles = props.mapFiles;
    /* const mapData = props.mapData; */

    /* console.log("PrevMap props.apiProject.projectId: ", props.apiProject.projectId);*/
    /* console.log("PrevMap props.mapFiles: ", props.mapFiles);  */

    const ref = React.useRef<HTMLDivElement>(null)
    const mapRef = React.useRef<Map>();
    const oMappingCalculator = new MappingCalculator({ debug: true });

    let mapsGeoA: number[] = useAppSelector((state) => state.guicontroller.value.mapsGeoCoordinates_A);
    let mapsGeoB: number[] = useAppSelector((state) => state.guicontroller.value.mapsGeoCoordinates_B);
    let mapsPixelA = useAppSelector((state) => state.guicontroller.value.mapsPixelCoordinates_A);
    let mapsPixelB = useAppSelector((state) => state.guicontroller.value.mapsPixelCoordinates_B);

    let mapData = useAppSelector((state) => state.mapDataController.value);
    let layerIds = useAppSelector((state) => state.mapDataController.value.layerIds);
    const mapsFiles = useAppSelector((state) => state.guicontroller.value.mapsFiles);
    /* console.log("PrevMap mapsFiles: ", mapsFiles);  */
    const [showAS2Values, setShowAS2Values] = React.useState<boolean>(false);

    const [projRotation, setProjRotation] = React.useState<number>(0);

    const [prePgwMapdata, setPrePgwMapdata] = React.useState<MapData>();

    const [bottomLeftGeoCoord, setBottomLeftGeoCoord] = React.useState<Coordinate>([0, 0]);
    const [topRightGeoCoord, setTopRightGeoCoord] = React.useState<Coordinate>([0, 0]);
    const [as2Rotation, setAs2Rotation] = React.useState<number>(0);

    /* const [reRender, setReRender] = React.useState<number>(0); */

    const [bDisableSave, setDisableSave] = React.useState<boolean>(false);
    /* const [mapsFiles, setMapsFiles] = React.useState<UploadedFiles>(); */

    const [projScale, setProjScale] = React.useState<number[]>([0]);
    const [projTranslation, setProjTranslation] = React.useState<number[]>([0]);
    const [maskPolygon, setMaskPolygon] = React.useState<Polygon>();
    const [geoimgLayer, setGeoImageLayer] = React.useState<ImageLayer<ImageSource>>();
    const [visibilityValue, setVisibilityValue] = React.useState<number>(0.85);
    /* const [currentGISource, setCurrentGISource] = React.useState<GeoImageSource>(); */
    const [editMap] = useEditMapMutation();
    const [uploadPgwToLayer] = useUploadPgwToLayerMutation();

    const apiUrl: string = getBaseUrl(); // http://api.creator.local | https://creator.guide3d.com/
    let pgwUploads: number = 0;

    /* const degrees_to_radians = (radians: number): number => {
        return radians * (pi / 180);
    } */

    const radians_to_degrees = (radians: number): number => {
        return radians * (180 / pi);
    }

    /* const transformLatLonToMercator = (coord: number[]): number[] => {
        let mercCoord: number[] = [0, 0];
        const tempCoord: Coordinate = [coord[0], coord[1]];
        const tempCoord2: Coordinate = transform(tempCoord, 'EPSG:4326', 'EPSG:3857');
        mercCoord[0] = tempCoord2[0];
        mercCoord[1] = tempCoord2[1];
        return mercCoord;
    }; */

    /* let mapsGeoA: number[];
    let mapsGeoB: number[];
    let mapsPixelA: number[];
    let mapsPixelB: number[];

    let mapData: MapData;
    let layerIds: string[];
    let mapsFiles: UploadedFiles;
    

    useEffect(() => {

        try {
            mapsGeoA = transformLatLonToMercator([props.mapData.rawPoints?.pointA_geoLongitude as number, props.mapData.rawPoints?.pointA_geoLatitude as number]);
            mapsGeoB = transformLatLonToMercator([props.mapData.rawPoints?.pointB_geoLongitude as number, props.mapData.rawPoints?.pointB_geoLatitude as number]);
            mapsPixelA = [(props.mapData.rawPoints?.pointA_imageX as number), (props.mapData.rawPoints?.pointA_imageY as number)];
            mapsPixelB = [(props.mapData.rawPoints?.pointB_imageX as number), (props.mapData.rawPoints?.pointB_imageY as number)];

            mapData = props.mapData;
            layerIds = props.mapData.layerIds;
            mapsFiles = props.mapFiles;
            setReRender(Math.random());
            // setMapsFiles(props.mapFiles);

        } catch (error) {
            // mach nix
        }
    }, [props.mapFiles]) */



    /* const calcBottomRight = (coords: Coordinate[]): Coordinate => {
        let tl: Coordinate = [0, 0];

        let tempLong: number = -999;
        let tempLat: number = -999;

        coords.forEach(element => {
            let elLon: number = element[0];
            let elLat: number = element[1];
            if (elLon > tempLong) {
                tempLong = elLon;
            }
            if (elLat > tempLat) {
                tempLat = elLat;
            }
        });
        tl = [tempLong, tempLat] as Coordinate;
        return tl;
    }

    const calcTopRight = (coords: Coordinate[]): Coordinate => {
        let tl: Coordinate = [0, 0];

        let tempLong: number = -999;
        let tempLat: number = 999;

        coords.forEach(element => {
            let elLon: number = element[0];
            let elLat: number = element[1];
            if (elLon > tempLong) {
                tempLong = elLon;
            }
            if (elLat < tempLat) {
                tempLat = elLat;
            }
        });
        tl = [tempLong, tempLat] as Coordinate;
        return tl;
    } */

    const determinBackgroundSave = (): string => {
        let sBackgroundSave: string = 'primary.contrastText';
        /* console.log("props.mapFiles: ", mapFiles); */
        if (mapFiles !== undefined) {
            let bPgwFound: boolean = false;
            mapFiles.files.forEach(element => {
                if (element !== undefined) {
                    if (element.pgw !== null) {
                        bPgwFound = true;
                    }
                    else {
                        /* sBackgroundSave = 'success.main'; */
                    }
                }
            });
            if (bPgwFound) {
                sBackgroundSave = 'primary.contrastText';
            }
            else {
                sBackgroundSave = 'success.main';
            }
        }
        return sBackgroundSave;
    }

    const determinColorSave = (): string => {
        let sColorSave: string = 'primary.main';
        if (mapFiles !== undefined) {
            let bPgwFound: boolean = false;
            mapFiles.files.forEach(element => {
                if (element !== undefined) {
                    /* console.log("element.pgw: ", element.pgw); */
                    if (element.pgw !== null) {
                        bPgwFound = true;
                    }
                    else {
                        /* sColorSave = 'success.contrastText'; */
                    }
                }
                if (bPgwFound) {
                    sColorSave = 'primary.main';
                }
                else {
                    sColorSave = 'success.contrastText';
                }
            });
        }
        return sColorSave;
    }

    const saveChangesToMap = () => {
        /*         console.log("mapData local: ", mapData);
                console.log("mapData global: ", props.mapData); */
        setDisableSave(true);
        /* console.log("mapData: ", mapData);
        console.log("mapData props: ", props.mapData);
        console.log("prePgwMapdata: ", prePgwMapdata); */
        /* const mapDataJSON = JSON.stringify(mapData); */
        const mapDataJSON = JSON.stringify(prePgwMapdata);
        callEditMapMutation(mapDataJSON);
    }

    /* const saveUpdatesToMap = (data: MapData) => {
        const mapDataJSON = JSON.stringify(data);
        callUpdateMapMutation(mapDataJSON);
    } */

    /* const callUpdateMapMutation = async (jsonString: string) => {
        const data: Object = { mapUuid: previewApiProject.mapUuid, bodydata: { "mapData": jsonString } }
        await editMap(data)
            .then((response: any) => {
                console.log("transform / raw / pgws updated!");
                // props.setMainMapData(response.data.data.mapData);
                props.requeryMap();
            }
            );
    } */

    const callEditMapMutation = async (jsonString: string) => {
        const data: Object = { mapUuid: previewApiProject.mapUuid, bodydata: { "mapData": jsonString } }
        await editMap(data)
            .then((response: any) => {
                console.log("uploadAllPgws()");
                uploadAllPgws();
            }
            );
    }

    const uploadAllPgws = () => {
        pgwUploads = 0;
        if (prePgwMapdata !== undefined) {
            if (prePgwMapdata.layerPgws !== undefined) {
                console.log("prevMap props.apiProject: ", props.apiProject);
                initUploadPgw(prePgwMapdata.layerPgws[0]);
            }
        }

    }

    const initUploadPgw = (element: LayerPgw) => {
        let content: string = element.line_0 + "\n" + element.line_1 + "\n" + element.line_2 + "\n" + element.line_3 + "\n" + element.line_4 + "\n" + element.line_5;
        callUploadPgw(content, element.layerId);
    }

    const callUploadPgw = async (content: string, layerId: string) => {
        const file = new File([content], (layerId + ".pgw"), {
            type: 'text/plain',
        });
        let formdata = new FormData();
        formdata.append('mapPgw', file);
        formdata.append('layer', layerId);

        await uploadPgwToLayer({ "bodyFormData": formdata, "mapUuid": previewApiProject.mapUuid })
            .then((responsePgw: any) => {
                if (responsePgw.success === false) {
                    console.log("ERROR: " + responsePgw);
                    /* setLoading('none'); */
                }
                else {
                    console.log("Uploading PGW to Server DONE!");
                    pgwUploads++;
                    if (prePgwMapdata !== undefined) {
                        if (prePgwMapdata.layerPgws !== undefined) {
                            if (prePgwMapdata.layerPgws[pgwUploads] !== undefined) {
                                initUploadPgw(prePgwMapdata.layerPgws[pgwUploads]);
                            }
                            else {
                                console.log("All PGW uploads finished!");
                                props.requeryMap();
                                setTimeout(() => {
                                    setDisableSave(false);
                                }, 3000);

                            }
                        }
                    }

                }
            }
            );
    }

    const handleVisibilityChange = (event: Event, newValue: number | number[]) => {
        setVisibilityValue(newValue as number);
        geoimgLayer?.setOpacity(newValue as number);
    };

    const setPgwsForLayers = (pgwString: string): LayerPgw[] => {
        let pgwStringArray: string[] = pgwString.split('\n');
        let pgwArray: LayerPgw[] = [];
        if (layerIds !== undefined) {
            layerIds.forEach(layerId => {
                let layerPgw: LayerPgw = {
                    layerId: layerId,
                    line_0: pgwStringArray[0],
                    line_1: pgwStringArray[1],
                    line_2: pgwStringArray[2],
                    line_3: pgwStringArray[3],
                    line_4: pgwStringArray[4],
                    line_5: pgwStringArray[5]
                }
                pgwArray.push(layerPgw);
            });
            /* console.log("PGW COORDS: ", toLonLat([parseFloat(pgwStringArray[4]),parseFloat(pgwStringArray[5])])); */
            dispatch(setCurrentMapDataController_layerPgws(pgwArray));

        }
        return pgwArray
    }

    useEffect(() => {
        if (props.mapFiles !== undefined) {
            if (maskPolygon) {
                const geosrc = new GeoImageSource({
                    // url: 'data/Layer-L03.png', 
                    url: apiUrl + props.mapFiles.files[props.mapIndex].png.thumbnail, //mapsAssistant_layerImages.images[0].imagesrc,
                    imageCenter: projTranslation,
                    imageScale: [projScale[0], projScale[1]],
                    imageRotate: projRotation,
                    imageMask: (maskPolygon as Polygon).getCoordinates()[0],
                })

                const coll: Collection<BaseLayer> = mapRef.current?.getLayers() as Collection<BaseLayer>;
                if (mapRef.current?.getLayers()) {
                    coll.forEach((element: any) => {
                        if (element instanceof ImageLayer === true) {
                            element.setSource(geosrc);
                        }
                    });
                }
            }
        }
    }, [props.mapIndex]);

    useEffect(() => {
        console.log("prevMap, projRotation: ", projRotation);
        mapRef.current?.getView().setRotation((projRotation * -1));
        dispatch(setCurrentMapDataController_rotation(parseFloat((radians_to_degrees(projRotation * -1)).toFixed(4))));
    }, [projRotation])

    /* useEffect(() => {
        console.log("NEW mapsGeoA[0] / mapsGeoB[0] : " + mapsGeoA[0] + " / " + mapsGeoB[0]);
    }, [mapsGeoA]) */

    useEffect(() => {
        /* console.log("mapsGeoA: ", mapsGeoA);
        console.log("mapsGeoB: ", mapsGeoB); */

        if (mapsFiles !== undefined && mapsGeoA !== undefined && mapsGeoB !== undefined) {
            const search = new SearchNominatim({
                //target: $(".options").get(0),
                polygon: false,
                reverse: true,
            });
            if (ref.current && !mapRef.current) {
                mapRef.current = new Map({
                    layers: [new TileLayer({ source: new OSM() })],
                    view: new View({ /* projection: 'EPSG:3857',  */center: [(mapsGeoA[0] + mapsGeoB[0]) / 2, (mapsGeoA[1] + mapsGeoB[1]) / 2], zoom: 18, constrainRotation: false }),
                    target: 'vectorLayerMap',
                    controls: [new Attribution(), new Fullscreen(), new Zoom(), new ScaleLine()],
                    interactions: defaults({
                        altShiftDragRotate: false,
                        shiftDragZoom: false,
                    }),

                });
                mapRef.current.addControl(search);

                const sLayer = new VectorLayer({
                    source: new VectorSource(),
                    style: new Style({
                        image: new CircleStyle({
                            radius: 5,
                            stroke: new Stroke({
                                color: 'rgb(255,165,0)',
                                width: 3
                            }),
                            fill: new Fill({
                                color: 'rgba(255,165,0,.3)'
                            })
                        }),
                        stroke: new Stroke({
                            color: 'rgb(255,165,0)',
                            width: 3
                        }),
                        fill: new Fill({
                            color: 'rgba(255,165,0,.3)'
                        })
                    })
                });
                mapRef.current.addLayer(sLayer);

                if (mapRef.current) {

                    const extentGeo: Extent = boundingExtent([mapsGeoA, mapsGeoB]);
                    const hF: HelmertTransformation = new HelmertTransformation();
                    const modifiedNormalizedCoordinatesA: [number, number] = [(mapsPixelA[0] - (8192 / 2)), (mapsPixelA[1] - (8192 / 2))];
                    const modifiedNormalizedCoordinatesB: [number, number] = [(mapsPixelB[0] - (8192 / 2)), (mapsPixelB[1] - (8192 / 2))];
                    hF.setControlPoints([modifiedNormalizedCoordinatesA, modifiedNormalizedCoordinatesB], [mapsGeoA, mapsGeoB]);
                    // hF.setControlPoints([mapsPixelA, mapsPixelB], [mapsGeoA, mapsGeoB]);

                    const sc = hF.getScale();
                    // console.log("scale: ", sc);
                    const a = hF.getRotation();
                    const t = hF.getTranslation();
                    // console.log("center: ", t);
                    setProjScale(sc);
                    setProjRotation(a);
                    setProjTranslation(t);

                    const geom = fromExtent(extentGeo);
                    geom.scale(500);
                    setMaskPolygon(geom);

                    /* setHelmertTF(hF); */

                    const geosrc = new GeoImageSource({
                        // url: 'data/Layer-L03.png', 
                        url: apiUrl + mapsFiles.files[props.mapIndex].png.thumbnail, //mapsAssistant_layerImages.images[0].imagesrc,
                        imageCenter: t,
                        imageScale: [sc[0], sc[1]],
                        imageRotate: a,
                        imageMask: geom.getCoordinates()[0],
                    })
                    /* setCurrentGISource(geosrc); */


                    const newgeoimgLayer = new ImageLayer({
                        opacity: 0.7

                    });
                    newgeoimgLayer.setSource(geosrc);
                    setGeoImageLayer(newgeoimgLayer);
                    mapRef.current?.addLayer(newgeoimgLayer);
                    /* console.log("OLD mapsGeoA[0] / mapsGeoB[0] : " + mapsGeoA[0] + " / " + mapsGeoB[0]); */
                    mapRef.current?.getView().setCenter([(mapsGeoA[0] + mapsGeoB[0]) / 2, (mapsGeoA[1] + mapsGeoB[1]) / 2]);

                    const extent = new ExtentInteraction({ condition: shiftKeyOnly });
                    mapRef.current?.addInteraction(extent);


                    const topLeft: Coordinate = hF?.transform([4096, -4096]) as Coordinate;
                    const bottomRight: Coordinate = hF?.transform([-4096, 4096]) as Coordinate;
                    const topRight: Coordinate = hF?.transform([-4096, -4096]) as Coordinate;
                    const bottomLeft: Coordinate = hF?.transform([4096, 4096]) as Coordinate;

                    /* const bottomLeft: Coordinate = hF?.transform([4096, 4096]) as Coordinate;
                    const centerCoord: Coordinate = hF?.transform([0, 0]) as Coordinate; */

                    let bottomLeftGeo: Coordinate = toLonLat(bottomLeft);
                    let topLeftGeo: Coordinate = toLonLat(topLeft);
                    let bottomRightGeo: Coordinate = toLonLat(bottomRight);
                    let topRightGeo: Coordinate = toLonLat(topRight);

                    setBottomLeftGeoCoord(bottomLeftGeo);
                    setTopRightGeoCoord(topRightGeo);
                    setAs2Rotation(a);

                    const worldfile = require('world-file');
                    let uploadedMapWidth: number = mapsFiles.files[0].png.width; // mapsAssistant_layerImages.images[0].width;
                    /* console.log("topRightGeo [-4096, -4096]: ", topRightGeo);
                    console.log("topLeftGeo [4096, -4096]: ", topLeftGeo);
                    console.log("bottomRightGeo [-4096, 4096]: ", bottomRightGeo);
                    console.log("bottomLeftGeo [4096, 4096]: ", bottomRightGeo);
                    console.log("mapsGeoA [4096, 4096]: ", toLonLat(mapsGeoA));
                    console.log("mapsGeoB [-4096, -4096]: ", toLonLat(mapsGeoB));

                    console.log("hF: ", hF);
                    console.log("hF[matrix]: ", hF["matrix"]); */
                    const wf = worldfile.generateWorldFile(uploadedMapWidth, uploadedMapWidth, { tr: [topRightGeo[0], topRightGeo[1]], tl: [topLeftGeo[0], topLeftGeo[1]], br: [bottomRightGeo[0], bottomRightGeo[1]] });
                    /* console.log("INIT WOLRDFILE: ", wf); */
                    const worldString: string = wf as string;
                    /* console.log("INIT worldString: ", worldString);
                    console.log("INIT worldArray: ", worldString.split('\n')); */

                    /* const hfMatrix: number[] = hF["matrix"] as number[];
                    const pgwTest: string[] = [];
                    pgwTest.push("" + (hfMatrix[4] / 2));
                    pgwTest.push("" + (hfMatrix[3] / 2));
                    pgwTest.push("" + (hfMatrix[3] / 2));
                    pgwTest.push("" + ((hfMatrix[4] / 2) * (-1)));
                    pgwTest.push("" + (hfMatrix[2]));
                    pgwTest.push("" + (hfMatrix[5]));

                    console.log("pgwTest: ", pgwTest); */

                    /* 
                    0 = 0 (/0.5)
                    3 = 1 & 2 (/0.5)
                    2 = 5
                    6 = 6
                    */

                    // pgwTest.push

                    let pgwArray: LayerPgw[] = setPgwsForLayers(worldString);

                    let lonlatA = transform(mapsGeoA, 'EPSG:3857', 'EPSG:4326');
                    let lonA = lonlatA[0];
                    let latA = lonlatA[1];

                    let lonlatB = transform(mapsGeoB, 'EPSG:3857', 'EPSG:4326');
                    let lonB = lonlatB[0];
                    let latB = lonlatB[1];

                    /* console.log("bottomLeftGeo A: " + lonA + " / " + latA);
                    console.log("topRightGeo B: " + lonB + " / " + latB); */

                    let rawPoints: RawPoints = {
                        pointA_imageX: mapsPixelA[0],
                        pointA_imageY: mapsPixelA[1],
                        pointB_imageX: mapsPixelB[0],
                        pointB_imageY: mapsPixelB[1],
                        pointA_geoLatitude: latA,
                        pointA_geoLongitude: lonA,
                        pointB_geoLatitude: latB,
                        pointB_geoLongitude: lonB
                    }

                    /* mapRef.current?.getView().setRotation((projRotation * -1));
                    dispatch(setCurrentMapDataController_rotation(radians_to_degrees(projRotation * -1))); */
                    dispatch(setCurrentMapDataController_rawPoints(rawPoints))

                    let distance = getDistance([bottomLeftGeo[0], bottomLeftGeo[1]], [bottomRightGeo[0], bottomRightGeo[0]]) / 1000;

                    oMappingCalculator.setX3dToSvgTransformProperty("pointAX3dX", 0);
                    oMappingCalculator.setX3dToSvgTransformProperty("pointAX3dZ", 0);
                    oMappingCalculator.setX3dToSvgTransformProperty("pointASvgX", 0);
                    oMappingCalculator.setX3dToSvgTransformProperty("pointASvgY", 8192);
                    oMappingCalculator.setX3dToSvgTransformProperty("pointBX3dX", distance);
                    oMappingCalculator.setX3dToSvgTransformProperty("pointBX3dZ", (distance * -1));
                    oMappingCalculator.setX3dToSvgTransformProperty("pointBSvgX", 8192);
                    oMappingCalculator.setX3dToSvgTransformProperty("pointBSvgY", 0);
                    oMappingCalculator.setX3dToSvgTransformProperty("imageWidth", 8192);
                    oMappingCalculator.setX3dToSvgTransformProperty("imageHeight", 8192);

                    oMappingCalculator.setSvgToGeoTransformProperty("rotationAngle", a);
                    oMappingCalculator.setSvgToGeoTransformProperty("rotationPointX", 4096);
                    oMappingCalculator.setSvgToGeoTransformProperty("rotationPointY", 4096);
                    // oMappingCalculator.setSvgToGeoTransformProperty("point-a-name", "left-bottom to south-west");
                    oMappingCalculator.setSvgToGeoTransformProperty("pointASvgX", 0);
                    oMappingCalculator.setSvgToGeoTransformProperty("pointASvgY", 8192);
                    oMappingCalculator.setSvgToGeoTransformProperty("pointAGeoLatitude", bottomLeftGeo[1]);
                    oMappingCalculator.setSvgToGeoTransformProperty("pointAGeoLongitude", bottomLeftGeo[0]);
                    // oMappingCalculator.setSvgToGeoTransformProperty("point-b-name", "right-top to north-east");
                    oMappingCalculator.setSvgToGeoTransformProperty("pointBSvgX", 8192);
                    oMappingCalculator.setSvgToGeoTransformProperty("pointBSvgY", 0);
                    oMappingCalculator.setSvgToGeoTransformProperty("pointBGeoLatitude", topRightGeo[1]);
                    oMappingCalculator.setSvgToGeoTransformProperty("pointBGeoLongitude", topRightGeo[0]);

                    let x3dToSvgObject: X3dToSvg = {
                        xSlope: oMappingCalculator.getX3dToSvgTransformProperty("xSlope"),
                        xConstant: oMappingCalculator.getX3dToSvgTransformProperty("xConstant"),
                        ySlope: oMappingCalculator.getX3dToSvgTransformProperty("ySlope"),
                        yConstant: oMappingCalculator.getX3dToSvgTransformProperty("yConstant"),
                        imageWidth: 8192,
                        imageHeight: 8192
                    }

                    let svgToGeoObject: SvgToGeo = {
                        scale: oMappingCalculator.getSvgToGeoTransformProperty("scale"),
                        xOffset: oMappingCalculator.getSvgToGeoTransformProperty("xOffset"),
                        yOffset: oMappingCalculator.getSvgToGeoTransformProperty("xOffset"),
                        angleRotation: oMappingCalculator.getSvgToGeoTransformProperty("angleRotation"),
                        xRotationPoint: oMappingCalculator.getSvgToGeoTransformProperty("xRotationPoint"),
                        yRotationPoint: oMappingCalculator.getSvgToGeoTransformProperty("yRotationPoint"),
                        longitudeSlope: oMappingCalculator.getSvgToGeoTransformProperty("longitudeSlope"),
                        longitudeConstant: oMappingCalculator.getSvgToGeoTransformProperty("longitudeConstant"),
                        latitudeSlope: oMappingCalculator.getSvgToGeoTransformProperty("latitudeSlope"),
                        latitudeConstant: oMappingCalculator.getSvgToGeoTransformProperty("latitudeConstant"),
                        groundElevation: 15
                    }

                    try {
                        dispatch(setCurrentMapDataController_x3dToSvg(x3dToSvgObject));
                        dispatch(setCurrentMapDataController_svgToGeo(svgToGeoObject));
                    } catch (error) {
                        // do nothing
                        // console.log("error: ", error);
                        let tN: transformNode = {
                            x3dToSvg: x3dToSvgObject,
                            svgToGeo: svgToGeoObject
                        }
                        dispatch(setCurrentMapDataController_transformNode(tN));
                    }

                    let tN2: transformNode = {
                        x3dToSvg: x3dToSvgObject,
                        svgToGeo: svgToGeoObject
                    }

                    console.log("Prev props.mapData: ", props.mapData);

                    if (props.mapData !== undefined) {
                        let mapUpdateData: MapData = structuredClone(props.mapData);
                        mapUpdateData.transform = tN2;
                        mapUpdateData.layerPgws = pgwArray;
                        mapUpdateData.rawPoints = rawPoints;

                        console.log("mapData: ", mapData);
                        console.log("mapData props: ", props.mapData);
                        console.log("prePgwMapdata: ", mapUpdateData);

                        setPrePgwMapdata(mapUpdateData);
                    }
                    else {
                        let mapUpdateData2: MapData = structuredClone(mapData);
                        mapUpdateData2.transform = tN2;
                        mapUpdateData2.layerPgws = pgwArray;
                        mapUpdateData2.rawPoints = rawPoints;

                        console.log("mapData2: ", mapData);
                        console.log("mapData2 props: ", props.mapData);
                        console.log("prePgwMapdata2: ", mapUpdateData2);

                        setPrePgwMapdata(mapUpdateData2);
                    }



                    /* console.log("x3dToSvgObject: ", x3dToSvgObject);
                    console.log("svgToGeoObject: ", svgToGeoObject); */
                }

                search.on('select', e => { // console.log(e);
                    sLayer.getSource()?.clear()
                    // Check if we get a geojson to describe the search
                    if (e.search.geojson) {
                        const format = new GeoJSON()
                        const f = format.readFeature(e.search.geojson, {
                            dataProjection: 'EPSG:4326',
                            featureProjection: mapRef.current?.getView().getProjection(),
                        })
                        sLayer.getSource()?.addFeature(f)
                        const view = mapRef.current?.getView()
                        const resolution = view?.getResolutionForExtent(f.getGeometry()!.getExtent(), mapRef.current?.getSize())
                        const zoom = view?.getZoomForResolution(resolution as number);
                        const center = getCenter(f.getGeometry()!.getExtent());

                        // redraw before zoom
                        setTimeout(() => {
                            view?.animate({
                                center,
                                zoom: Math.min(zoom as number, 16),
                            })
                        }, 100)
                    } else {
                        mapRef.current?.getView().animate({
                            center: e.coordinate,
                            zoom: Math.max(mapRef.current?.getView().getZoom()!, 16),
                        })
                    }
                })
            }
        }
    }, []);

    const handleChangeAdvanced = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowAS2Values(event.target.checked);
    };

    const handleCopy = async (content: string) => {
        console.log("content: ", content);
        try {
            await navigator.clipboard.writeText(content);
            console.log('Copied to clipboard:', content);
        } catch (error) {
            console.error('Unable to copy to clipboard:', error);
        }
    };

    return (
        <div id="vectorLayerMap" style={{ height: '77vh', width: '92vw', marginLeft: '0vh' }} className="map-container" ref={ref}>
            <Stack spacing={2} direction="row" sx={{ mb: 1, position: 'absolute', bottom: '0.0em', left: '40%', width: '20%', pl: '0.5em', pr: '0.5em', backgroundColor: '#12526A', zIndex: 1 }} alignItems="center">
                <VisibilityOffIcon />
                <Slider aria-label="Volume" min={0.0} max={1.0} step={0.01} value={visibilityValue} onChange={handleVisibilityChange} />
                <VisibilityIcon />
            </Stack>
            <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', pl: '0.5vw', bottom: '0.5em', left: '67em', backgroundColor: '#092935', zIndex: 1 }}>
                <FormControlLabel labelPlacement="end" control={<Checkbox color='default' aria-label='active'
                    checked={showAS2Values}
                    onChange={handleChangeAdvanced}
                    sx={{
                        pt: '0.2em',
                        pb: '0.2em',
                        color: 'rgba(255,255,255,1)'
                    }}
                ></Checkbox>} label={t('tools.maps.showAs2Values')} aria-required />
                {showAS2Values === true ?
                    <Box sx={{ display: 'flex', position: 'absolute', left: 0, bottom: '1.9em', flexDirection: 'column', pl: '0.5vw', backgroundColor: '#092935', }}>
                        <Typography gutterBottom align="left" component="div" sx={{
                            pl: '2vh',
                            pt: '0.5vh',
                            fontSize: '16px'
                        }}>
                            {t('tools.maps.as2Values')}:
                        </Typography>
                        <Box sx={{ display: 'flex', width: '17.5vw', justifyContent: 'space-around' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '10em' }}>
                                <AS2TextField
                                    margin="normal"
                                    label="rotation-angle-radians"
                                    size="small"
                                    defaultValue={as2Rotation.toFixed(6)} 
                                    onFocus={() => handleCopy(as2Rotation.toFixed(6).toString())}
                                    inputProps={{ readOnly: true }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '10em' }}>
                                <AS2TextField
                                    margin="normal"
                                    label="rotation-angle-degrees"
                                    size="small"
                                    defaultValue={radians_to_degrees(as2Rotation).toFixed(4)}
                                    onFocus={() => handleCopy(radians_to_degrees(as2Rotation).toFixed(4).toString())}
                                    inputProps={{ readOnly: true }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', width: '35vw', justifyContent: 'space-around' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '10em' }}>
                                <AS2TextField
                                    margin="normal"
                                    label="point-a-image-x"
                                    size="small"
                                    defaultValue={0}
                                    onFocus={() => handleCopy("0")}
                                    inputProps={{ readOnly: true }}
                                />
                                <AS2TextField
                                    margin="normal"
                                    label="point-a-image-y"
                                    size="small"
                                    defaultValue={0}
                                    onFocus={() => handleCopy("0")}
                                    inputProps={{ readOnly: true }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '10em' }}>
                                <AS2TextField
                                    margin="normal"
                                    label="point-a-geo-longitude"
                                    size="small"
                                    defaultValue={bottomLeftGeoCoord[0].toFixed(6)}
                                    onFocus={() => handleCopy(bottomLeftGeoCoord[0].toFixed(6).toString())}
                                    inputProps={{ readOnly: true }}
                                />
                                <AS2TextField
                                    margin="normal"
                                    label="point-a-geo-latitude"
                                    size="small"
                                    defaultValue={bottomLeftGeoCoord[1].toFixed(6)}
                                    onFocus={() => handleCopy(bottomLeftGeoCoord[1].toFixed(6).toString())}
                                    inputProps={{ readOnly: true }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '10em' }}>
                                <AS2TextField
                                    margin="normal"
                                    label="point-b-image-x"
                                    size="small"
                                    defaultValue={8192}
                                    onFocus={() => handleCopy((8192).toString())}
                                    inputProps={{ readOnly: true }}
                                />
                                <AS2TextField
                                    margin="normal"
                                    label="point-b-image-y"
                                    size="small"
                                    defaultValue={8192}
                                    onFocus={() => handleCopy((8192).toString())}
                                    inputProps={{ readOnly: true }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '10em' }}>
                                <AS2TextField
                                    margin="normal"
                                    label="point-b-geo-longitude"
                                    size="small"
                                    defaultValue={topRightGeoCoord[0].toFixed(6)}
                                    onFocus={() => handleCopy(topRightGeoCoord[0].toFixed(6).toString())}
                                    inputProps={{ readOnly: true }}
                                />
                                <AS2TextField
                                    margin="normal"
                                    label="point-b-geo-latitude"
                                    size="small"
                                    defaultValue={topRightGeoCoord[1].toFixed(6)}
                                    inputProps={{ readOnly: true }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    : null
                }
            </Box>
            <Box sx={{ position: 'absolute', top: '-4.5em', right: '44.6em' }}>
                <CssWhiteButton
                    type="submit"
                    /* fullWidth */
                    /* disabled={determinDisabledSave()} */
                    disabled={bDisableSave}
                    variant="contained"
                    onClick={saveChangesToMap}
                    sx={{
                        /* position: 'absolute',
                        bottom: '4vh',
                        left: '7vh', */
                        mt: '2.5vh',
                        ml: '2vh',
                        backgroundColor: determinBackgroundSave(),
                        color: determinColorSave(),
                        width: 'fit-content',
                        '&:hover': {
                            color: 'primary.contrastText',
                        },
                    }}
                >
                    {t('tools.maps.save_geo')}
                    <SaveIcon sx={{
                        marginLeft: '6px'
                    }}></SaveIcon>
                </CssWhiteButton>
            </Box>
        </div>
    );
}

export default PrevMap;