import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import mediacardIcon from "../../mapcard.jpg";
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { setCurrentGuiController_mapsAssistant, setCurrentGuiController_mapsAssistant_uploads, setCurrentGuiController_mapsFiles, setCurrentGuiController_mapsGeoCoordinates_A, setCurrentGuiController_mapsGeoCoordinates_B, setCurrentGuiController_mapsPixelCoordinates_A, setCurrentGuiController_mapsPixelCoordinates_B, setCurrentGuiController_mapsRotation } from './../slices/guiControllerSlice';
import { setCurrentGuiController_mapsManualSetup } from './../slices/guiControllerSlice';
import { useAppDispatch } from '../hooks';

const CssWhiteButton = styled(Button)({
  '& .MuiButton-root:hover': {
    color: '#ffffff',
  },
});

export default function MediaCard() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleAssistantStart = () => {
    console.log("handleAssistantStart WTF");
    dispatch(setCurrentGuiController_mapsAssistant_uploads(0))
    dispatch(setCurrentGuiController_mapsFiles({
      files: []
    }));
    dispatch(setCurrentGuiController_mapsGeoCoordinates_A([-1, -1]));
    dispatch(setCurrentGuiController_mapsGeoCoordinates_B([-1, -1]));
    dispatch(setCurrentGuiController_mapsPixelCoordinates_A([-1, -1]));
    dispatch(setCurrentGuiController_mapsPixelCoordinates_B([-1, -1]));
    dispatch(setCurrentGuiController_mapsRotation(0));
    dispatch(setCurrentGuiController_mapsAssistant(true));
    dispatch(setCurrentGuiController_mapsManualSetup(false));
  }

  return (
    <Card sx={{ maxWidth: 306 }}>
      <CardMedia
        sx={{ height: 144 }}
        image={mediacardIcon}
        title="green iguana"
      />
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Typography gutterBottom variant="h5" align="left" component="div">
          {t('tools.maps.setup_assistant')}
        </Typography>
        <Typography variant="body2" align="left" color="text.secondary">
          {t('tools.maps.stepbystep')}
        </Typography>
        <CssWhiteButton
          type="submit"
          /* fullWidth */
          variant="contained"
          onClick={handleAssistantStart}
          sx={{
            mt: 3,
            mb: 0,
            backgroundColor: 'primary.contrastText',
            color: 'primary.main',
            width: 'fit-content',
            '&:hover': {
              color: 'primary.contrastText',
            },
          }}
        >
          {t('tools.maps.start')}
          <NavigateNextIcon sx={{
            marginLeft: '6px'
          }}></NavigateNextIcon>
        </CssWhiteButton>
      </CardContent>
    </Card>
  );
}
