import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, SetStateAction } from "react";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";

// url={betaPreviewUrl} closeIframe={setShowBetaPreview}

interface IModalIframeProps {
    title: string;
    url: string;
    hexBackgroundColor: string;
    closeIframe: Dispatch<SetStateAction<boolean>>;
}


export default function ModalIFrameElement(props: React.PropsWithChildren<IModalIframeProps>) {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}
            open
        >
            <Box sx={{ backgroundColor: '#092935', marginLeft: '5em', marginTop: '7em', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '1em', width: 'calc(100% - 6em)', height: 'calc(100% - 7em)' }}>
                <IconButton /* color="secondary" */ size="large" onMouseDown={() => {
                    props.closeIframe(false);
                }}
                    sx={{ position: 'absolute', right: '1.0em', top: '4.25em' }}
                >
                    <CloseIcon />
                </IconButton>
                <h4 style={{
                    paddingLeft: '5em',
                    paddingRight: '5em',
                    paddingTop: '0.5em',
                    paddingBottom: '0.5em',
                    marginTop: '0em',
                    marginBottom: '0em',
                    color: '#092935',
                    backgroundColor: props.hexBackgroundColor,
                }}>{props.title}</h4>
                <iframe src={props.url} style={{ width: '100%', height: '100%', marginLeft: '0em' }}></iframe>
            </Box>

        </Backdrop>
    )
}