import * as React from 'react';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useTranslation } from 'react-i18next';
import "./scrollList.css";
import Typography from '@mui/material/Typography';
import { useGetAddonsQuery } from '../slices/apiSlice';
import { useAppSelector, useAppDispatch } from '../hooks';
import { set_addons } from '../slices/currentAdminEditProjectSlice';
import { uuidObject } from '../../interfaces/commonInterfaces';

function not(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

interface SortedAddon {
    uuid: string;
    name: string;
    active: boolean;
    description: string;
}

const allAddons: SortedAddon[] = [
    {
        "uuid": "1e46c240-b86c-44c2-9876-d663f0469e07",
        "name": "3D-Wayfinding (Guide3D)",
        "active": true,
        "description": ""
    },
    {
        "uuid": "e9761eaf-a04e-47e2-a250-7bf8a040fdea",
        "name": "Customized Screensaver",
        "active": true,
        "description": ""
    },
    {
        "uuid": "1a3dab93-67f5-4303-adc6-8f34c57b6c90",
        "name": "Banner Ad",
        "active": true,
        "description": ""
    },
    {
        "uuid": "a8ae7535-e0d4-4777-a156-064d20bc8faa",
        "name": "Offline-Mode",
        "active": true,
        "description": ""
    },
    {
        "uuid": "e4538bbd-6b53-4dd1-bf7e-89877b4d175e",
        "name": "'Google Play Store'-App",
        "active": true,
        "description": ""
    },
    {
        "uuid": "489ce4da-759b-4569-86db-880a5cf3d40c",
        "name": "Additional Language",
        "active": true,
        "description": ""
    },
    {
        "uuid": "2470e866-3dc1-486e-b495-cc2c0cb255e6",
        "name": "Data connection",
        "active": true,
        "description": ""
    },
    {
        "uuid": "a01280da-b771-44a8-a90f-aefe1e6e7995",
        "name": "Usage-Statistics",
        "active": true,
        "description": ""
    },
    {
        "uuid": "4e27ae72-e7f0-44c2-bcd9-c1d8527602c1",
        "name": "3D-Environment-Map",
        "active": true,
        "description": ""
    },
    {
        "uuid": "94900e2d-9ec5-4773-8262-63b59ba6a461",
        "name": "Level-of-Detail (LOD)",
        "active": true,
        "description": ""
    },
    {
        "uuid": "0de03575-3bc7-456e-8bf5-5bc65f9f44ec",
        "name": "Sign design according to customer requirements",
        "active": false,
        "description": ""
    }
]

export default function AddonsTransferList() {
    const currentAdminEditProject = useAppSelector((state) => state.currentAdminEditProject.value);
    const { data, error, isLoading } = useGetAddonsQuery(currentAdminEditProject.projectId.toString());
    const [apiReady, setApiReady] = React.useState(false);
    const [checked, setChecked] = React.useState<readonly string[]>([]);
    const [addonList, setAddonList] = React.useState<readonly string[]>([]);
    const [left, setLeft] = React.useState<readonly string[]>([]);
    const [right, setRight] = React.useState<readonly string[]>([]);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    /* const [fill, setFill] = React.useState(false); */
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const [usedAddons, setUsedAddons] = React.useState([{
        uuid: "",
        name: "",
        active: false,
        description: "",
    } as SortedAddon]);

    React.useEffect(() => {
        if (currentAdminEditProject.projectId === 9999999) {
            const addonsUuidList: uuidObject[] = [];
            allAddons.forEach(addonObject => {
                left.forEach(element => {
                    if (element === addonObject.name) {
                        addonsUuidList.push(addonObject);
                    }
                });
            });
            dispatch(set_addons(addonsUuidList));
            console.log("Addons SET in AddProject context! => ", addonsUuidList);
        }
        else {
            const addonsUuidList: uuidObject[] = [];
            allAddons.forEach(addonObject => {
                left.forEach(element => {
                    if (element === addonObject.name) {
                        addonsUuidList.push(addonObject);
                    }
                });
            });
            dispatch(set_addons(addonsUuidList));
            console.log("Addons SET in EditProject context! => ", addonsUuidList);
        }
    }, [left, currentAdminEditProject.projectId, dispatch]);

    React.useEffect(() => {
        /* console.log("####################### API getAddons");
        console.log("################### isLoading: ", isLoading);
        console.log("################### error: ", error);
        console.log("################### data: ", data);
        console.log("####################### API getAddons"); */
        /*  handleApiProjectsList(); */
        if (isLoading === false && error === undefined) {

            const addons = data.data as SortedAddon[];
            console.log("LOADED ADDONS: ", addons)
            setUsedAddons(addons);
            setApiReady(true);
            // setFill(true);
        }
    }, [data, error, isLoading]);

    React.useEffect(() => {
        if (apiReady === true) {
            /* console.log("trying AddonsFill...."); */
            let origAddonsList: string[] = [];
            allAddons.forEach((addon: SortedAddon) => {
                origAddonsList.push(addon.name);
            });
            if (usedAddons.length === 0) {
                setAddonList(origAddonsList);
                setRight(origAddonsList);
            }
            else {
                let activeAddonsList: string[] = [];
                let inactiveAddonList: string[] = [];
                usedAddons.forEach((activeAddon: SortedAddon) => {
                    activeAddonsList.push(activeAddon.name);
                });
                allAddons.forEach((inactiveAddon: SortedAddon) => {
                    if (activeAddonsList.indexOf(inactiveAddon.name) === -1) {
                        inactiveAddonList.push(inactiveAddon.name);
                    }
                });
                setAddonList(origAddonsList);
                setLeft(activeAddonsList);
                setRight(inactiveAddonList);
            }
            /* setFill(true); */
        }
        if (error !== undefined) {
            let origAddonsList: string[] = [];
            allAddons.forEach((addon: SortedAddon) => {
                origAddonsList.push(addon.name);
            });
            setAddonList(origAddonsList);
            setRight(origAddonsList);
        }

    }, [apiReady, usedAddons, error]);

    const sorter = (a: string, b: string) => {
        return addonList.indexOf(a) - addonList.indexOf(b);
    }

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked.sort(sorter));
    };

    const handleAllRight = () => {
        setRight(addonList);
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked).sort(sorter));
        setLeft(not(left, leftChecked).sort(sorter));
        setChecked(not(checked, leftChecked).sort(sorter));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked).sort(sorter));
        setRight(not(right, rightChecked).sort(sorter));
        setChecked(not(checked, rightChecked).sort(sorter));
    };

    const handleAllLeft = () => {
        /* let unsortedListL: string[] = left.concat(right); */
        setLeft(addonList);
        setRight([]);
    };

    const customList = (items: readonly string[], active: boolean) => (
        <div>
            {active === true ?
                <Typography variant="subtitle1" align='left' sx={{
                    paddingTop: '0rem'
                }}>{t('admin_projects.addons_active') + ':'}</Typography>
                :
                <Typography variant="subtitle1" align='left' sx={{
                    paddingTop: '0rem'
                }}>{t('admin_projects.addons_inactive') + ':'}</Typography>
            }
            <div className="list_scroll_area">
                <PerfectScrollbar>
                    <div className="content">
                        {items.map((value: string) => {
                            const labelId = `transfer-list-item-${value}-label`;

                            return (
                                <ListItem
                                    key={value}
                                    role="listitem"
                                    disablePadding
                                    button
                                    onClick={handleToggle(value)}
                                    sx={{
                                        ...(checked.indexOf(value) !== -1 && { backgroundColor: 'success.main', '&:hover': { backgroundColor: 'success.main' } })//'success.main' 
                                    }}
                                >
                                    {/* <ListItemIcon sx={{ padding: '0', paddingLeft: '1rem' }}>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            color='success'
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                            sx={{
                                                padding: '0 !important'                                                
                                            }}
                                        />
                                    </ListItemIcon> */}
                                    <ListItemText id={labelId} primary={value} sx={{ paddingLeft: '1rem' }} />
                                </ListItem>
                            );
                        })}
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
    );

    return (
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" marginLeft={'3rem'}>
            <Grid item paddingTop={"0px !important"}>{customList(left, true)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item paddingTop={"0px !important"}>{customList(right, false)}</Grid>
        </Grid>
    );
}