
import OSM from "ol/source/OSM";
import Map from 'ol/Map'
/* import Image from 'ol/layer/Image' */
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke"
import Fill from "ol/style/Fill";
import { useEffect } from "react";
import TileLayer from "ol/layer/WebGLTile";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import React from "react";
import SearchNominatim from "ol-ext/control/SearchNominatim";
import { useAppSelector, useAppDispatch } from '../hooks';
/* import {defaults as defaultControls} from "ol/control/defaults" */
import Attribution from "ol/control/Attribution"
import Fullscreen from "ol/control/FullScreen"
import Zoom from "ol/control/Zoom"
import { Modify, defaults } from 'ol/interaction';
import { boundingExtent, getCenter } from "ol/extent";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Coordinate } from "ol/coordinate";
import { setCurrentGuiController_mapsGeoCoordinates_A } from "../slices/guiControllerSlice";
import { setCurrentGuiController_mapsGeoCoordinates_B } from "../slices/guiControllerSlice";
import { MapData } from "../../interfaces/interfaceGuiController";
/* import { setCurrentMapDataController_rotation } from "../slices/MapDataSlice"; */

const pi = Math.PI;

interface ILocatorProps {
    locatorProp: {
        assistNumber: number;
        mapObject: MapData | undefined;
    }
}


function LocatorMap(props: React.PropsWithChildren<ILocatorProps>) {
    const dispatch = useAppDispatch();
    // pull refs
    const assistNumber: number = props?.locatorProp.assistNumber;
    /* const mapData: MapData = props?.locatorProp.mapObject as MapData; */
    const ref = React.useRef<HTMLDivElement>(null)
    const mapRef = React.useRef<Map>();
    const [mapRefCurr, setMapRefCurr] = React.useState(mapRef.current);
    const [aPlaced, setAPlaced] = React.useState(false);
    const [bPlaced, setBPlaced] = React.useState(false);
    /* let mapself: boolean = false; */


    // const mapsAssistant_rotation = useAppSelector((state) => state.guicontroller.value.mapsAssistant_rotation);
    const mapsGeoA = useAppSelector((state) => state.guicontroller.value.mapsGeoCoordinates_A);
    const mapsGeoB = useAppSelector((state) => state.guicontroller.value.mapsGeoCoordinates_B);
    const mapRotation = useAppSelector((state) => state.mapDataController.value.rotation);


    const degrees_to_radians = (radians: number): number => {
        /* const pi = Math.PI; */
        return radians * (pi / 180);
    }

    const iconFeatureA = new Feature({
        geometry: new Point([0, 0]),
        name: 'PinA',
    });


    const iconFeatureB = new Feature({
        geometry: new Point([0, 0]),
        name: 'PinB',
    });

    const iconStyleA = new Style({
        image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: 'https://creator.guide3d.com/data/pin_a.png',
        }),
    });
    const iconStyleB = new Style({
        image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: 'https://creator.guide3d.com/data/pin_b.png',
        }),
    });

    iconFeatureA.setStyle(iconStyleA);
    iconFeatureB.setStyle(iconStyleB);


    const vectorSourceA = new VectorSource({
        features: [iconFeatureA],
    });

    const vectorLayerA = new VectorLayer({
        source: vectorSourceA,
    });

    const vectorSourceB = new VectorSource({
        features: [iconFeatureB],
    });

    const vectorLayerB = new VectorLayer({
        source: vectorSourceB,
    });



    /*  const radians_to_degrees = (radians: number): number => {
         return radians * (180 / pi);
     } */


    /* mapRef.current?.getView().on(['change:rotation'], (e) => {
        const mapRot: number = Number(mapRef.current?.getView().getRotation());
        if (mapRot !== degrees_to_radians(mapsAssistant_rotation)) {
            dispatch(setCurrentGuiController_mapsAssistant_rotation(radians_to_degrees(mapRot)));
        }
    }) */

    useEffect(() => {
        /* console.log("assistNumber in LocatorMap: ", assistNumber); */
        if (assistNumber === 7) {
            if (mapRef.current) {
                if (aPlaced === false) {
                    setAPlaced(true);
                    // Der Layer wurde noch nicht hinzugefügt, fügen Sie ihn zur Karte hinzu
                    iconFeatureA.setGeometry(new Point(mapRef.current.getView().getCenter() as Coordinate))
                    mapRef.current.addLayer(vectorLayerA);

                    const modify = new Modify({
                        hitDetection: vectorLayerA,
                        source: vectorSourceA,
                    });
                    modify.on(['modifystart', 'modifyend'], (evt: any) => {
                        if (ref.current !== null) {
                            ref.current.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'pointer';
                            if (evt.type === 'modifyend') {
                                const modifiedCoordinates = evt.features.getArray()[0].getGeometry().getCoordinates() as [number, number];
                                dispatch(setCurrentGuiController_mapsGeoCoordinates_A(modifiedCoordinates));
                            }
                        }
                    });

                    mapRef.current?.addInteraction(modify);
                }
            }
        }
        if (assistNumber === 8) {
            if (mapRef.current) {
                if (mapsGeoB[0] === -1 || mapsGeoB[1] === -1) {
                    if (bPlaced === false) {
                        setBPlaced(true);
                        iconFeatureB.setGeometry(new Point(mapRef.current.getView().getCenter() as Coordinate))
                        mapRef.current.addLayer(vectorLayerB);

                        const modify = new Modify({
                            hitDetection: vectorLayerB,
                            source: vectorSourceB,
                        });
                        modify.on(['modifystart', 'modifyend'], (evt: any) => {
                            if (ref.current !== null) {
                                ref.current.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'pointer';
                                if (evt.type === 'modifyend') {
                                    const modifiedCoordinates = evt.features.getArray()[0].getGeometry().getCoordinates() as [number, number];
                                    dispatch(setCurrentGuiController_mapsGeoCoordinates_B(modifiedCoordinates));
                                }
                            }
                        });

                        mapRef.current?.addInteraction(modify);
                    }
                }
                if (mapsGeoA[0] !== -1 && mapsGeoA[1] !== -1 && mapsGeoB[0] !== -1 && mapsGeoB[1] !== -1) {
                    if (aPlaced === false) {
                        setAPlaced(true);
                        // Der Layer wurde noch nicht hinzugefügt, fügen Sie ihn zur Karte hinzu
                        iconFeatureA.setGeometry(new Point(mapsGeoA as Coordinate))
                        mapRef.current.addLayer(vectorLayerA);

                        const modify = new Modify({
                            hitDetection: vectorLayerA,
                            source: vectorSourceA,
                        });
                        modify.on(['modifystart', 'modifyend'], (evt: any) => {
                            if (ref.current !== null) {
                                ref.current.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'pointer';
                                if (evt.type === 'modifyend') {
                                    const modifiedCoordinates = evt.features.getArray()[0].getGeometry().getCoordinates() as [number, number];
                                    dispatch(setCurrentGuiController_mapsGeoCoordinates_A(modifiedCoordinates));
                                }
                            }
                        });

                        mapRef.current?.addInteraction(modify);
                    }
                    if (bPlaced === false) {
                        setBPlaced(true);
                        iconFeatureB.setGeometry(new Point(mapsGeoB as Coordinate))
                        mapRef.current.addLayer(vectorLayerB);

                        const modify = new Modify({
                            hitDetection: vectorLayerB,
                            source: vectorSourceB,
                        });
                        modify.on(['modifystart', 'modifyend'], (evt: any) => {
                            if (ref.current !== null) {
                                ref.current.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'pointer';
                                if (evt.type === 'modifyend') {
                                    const modifiedCoordinates = evt.features.getArray()[0].getGeometry().getCoordinates() as [number, number];
                                    dispatch(setCurrentGuiController_mapsGeoCoordinates_B(modifiedCoordinates));
                                }
                            }
                        });

                        mapRef.current?.addInteraction(modify);
                    }
                    mapRef.current.once('loadend', () => {
                        setTimeout(() => {
                            mapRef.current?.getView().fit(boundingExtent([mapsGeoA, mapsGeoB]), { padding: [0, 0, 0, 0], duration: 1000 });
                        }, 1000);
                    });

                }



            }
        }
    }, [assistNumber, mapRefCurr]);


    useEffect(() => {
        if (mapsGeoA[0] !== -1 && mapsGeoA[1] !== -1 && mapsGeoB[0] !== -1 && mapsGeoB[1] !== -1) {
            if (aPlaced === true) {
                iconFeatureA.getGeometry()?.setCoordinates([mapsGeoA[0], mapsGeoA[1]] as Coordinate);
            }
            if (bPlaced === true) {
                iconFeatureB.getGeometry()?.setCoordinates([mapsGeoB[0], mapsGeoB[1]] as Coordinate);
            }
        }

    }, [mapsGeoA, mapsGeoB]);


    useEffect(() => {
        if (mapRotation !== undefined) {
            mapRef.current?.getView().setRotation(degrees_to_radians(mapRotation as number));
        }
        else {
            mapRef.current?.getView().setRotation(degrees_to_radians(0));
        }
        /* dispatch(setCurrentMapDataController_rotation(mapsAssistant_rotation)); */
    }, [mapRef, mapRotation, mapRefCurr]);

    useEffect(() => {

        const search = new SearchNominatim({
            //target: $(".options").get(0),
            polygon: false,
            reverse: true,
        });

        /* if(degrees_to_radians(mapsAssistant_rotation) !== Number(mapRef.current?.getView().getRotation())){            
            console.log("Abweichung! MACH WAS!");
            mapself = true;
            mapRef.current?.getView().setRotation(degrees_to_radians(mapsAssistant_rotation));
        } */

        /* console.log("I'm mounting!"); */
        if (ref.current && !mapRef.current) {
            try {
                mapRef.current = new Map({
                    layers: [new TileLayer({ source: new OSM() })],
                    view: new View({ projection: 'EPSG:3857', center: [907253.8499651796, 6446275.841017161], zoom: 4.5, constrainRotation: false }),
                    target: ref.current,
                    controls: [new Attribution(), new Fullscreen(), new Zoom()],
                    interactions: defaults({
                        altShiftDragRotate: false
                    }),

                });
                mapRef.current.addControl(search);

                const sLayer = new VectorLayer({
                    source: new VectorSource(),
                    style: new Style({
                        image: new CircleStyle({
                            radius: 5,
                            stroke: new Stroke({
                                color: 'rgb(255,165,0)',
                                width: 3
                            }),
                            fill: new Fill({
                                color: 'rgba(255,165,0,.3)'
                            })
                        }),
                        stroke: new Stroke({
                            color: 'rgb(255,165,0)',
                            width: 3
                        }),
                        fill: new Fill({
                            color: 'rgba(255,165,0,.3)'
                        })
                    })
                });
                mapRef.current.addLayer(sLayer);

                search.on('select', e => { // console.log(e);
                    sLayer.getSource()?.clear()
                    // Check if we get a geojson to describe the search
                    if (e.search.geojson) {
                        const format = new GeoJSON()
                        const f = format.readFeature(e.search.geojson, {
                            dataProjection: 'EPSG:4326',
                            featureProjection: mapRef.current?.getView().getProjection(),
                        })
                        sLayer.getSource()?.addFeature(f)
                        const view = mapRef.current?.getView()
                        const resolution = view?.getResolutionForExtent(f.getGeometry()!.getExtent(), mapRef.current?.getSize())
                        const zoom = view?.getZoomForResolution(resolution as number);
                        const center = getCenter(f.getGeometry()!.getExtent());

                        // redraw before zoom
                        setTimeout(() => {
                            view?.animate({
                                center,
                                zoom: Math.min(zoom as number, 16),
                            })
                        }, 100)
                    } else {
                        mapRef.current?.getView().animate({
                            center: e.coordinate,
                            zoom: Math.max(mapRef.current?.getView().getZoom()!, 16),
                        })
                    }
                })
                setMapRefCurr(mapRef.current);
            } catch (error) {
                // mach nix
            }

        }
    }, [ref, mapRef]);

    return (
        <div style={{ height: '500px', width: '500px', marginLeft: '2vh' }} className="map-container" ref={ref}></div>
    );
}

export default LocatorMap;

