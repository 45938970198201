import * as React from 'react';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import MonitorIcon from '@mui/icons-material/Monitor';
import CancelIcon from '@mui/icons-material/Cancel';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LanguageIcon from '@mui/icons-material/Language';
import PrintIcon from '@mui/icons-material/Print';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CodeIcon from '@mui/icons-material/Code';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useTranslation } from 'react-i18next';
import "./scrollList.css";
import Typography from '@mui/material/Typography';
import { useGetProductsQuery } from '../slices/apiSlice';
import { useAppSelector, useAppDispatch } from '../hooks';
import { set_products } from '../slices/currentAdminEditProjectSlice';
import { uuidObject } from '../../interfaces/commonInterfaces';

function not(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

interface Product {
    uuid: string;
    name: string;
    active: boolean;
}

interface SortedProduct {
    uuid: string;
    name: string;
    active: boolean;
    order: string;
}

const allProducts: SortedProduct[] = [
    {
        "uuid": "d0704198-6348-49fc-9d15-4d8a7f825ad4",
        "name": "easyGuide kiosk retail",
        "active": true,
        "order": "a"
    },
    {
        "uuid": "04d0d8aa-7b73-43b9-b99f-1174459dd50f",
        "name": "easyGuide kiosk business",
        "active": true,
        "order": "b"
    },
    {
        "uuid": "043e6a47-55b3-425f-ab09-99c47b052844",
        "name": "easyGuide mobile",
        "active": true,
        "order": "c"
    },
    {
        "uuid": "29aa6d4d-5d9e-44b6-a893-c3330c9af794",
        "name": "easyGuide web",
        "active": true,
        "order": "d"
    },
    {
        "uuid": "33e4a4ce-2f43-4e9e-996e-2e214aca4fc4",
        "name": "easyGuide print",
        "active": true,
        "order": "e"
    },
    {
        "uuid": "35f6213a-5d4a-4c2b-b654-12c6061e976d",
        "name": "easyMaps mobile",
        "active": true,
        "order": "f"
    },
    {
        "uuid": "d2b04b3a-b66d-496b-94f8-6a3fdf97c920",
        "name": "easyMaps Kiosk",
        "active": true,
        "order": "g"
    },
    {
        "uuid": "298def55-ad40-4a8c-a945-f29fb2d96f29",
        "name": "carFinder",
        "active": true,
        "order": "h"
    },
    {
        "uuid": "6be06cd4-20be-4357-a0ba-4b0333cb815d",
        "name": "mapsEngine",
        "active": true,
        "order": "i"
    },
    {
        "uuid": "3e58388b-5f39-4c79-88b3-387d3ee72741",
        "name": "custom",
        "active": true,
        "order": "j"
    },
    {
        "uuid": "0d9a8d8b-de8b-4149-9e1e-4cd52e830d00",
        "name": "'End-of-life' kiosk-application (custom development or 'Guide3D kiosk')",
        "active": false,
        "order": "k"
    },
    {
        "uuid": "7d3cb709-2c0e-4357-bd98-0306c201b624",
        "name": "'End-of-life' mobile-application ('Guide3D mobile')",
        "active": false,
        "order": "l"
    },
    {
        "uuid": "c6b31bdc-cba9-4e60-9948-948430b21a32",
        "name": "'End-of-life' web-application (custom development or 'Guide3D web')",
        "active": false,
        "order": "m"
    }
]

export default function ProductsTransferList() {
    const currentAdminEditProject = useAppSelector((state) => state.currentAdminEditProject.value);
    const { data, error, isLoading } = useGetProductsQuery(currentAdminEditProject.projectId.toString());
    const [apiReady, setApiReady] = React.useState(false);
    const [checked, setChecked] = React.useState<readonly string[]>([]);
    const [prodList, setProdList] = React.useState<readonly string[]>([]);
    const [left, setLeft] = React.useState<readonly string[]>([]);
    const [right, setRight] = React.useState<readonly string[]>([]);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    /* const [fill, setFill] = React.useState(false); */
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const [usedProducts, setUsedProducts] = React.useState([{
        uuid: "",
        name: "",
        active: false,
    } as Product]);

    React.useEffect(() => {
        if (currentAdminEditProject.projectId === 9999999) {
            const productsUuidList: uuidObject[] = [];
            allProducts.forEach(productObject => {
                left.forEach(element => {
                    if (element === productObject.name) {
                        productsUuidList.push(productObject);
                    }
                });
            });
            dispatch(set_products(productsUuidList));
            /* console.log("Products SET in AddProject context! => ", productsUuidList); */
        }
        else {
            const productsUuidList: uuidObject[] = [];
            allProducts.forEach(productObject => {
                left.forEach(element => {
                    if (element === productObject.name) {
                        productsUuidList.push(productObject);
                    }
                });
            });
            dispatch(set_products(productsUuidList));
            /* console.log("Products SET in EditProject context! => ", productsUuidList); */
        }
    }, [left, currentAdminEditProject.projectId, dispatch]);

    React.useEffect(() => {

        /* console.log("################### isLoading: ", isLoading);
        console.log("################### error: ", error);
        console.log("################### data: ", data);
        console.log("####################### API getProducts"); */
        /*  handleApiProjectsList(); */
        if (isLoading === false && error === undefined) {
            if (apiReady === false) {
                /* console.log("####################### API getProducts LOADED"); */
                const products = data.data as Product[];
                /* console.log("products loaded: ", products); */
                setUsedProducts(products);
                setApiReady(true);
            }
        }
    }, [data, error, isLoading, apiReady]);

    React.useEffect(() => {
        if (apiReady === true) {
            let origProdList: string[] = [];
            allProducts.forEach((product: Product) => {
                origProdList.push(product.name);
            });
            if (usedProducts.length === 0) {
                setProdList(origProdList);
                setRight(origProdList);
            }
            else {
                let activeProdList: string[] = [];
                let inactiveProdList: string[] = [];
                usedProducts.forEach((activeProd: Product) => {
                    activeProdList.push(activeProd.name);
                });
                allProducts.forEach((inactiveProd: Product) => {
                    if (activeProdList.indexOf(inactiveProd.name) === -1) {
                        inactiveProdList.push(inactiveProd.name);
                    }
                });
                setProdList(origProdList);
                setLeft(activeProdList);
                setRight(inactiveProdList);
            }
            /* setFill(true); */
        }
        if (error !== undefined) {
            let origProdList: string[] = [];
            allProducts.forEach((product: Product) => {
                origProdList.push(product.name);
            });
            setProdList(origProdList);
            setRight(origProdList);
        }

    }, [apiReady, usedProducts, error]);


    const sorter = (a: string, b: string) => {
        return prodList.indexOf(a) - prodList.indexOf(b);
    }

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked.sort(sorter));
    };

    const handleAllRight = () => {
        setRight(prodList);
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked).sort(sorter));
        setLeft(not(left, leftChecked).sort(sorter));
        setChecked(not(checked, leftChecked).sort(sorter));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked).sort(sorter));
        setRight(not(right, rightChecked).sort(sorter));
        setChecked(not(checked, rightChecked).sort(sorter));
    };

    const handleAllLeft = () => {
        /* let unsortedListL: string[] = left.concat(right); */
        setLeft(prodList);
        setRight([]);
    };

    const getProductIcon = (value: string) => {
        let returnValue = null;
        switch (value) {
            case "easyGuide kiosk retail":
                returnValue = <MonitorIcon sx={{ marginLeft: '1rem' }}></MonitorIcon>
                break;
            case "easyGuide kiosk business":
                returnValue = <MonitorIcon sx={{ marginLeft: '1rem' }}></MonitorIcon>
                break;
            case "easyGuide mobile":
                returnValue = <PhoneAndroidIcon sx={{ marginLeft: '1rem' }}></PhoneAndroidIcon>
                break;
            case "easyGuide web":
                returnValue = <LanguageIcon sx={{ marginLeft: '1rem' }}></LanguageIcon>
                break;
            case "easyGuide print":
                returnValue = <PrintIcon sx={{ marginLeft: '1rem' }}></PrintIcon>
                break;
            case "easyMaps mobile":
                returnValue = <InsertLinkIcon sx={{ marginLeft: '1rem' }}></InsertLinkIcon>
                break;
            case "carFinder":
                returnValue = <NoCrashIcon sx={{ marginLeft: '1rem' }}></NoCrashIcon>
                break;
            case "easyMaps Kiosk":
                returnValue = <MonitorIcon sx={{ marginLeft: '1rem' }}></MonitorIcon>
                break;
            case "mapsEngine":
                returnValue = <CodeIcon sx={{ marginLeft: '1rem' }}></CodeIcon>
                break;
            case "custom":
                returnValue = <DashboardCustomizeIcon sx={{ marginLeft: '1rem' }}></DashboardCustomizeIcon>
                break;
            case "'End-of-life' kiosk-application (custom development or 'Guide3D kiosk')":
                returnValue = <CancelIcon sx={{ marginLeft: '1rem' }}></CancelIcon>
                break;
            case "'End-of-life' mobile-application ('Guide3D mobile')":
                returnValue = <CancelIcon sx={{ marginLeft: '1rem' }}></CancelIcon>
                break;
            case "'End-of-life' web-application (custom development or 'Guide3D web')":
                returnValue = <CancelIcon sx={{ marginLeft: '1rem' }}></CancelIcon>
                break;
        }
        return returnValue;
    }

    const customList = (items: readonly string[], active: boolean) => (
        <div>
            {active === true ?
                <Typography variant="subtitle1" align='left' sx={{
                    paddingTop: '0rem'
                }}>{t('admin_projects.prod_active') + ':'}</Typography>
                :
                <Typography variant="subtitle1" align='left' sx={{
                    paddingTop: '0rem'
                }}>{t('admin_projects.prod_inactive') + ':'}</Typography>
            }
            <div className="list_scroll_area">
                <PerfectScrollbar>
                    <div className="content">
                        {items.map((value: string) => {
                            const labelId = `transfer-list-item-${value}-label`;

                            return (
                                <ListItem
                                    key={value}
                                    role="listitem"
                                    disablePadding
                                    button
                                    onClick={handleToggle(value)}
                                    sx={{
                                        ...(checked.indexOf(value) !== -1 && { backgroundColor: 'success.main', '&:hover': { backgroundColor: 'success.main' } })//'success.main' 
                                    }}
                                >
                                    {/* <ListItemIcon sx={{ padding: '0', paddingLeft: '1rem' }}>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            color='success'
                                            tabIndex={-1}

                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                            sx={{
                                                padding: '0 !important'                                                
                                            }}
                                        />

                                    </ListItemIcon> */}
                                    {getProductIcon(value)}
                                    <ListItemText id={labelId} primary={value} sx={{ paddingLeft: '1rem' }} />
                                </ListItem>
                            );
                        })}
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
    );

    return (
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item paddingTop={"0px !important"}>{customList(left, true)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item paddingTop={"0px !important"}>{customList(right, false)}</Grid>
        </Grid>
    );
}