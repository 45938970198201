import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import { visuallyHidden } from '@mui/utils';
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from '../../hooks';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
/* import { User } from '../../../interfaces/interfaceUser'; */
import { setCurrentAdminEditProject } from '../../slices/currentAdminEditProjectSlice';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useGetProjectsQuery } from '../../slices/apiSlice';
import { ApiProject } from '../../../interfaces/commonInterfaces';
import { getI18n } from 'react-i18next';
import { setCurrentAdminEditCopyProject } from '../../slices/currentAdminEditCopyProjectSlice';
import { setCurrentGuiController_projectstable_page } from '../../slices/guiControllerSlice';


interface langObject {
    code: string,
    name: string,
}

interface uuidObject {
    uuid: string,
    name: string,
}

interface TableApiDataProjects {
    active: boolean;
    addons: string[] | uuidObject[];
    city: string;
    comment: string;
    country: string;
    createdAt: string;
    demoGalleryEnabled: boolean;
    demoGalleryPublic: boolean;
    description: string;
    hasService: boolean;
    languages: langObject[];
    links: string[];
    mapUuid: string | null;
    market: string;
    modifiedAt: string;
    name: string;
    products: string[] | uuidObject[];
    projectId: number;
    shortName: string;
    tags: string[];
}

function createApiData(
    active: boolean,
    addons: string[] | uuidObject[],
    city: string,
    comment: string,
    country: string,
    createdAt: string,
    demoGalleryEnabled: boolean,
    demoGalleryPublic: boolean,
    description: string,
    hasService: boolean,
    languages: langObject[],
    links: string[],
    mapUuid: string | null,
    market: string,
    modifiedAt: string,
    name: string,
    products: string[] | uuidObject[],
    projectId: number,
    shortName: string,
    tags: string[],
): TableApiDataProjects {
    return {
        active,
        addons,
        city,
        comment,
        country,
        createdAt,
        demoGalleryEnabled,
        demoGalleryPublic,
        description,
        hasService,
        languages,
        links,
        mapUuid,
        market,
        modifiedAt,
        name,
        products,
        projectId,
        shortName,
        tags,
    };
}




/* let rows: any = [
]; */

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | number[] | string[] | boolean | langObject[] | uuidObject[] | null },
    b: { [key in Key]: number | string | number[] | string[] | boolean | langObject[] | uuidObject[] | null },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface ApiHeadCell {
    disablePadding: boolean;
    id: keyof TableApiDataProjects;
    label: string;
    numeric: boolean;
}




const apiHeadCells: readonly ApiHeadCell[] = [
    {
        id: 'projectId',
        numeric: true,
        disablePadding: true,
        label: 'admin_projects.projectId',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'admin_projects.name',
    },
    {
        id: 'city',
        numeric: false,
        disablePadding: false,
        label: 'admin_projects.city',
    },
    {
        id: 'country',
        numeric: false,
        disablePadding: false,
        label: 'admin_projects.country',
    },
    {
        id: 'market',
        numeric: false,
        disablePadding: false,
        label: 'admin_projects.market',
    },
    {
        id: 'active',
        numeric: false,
        disablePadding: true,
        label: 'admin_projects.active',
    },
    {
        id: 'hasService',
        numeric: false,
        disablePadding: true,
        label: 'admin_projects.hasService',
    },
    {
        id: 'demoGalleryEnabled',
        numeric: false,
        disablePadding: true,
        label: 'admin_projects.demogallery',
    },
    {
        id: 'demoGalleryPublic',
        numeric: false,
        disablePadding: true,
        label: 'admin_projects.demogallery_public',
    },

]



const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
        color: '#ffffff',
    },
});


interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableApiDataProjects) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { /* onSelectAllClick, */ order, orderBy, /* numSelected, rowCount, */ onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof TableApiDataProjects) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    const { t } = useTranslation();


    return (
        <TableHead>
            <TableRow>
                {<TableCell padding="checkbox" sx={{
                    backgroundColor: (orderBy.toString()) === 'projectId' ? '#28444e' : 'unset',
                    borderBottom: "2px solid rgba(0, 0, 0, 1)",
                }} >
                    {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
                </TableCell>}
                {apiHeadCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={(headCell.id === 'projectId' || headCell.id === 'name') ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            backgroundColor: orderBy === headCell.id ? '#28444e' : 'unset',
                            borderLeft: headCell.id !== "projectId" ? "1px solid rgba(0, 0, 0, 1)" : 'unset',
                            borderRight: headCell.id !== "projectId" ? "1px solid rgba(0, 0, 0, 1)" : "2px solid rgba(0, 0, 0, 1)",
                            borderBottom: "2px solid rgba(0, 0, 0, 1)",
                            paddingLeft: '1.3rem'
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}

                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function EnhancedTable() {
    // const currentAdminEditProject = useAppSelector((state) => state.currentAdminEditProject.value);
    const projectstable_page = useAppSelector((state) => state.guicontroller.value.projectstable_page);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof TableApiDataProjects>('projectId');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [tablerows, setTablerows] = React.useState<TableApiDataProjects[]>([]);
    const [originalRows, setOriginalrows] = React.useState<TableApiDataProjects[]>([]);
    const [projectList, setProjectList] = React.useState<any>();
    const [apiReady, setApiReady] = React.useState(false);
    const [apiTimeStamp, setApiTimeStamp] = React.useState<number | undefined>(0);
    /* const [searched, setSearched] = React.useState<string>(""); */
    const [jsonloaded, setJsonloaded] = React.useState(0);
    /* const [page, setPage] = React.useState(0); */
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { data, error, isLoading, fulfilledTimeStamp } = useGetProjectsQuery('');
    /* const mounted = useRef(false); */

    const rowsPerPage: number = 11;
    /* let userList: any = undefined; */



    /*  useEffect(() => {
         mounted.current = true;
         refetch();
         return () => {
             mounted.current = false;
         };
     }, [refetch]); */

    useEffect(() => {
        let renderList: boolean = false; //  && apiTimeStamp !== fulfilledTimeStamp
        if (isLoading === true) {
            console.log("####################### API getProjects Loading...");
        }
        /*  handleApiProjectsList(); */
        if (isLoading === false && error === undefined && apiReady === false) {
            renderList = true;
        }
        if (isLoading === false && error === undefined && apiReady === true && apiTimeStamp !== fulfilledTimeStamp) {
            renderList = true;
        }
        if (renderList === true) {
            setApiTimeStamp(fulfilledTimeStamp);
            let tableArray: TableApiDataProjects[] = [];
            const apiProjects = data.data as ApiProject[];
            /* console.log("apiProjects loaded: ", apiProjects); */
            /* setApiProjects(apiProjects);
            setApiReady(true); */
            tableArray = [];
            apiProjects.forEach((project: ApiProject) => {
                const projectEntry: TableApiDataProjects = createApiData(
                    project.active,
                    project.addons,
                    project.city,
                    project.comment,
                    project.country,
                    project.createdAt,
                    project.demoGalleryEnabled,
                    project.demoGalleryPublic,
                    project.description,
                    project.hasService,
                    project.languages,
                    project.links,
                    project.mapUuid,
                    project.market,
                    project.modifiedAt,
                    project.name,
                    project.products,
                    project.projectId,
                    project.shortName,
                    project.tags,
                );
                if (tableArray.indexOf(projectEntry) === -1) {
                    tableArray.push(projectEntry);
                }

                if (tablerows !== tableArray) {
                    setTablerows(tableArray);
                }
                setOriginalrows(tableArray);
                setJsonloaded(jsonloaded + 1);
            });
            setProjectList(apiProjects);
            setApiReady(true);
        }
    }, [data, error, isLoading, jsonloaded, tablerows, apiReady, apiTimeStamp, fulfilledTimeStamp]);

    const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        /* setPage(0); */
        dispatch(setCurrentGuiController_projectstable_page(0));
        const enteredName = event.target.value;

        const filteredRows = originalRows.filter((row) => {

            let bShow: boolean = false;
            const findArray: boolean[] = [];
            Object.entries(row).forEach(([key, value]) => {
                if (typeof value === "string") {
                    findArray.push(value.toLowerCase().includes(enteredName.toLowerCase()))
                }
                if (typeof value === "number") {
                    findArray.push(value.toString().includes(enteredName.toLowerCase()))
                }
            });
            if (findArray.indexOf(true) >= 0) {
                bShow = true;
            }
            return bShow;
        });
        setTablerows(filteredRows);
        /* console.log("filteredRows: ", filteredRows); */
    };


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof TableApiDataProjects,
    ) => {
        console.log("orderBy -> ", orderBy.toString())
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = tablerows.map((n: any) => n.surname);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleUserEditClick = (event: React.MouseEvent<unknown>, rowObj: TableApiDataProjects) => {
        /* const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = []; */
        let selectedProject: ApiProject;
        projectList.forEach((project: ApiProject) => {
            if (project.projectId === rowObj.projectId) {
                selectedProject = project;
                console.log("Project set for Edit! -> ", selectedProject);
                // NOCH FIXEN: dispatch(setCurrentAdminEditUser(selectedProject));
                dispatch(setCurrentAdminEditCopyProject(selectedProject));
                dispatch(setCurrentAdminEditProject(selectedProject));
            }
        });
    };

    const handleAddProjectClick = () => {
        console.log("handleAddUserClick");
        const newEmptyProject: ApiProject = {
            "projectId": 9999999,
            "name": "",
            "shortName": "",
            "description": "",
            "comment": "",
            "city": "",
            "openingTime": "10:00:00",
            "closingTime": "20:00:00",
            "country": "DE",
            "active": true,
            "hasService": false,
            "market": "",
            "tags": [],
            "links": [],
            "products": [],
            "addons": [],
            "demoGalleryEnabled": false,
            "demoGalleryPublic": false,
            "mapUuid": "",
            "languages": [
                {
                    "code": "de",
                    "name": "German"
                },
            ],
            "createdAt": "2023-08-12T15:29:39.000000Z",
            "modifiedAt": "2023-08-12T15:29:39.000000Z",
            "timezone": null
        }

        // NOCH FIXEN: dispatch(setCurrentAdminEditUser(newEmptyUser));
        /* dispatch(resetCurrentAdminEditProject()); */
        dispatch(setCurrentAdminEditProject(newEmptyProject));
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        /* setPage(newPage); */
        dispatch(setCurrentGuiController_projectstable_page(newPage));
    };

    const handleMarketTableName = (sEnValue: string): string => {
        let sName: string = "";
        let indexLang: number = 0;
        const i18 = getI18n();
        if (i18.language === "en") {
            indexLang = 0;
        }
        else {
            indexLang = 1;
        }

        if (indexLang === 0) {
            sName = sEnValue;
        }
        else {
            switch (sEnValue) {
                case "Hospitals & Healthcare":
                    sName = "Krankenhäuser & Gesundheitswesen";
                    break;
                case "Airports & Transportation":
                    sName = "Flughäfen & Transport";
                    break;
                case "Universities & Education":
                    sName = "Universitäten & Bildung";
                    break;
                case "Corporate Offices & Organizations":
                    sName = "Unternehmenszentralen & Organisation";
                    break;
                case "Shopping Malls & Retail":
                    sName = "Einkaufszentren & Einzelhandel";
                    break;
                case "Government Agencies & Administration":
                    sName = "Behörden & Verwaltung";
                    break;
                case "Convention Centers & Events":
                    sName = "Kongresszentren & Veranstaltungen";
                    break;
                case "Theme parks & Entertainment":
                    sName = "Vergnügungsparks & Unterhaltung";
                    break;
                case "Stadiums & Sports":
                    sName = "Stadien & Sportstätten";
                    break;
                case "Hotels & Vacation":
                    sName = "Hotels & Erholung";
                    break;
                case "Museums & Culture":
                    sName = "Museen & Kultur";
                    break;
                case "Cruise ships & Leisure":
                    sName = "Kreuzfahrtschiffe & Urlaub";
                    break;
                case "Parking garage & Logistics":
                    sName = "Parkhäuser & Logistik";
                    break;
                case "Other":
                    sName = "Weitere";
                    break;
                case null:
                    sName = "";
                    break;
                default:
                    console.log("The market / industry \"" + sEnValue + "\"is unknown.");
                    break;
            }
        }
        return sName;
    }

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    return (
        <Box sx={{
            width: '100%',
            pt: '1rem',
            /* display: (currentAdminEditProject.projectId !== 0) ? 'none' : 'block', */
        }}>
            <Container sx={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 0,
                marginRight: 0,
                marginBottom: '1rem',
                paddingLeft: '0 !important',
                width: 'auto'
            }}>
                <TextField
                    id="input-with-icon-textfield"
                    sx={{
                        width: '19rem',

                    }}
                    size='medium'
                    /* value={searched} */
                    onChange={requestSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize='large' />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                <CssWhiteButton
                    /* onClick={handleCloseDialog} */
                    variant="contained"
                    onClick={handleAddProjectClick}
                    sx={{
                        mt: 0,
                        mb: 0,
                        pt: 0,
                        pl: '2rem',
                        pr: '2rem',
                        pb: 0,
                        ml: '2rem',
                        backgroundColor: 'primary.contrastText',
                        color: 'primary.main',
                        width: 'fit-content',
                        '&:hover': {
                            color: 'primary.contrastText',
                        },
                    }}
                >
                    <AddIcon sx={{
                        marginRight: '6px'
                    }}></AddIcon>
                    {t('admin_projects.add_project')}
                </CssWhiteButton>
            </Container>
            <Paper sx={{ width: '100%', mb: 2 }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={tablerows.length}
                        />
                        <TableBody>
                            {stableSort(tablerows, getComparator(order, orderBy))
                                .slice(projectstable_page * rowsPerPage, projectstable_page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name as string);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleUserEditClick(event, row)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.projectId}
                                            selected={isItemSelected}
                                            sx={{
                                                backgroundColor: row.active === true ? 'unset' : 'rgba(255,255,255,0.38) !important',
                                            }}
                                        >
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'projectId' ? '#28444e' : 'unset',
                                                lineHeight: 0
                                            }} >
                                                {/* <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                /> */}
                                                < AddCircleOutlineIcon />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                sx={{
                                                    backgroundColor: (orderBy.toString()) === 'projectId' ? '#28444e' : 'unset',
                                                    borderRight: "2px solid rgba(0, 0, 0, 1)",
                                                }}
                                            >
                                                {row.projectId}
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'name' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)",
                                                maxWidth: '12vw'
                                            }} align="left">{row.name}</TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'city' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="left">{row.city}</TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'country' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="left">{row.country}</TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'market' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="left">{handleMarketTableName(row.market)}</TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'active' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.active}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.active === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'hasService' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.hasService}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.hasService === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'demoGalleryEnabled' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.demoGalleryEnabled}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.demoGalleryEnabled === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'demoGalleryPublic' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.demoGalleryPublic}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.demoGalleryPublic === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            {/* <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'groups' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">{row.groups}</TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'kiosk_retail' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.kiosk_retail as boolean}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.active === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'kiosk_business' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.kiosk_business as boolean}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.active === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'mobile' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.mobile as boolean}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.active === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'web' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.web as boolean}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.active === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'print' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.print as boolean}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.active === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'engine' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.engine as boolean}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.active === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'custom_development' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="center">
                                                <Checkbox color='default'
                                                    checked={row.custom_development as boolean}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: row.active === true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.64)',
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'addons' ? '#28444e' : 'unset',
                                                borderLeft: "1px solid rgba(0, 0, 0, 1)"
                                            }} align="left">{row.addons}
                                            </TableCell> */}
                                        </TableRow>
                                    );
                                })}
                            {/* {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                > 
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={tablerows.length}
                    rowsPerPage={rowsPerPage}
                    /* page={projectstable_page} */
                    page={!tablerows.length || tablerows.length <= 0 ? 0 : projectstable_page}
                    onPageChange={handleChangePage}
                /* onRowsPerPageChange={handleChangeRowsPerPage} */
                />
            </Paper>
        </Box>
    );
}