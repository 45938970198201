
// tsc.cmd --lib es2015,dom .\MappingCalculator.ts


interface IMyClassTemplateOptions {
    "debug"?: boolean;
    "year"?: number;
}

export class MappingCalculator {

    // ================================================================== //
    // ====                 Properties (public)                ==== //
    // ================================================================== //

    public DEBUG: boolean = false;

    // ================================================================== //
    // ====                  Methods (public)                  ==== //
    // ================================================================== //

    /**
     * @category 
     */
    public toString(): string {

        return this._sCLASS_NAME;

    }

    /**
     * @category 
     */
    public printDebug(sModuleName: string, sFunctionName: string, sDebugMessage: string, bDebug: boolean): void {

        if (bDebug) {
            console.log(sModuleName + " @ " + sFunctionName + "(): " + sDebugMessage);
        }
        return;

    }

    /**
     * @category 
     */
    public printWarning(sModuleName: string, sFunctionName: string, sWarningMessage: string): void {

        console.warn(sModuleName + " @ " + sFunctionName + "(): " + sWarningMessage);
        return;

    }

    /**
     * @category 
     */
    public printError(sModuleName: string, sFunctionName: string, sErrorMessage: string): void {

        console.error(sModuleName + " @ " + sFunctionName + "(): " + sErrorMessage);
        return;

    }

    // ================================================================== //
    // ====                Properties (private)                ==== //
    // ================================================================== //

    private _oVERSION: number[] = [1, 0, 0];
    private _sCLASS_NAME: string = "Mapping-Calculator V" + this._oVERSION.join(".");

    count: number = 0;

    // ================================================================== //
    // ====                  Methods (private)                 ==== //
    // ================================================================== //



    public _doSomethingPrivate(): string {

        return "ThisIsSomethingPrivate";

    }

    // ================================================================== //
    // ====                      Public fields                       ==== //
    // ================================================================== //

    // ================================================================== //
    // ====                      Private fields                      ==== //
    // ================================================================== //

    private _oMapX3dToSvgTransformProperty: Map<string, number> = new Map();
    private _oMapSvgToGeoTransformProperty: Map<string, number> = new Map();

    // ================================================================== //
    // ====                      Public methods                      ==== //
    // ================================================================== //

    constructor(public oMyClassTemplateOptions: IMyClassTemplateOptions = {}) {

        this.DEBUG = !!oMyClassTemplateOptions.debug;

    }

    public setX3dToSvgTransformProperty(sProperty: "pointAX3dX" | "pointAX3dZ" | "pointASvgX" | "pointASvgY" | "pointBX3dX" | "pointBX3dZ" | "pointBSvgX" | "pointBSvgY" | "imageWidth" | "imageHeight", nValue: number): number {

        switch (sProperty) {
            case "pointAX3dX":
            case "pointAX3dZ":
            case "pointASvgX":
            case "pointASvgY":
            case "pointBX3dX":
            case "pointBX3dZ":
            case "pointBSvgX":
            case "pointBSvgY":
            case "imageWidth":
            case "imageHeight":
                // this._oMapX3dToSvgTransformPoints.set(sProperty, this._getNumber(nValue, 0, 32768, 0));
                if (isNaN(nValue) === false) {
                    this._oMapX3dToSvgTransformProperty.set(sProperty, nValue);
                } else {
                    this.printError(this.toString(), "setX3dToSvgTransformProperty", "The X3D-to-SVG-point \"" + sProperty + "\" is not a number.");
                }
                break;
            default:
                this.printError(this.toString(), "setX3dToSvgTransformProperty", "The X3D-to-SVG-point \"" + sProperty + "\" is not supported.");
                break;
        }

        return nValue;
    }

    public getX3dToSvgTransformProperty(sProperty: "xSlope" | "xConstant" | "ySlope" | "yConstant" | "imageWidth" | "imageHeight"): number {

        ["pointAX3dX", "pointAX3dZ", "pointASvgX", "pointASvgY", "pointBX3dX", "pointBX3dZ", "pointBSvgX", "pointBSvgY", "imageWidth", "imageHeight"].forEach((sProperty: string) => {
            if (this._oMapX3dToSvgTransformProperty.has(sProperty) === false) {
                this.printWarning(this.toString(), "getX3dToSvgTransformProperty", "The X3D-to-SVG-property \"" + sProperty + "\" has not yet been set.");
            }
        });

        let nValue: number = Number.NaN;

        switch (sProperty) {
            case "xSlope": {
                const $nX3DtoSVG_Point_A_Input_Coordinate_1: number = this._oMapX3dToSvgTransformProperty.get("pointAX3dX") || 0;
                const $nX3DtoSVG_Point_A_Output_Coordinate_1: number = this._oMapX3dToSvgTransformProperty.get("pointASvgX") || 0;
                const $nX3DtoSVG_Point_B_Input_Coordinate_1: number = this._oMapX3dToSvgTransformProperty.get("pointBX3dX") || 0;
                const $nX3DtoSVG_Point_B_Output_Coordinate_1: number = this._oMapX3dToSvgTransformProperty.get("pointBSvgX") || 0;
                nValue = this._calculateSlope($nX3DtoSVG_Point_A_Input_Coordinate_1, $nX3DtoSVG_Point_A_Output_Coordinate_1, $nX3DtoSVG_Point_B_Input_Coordinate_1, $nX3DtoSVG_Point_B_Output_Coordinate_1);
            }
                break;
            case "xConstant": {
                const $nX3DtoSVG_Point_A_Input_Coordinate_1: number = this._oMapX3dToSvgTransformProperty.get("pointAX3dX") || 0;
                const $nX3DtoSVG_Point_A_Output_Coordinate_1: number = this._oMapX3dToSvgTransformProperty.get("pointASvgX") || 0;
                const $nX3DtoSVG_Point_B_Input_Coordinate_1: number = this._oMapX3dToSvgTransformProperty.get("pointBX3dX") || 0;
                const $nX3DtoSVG_Point_B_Output_Coordinate_1: number = this._oMapX3dToSvgTransformProperty.get("pointBSvgX") || 0;
                nValue = this._calculateConstant($nX3DtoSVG_Point_A_Input_Coordinate_1, $nX3DtoSVG_Point_A_Output_Coordinate_1, $nX3DtoSVG_Point_B_Input_Coordinate_1, $nX3DtoSVG_Point_B_Output_Coordinate_1);
            }
                break;
            case "ySlope": {
                const $nX3DtoSVG_Point_A_Input_Coordinate_2: number = this._oMapX3dToSvgTransformProperty.get("pointAX3dZ") || 0;
                const $nX3DtoSVG_Point_A_Output_Coordinate_2: number = this._oMapX3dToSvgTransformProperty.get("pointASvgY") || 0;
                const $nX3DtoSVG_Point_B_Input_Coordinate_2: number = this._oMapX3dToSvgTransformProperty.get("pointBX3dZ") || 0;
                const $nX3DtoSVG_Point_B_Output_Coordinate_2: number = this._oMapX3dToSvgTransformProperty.get("pointBSvgY") || 0;
                nValue = this._calculateSlope($nX3DtoSVG_Point_A_Input_Coordinate_2, $nX3DtoSVG_Point_A_Output_Coordinate_2, $nX3DtoSVG_Point_B_Input_Coordinate_2, $nX3DtoSVG_Point_B_Output_Coordinate_2);
            }
                break;
            case "yConstant": {
                const $nX3DtoSVG_Point_A_Input_Coordinate_2: number = this._oMapX3dToSvgTransformProperty.get("pointAX3dZ") || 0;
                const $nX3DtoSVG_Point_A_Output_Coordinate_2: number = this._oMapX3dToSvgTransformProperty.get("pointASvgY") || 0;
                const $nX3DtoSVG_Point_B_Input_Coordinate_2: number = this._oMapX3dToSvgTransformProperty.get("pointBX3dZ") || 0;
                const $nX3DtoSVG_Point_B_Output_Coordinate_2: number = this._oMapX3dToSvgTransformProperty.get("pointBSvgY") || 0;
                nValue = this._calculateConstant($nX3DtoSVG_Point_A_Input_Coordinate_2, $nX3DtoSVG_Point_A_Output_Coordinate_2, $nX3DtoSVG_Point_B_Input_Coordinate_2, $nX3DtoSVG_Point_B_Output_Coordinate_2);
            }
                break;
            case "imageWidth":
                const $nX3DtoSVG_ImageWidth: number = this._oMapX3dToSvgTransformProperty.get("imageWidth") || 0;
                nValue = $nX3DtoSVG_ImageWidth;
                break;
            case "imageHeight":
                const $nX3DtoSVG_ImageHeight: number = this._oMapX3dToSvgTransformProperty.get("imageHeight") || 0;
                nValue = $nX3DtoSVG_ImageHeight;
                break;
            default:
                this.printError(this.toString(), "getX3dToSvgTransformProperty", "The X3D-to-SVG-property \"" + sProperty + "\" is not supported.");
                break;
        }

        return nValue;
    }

    public setSvgToGeoTransformProperty(sProperty: "rotationAngle" | "rotationPointX" | "rotationPointY" | "pointASvgX" | "pointAGeoLongitude" | "pointBSvgX" | "pointBGeoLongitude" | "pointASvgY" | "pointAGeoLatitude" | "pointBSvgY" | "pointBGeoLatitude", nValue: number): number {

        switch (sProperty) {
            case "rotationAngle":
            case "rotationPointX":
            case "rotationPointY":
            case "pointASvgX":
            case "pointAGeoLongitude":
            case "pointBSvgX":
            case "pointBGeoLongitude":
            case "pointASvgY":
            case "pointAGeoLatitude":
            case "pointBSvgY":
            case "pointBGeoLatitude":
                if (isNaN(nValue) === false) {
                    this._oMapSvgToGeoTransformProperty.set(sProperty, nValue);
                } else {
                    this.printError(this.toString(), "setSvgToGeoTransformProperty", "The SVG-to-GEO-point \"" + sProperty + "\" is not a number.");
                }
                break;
            default:
                this.printError(this.toString(), "setSvgToGeoTransformProperty", "The SVG-to-GEO-point \"" + sProperty + "\" is not supported.");
                break;
        }

        return nValue;
    }

    public getSvgToGeoTransformProperty(sProperty: "scale" | "xOffset" | "yOffset" | "angleRotation" | "xRotationPoint" | "yRotationPoint" | "longitudeSlope" | "longitudeConstant" | "latitudeSlope" | "latitudeConstant"): number {

        ["rotationAngle", "rotationPointX", "rotationPointY", "pointASvgX", "pointAGeoLongitude", "pointBSvgX", "pointBGeoLongitude", "pointASvgY", "pointAGeoLatitude", "pointBSvgY", "pointBGeoLatitude"].forEach((sProperty: string) => {
            if (this._oMapSvgToGeoTransformProperty.has(sProperty) === false) {
                this.printWarning(this.toString(), "getSvgToGeoTransformProperty", "The SVG-to-GEO-property \"" + sProperty + "\" has not yet been set.");
            }
        });

        let nValue: number = Number.NaN;

        switch (sProperty) {
            case "scale": {
                const $nSVGtoGEO_ImageMinimum: number = Math.min(this._oMapSvgToGeoTransformProperty.get("pointBSvgX") || 0, this._oMapSvgToGeoTransformProperty.get("pointASvgY") || 0);
                const $nX3DtoSVG_ImageMaximum: number = Math.max(this._oMapX3dToSvgTransformProperty.get("imageWidth") || 0, this._oMapX3dToSvgTransformProperty.get("imageHeight") || 0);
                const $nSVGtoGEO_ImageScale: number = $nSVGtoGEO_ImageMinimum / $nX3DtoSVG_ImageMaximum;
                nValue = $nSVGtoGEO_ImageScale;
            }
                break;
            case "xOffset": {
                const $nSVGtoGEO_ImageWidth: number = this._oMapSvgToGeoTransformProperty.get("pointBSvgX") || 0;
                const $nSVGtoGEO_ImageScale: number = this.getSvgToGeoTransformProperty("scale");
                const $nX3DtoSVG_ImageWidth: number = this._oMapX3dToSvgTransformProperty.get("imageWidth") || 0;
                const $nSVGtoGEO_XOffset: number = ($nSVGtoGEO_ImageWidth - ($nSVGtoGEO_ImageScale * $nX3DtoSVG_ImageWidth)) / 2;
                nValue = $nSVGtoGEO_XOffset;
            }
                break;
            case "yOffset": {
                const $nSVGtoGEO_ImageHeight: number = this._oMapSvgToGeoTransformProperty.get("pointASvgY") || 0;
                const $nSVGtoGEO_ImageScale: number = this.getSvgToGeoTransformProperty("scale");
                const $nX3DtoSVG_ImageHeight: number = this._oMapX3dToSvgTransformProperty.get("imageHeight") || 0;
                const $nSVGtoGEO_YOffset: number = ($nSVGtoGEO_ImageHeight - ($nSVGtoGEO_ImageScale * $nX3DtoSVG_ImageHeight)) / 2;
                nValue = $nSVGtoGEO_YOffset;
            }
                break;
            case "angleRotation": {
                const $nSVGtoGEO_Rotation_Angle: number = this._oMapSvgToGeoTransformProperty.get("rotationAngle") || 0;
                nValue = $nSVGtoGEO_Rotation_Angle;
            }
                break;
            case "xRotationPoint": {
                const $nSVGtoGEO_Rotation_Point_X: number = this._oMapSvgToGeoTransformProperty.get("rotationPointX") || 0;
                nValue = $nSVGtoGEO_Rotation_Point_X;
            }
                break;
            case "yRotationPoint": {
                const $nSVGtoGEO_Rotation_Point_Y: number = this._oMapSvgToGeoTransformProperty.get("rotationPointY") || 0;
                nValue = $nSVGtoGEO_Rotation_Point_Y;
            }
                break;
            case "longitudeSlope": {
                const $nSVGtoGEO_Point_A_Input_Coordinate_1: number = this._oMapSvgToGeoTransformProperty.get("pointASvgX") || 0;
                const $nSVGtoGEO_Point_A_Output_Coordinate_1: number = this._oMapSvgToGeoTransformProperty.get("pointAGeoLongitude") || 0;
                const $nSVGtoGEO_Point_B_Input_Coordinate_1: number = this._oMapSvgToGeoTransformProperty.get("pointBSvgX") || 0;
                const $nSVGtoGEO_Point_B_Output_Coordinate_1: number = this._oMapSvgToGeoTransformProperty.get("pointBGeoLongitude") || 0;
                nValue = this._calculateSlope($nSVGtoGEO_Point_A_Input_Coordinate_1, $nSVGtoGEO_Point_A_Output_Coordinate_1, $nSVGtoGEO_Point_B_Input_Coordinate_1, $nSVGtoGEO_Point_B_Output_Coordinate_1);
            }
                break;
            case "longitudeConstant": {
                const $nSVGtoGEO_Point_A_Input_Coordinate_1: number = this._oMapSvgToGeoTransformProperty.get("pointASvgX") || 0;
                const $nSVGtoGEO_Point_A_Output_Coordinate_1: number = this._oMapSvgToGeoTransformProperty.get("pointAGeoLongitude") || 0;
                const $nSVGtoGEO_Point_B_Input_Coordinate_1: number = this._oMapSvgToGeoTransformProperty.get("pointBSvgX") || 0;
                const $nSVGtoGEO_Point_B_Output_Coordinate_1: number = this._oMapSvgToGeoTransformProperty.get("pointBGeoLongitude") || 0;
                nValue = this._calculateConstant($nSVGtoGEO_Point_A_Input_Coordinate_1, $nSVGtoGEO_Point_A_Output_Coordinate_1, $nSVGtoGEO_Point_B_Input_Coordinate_1, $nSVGtoGEO_Point_B_Output_Coordinate_1);
            }
                break;
            case "latitudeSlope": {
                const $nSVGtoGEO_Point_A_Input_Coordinate_2: number = this._oMapSvgToGeoTransformProperty.get("pointASvgY") || 0;
                const $nSVGtoGEO_Point_A_Output_Coordinate_2: number = this._oMapSvgToGeoTransformProperty.get("pointAGeoLatitude") || 0;
                const $nSVGtoGEO_Point_B_Input_Coordinate_2: number = this._oMapSvgToGeoTransformProperty.get("pointBSvgY") || 0;
                const $nSVGtoGEO_Point_B_Output_Coordinate_2: number = this._oMapSvgToGeoTransformProperty.get("pointBGeoLatitude") || 0;
                nValue = this._calculateSlope($nSVGtoGEO_Point_A_Input_Coordinate_2, $nSVGtoGEO_Point_A_Output_Coordinate_2, $nSVGtoGEO_Point_B_Input_Coordinate_2, $nSVGtoGEO_Point_B_Output_Coordinate_2);
            }
                break;
            case "latitudeConstant": {
                const $nSVGtoGEO_Point_A_Input_Coordinate_2: number = this._oMapSvgToGeoTransformProperty.get("pointASvgY") || 0;
                const $nSVGtoGEO_Point_A_Output_Coordinate_2: number = this._oMapSvgToGeoTransformProperty.get("pointAGeoLatitude") || 0;
                const $nSVGtoGEO_Point_B_Input_Coordinate_2: number = this._oMapSvgToGeoTransformProperty.get("pointBSvgY") || 0;
                const $nSVGtoGEO_Point_B_Output_Coordinate_2: number = this._oMapSvgToGeoTransformProperty.get("pointBGeoLatitude") || 0;
                nValue = this._calculateConstant($nSVGtoGEO_Point_A_Input_Coordinate_2, $nSVGtoGEO_Point_A_Output_Coordinate_2, $nSVGtoGEO_Point_B_Input_Coordinate_2, $nSVGtoGEO_Point_B_Output_Coordinate_2);
            }
                break;
            default:
                this.printError(this.toString(), "getSvgToGeoTransformProperty", "The SVG-to-GEO-property \"" + sProperty + "\" is not supported.");
                break;
        }

        return nValue;
    }


    // ================================================================== //
    // ====                    Protected methods                     ==== //
    // ================================================================== //

    // ================================================================== //
    // ====                     Private methods                      ==== //
    // ================================================================== //

    private _getNumber(nNumber: number, nMinimumValue: number, nMaximumValue: number, nDefaultValue: number) {

        let nValidNumber = Number("NaN");

        if (!isNaN(parseFloat(String(nNumber))) && isFinite(nNumber)) {  // see: jquery.isNumeric() https://gist.github.com/kwokhou/5971995
            nValidNumber = (isNaN(nNumber) || nNumber < Math.min(nMinimumValue, nMaximumValue) || nNumber > Math.max(nMinimumValue, nMaximumValue))
                ? nDefaultValue
                : Number(nNumber);
        } else {
            nValidNumber = nDefaultValue;
        }

        if (nNumber !== nValidNumber) {
            this.printDebug(this.toString(), "_getNumber", "Validated number is set to default value \"" + nValidNumber + "\" as given number \"" + nNumber + "\" doesn't meet the required constraints.", this.DEBUG);
        }

        return nValidNumber;

    }

    private _calculateSlope(nPointAInput: number, nPointAOutput: number, nPointBInput: number, nPointBOutput: number): number {

        return (nPointBOutput - nPointAOutput) / (nPointBInput - nPointAInput);

    }

    private _calculateConstant(nPointAInput: number, nPointAOutput: number, nPointBInput: number, nPointBOutput: number): number {

        const nSlope: number = this._calculateSlope(nPointAInput, nPointAOutput, nPointBInput, nPointBOutput);

        return nPointAOutput - nPointAInput * nSlope;

    }

}
