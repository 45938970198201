import Typography from '@mui/material/Typography';
import { useAppSelector } from '../hooks';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import UserTable from './adminComponents/userTable'
import UserEdit from './adminComponents/userEdit';
import UserAdd from './adminComponents/userAdd';
import React from 'react';


export default function Tool_users() {
  const currentAdminMode = useAppSelector((state) => state.guicontroller.value.currentAdminMode);
  const currentProjectTool = useAppSelector((state) => state.guicontroller.value.currentProjectTool);
  const currentUserEdit = useAppSelector((state) => state.currentAdminEditUser.value);
  /* const [showUserAdd, setShowUserAdd] = React.useState(false); */
  const { t } = useTranslation();

  /* React.useEffect(() => {
    console.log("currentUserEdit.userid: " + currentUserEdit.userid);
  }, [currentUserEdit]); */

  if (currentAdminMode === 0) {
    return null;
  }

  if (currentProjectTool !== 0) {
    return null;
  }



  /* if(currentUserEdit.userid !== 345) {
    console.log("currentUserEdit: ", currentUserEdit); 
  } */

  return (
    <Container sx={{
      /* position: 'absolute',
      top: '6.8%',
      left: '3.4%',
      width: '96.6%', */
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '3rem !important',
      height: '100%',
      /* background: 'linear-gradient(#08222c 0%, #12526A 100%) 0% 0% no-repeat padding-box', */
      backgroundColor: '#092935',
      maxWidth: 'unset !important'
    }}>

      <Typography variant="h6" gutterBottom align='left' sx={{
        paddingTop: '1rem'
      }}>{t('admin_users.title')}</Typography>

      <UserTable />



      {(currentUserEdit.uuid !== "0" && currentUserEdit.uuid !== "99999")
        ?
        <UserEdit />
        : null
      }
      {currentUserEdit.uuid === "99999"
        ?
        <UserAdd />
        : null
      }

    </Container>
  );
}