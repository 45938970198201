import * as React from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { resetCurrentAdminEditProject } from '../../slices/currentAdminEditProjectSlice';
import DropDownMarketsMenu from '../../DropDownMarketsMenu';
import ProductsTransferList from '../../smallComponents/ProductsTransferList';
import AddonsTransferList from '../../smallComponents/AddonsTransferList';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useCreateProjectMutation, useAddAddonToProjectMutation, useAddProductToProjectMutation } from '../../slices/apiSlice'
import { setCurrentGuiController_modalLoading } from '../../slices/guiControllerSlice'
import LanguageTransferList from '../../smallComponents/LanguageTransferList';
import { uuidObject } from '../../../interfaces/commonInterfaces';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
/* import TimezonesTransferList from '../../smallComponents/TimezonesTransferList'; */
import TimeZoneDropdown from '../../smallComponents/TimeZoneDropdown';

const CssTextField = styled(TextField)({
    width: '30%',
    marginTop: 0,
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
});

const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
        color: '#ffffff',
    },
});

const CssWhiteInputLabel = styled(InputLabel)({
    '&.Mui-focused': {
        color: 'rgba(255,255,255,0.64)'
    }
});

const CssWhiteSelect = styled(Select)({
    color: '#ffffff',
    marginTop: '16px'
});



const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
}

/* const roleOptions = [
    { label: "Admin", value: "admin" },
    { label: "Poweruser", value: "poweruser" },
    { label: "User", value: "user" }
];

const languageOptions = [
    { label: "Deutsch", value: "de" },
    { label: "English", value: "en" }
]; */

export default function ProjectAdd() {
    const currentAdminEditProject = useAppSelector((state) => state.currentAdminEditProject.value);
    /* const [loginFail, setloginFail] = React.useState(false); */
    const [nameValue, setNameValue] = React.useState(currentAdminEditProject.name);
    const [shortNameValue, setShortNameValue] = React.useState(currentAdminEditProject.shortName);
    const [countryValue, setCountryValue] = React.useState(currentAdminEditProject.country);
    const [cityValue, setCityValue] = React.useState(currentAdminEditProject.city);
    /* const [languagesValue] = React.useState(currentAdminEditProject.languages); */
    const [hasServiceValue, sethasServiceValue] = React.useState(currentAdminEditProject.hasService);
    const [projectIdValue, setProjectIdValue] = React.useState(currentAdminEditProject.projectId);
    const [activeValue, setActiveValue] = React.useState(currentAdminEditProject.active);
    const [dgValue, setdgValue] = React.useState(currentAdminEditProject.demoGalleryEnabled);
    const [dgpValue, setdgpValue] = React.useState(currentAdminEditProject.demoGalleryPublic);
    const [timeOpenvalue, setTimeOpenValue] = React.useState<Dayjs | null>(dayjs('2022-04-17T' + currentAdminEditProject.openingTime));
    const [timeClosevalue, setTimeClosevalue] = React.useState<Dayjs | null>(dayjs('2022-04-17T' + currentAdminEditProject.closingTime));
    const [descriptionValue, setDescriptionValue] = React.useState(currentAdminEditProject.description);
    const [commentValue, setCommentValue] = React.useState(currentAdminEditProject.comment);
    const [createProject] = useCreateProjectMutation();
    const [addAddonToProject] = useAddAddonToProjectMutation();
    const [addProductToProject] = useAddProductToProjectMutation();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    let expectedCalls: number = 0;

    interface MarketIdObject {
        id: number,
        name: string
    }

    const marketsObject: MarketIdObject[] = [
        {
            "id": 1,
            "name": "Corporate Offices & Organizations"
        },
        {
            "id": 2,
            "name": "Government Agencies & Administration"
        },
        {
            "id": 3,
            "name": "Convention Centers & Events"
        },
        {
            "id": 4,
            "name": "Hospitals & Healthcare"
        },
        {
            "id": 5,
            "name": "Universities & Education"
        },
        {
            "id": 6,
            "name": "Airports & Transportation"
        },
        {
            "id": 7,
            "name": "Shopping Malls & Retail"
        },
        {
            "id": 8,
            "name": "Theme parks & Entertainment"
        },
        {
            "id": 9,
            "name": "Stadiums & Sports"
        },
        {
            "id": 10,
            "name": "Hotels & Vacation"
        },
        {
            "id": 11,
            "name": "Museums & Culture"
        },
        {
            "id": 12,
            "name": "Cruise ships & Leisure"
        },
        {
            "id": 13,
            "name": "Parking garage & Logistics"
        },
        {
            "id": 14,
            "name": "Other"
        }
    ];

    React.useEffect(() => {
        if (currentAdminEditProject.projectId !== 0) {
            // setLanguageValue(currentAdminEditProject.language as string);
        }
    }, [currentAdminEditProject]);

    const handleSave = () => {
        onSavePostClicked();

    }

    const onSaveAddProducts = async (productUuid: string, projectId: string) => {
        try {
            /* console.log("$$$$$$ addProductToProject Started!!! (" + productUuid + ")"); */
            await addProductToProject({ productUuid, projectId });
            expectedCalls -= 1;
            checkExpectedCalls();
            /* console.log("$$$$$$ addProductToProject Finished!!! (" + productUuid + ")"); */
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }

    const onSaveAddAddons = async (productUuid: string, projectId: string) => {
        try {
            /* console.log("$$$$$$ addAddonToProject Started!!! (" + productUuid + ")"); */
            await addAddonToProject({ productUuid, projectId });
            expectedCalls -= 1;
            checkExpectedCalls();
            /* console.log("$$$$$$ addAddonToProject Finished!!! (" + productUuid + ")"); */
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }

    const checkExpectedCalls = () => {
        if (expectedCalls === 0) {
            dispatch(resetCurrentAdminEditProject());
            setTimeout(() => {
                dispatch(setCurrentGuiController_modalLoading(false));
            }, 3000);
        }
    }

    const onSavePostClicked = async () => {
        expectedCalls = 0;
        dispatch(setCurrentGuiController_modalLoading(true));

        const constructedApiProject: Object = constructJsonProject();
        /* console.log("currentAdminEditProject: ", currentAdminEditProject);
        console.log("SAVE constructedApiProject: ", constructedApiProject); */
        try {
            await createProject(constructedApiProject).unwrap();
            if (currentAdminEditProject.products.length !== 0) {
                expectedCalls += currentAdminEditProject.products.length;
                currentAdminEditProject.products.forEach((productUuidObject: uuidObject) => {
                    onSaveAddProducts(productUuidObject.uuid, projectIdValue.toString());
                });
            }
            if (currentAdminEditProject.addons.length !== 0) {
                expectedCalls += currentAdminEditProject.addons.length;
                currentAdminEditProject.addons.forEach((addonsUuidObject: uuidObject) => {
                    onSaveAddAddons(addonsUuidObject.uuid, projectIdValue.toString());
                });
            }
            if (currentAdminEditProject.products.length === 0 && currentAdminEditProject.addons.length === 0) {
                checkExpectedCalls();
            }
            // dispatch(resetCurrentAdminEditProject());
        } catch (err) {
            console.error('Failed to save the post: ', err)
        }
    }

    const getMarketId = (): number | null => {
        let id: number | null = null;
        marketsObject.forEach((element: MarketIdObject) => {
            if (element.name === currentAdminEditProject.market) {
                id = element.id;
            }
        });
        return id;
    }

    interface langObject {
        code: string,
        name: string,
    }

    const getLanguageShorts = (): string[] => {
        /* console.log("languagesValue: ", languagesValue)
        console.log("currentAdminEditProject.languages: ", currentAdminEditProject.languages) */
        let languagArray: string[] = [];
        currentAdminEditProject.languages.forEach((element: langObject) => {
            /* console.log("element: ", element); */
            languagArray.push(element.code)
        });
        return languagArray;
    }

    const constructJsonProject = () => {
        let project = {
            projectId: projectIdValue,
            name: nameValue,
            shortName: shortNameValue,
            description: descriptionValue,
            comment: commentValue,
            active: activeValue,
            hasService: hasServiceValue,
            addons: [],
            country: countryValue,
            city: cityValue,
            languages: getLanguageShorts(),
            timezone: "",
            openingTime: timeOpenvalue?.format('HH[:]mm[:]ss'),
            closingTime: timeClosevalue?.format('HH[:]mm[:]ss'),
            marketId: getMarketId(),
            mapUuid: null,
            demoGalleryEnabled: dgValue,
            demoGalleryPublic: dgpValue,
        }
        return project;
    }

    const handleClose = () => {
        dispatch(resetCurrentAdminEditProject());
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameValue(event.target.value);
    };

    const handleChangeShortName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShortNameValue(event.target.value);
    };

    const handleChangeProjectId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectIdValue(parseInt(event.target.value));
    };

    const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCountryValue(event.target.value);
    };

    const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCityValue(event.target.value);
    };

    const handleChangehasService = () => {
        sethasServiceValue(!hasServiceValue);
    };

    /* const numToBoolean = (n: number): boolean => {
        let bValue: boolean = false
        switch (n) {
            case 0: 
                bValue = false;
                break;
            case 1:
                bValue = true;
                break
            default:
                bValue = false;
                break;
        }
        return bValue;
    } */

    /* const boolToNum = (b: boolean): number => {
        let nValue: number = 0
        switch (b) {
            case false:
                nValue = 0;
                break;
            case true:
                nValue = 1;
                break
            default:
                nValue = 0;
                break;
        }
        return nValue;
    } */

    const handleChangeActive = () => {
        setActiveValue(!activeValue);
    };

    const handleChangeDG = () => {
        setdgValue(!dgValue);
    };

    const handleChangeDGP = () => {
        setdgpValue(!dgpValue);
    };

    const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescriptionValue(event.target.value);
    };

    const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommentValue(event.target.value);
    };



    return (

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{
            display: currentAdminEditProject.projectId === 0 ? 'none' : 'flex',
            mt: 1,
            /* backgroundColor: 'blue', */ // 'primary.main',
            padding: '1%',
            paddingTop: '0.5%',
            marginTop: '0%',
            /* display: 'flex', */
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            width: '95%',
            height: '92%',
        }}>
            <div className="list_scroll_area_project">
                <PerfectScrollbar>
                    <div className="content">
                        <Box sx={{
                            display: currentAdminEditProject.projectId === 0 ? 'none' : 'flex',
                            mt: 1,
                            /* backgroundColor: 'red', */
                            padding: '0%',
                            marginTop: '0%',
                            /* display: 'flex', */
                            /* height: '42vh', */
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}>

                            <Box sx={{ width: '33%', height: '25rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <CssTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name_edit"
                                    label={t('admin_projects.name')}
                                    name="name"
                                    autoComplete=""
                                    variant="standard"
                                    onChange={handleChangeName}
                                    value={nameValue}
                                    multiline
                                    sx={{
                                        backgroundColor: 'primary.dark',
                                        padding: '0.3rem',
                                        marginTop: 0,
                                        width: '100%'
                                    }}
                                />
                                <CssTextField
                                    margin="normal"
                                    fullWidth
                                    id="shortName_edit"
                                    label={t('admin_projects.shortName')}
                                    name="name"
                                    autoComplete=""
                                    variant="standard"
                                    onChange={handleChangeShortName}
                                    value={shortNameValue === null ? "" : shortNameValue}
                                    multiline
                                    sx={{
                                        backgroundColor: 'primary.dark',
                                        padding: '0.3rem',
                                        marginTop: 0,
                                        width: '100%'
                                    }}
                                />
                                <CssTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="id_edit"
                                    label={t('admin_projects.projectId')}
                                    name="id"
                                    autoComplete="id"
                                    variant="standard"
                                    value={projectIdValue}
                                    onChange={handleChangeProjectId}
                                    sx={{
                                        backgroundColor: 'primary.dark',
                                        padding: '0.3rem',
                                        marginTop: 0,
                                        width: '100%'
                                    }}
                                />
                                <CssTextField
                                    margin="normal"
                                    fullWidth
                                    id="country_edit"
                                    label={t('admin_projects.country')}
                                    name="country"
                                    autoComplete=""
                                    variant="standard"
                                    onChange={handleChangeCountry}
                                    value={countryValue === null ? "" : countryValue}
                                    sx={{
                                        backgroundColor: 'primary.dark',
                                        padding: '0.3rem',
                                        marginTop: 0,
                                        width: '100%'
                                    }}
                                />
                                <CssTextField
                                    margin="normal"
                                    fullWidth
                                    id="city_edit"
                                    label={t('admin_projects.city')}
                                    name="city"
                                    autoComplete=""
                                    variant="standard"
                                    onChange={handleChangeCity}
                                    value={cityValue === null ? "" : cityValue}
                                    sx={{
                                        backgroundColor: 'primary.dark',
                                        padding: '0.3rem',
                                        marginTop: 0,
                                        width: '100%'
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: '32%', height: '80%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderLeft: '1px solid #FFFFFF;', borderRight: '1px solid #FFFFFF;', paddingRight: '1rem', paddingLeft: '1rem', paddingBottom: '2rem' }}>
                                <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                    paddingTop: '0.0rem'
                                }}>{t('admin_users.groups') + ':'}</Typography>
                                <FormControl variant="standard" sx={{ mt: '0', mb: '8px', minWidth: 140, lineHeight: '0.5rem', backgroundColor: 'primary.dark', width: '100%', padding: '0.3rem' }}>
                                    <CssWhiteInputLabel id="demo-simple-select-standard-label">{t('admin_users.groups')}</CssWhiteInputLabel>
                                    <CssWhiteSelect
                                        labelId="demo-simple-select-standard-label"
                                        id="groups_edit"
                                        label="Groups"
                                        value=""
                                        fullWidth
                                        sx={{ width: '100%' }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                    </CssWhiteSelect>
                                </FormControl>
                                <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                    paddingTop: '0.0rem'
                                }}>{t('admin_projects.market') + ':'}</Typography>
                                <DropDownMarketsMenu></DropDownMarketsMenu>
                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '0.0rem' }}>
                                    <FormControlLabel labelPlacement="start" control={<Checkbox color='default' aria-label='active'
                                        checked={activeValue as boolean}
                                        required={true}
                                        onChange={handleChangeActive}
                                        sx={{
                                            color: 'rgba(255,255,255,1)'
                                        }}
                                    ></Checkbox>} label={t('admin_projects.active')} aria-required />
                                    <FormControlLabel labelPlacement="start" control={<Checkbox color='default' aria-label='active'
                                        checked={hasServiceValue}
                                        required={true}
                                        onChange={handleChangehasService}
                                        sx={{
                                            color: 'rgba(255,255,255,1)'
                                        }}

                                    ></Checkbox>} label={t('admin_projects.hasService')} aria-required />
                                </Box>
                                {/*  Demo-Gallery: */}
                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '0.0rem' }}>
                                    <FormControlLabel labelPlacement="start" control={<Checkbox color='default' aria-label='active'
                                        checked={dgValue}
                                        required={true}
                                        onChange={handleChangeDG}
                                        sx={{
                                            color: 'rgba(255,255,255,1)'
                                        }}
                                    ></Checkbox>} label={t('admin_projects.demogallery')} aria-required />
                                    {/*  Demo-Gallery public: */}
                                    <FormControlLabel labelPlacement="start" control={<Checkbox color='default' aria-label='active'
                                        checked={dgpValue}
                                        required={true}
                                        onChange={handleChangeDGP}
                                        sx={{
                                            color: 'rgba(255,255,255,1)'
                                        }}

                                    ></Checkbox>} label={t('admin_projects.demogallery_public')} aria-required />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '0.2rem' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            ampm={false}
                                            label={t('admin_projects.opening_time') + ':'}
                                            value={timeOpenvalue}
                                            onChange={(newValueOpen) => setTimeOpenValue(newValueOpen)}
                                        />
                                        <TimePicker
                                            ampm={false}
                                            sx={{ marginLeft: '1rem' }}
                                            label={t('admin_projects.closing_time') + ':'}
                                            value={timeClosevalue}
                                            onChange={(newValueClose) => setTimeClosevalue(newValueClose)}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                    paddingTop: '0.5rem'
                                }}>{t('admin_projects.timezone') + ':'}</Typography>
                                <TimeZoneDropdown></TimeZoneDropdown>
                            </Box>
                            <Box sx={{ width: '33%', height: '80%', display: 'flex', flexDirection: 'column' }}>
                                <Box height={'45%'}>
                                    <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                        paddingTop: '0.0rem'
                                    }}>{t('admin_users.description') + ':'}</Typography>
                                    <CssTextField
                                        margin="normal"
                                        required={false}
                                        fullWidth
                                        id="description_edit"
                                        /* label={t('admin_users.description')} */
                                        name="description"
                                        autoComplete=""
                                        variant="standard"
                                        onChange={handleChangeDescription}
                                        value={descriptionValue === null ? "" : descriptionValue}
                                        multiline
                                        minRows={5}
                                        sx={{
                                            backgroundColor: 'primary.dark',
                                            padding: '0.3rem',
                                            marginTop: 0,
                                            width: '100%'
                                        }}
                                    />
                                </Box>
                                <Box height={'45%'}>
                                    <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                        paddingTop: '0.0rem'
                                    }}>{t('admin_projects.comment') + ':'}</Typography>
                                    <CssTextField
                                        margin="normal"
                                        required={false}
                                        fullWidth
                                        id="comment_edit"
                                        /* label={t('admin_users.description')} */
                                        name="comment"
                                        autoComplete=""
                                        variant="standard"
                                        onChange={handleChangeComment}
                                        value={commentValue === null ? "" : commentValue}
                                        multiline
                                        minRows={5}
                                        sx={{
                                            backgroundColor: 'primary.dark',
                                            padding: '0.3rem',
                                            marginTop: 0,
                                            width: '100%'
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', borderTop: '1px solid #FFFFFF;', alignItems: 'flex-start', paddingTop: '1.5rem' }}>
                                <Typography variant="subtitle1" gutterBottom align='left' sx={{
                                    paddingTop: '0.0rem'
                                }}>{t('admin_projects.languages') + ':'}</Typography>
                                <LanguageTransferList></LanguageTransferList>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: currentAdminEditProject.projectId === 0 ? 'none' : 'flex',
                            mt: 1,
                            /* backgroundColor: 'green', */
                            padding: '0%',
                            marginTop: '2rem',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}>
                            <ProductsTransferList></ProductsTransferList>
                            <AddonsTransferList></AddonsTransferList>
                        </Box>

                        <Container sx={{
                            width: '30%',
                            padding: 'unset',
                            pl: 'unset !important',
                            pr: 'unset !important',
                            margin: 'unset !important',
                            display: 'flex'
                        }}>
                            <CssWhiteButton
                                type="button"
                                /* fullWidth */
                                variant="contained"
                                onClick={handleSave}
                                sx={{
                                    mt: 3,
                                    mr: 3,
                                    mb: 2,
                                    backgroundColor: 'primary.contrastText',
                                    color: 'primary.main',
                                    width: 'fit-content',
                                    '&:hover': {
                                        color: 'primary.contrastText',
                                    },
                                }}
                            >
                                <CheckIcon sx={{
                                    marginRight: '6px'
                                }}></CheckIcon>
                                {t('admin_projects.add_project')}
                            </CssWhiteButton>
                            <CssWhiteButton
                                type="button"
                                /* fullWidth */
                                variant="contained"
                                onClick={handleClose}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: 'primary.contrastText',
                                    color: 'primary.main',
                                    width: 'fit-content',
                                    '&:hover': {
                                        color: 'primary.contrastText',
                                    },
                                }}
                            >
                                <CloseIcon sx={{
                                    marginRight: '6px'
                                }}></CloseIcon>
                                {t('admin_users.cancel')}
                            </CssWhiteButton>
                        </Container>
                    </div>
                </PerfectScrollbar>
            </div>
        </Box>

    )
}