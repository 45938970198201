import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAppDispatch, useAppSelector } from './hooks';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
/* import { setGuiStatus } from './slices/guiStatusSlice'; */
import { useTranslation } from 'react-i18next';
/* import _projects from "../projectlist.json"
import { setCurrentGuiController_currentProject } from './slices/guiControllerSlice'; */
import { useGetMarketsQuery } from './slices/apiSlice';
import { setCurrentAdminEditProject_market } from './slices/currentAdminEditProjectSlice';
import { getI18n } from 'react-i18next';



export default function MarketsMenu() {
    const { data, error, isLoading } = useGetMarketsQuery('');
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [fill, setFill] = React.useState(false);
    const [apiReady, setApiReady] = React.useState(false);
    const [marketReady, setMarketReady] = React.useState(false);
    const [menuTitleMarket, setMenuTitleMarket] = React.useState("");
    const currentAdminEditProject = useAppSelector((state) => state.currentAdminEditProject.value);
    const [usedMarkets, setUsedMarkets] = React.useState([{
        name: "",
    } as Market]);
    const [translatedMarkets, setTranslatedMarkets] = React.useState([{
        name: ["", ""],
    } as TranslatedMarket]);
    const dispatch = useAppDispatch();

    interface Market {
        name: string;
    }

    interface TranslatedMarket {
        name: [string, string];
    }

    // const i18 = getI18n();
    //  console.log("i18.language: ", i18.language);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (param: string) => {
        setAnchorEl(null);
        switch (param) {
            case "profile":
                console.log("menu select: " + param);
                break;
            case "account":
                console.log("menu select: " + param);
                break;
            case "logout":
                console.log("menu select: " + param);
                /* dispatch(setGuiStatus("logout")); */
                break;
            default:
                break;
        }
    };

    const handleMarketSelect = (name: string) => {
        setAnchorEl(null);
        setMenuTitleMarket(name);
        let englishMarketName: string = "";
        translatedMarkets.forEach((element, index) => {
            try {
                if (element.name[0] === name || element.name[1] === name) {
                    englishMarketName = element.name[0];
                }
            } catch (error) {
                console.log("handleMarketName ERROR: ", error);
            }
        });
        dispatch(setCurrentAdminEditProject_market(englishMarketName));
    }

    /* const handleApiProjectsList = () => {
        if(isLoading === false && error === undefined){
            const apiProjects = data.data as ApiProject[];
            setApiProjects(apiProjects);
            setApiReady(true);
        }
    } */
    React.useEffect(() => {
        if (apiReady === true && marketReady === false && translatedMarkets.length > 1) {
            /* console.log("####################### currentAdminEditProject getMarket: ", currentAdminEditProject.market); */
            let sName: string = "";
            let indexLang: number = 0;
            const i18 = getI18n();
            if (i18.language === "en") {
                indexLang = 0;
            }
            else {
                indexLang = 1;
            }
            /* console.log("translatedMarkets.length: " + translatedMarkets.length); */
            translatedMarkets.forEach(element => {
                try {
                    /* console.log("element.name[0]: " + element.name[0] + " / currentAdminEditProject.market: " + currentAdminEditProject.market); */
                    if (element.name[0] === currentAdminEditProject.market) {
                        sName = element.name[indexLang];
                    }
                } catch (error) {
                    console.log("handleMarketName ERROR: ", error);
                }
            });
            setAnchorEl(null);
            setMenuTitleMarket(sName);
            let englishMarketName: string = "";
            translatedMarkets.forEach((element, index) => {
                try {
                    if (element.name[0] === sName || element.name[1] === sName) {
                        englishMarketName = element.name[0];
                    }
                } catch (error) {
                    console.log("handleMarketName ERROR: ", error);
                }
            });
            dispatch(setCurrentAdminEditProject_market(englishMarketName));
            setMarketReady(true);
        }
    }, [apiReady, marketReady, translatedMarkets, currentAdminEditProject.market, dispatch]);

    React.useEffect(() => {
        /* console.log("####################### API getMarkets"); */
        /*         console.log("################### isLoading: ", isLoading);
                console.log("################### error: ", error);
                console.log("################### data: ", data);
                console.log("####################### API getMarkets"); */
        /*  handleApiProjectsList(); */
        if (isLoading === false && error === undefined) {
            console.log("####################### API getMarkets LOADED");
            setApiReady(true);
            const markets = data.data as Market[];
            setUsedMarkets(markets);
            setFill(true);
        }
    }, [data, error, isLoading]);

    React.useEffect(() => {
        if (apiReady === true) {
            let languageMarket: TranslatedMarket;
            let languageMarkets: TranslatedMarket[] = [];
            usedMarkets.forEach((oMarket: Market) => {
                switch (oMarket.name) {
                    case "Hospitals & Healthcare":
                        languageMarket = { name: [oMarket.name, "Krankenhäuser & Gesundheitswesen"] };
                        break;
                    case "Airports & Transportation":
                        languageMarket = { name: [oMarket.name, "Flughäfen & Transport"] };
                        break;
                    case "Universities & Education":
                        languageMarket = { name: [oMarket.name, "Universitäten & Bildung"] };
                        break;
                    case "Corporate Offices & Organizations":
                        languageMarket = { name: [oMarket.name, "Unternehmenszentralen & Organisation"] };
                        break;
                    case "Shopping Malls & Retail":
                        languageMarket = { name: [oMarket.name, "Einkaufszentren & Einzelhandel"] };
                        break;
                    case "Government Agencies & Administration":
                        languageMarket = { name: [oMarket.name, "Behörden & Verwaltung"] };
                        break;
                    case "Convention Centers & Events":
                        languageMarket = { name: [oMarket.name, "Kongresszentren & Veranstaltungen"] };
                        break;
                    case "Theme parks & Entertainment":
                        languageMarket = { name: [oMarket.name, "Vergnügunsparks & Unterhaltung"] };
                        break;
                    case "Stadiums & Sports":
                        languageMarket = { name: [oMarket.name, "Stadien & Sportstätten"] };
                        break;
                    case "Hotels & Vacation":
                        languageMarket = { name: [oMarket.name, "Hotels & Erholung"] };
                        break;
                    case "Museums & Culture":
                        languageMarket = { name: [oMarket.name, "Museen & Kultur"] };
                        break;
                    case "Cruise ships & Leisure":
                        languageMarket = { name: [oMarket.name, "Kreuzfahrtschiffe & Urlaub"] };
                        break;
                    case "Parking garage & Logistics":
                        languageMarket = { name: [oMarket.name, "Parkhäuser & Logistik"] };
                        break;
                    case "Other":
                        languageMarket = { name: [oMarket.name, "Weitere"] };
                        break;
                    default:
                        console.log("The market / industry \"" + oMarket.name + "\"is unknown.");
                        break;
                }
                if (languageMarkets.indexOf(languageMarket) === -1) {
                    languageMarkets.push(languageMarket);
                }
            });
            setTranslatedMarkets(languageMarkets);
            setFill(true);
        }

    }, [apiReady, usedMarkets]);

    const handleMarketName = (param: string): string => {
        let sName: string = "";
        let indexLang: number = 0;
        const i18 = getI18n();
        if (i18.language === "en") {
            indexLang = 0;
        }
        else {
            indexLang = 1;
        }
        translatedMarkets.forEach(element => {
            try {
                if (element.name[0] === param) {
                    sName = element.name[indexLang];
                }
            } catch (error) {
                console.log("handleMarketName ERROR: ", error);
            }
        });
        return sName;
    }

    /* React.useEffect(() => {
        if (apiReady === true) {
            let marketList: Market[] = [];
            currentUser.projectlist.forEach(userProjectId => {
                apiProjects.forEach(project => {
                    if (project.projectId === userProjectId) {
                        marketList.push(project)
                    }                    
                });
                if (marketList.length > 0) {
                    setUsedProjects(marketList);
                    setFill(true);
                }
                setMenuTitleProj(t('drpdwnmnproj.title'));
            });
        }
    }, [currentUser, projects, t, apiReady, apiProjects]); */



    return (
        <Box sx={{ width: '100%' }}>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                fullWidth
                sx={{
                    marginLeft: '0',
                    color: 'primary.contrastText',
                    textTransform: 'unset !important',
                    fontSize: '1em',
                    fontWeight: 'normal',
                    backgroundColor: 'primary.dark',
                    width: '100%'
                }}
            >
                {/* {userStatus.firstName} */}
                {menuTitleMarket === "" ? t('drpdwnmnmarket.title') : menuTitleMarket}
                {/* {t('drpdwnmnmarket.title')} */}
                {open ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        backgroundColor: 'info.main',
                        paddingBottom: '0px !important'
                    }
                }}
            >
                {fill
                    ?
                    usedMarkets.map((element: Market) => {

                        return <div key={element.name}>
                            <Divider sx={{
                                borderColor: 'primary.contrastText',
                                marginTop: '0px !important',
                                marginBottom: '0px !important',
                            }}></Divider>
                            <MenuItem onClick={() => handleMarketSelect(handleMarketName(element.name))}>{handleMarketName(element.name)}</MenuItem>
                        </div>

                    })
                    : null
                }


            </Menu>
        </Box>
    );
}


