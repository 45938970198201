import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import { visuallyHidden } from '@mui/utils';
/* import { useEffect } from "react"; */
import { useAppSelector, useAppDispatch } from '../../hooks';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { User } from '../../../interfaces/interfaceUser';
import { setCurrentAdminEditUser } from '../../slices/currentAdminEditUserSlice';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useGetUsersQuery } from '../../slices/apiSlice';
/* import { useGetRolesForUserQuery } from '../../slices/apiSlice'; */

/* interface UserData {
    userid: number;
    active: boolean;
    group: string;
    first_name: string;
    lastName: string;
    company: string;
    role: string;
    projectlist: number[];
    email: string;
    language: string;
    passwort: number;
    description: string;
} */

interface TableData {
    uuid: string;
    email: string;
    enabled: boolean;
    lastname: string;
    firstname: string;
    description: string;
    language: string;
}

function createData(
    uuid: string,
    email: string,
    enabled: boolean,
    lastname: string,
    firstname: string,
    description: string,
    language: string,
): TableData {
    return {
        uuid,
        email,
        enabled,
        lastname,
        firstname,
        description,
        language
    };
}


/* let rows: any = [
]; */

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | number[] | string[] | boolean },
    b: { [key in Key]: number | string | number[] | string[] | boolean },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof TableData;
    label: string;
    numeric: boolean;
}

/* 
first_name,
        surname,
        mail,
        role,
        projects,
        company,
        groups,
        active
*/

const headCells: readonly HeadCell[] = [
    {
        id: 'firstname',
        numeric: false,
        disablePadding: true,
        label: 'admin_users.first_name',
    },
    {
        id: 'lastname',
        numeric: false,
        disablePadding: false,
        label: 'admin_users.surname',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'admin_users.mail',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'admin_users.description',
    },
    {
        id: 'language',
        numeric: false,
        disablePadding: false,
        label: 'admin_users.language',
    },
    {
        id: 'enabled',
        numeric: false,
        disablePadding: false,
        label: 'admin_users.active',
    },
];

const CssWhiteButton = styled(Button)({
    '& .MuiButton-root:hover': {
        color: '#ffffff',
    },
});


interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableData) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { /* onSelectAllClick, */ order, orderBy, /* numSelected, rowCount, */ onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                {<TableCell padding="checkbox" sx={{
                    backgroundColor: (orderBy.toString()) === 'first_name' ? '#28444e' : 'unset'
                }} >
                    {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
                </TableCell>}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            backgroundColor: orderBy === headCell.id ? '#28444e' : 'unset'
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function EnhancedTable() {
    const currentAdminEditUser = useAppSelector((state) => state.currentAdminEditUser.value);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof TableData>('lastname');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [tablerows, setTablerows] = React.useState<TableData[]>([]);
    const [originalRows, setOriginalrows] = React.useState<TableData[]>([]);
    const [userList, setUserList] = React.useState<any>();
    const [page, setPage] = React.useState(0);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { data, error, isLoading } = useGetUsersQuery('');
    const rowsPerPage: number = 8;



    React.useEffect(() => {
        if (isLoading === false && error === undefined) {
            /*  const apiUsers = data; *///.data as ApiProject[];
            /* console.log("####################### API getUsers");
            console.log("################### LOADED!");
            console.log("apiProjects loaded: ", apiUsers); */
            setUserList(data.data);
            preprocessTableData(data.data);
            /* setApiProjects(apiProjects);
            setApiReady(true); */
        }
    }, [data, error, isLoading]);

    const preprocessTableData = async (data: any) => {
        let tableArray: TableData[] = [];
        data.forEach(async (user: any) => {
            /* console.log("user: ", user); */
            /* requestRoles(user.uuid); */
            const userEntry: TableData = createData(user.uuid, user.email, user.enabled, user.lastname, user.firstname, user.description, user.language);
            if (tableArray.indexOf(userEntry) === -1) {
                tableArray.push(userEntry);
            }
        });
        /* requestRoles("user.uuid"); */
        if (tablerows !== tableArray) {
            setTablerows(tableArray);
        }
        setOriginalrows(tableArray);
    }


    const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredName = event.target.value;

        const filteredRows = originalRows.filter((row) => {

            let bShow: boolean = false;
            const findArray: boolean[] = [];
            Object.entries(row).forEach(([key, value]) => {
                if (typeof value === "string") {
                    findArray.push(value.toLowerCase().includes(enteredName.toLowerCase()))
                }
            });
            if (findArray.indexOf(true) >= 0) {
                bShow = true;
            }
            return bShow;
        });
        setTablerows(filteredRows);
        /* console.log("filteredRows: ", filteredRows); */
    };


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof TableData,
    ) => {
        console.log("orderBy -> ", orderBy.toString())
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = tablerows.map((n: any) => n.surname);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleUserEditClick = (event: React.MouseEvent<unknown>, rowObj: TableData) => {
        /* const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = []; */
        /* console.log("row selected: ", rowObj);
        console.log("userList ", userList); */
        let selectedUser: User;
        userList.forEach((user: User) => {
            if (user.uuid === rowObj.uuid) {
                selectedUser = user;
                /* console.log("User set for Edit! -> ", selectedUser); */
                dispatch(setCurrentAdminEditUser(selectedUser));
            }
        });
    };

    const handleAddUserClick = () => {
        console.log("handleAddUserClick");
        let newEmptyUser: User = {
            uuid: "99999",
            enabled: true,
            firstname: "",
            lastname: "",
            email: "",
            language: "de",
            description: "",
        };

        dispatch(setCurrentAdminEditUser(newEmptyUser));
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    return (
        <Box sx={{
            width: '100%',
            pt: '1rem',
            display: (currentAdminEditUser.uuid !== "0") ? 'none' : 'block',
        }}>
            <Container sx={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 0,
                marginRight: 0,
                marginBottom: '1rem',
                paddingLeft: '0 !important',
                width: 'auto'
            }}>
                <TextField
                    id="input-with-icon-textfield"
                    sx={{
                        width: '19rem',

                    }}
                    size='medium'
                    /* value={searched} */
                    onChange={requestSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize='large' />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                <CssWhiteButton
                    /* onClick={handleCloseDialog} */
                    variant="contained"
                    onClick={handleAddUserClick}
                    sx={{
                        mt: 0,
                        mb: 0,
                        pt: 0,
                        pl: '2rem',
                        pr: '2rem',
                        pb: 0,
                        ml: '2rem',
                        backgroundColor: 'primary.contrastText',
                        color: 'primary.main',
                        width: 'fit-content',
                        '&:hover': {
                            color: 'primary.contrastText',
                        },
                    }}
                >
                    <AddIcon sx={{
                        marginRight: '6px'
                    }}></AddIcon>
                    {t('admin_users.add_user')}
                </CssWhiteButton>
            </Container>
            <Paper sx={{ width: '100%', mb: 2 }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='medium'
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={tablerows.length}
                        />
                        <TableBody>
                            {stableSort(tablerows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.firstname as string);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleUserEditClick(event, row)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.uuid}
                                            selected={isItemSelected}
                                        >
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'first_name' ? '#28444e' : 'unset'
                                            }} >
                                                {/* <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                /> */}
                                                < AddCircleOutlineIcon />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                sx={{
                                                    backgroundColor: (orderBy.toString()) === 'first_name' ? '#28444e' : 'unset'
                                                }}
                                            >
                                                {row.firstname}
                                            </TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'surname' ? '#28444e' : 'unset'
                                            }} align="left">{row.lastname}</TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'mail' ? '#28444e' : 'unset'
                                            }} align="left">{row.email}</TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'company' ? '#28444e' : 'unset'
                                            }} align="left">{row.description}</TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'groups' ? '#28444e' : 'unset'
                                            }} align="left">{row.language}</TableCell>
                                            <TableCell sx={{
                                                backgroundColor: (orderBy.toString()) === 'active' ? '#28444e' : 'unset'
                                            }} align="left">
                                                <Checkbox color='default'
                                                    checked={row.enabled as boolean}
                                                    disabled={true as boolean}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                ></Checkbox>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {/* {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={tablerows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                /* onRowsPerPageChange={handleChangeRowsPerPage} */
                />
            </Paper>
        </Box>
    );
}


