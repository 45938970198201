import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
/* import type { RootState } from '../store' */
import { CurrentUser, GUIController, LayerPath, UploadedFiles, UploadedImages } from '../../interfaces/interfaceGuiController';

// Define a type for the slice state
interface CurrentGuiController {
    value: GUIController
}

// Define the initial state using that type
const initialState: CurrentGuiController = {
    value: {
        guiStatus: "login",
        currentProject: 0,
        currentProjectTool: -1,
        currentAdminTool: -1,
        currentAdminMode: 0,
        currentUser: {
            uuid: "0",
            enabled: true,
            firstname: "",
            lastname: "",
            email: "",
            language: "",
            description: "",
        },
        mapsAssistant: false,
        mapsAssistant_uploads: 0,
        mapsAssistant_note: "none",
        mapsAssistant_note_read: false,
        mapsManualSetup: false,
        mapsAssistant_rotation: 0,
        editedLayerNames: [],
        editedLayerIds: [],
        mapsStep: 6,
        valueTabs: 0,
        mapsAssistant_layers: {
            images: []
        },
        mapsLayerPaths: [],
        mapsFiles: {
            files: []
        },
        mapsGeoCoordinates_A: [-1, -1],
        mapsGeoCoordinates_B: [-1, -1],
        mapsPixelCoordinates_A: [-1, -1],
        mapsPixelCoordinates_B: [-1, -1],
        mapsPreviewRenew: false,
        mapsRefetch: false,
        mapsRotation: 0,
        mapsAssistant_layers_index: 0,
        modalLoading: false,
        projectstable_page: 0
    }
}

export const currentGuiControllerSlice = createSlice({
    name: 'setCurrentGuiController',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setCurrentGuiController: (state, action: PayloadAction<GUIController>) => {
            state.value = action.payload;
        },
        setCurrentGuiController_guistatus: (state, action: PayloadAction<string>) => {
            state.value.guiStatus = action.payload;
        },
        setCurrentGuiController_currentProject: (state, action: PayloadAction<number>) => {
            state.value.currentProject = action.payload;
        },
        setCurrentGuiController_currentProjectTool: (state, action: PayloadAction<number>) => {
            state.value.currentProjectTool = action.payload;
        },
        setCurrentGuiController_currentAdminTool: (state, action: PayloadAction<number>) => {
            state.value.currentAdminTool = action.payload;
        },
        setCurrentGuiController_currentAdminMode: (state, action: PayloadAction<number>) => {
            state.value.currentAdminMode = action.payload;
        },
        setCurrentGuiController_currentUser: (state, action: PayloadAction<CurrentUser>) => {
            state.value.currentUser = action.payload;
        },
        setCurrentGuiController_mapsAssistant: (state, action: PayloadAction<boolean>) => {
            state.value.mapsAssistant = action.payload;
        },
        setCurrentGuiController_mapsAssistant_uploads: (state, action: PayloadAction<number>) => {
            state.value.mapsAssistant_uploads = action.payload;
        },
        setCurrentGuiController_mapsManualSetup: (state, action: PayloadAction<boolean>) => {
            state.value.mapsManualSetup = action.payload;
        },
        setCurrentGuiController_mapsAssistant_rotation: (state, action: PayloadAction<number>) => {
            state.value.mapsAssistant_rotation = action.payload;
        },
        setCurrentGuiController_mapsAssistant_layerImages: (state, action: PayloadAction<UploadedImages>) => {
            state.value.mapsAssistant_layers = action.payload;
        },
        setCurrentGuiController_mapsLayerPaths: (state, action: PayloadAction<LayerPath[]>) => {
            state.value.mapsLayerPaths = action.payload;
        },
        setCurrentGuiController_mapsAssistant_layerImages_index: (state, action: PayloadAction<number>) => {
            state.value.mapsAssistant_layers_index = action.payload;
        },
        setCurrentGuiController_mapsAssistant_note: (state, action: PayloadAction<string>) => {
            state.value.mapsAssistant_note = action.payload;
        },
        setCurrentGuiController_mapsAssistant_note_read: (state, action: PayloadAction<boolean>) => {
            state.value.mapsAssistant_note_read = action.payload;
        },
        setCurrentGuiController_modalLoading: (state, action: PayloadAction<boolean>) => {
            state.value.modalLoading = action.payload;
        },
        setCurrentGuiController_projectstable_page: (state, action: PayloadAction<number>) => {
            state.value.projectstable_page = action.payload;
        },
        setCurrentGuiController_mapsGeoCoordinates_A: (state, action: PayloadAction<[number, number]>) => {
            state.value.mapsGeoCoordinates_A = action.payload;
        },
        setCurrentGuiController_mapsGeoCoordinates_B: (state, action: PayloadAction<[number, number]>) => {
            state.value.mapsGeoCoordinates_B = action.payload;
        },
        setCurrentGuiController_mapsPixelCoordinates_A: (state, action: PayloadAction<[number, number]>) => {
            state.value.mapsPixelCoordinates_A = action.payload;
        },
        setCurrentGuiController_mapsPixelCoordinates_B: (state, action: PayloadAction<[number, number]>) => {
            state.value.mapsPixelCoordinates_B = action.payload;
        },
        setCurrentGuiController_mapsRotation: (state, action: PayloadAction<number>) => {
            state.value.mapsRotation = action.payload;
        },
        setCurrentGuiController_editedLayerNames: (state, action: PayloadAction<string[]>) => {
            state.value.editedLayerNames = action.payload;
        },
        setCurrentGuiController_editedLayerIds: (state, action: PayloadAction<string[]>) => {
            state.value.editedLayerIds = action.payload;
        },
        setCurrentGuiController_mapsStep: (state, action: PayloadAction<number>) => {
            state.value.mapsStep = action.payload;
        },
        setCurrentGuiController_valueTabs: (state, action: PayloadAction<number>) => {
            state.value.valueTabs = action.payload;
        },
        setCurrentGuiController_mapsFiles: (state, action: PayloadAction<UploadedFiles>) => {
            state.value.mapsFiles = action.payload;
        },
        setCurrentGuiController_mapsPreviewRenew: (state, action: PayloadAction<boolean>) => {
            state.value.mapsPreviewRenew = action.payload;
        },
        setCurrentGuiController_mapsRefetch: (state, action: PayloadAction<boolean>) => {
            state.value.mapsRefetch = action.payload;
        },
        setCurrentGuiController_projectChange: (state, action: PayloadAction<GUIController>) => {
            state.value = action.payload;
        },
        resetCurrentGuiController: () => initialState



    },
})

export const { setCurrentGuiController } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_guistatus } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_currentProject } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_currentProjectTool } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_currentAdminTool } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_currentAdminMode } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_currentUser } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsAssistant } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsAssistant_uploads } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsManualSetup } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsAssistant_rotation } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsAssistant_layerImages } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsAssistant_note } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsAssistant_note_read } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsAssistant_layerImages_index } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_modalLoading } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_projectstable_page } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsGeoCoordinates_A } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsGeoCoordinates_B } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsPixelCoordinates_A } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsPixelCoordinates_B } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsRotation } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_editedLayerNames } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_editedLayerIds } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsStep } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_valueTabs } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsLayerPaths } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsFiles } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsPreviewRenew } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_mapsRefetch } = currentGuiControllerSlice.actions
export const { setCurrentGuiController_projectChange } = currentGuiControllerSlice.actions
export const { resetCurrentGuiController } = currentGuiControllerSlice.actions





// Other code such as selectors can use the imported `RootState` type

export default currentGuiControllerSlice.reducer