import Lottie from 'react-lottie-player'
import Backdrop from '@mui/material/Backdrop';
import lottieJson from '../../../creator-upload-weiss.json'
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box/Box';
/* import React from 'react'; */

export default function MapsLoadingElement() {
    const { t } = useTranslation();
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}
            open
        >
            <Box>
                <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 400, height: 400 }}
            />
            <Typography gutterBottom variant="h4" align="center" component="div" /* sx={{
                pl: '2vh', width: '20vh'
            }} */>
                {t('tools.maps.floorsetup_3') + "..."}
            </Typography>
            </Box>            
        </Backdrop>
    )
}