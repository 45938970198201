import { useAppSelector } from "./hooks";
import { useGetAddonsQuery, useGetProductsQuery, useGetProjectQuery } from "./slices/apiSlice";
import { useEffect, useState } from "react";
import { ApiProject } from "../interfaces/commonInterfaces";
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import MonitorIcon from '@mui/icons-material/Monitor';
import CancelIcon from '@mui/icons-material/Cancel';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LanguageIcon from '@mui/icons-material/Language';
import PrintIcon from '@mui/icons-material/Print';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CodeIcon from '@mui/icons-material/Code';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import NoCrashIcon from '@mui/icons-material/NoCrash';

/* const CssTextField = styled(TextField)({
    marginTop: 0,
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:hover': {
        borderBottomColor: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
}); */

interface IProjectInfoProps {
    projId: number;
}

export default function ProjectInfos(props: React.PropsWithChildren<IProjectInfoProps>) {

    const currentProject = useAppSelector((state) => state.guicontroller.value.currentProject);
    // const currentUser = useAppSelector((state) => state.userstatus.value);
    /* const currentUser = useAppSelector((state) => state.guicontroller.value.currentUser);
    const currentAdminMode = useAppSelector((state) => state.guicontroller.value.currentAdminMode); */

    const { data, error, isLoading } = useGetProjectQuery(props.projId);
    const { data: dataProd, error: errorProd, isLoading: isLoadingProd } = useGetProductsQuery(props.projId.toString());
    const { data: dataAdd, error: errorAdd, isLoading: isLoadingAdd } = useGetAddonsQuery(props.projId.toString());

    const [apiProject, setApiProject] = useState<ApiProject>();
    /* const [fill, setFill] = useState(false); */

    const [nameValue, setNameValue] = useState("");
    const [shortNameValue, setShortNameValue] = useState("");
    const [countryValue, setCountryValue] = useState("");
    const [cityValue, setCityValue] = useState("");


    const [hasServiceValue, setHasServiceValue] = useState(false);
    const [activeValue, setActiveValue] = useState(false);
    const [dgValue, setDgValue] = useState(false);
    const [dgpValue, setDgpValue] = useState(false);
    const [timeOpenvalue, setTimeOpenvalue] = useState<Dayjs | null>(null);
    const [timeClosevalue, setTimeClosevalue] = useState<Dayjs | null>(null);
    const [productsArray, setProductsArray] = useState([""]);
    const [addonsArray, setAddonsArray] = useState([""]);
    const [timezoneValue, setTimezoneValue] = useState("");
    const [marketValue, setMarketValue] = useState("");
    const [descriptionValue, setDescriptionValue] = useState("");
    const [commentValue, setCommentValue] = useState("");
    /* const dispatch = useAppDispatch(); */
    const { t } = useTranslation();

    useEffect(() => {
        /* console.log("CurrentProject changed: ", currentProject); */
        setMarketValue("");
        setNameValue("");
        setShortNameValue("");
        setCountryValue("");
        setCityValue("");
        setDgValue(false);
        setDgpValue(false);
        setActiveValue(false);
        setHasServiceValue(false);
        setTimezoneValue("");
        setTimeOpenvalue(null);
        setTimeClosevalue(null);
        setDescriptionValue("");
        setCommentValue("");
        setAddonsArray([""]);
        setProductsArray([""]);
        setApiProject(undefined);
    }, [currentProject])


    useEffect(() => {
        /* console.log("apiProject: ", apiProject); */
        setMarketValue(apiProject?.market as string);
        setNameValue(apiProject?.name as string);
        setShortNameValue(apiProject?.shortName as string);
        setCountryValue(apiProject?.country as string);
        setCityValue(apiProject?.city as string);
        setDgValue(apiProject?.demoGalleryEnabled as boolean);
        setDgpValue(apiProject?.demoGalleryPublic as boolean);
        setActiveValue(apiProject?.active as boolean);
        setHasServiceValue(apiProject?.hasService as boolean);
        setTimezoneValue(apiProject?.timezone as string);
        setTimeOpenvalue(dayjs('2022-04-17T' + apiProject?.openingTime));
        setTimeClosevalue(dayjs('2022-04-17T' + apiProject?.closingTime));
        setDescriptionValue(apiProject?.description as string)
        setCommentValue(apiProject?.comment as string)
    }, [apiProject]);

    useEffect(() => {
        /* console.log("####################### API getMarkets"); */
       /*  console.log("################### isLoading: ", isLoading);
        console.log("################### error: ", error);
        console.log("################### data: ", data);
        console.log("####################### API getProject"); */
        /*  handleApiProjectsList(); */
        if (isLoading === false && error === undefined) {
            /* console.log("####################### API useGetProjectQuery LOADED"); */
            const apiTempProject: ApiProject = data.data as ApiProject;
            /* console.log("apiTempProject: ", apiTempProject); */
            setApiProject(apiTempProject);

            /*  const project = data.data as Market[]; */
        }
    }, [data, error, isLoading]);

    useEffect(() => {
        if (isLoadingProd === false && errorProd === undefined) {
            /* console.log("####################### API useGetProductsQuery LOADED"); */
            if (dataProd.data !== undefined) {
                let prods: string[] = [];
                dataProd.data.forEach((element: any) => {
                    prods.push(element.name);
                });
                setProductsArray(prods);
            }
        }
    }, [dataProd, errorProd, isLoadingProd]);

    useEffect(() => {
        /* console.log("dataProd: ", dataProd);
        console.log("errorProd: ", errorProd);
        console.log("isLoadingProd: ", isLoadingProd); */
        if (isLoadingAdd === false && errorAdd === undefined) {
            /* console.log("####################### API useGetAddonsQuery LOADED "); */
            if (dataAdd.data !== undefined) {
                let addons: string[] = [];
                dataAdd.data.forEach((element: any) => {
                    addons.push(element.name);
                });
                setAddonsArray(addons);
            }
        }
    }, [dataAdd, errorAdd, isLoadingAdd]);



    const getOpenTime = (): string => {

        let openString: string = "";
        if (timeOpenvalue?.format('HH[:]mm[:]ss') === "Invalid Date") {
            openString = "-"
        }
        else {
            openString = timeOpenvalue?.format('HH[:]mm[:]ss') as string + " " + timezoneValue;
        }
        return openString;
    }

    const getCloseTime = (): string => {
        let closeString: string = "";
        if (timeClosevalue?.format('HH[:]mm[:]ss') === "Invalid Date") {
            closeString = "-"
        }
        else {
            closeString = timeClosevalue?.format('HH[:]mm[:]ss') as string + " " + timezoneValue;
        }
        return closeString;
    }

    const getProductIcon = (value: string) => {
        let returnValue = null;
        switch (value) {
            case "easyGuide kiosk retail":
                returnValue = <MonitorIcon sx={{ marginLeft: '1rem' }}></MonitorIcon>
                break;
            case "easyGuide kiosk business":
                returnValue = <MonitorIcon sx={{ marginLeft: '1rem' }}></MonitorIcon>
                break;
            case "easyGuide mobile":
                returnValue = <PhoneAndroidIcon sx={{ marginLeft: '1rem' }}></PhoneAndroidIcon>
                break;
            case "easyGuide web":
                returnValue = <LanguageIcon sx={{ marginLeft: '1rem' }}></LanguageIcon>
                break;
            case "easyGuide print":
                returnValue = <PrintIcon sx={{ marginLeft: '1rem' }}></PrintIcon>
                break;
            case "easyMaps mobile":
                returnValue = <InsertLinkIcon sx={{ marginLeft: '1rem' }}></InsertLinkIcon>
                break;
            case "carFinder":
                returnValue = <NoCrashIcon sx={{ marginLeft: '1rem' }}></NoCrashIcon>
                break;
            case "easyMaps Kiosk":
                returnValue = <MonitorIcon sx={{ marginLeft: '1rem' }}></MonitorIcon>
                break;
            case "mapsEngine":
                returnValue = <CodeIcon sx={{ marginLeft: '1rem' }}></CodeIcon>
                break;
            case "custom":
                returnValue = <DashboardCustomizeIcon sx={{ marginLeft: '1rem' }}></DashboardCustomizeIcon>
                break;
            case "'End-of-life' kiosk-application (custom development or 'Guide3D kiosk')":
                returnValue = <CancelIcon sx={{ marginLeft: '1rem' }}></CancelIcon>
                break;
            case "'End-of-life' mobile-application ('Guide3D mobile')":
                returnValue = <CancelIcon sx={{ marginLeft: '1rem' }}></CancelIcon>
                break;
            case "'End-of-life' web-application (custom development or 'Guide3D web')":
                returnValue = <CancelIcon sx={{ marginLeft: '1rem' }}></CancelIcon>
                break;
        }
        return returnValue;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ minWidth: '50vh' }}>
                <Box
                    sx={{
                        /* position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', */
                        padding: '0.5em',
                        marginTop: '6rem',
                        marginLeft: 'unset',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        backgroundColor: 'primary.main'
                    }}>
                    <Typography variant="caption">
                        {t('admin_projects.name') + ":"}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '-6px', marginLeft: '8px' }}>
                        {nameValue ?? ''}
                    </Typography>
                    <Typography variant="caption">
                        {t('admin_projects.projectId') + ":"}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '-6px', marginLeft: '8px' }}>
                        {currentProject ?? ''}
                    </Typography>
                    <Typography variant="caption">
                        {t('admin_projects.country') + ":"}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '-6px', marginLeft: '8px' }}>
                        {countryValue ?? ''}
                    </Typography>
                    <Typography variant="caption">
                        {t('admin_projects.city') + ":"}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '-6px', marginLeft: '8px' }}>
                        {cityValue ?? ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        /* position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', */
                        padding: '0.5em',
                        marginTop: '1rem',
                        marginLeft: 'unset',
                        left: '10%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        backgroundColor: 'primary.main'
                    }}>
                    <Typography variant="caption">
                        {t('admin_projects.market') + ":"}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '-6px', marginLeft: '8px' }}>
                        {marketValue ?? ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        /* position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', */
                        /* width: '25vw', */
                        marginTop: '1rem',
                        padding: '0.5em',
                        marginLeft: 'unset',
                        left: '10%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'primary.main'
                    }}>

                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '0.0rem' }}>
                        <FormControlLabel labelPlacement="start" control={<Checkbox color='default'
                            checked={dgValue ?? false}
                            sx={{
                                color: 'rgba(255,255,255,1)'
                            }}
                        ></Checkbox>} label={t('admin_projects.demogallery')} aria-required />
                        {/*  Demo-Gallery public: */}
                        <FormControlLabel labelPlacement="start" control={<Checkbox color='default' aria-label='active'
                            checked={dgpValue ?? false}
                            sx={{
                                color: 'rgba(255,255,255,1)'
                            }}
                        ></Checkbox>} label={t('admin_projects.demogallery_public')} aria-required />
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '0.0rem' }}>
                        <FormControlLabel labelPlacement="start" control={
                            <Checkbox color='default' aria-label='active'
                                checked={activeValue ?? false}
                                sx={{
                                    color: 'rgba(255,255,255,1)'
                                }}
                            ></Checkbox>
                        } label={t('admin_projects.active')} />
                        <FormControlLabel labelPlacement="start" control={
                            <Checkbox color='default' aria-label='active'
                                checked={hasServiceValue ?? false}
                                sx={{
                                    color: 'rgba(255,255,255,1)'
                                }}
                            ></Checkbox>
                        } label={t('admin_projects.hasService')} aria-required />
                    </Box>
                </Box>
                <Box
                    sx={{
                        /* position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', */
                        padding: '0.5em',
                        marginTop: '1rem',
                        marginLeft: 'unset',
                        left: '10%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        backgroundColor: 'primary.main'
                    }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="caption">
                            {t('admin_projects.opening_time') + ":"}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ marginLeft: '8px' }}>
                            {getOpenTime()}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="caption">
                            {t('admin_projects.closing_time') + ":"}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ marginLeft: '8px' }}>
                            {getCloseTime()}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    /* position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', */
                    padding: '0.5em',
                    marginTop: '1rem',
                    marginLeft: 'unset',
                    left: '10%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    backgroundColor: 'primary.main'
                }}>
                    <Box
                        sx={{
                            /* position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)', */
                            /* padding: '0.5em',
                            marginTop: '1rem', */
                            marginLeft: 'unset',
                            left: '10%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                        }}>
                        <Typography variant="caption">
                            {t('admin_projects.prod_active') + ":"}
                        </Typography>
                        {productsArray.reverse().map((prod, index) => (
                            <Box key={prod + "_" + index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {getProductIcon(prod)}
                                <Typography variant="subtitle1" gutterBottom sx={{ marginBottom: 'unset', marginLeft: '8px' }}>
                                    {prod}
                                </Typography>
                            </Box>
                        ))
                        }
                    </Box>
                    <Box
                        sx={{
                            /* position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)', */
                            /* padding: '0.5em',
                            marginTop: '1rem', */
                            marginLeft: '2rem',
                            left: '10%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                        }}>
                        <Typography variant="caption">
                            {t('admin_projects.addons_active') + ":"}
                        </Typography>
                        {addonsArray.reverse().map((addon, index) => (
                            <Box key={addon + "_" + index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography variant="subtitle1" gutterBottom sx={{ marginBottom: 'unset', marginLeft: '8px' }}>
                                    {addon}
                                </Typography>
                            </Box>
                        ))
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                /* position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)', */
                padding: '0.5em',
                marginTop: '6rem',
                marginLeft: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                backgroundColor: 'primary.main',
                height: 'fit-content',
                width: '50vh'
            }}>
                <Typography variant="caption">
                    {t('admin_projects.description') + ":"}
                </Typography>
                <Typography variant="subtitle1" align="left" sx={{ marginTop: '-6px', marginLeft: '8px' }}>
                    {descriptionValue ?? ''}
                </Typography>
                <Typography variant="caption" sx={{ marginTop: '1rem' }}>
                    {t('admin_projects.comment') + ":"}
                </Typography>
                <Typography variant="subtitle1" align="left" gutterBottom sx={{ marginTop: '-6px', marginLeft: '8px' }}>
                    {commentValue ?? ''}
                </Typography>
            </Box>
        </Box>
    );

}