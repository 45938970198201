import * as React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
/* import { useTranslation } from 'react-i18next'; */
import "./scrollList.css";
import { useGetCompaniesQuery, useAddCompanyToUserMutation, useRemoveCompanyFromUserMutation } from '../slices/apiSlice';
/* import { useAppDispatch } from '../hooks'; */
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
/* import Chip from '@mui/material/Chip'; */
import { CompanyObject } from '../../interfaces/commonInterfaces';

/* function not(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
} */

/* function intersection(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
} */

interface CompanySelect {
    uuid: string;
    name: string;
    description: string;
}

interface ICompanyTransferListProps {
    user_uuid: string;
    saveChanges: boolean;
}


export default function CompanyTransferList(props: React.PropsWithChildren<ICompanyTransferListProps>) { 
    /* const { data: dataCompaniesUser, error: errorCompaniesUser, isLoading: isLoadingCompaniesUser } = useGetCompaniesForUserQuery(props.user_uuid); */
    const { data: dataCompanies, error: errorCompanies, isLoading: isLoadingCompanies } = useGetCompaniesQuery('');
    const [apiAllReady, setApiAllReady] = React.useState(false);
    /* const [apiCompanyUserReady, setApiCompanyUserReady] = React.useState(false); */

    /* const dispatch = useAppDispatch(); */
    const [selectedLoadedCompanies] = React.useState<CompanySelect[]>([]);
    const [selectedCompanies, setSelectedCompanies] = React.useState<CompanySelect[]>([]);
    const [allCompanies, setAllCompanies] = React.useState([{
        uuid: "",
        name: "",
        description: ""
    } as CompanySelect]);
    const [addCompanyToUser] = useAddCompanyToUserMutation();
    const [removeCompanyFromUser] = useRemoveCompanyFromUserMutation();

    React.useEffect(() => {        
        if(props.saveChanges === true){
            handleCompanyComparison();
        }
    }, [props.saveChanges]);


    React.useEffect(() => {
        console.log("COMPANY TRANSFERS CURRENT USER UUID: ", props.user_uuid);
        /* console.log("################### isLoading: ", isLoading);
        console.log("################### error: ", error);
        console.log("################### data: ", data);
        console.log("####################### API getProducts"); */
        /*  handleApiProjectsList(); */
        if (isLoadingCompanies === false && errorCompanies === undefined && apiAllReady === false) {
            console.log("####################### API getCompanies LOADED");
            const apiCompanies = dataCompanies.data as CompanyObject[];
            const companiesList: CompanySelect[] = [];

            apiCompanies.forEach(element => {
                const company: CompanySelect = {
                    uuid: element.uuid,
                    name: element.name,
                    description: element.description
                }
                companiesList.push(company);
            });
            setAllCompanies(companiesList);
            setApiAllReady(true);            
        }
    }, [dataCompanies, errorCompanies, isLoadingCompanies, apiAllReady]);

    const handleCompanyComparison = () => {
        if(selectedLoadedCompanies !== selectedCompanies){
            console.log("NEW COMPANIES mach was! *schluck*");
            // check first if any Companies were deleted:
            selectedLoadedCompanies.forEach(element => {
                console.log(element);
                if(selectedCompanies.indexOf(element) === -1){
                    // call api to delete that company from user:
                    removeCompanyFromUserCall(element.uuid,props.user_uuid);
                }                
            });
            // check if new Companies have been added:
            selectedCompanies.forEach(element => {
                console.log(element);
                if(selectedLoadedCompanies.indexOf(element) === -1){
                    // call api to add that company to the user:
                    addCompanyToUserCall(element.uuid,props.user_uuid);
                }
            });
        }
        else{
            console.log("Keine Companies Änderungen *schnarch*");
        }
    }

    const addCompanyToUserCall = async (companyUuid: string, userUuid: string) => {
        try {
            await addCompanyToUser({ companyUuid, userUuid });
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }

    const removeCompanyFromUserCall = async (companyUuid: string, userUuid: string) => {
        try {
            await removeCompanyFromUser({ companyUuid, userUuid });
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }


    return (
        <Box sx={{ width: '100%' }}>
            {(apiAllReady === true)
                ?
                <Stack spacing={3} sx={{ width: '100%' }}>
                    <Autocomplete
                        sx={{ backgroundColor: 'primary.dark', padding: '0.3rem', minHeight: '3em' }}
                        multiple
                        id="tags-standardC1"
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        options={allCompanies}
                        getOptionLabel={(option: CompanySelect) => (option.name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                            />
                        )}
                        onChange={(event: any, newValue: CompanySelect[]) => {
                            setSelectedCompanies(newValue);
                        }}
                        value={selectedCompanies}
                    />
                </Stack>
                : null
            }
        </Box>


    );
}