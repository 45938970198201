import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import guiControllerReducer from './slices/guiControllerSlice';
import mapDataControllerReducer from './slices/MapDataSlice'
import roleStatusReducer from './slices/currentRoleSlice'
import currentAdminEditUserReducer from './slices/currentAdminEditUserSlice'
import currentAdminEditProjectReducer from './slices/currentAdminEditProjectSlice';
import currentAdminEditCopyProjectReducer from './slices/currentAdminEditCopyProjectSlice';
import { creatorApi } from './slices/apiSlice';
import { setupListeners } from '@reduxjs/toolkit/query'

export const store = configureStore({
  reducer: {
    guicontroller: guiControllerReducer,
    mapDataController: mapDataControllerReducer,
    rolestatus: roleStatusReducer,
    currentAdminEditUser: currentAdminEditUserReducer,
    currentAdminEditProject: currentAdminEditProjectReducer,
    currentAdminEditCopyProject: currentAdminEditCopyProjectReducer,
    [creatorApi.reducerPath]: creatorApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(creatorApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
