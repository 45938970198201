import * as React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
/* import { useTranslation } from 'react-i18next'; */
import "./scrollList.css";
import { useGetRolesQuery, useAddRoleToUserMutation, useRemoveRoleFromUserMutation } from '../slices/apiSlice';
/* import { useAppDispatch } from '../hooks'; */
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
/* import Chip from '@mui/material/Chip'; */
import { RoleObject } from '../../interfaces/commonInterfaces';

/* function not(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
} */

/* function intersection(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
} */

interface RoleSelect {
    uuid: string;
    name: string;
}

interface IRolesTransferListAddUserProps {
    user_uuid: string;
    saveChanges: boolean;
}


export default function RolesTransferListAddUser(props: React.PropsWithChildren<IRolesTransferListAddUserProps>) { 
    /* const { data: dataRoleUser, error: errorRoleUser, isLoading: isLoadingRoleUser } = useGetRolesForUserQuery(props.user_uuid); */
    const { data: dataRoles, error: errorRoles, isLoading: isLoadingRoles } = useGetRolesQuery('');
    const [apiAllReady, setApiAllReady] = React.useState(false);
    /* const [apiRoleUserReady, setApiRoleUserReady] = React.useState(false); */

    /* const dispatch = useAppDispatch(); */
    const [selectedLoadedRoles] = React.useState<RoleSelect[]>([]);
    const [selectedRoles, setSelectedRoles] = React.useState<RoleSelect[]>([]);
    const [allRoles, setAllRoles] = React.useState([{
        uuid: "",
        name: "",
    } as RoleSelect]);
    const [addRoleToUser] = useAddRoleToUserMutation();
    const [removeRoleFromUser] = useRemoveRoleFromUserMutation();

    React.useEffect(() => {        
        if(props.saveChanges === true){
            handleRoleComparison();
        }
    }, [props.saveChanges]);


    React.useEffect(() => {

        /* console.log("################### isLoading: ", isLoading);
        console.log("################### error: ", error);
        console.log("################### data: ", data);
        console.log("####################### API getProducts"); */
        /*  handleApiProjectsList(); */
        if (isLoadingRoles === false && errorRoles === undefined && apiAllReady === false) {
            /* console.log("####################### API getRoles LOADED"); */
            const apiRoles = dataRoles.data as RoleObject[];
            const rolesList: RoleSelect[] = [];

            apiRoles.forEach(element => {
                const role: RoleSelect = {
                    uuid: element.uuid,
                    name: element.name
                }
                rolesList.push(role);
            });
            setAllRoles(rolesList);
            setApiAllReady(true);            
        }
    }, [dataRoles, errorRoles, isLoadingRoles, apiAllReady]);
   

    const handleRoleComparison = () => {
        if(selectedLoadedRoles !== selectedRoles){
            /* console.log("NEW ROLES mach was! *schluck*"); */
            // check first if any Roles were deleted:
            selectedLoadedRoles.forEach(element => {
                /* console.log(element); */
                if(selectedRoles.indexOf(element) === -1){
                    // call api to delete that role from user:
                    removeRoleFromUserCall(element.uuid,props.user_uuid);
                }                
            });
            // check if new Roles have been added:
            selectedRoles.forEach(element => {
                /* console.log(element); */
                if(selectedLoadedRoles.indexOf(element) === -1){
                    // call api to add that role to the user:
                    addRoleToUserCall(element.uuid,props.user_uuid);
                }
            });
        }
        else{
            /* console.log("Keine Roles Änderungen *schnarch*"); */
        }
    }

    const addRoleToUserCall = async (roleUuid: string, userUuid: string) => {
        try {
            await addRoleToUser({ roleUuid, userUuid });
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }

    const removeRoleFromUserCall = async (roleUuid: string, userUuid: string) => {
        try {
            await removeRoleFromUser({ roleUuid, userUuid });
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }


    return (
        <Box sx={{ width: '100%' }}>
            {(apiAllReady === true)
                ?
                <Stack spacing={3} sx={{ width: '100%' }}>
                    <Autocomplete
                        sx={{ backgroundColor: 'primary.dark', padding: '0.3rem', minHeight: '3em' }}
                        multiple
                        id="tags-standardA2"
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        options={allRoles}
                        getOptionLabel={(option: RoleSelect) => (option.name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                            />
                        )}
                        onChange={(event: any, newValue: RoleSelect[]) => {
                            setSelectedRoles(newValue);
                        }}
                        value={selectedRoles}
                    />
                </Stack>
                : null
            }
        </Box>


    );
}