import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
/* import type { RootState } from '../store' */
import { Role } from '../../interfaces/interfaceRole';

// Define a type for the slice state
interface CurrentRole {
  value: Role;
}

// Define the initial state using that type
const initialState: CurrentRole = {
  value: {
    "name":   "",
    "tools":  [],
    "admin":  false,
    "rights": []
  },
}

export const currentRoleSlice = createSlice({
  name: 'currentRole',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentRoleStatus: (state, action: PayloadAction<Role>) => {
      state.value = action.payload;
    },
  },
})

export const { setCurrentRoleStatus } = currentRoleSlice.actions

// Other code such as selectors can use the imported `RootState` type
/* export const selectCurrentUserStatus = (state: RootState) => state.userstatus */

export default currentRoleSlice.reducer