import Typography from '@mui/material/Typography';
import { useAppSelector } from '../hooks';
import SettingsIcon from '@mui/icons-material/Settings';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useGetProjectQuery, useLazyGetProjectQuery } from '../slices/apiSlice';
import { ApiProject } from '../../interfaces/commonInterfaces';
import { useState, useEffect } from 'react';
import Deployment_Maps from './projectComponents/deployment_maps';

let currentMapUuid: string = "";

function Tool_deployment() {
  const currentProject = useAppSelector((state) => state.guicontroller.value.currentProject);
  const currentProjectTool = useAppSelector((state) => state.guicontroller.value.currentProjectTool);
  let layerPgwArray = useAppSelector((state) => state.mapDataController.value.layerPgws);
  const { data, error, isLoading } = useGetProjectQuery(currentProject);
  const [getProject] = useLazyGetProjectQuery();
  const { t } = useTranslation();


  const queryProject = async () => {
    await getProject(currentProject)
      .then((response: any) => {
        const apiTempProject: ApiProject = response.data.data as ApiProject;
        setApiProject(apiTempProject);
        /* console.log("apiTempProject ", apiTempProject); */
        if (nullCheck(apiTempProject.mapUuid) === true) {
          console.log("NO map in project " + apiTempProject.projectId);
        }
        else {
          currentMapUuid = apiTempProject.mapUuid as string;
          // load map
        }
      });
  }


  useEffect(() => {
    queryProject();
  }, [layerPgwArray]);

  useEffect(() => {
    /*  handleApiProjectsList(); */
    if (isLoading === false && error === undefined) {
      /* console.log("####################### API useGetProjectQuery LOADED"); */
      const apiTempProject: ApiProject = data.data as ApiProject;
      /*  console.log("apiTempProject: ", apiTempProject); */
      setApiProject(apiTempProject);

      if (nullCheck(apiTempProject.mapUuid) === true) {
        /* console.log("NO map in project " + apiTempProject.projectId); */
      }
      else {
        /* console.log("Project '" + apiTempProject.projectId + "' has already a map with Uuid '" + apiTempProject.mapUuid + "'! No map needs to be created!"); */
        currentMapUuid = apiTempProject.mapUuid as string;
        // load map
      }

      /*  const project = data.data as Market[]; */
    }
  }, [data, error, isLoading]);

  const [apiProject, setApiProject] = useState<ApiProject>({
    "projectId": 0,
    "name": "",
    "shortName": "",
    "description": "",
    "comment": "",
    "city": "",
    "openingTime": "10:00:00",
    "closingTime": "20:00:00",
    "country": "DE",
    "active": true,
    "hasService": false,
    "market": "",
    "tags": [],
    "links": [],
    "products": [],
    "addons": [],
    "demoGalleryEnabled": false,
    "demoGalleryPublic": false,
    "mapUuid": "",
    "languages": [
      {
        "code": "de",
        "name": "German"
      },
    ],
    "createdAt": "2023-08-12T15:29:39.000000Z",
    "modifiedAt": "2023-08-12T15:29:39.000000Z",
    "timezone": null
  });

  const nullCheck = (str: string | null): boolean => {
    let bNullValue = false;
    if (str === null) {
      bNullValue = true;
    }
    if (str === undefined) {
      bNullValue = true;
    }
    if (str === "") {
      bNullValue = true;
    }
    return bNullValue;
  }

  if (currentProject === 0) {
    return null;
  }

  if (currentProject !== 0 && currentProjectTool !== 7) {
    return null;
  }







  return (
    <Container sx={{
      /* position: 'absolute',
      top: '6.8%',
      left: '3.4%',
      width: '96.6%', */
      height: '100%',
      background: 'linear-gradient(#12526A 0%, #12526A 100%) 0% 0% no-repeat padding-box',
      maxWidth: 'unset !important'
    }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pt: '2vh', pl: '1vh', pb: '0vh', height: 'auto' }}>
        <Typography variant="h6" gutterBottom align='left' sx={{
          fontWeight: '400'
        }}>{t("sidebar.entry8")}</Typography>
        <SettingsIcon sx={{ pl: '1vh', width: '1.3em', height: '1.3em' }}></SettingsIcon>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", mt: '3vh' }}>
        <Deployment_Maps apiProject={apiProject}></Deployment_Maps>
      </Box>

    </Container>
  );
}

export default Tool_deployment;