import * as React from 'react';
/* import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; */
import { useAppDispatch, useAppSelector } from './hooks';
import Divider from '@mui/material/Divider';
/* import { setGuiStatus } from './slices/guiStatusSlice'; */
import { useTranslation } from 'react-i18next';
import _projects from "../projectlist.json"
import { setCurrentGuiController_currentProject, setCurrentGuiController_mapsGeoCoordinates_A, setCurrentGuiController_mapsGeoCoordinates_B, setCurrentGuiController_mapsPreviewRenew, setCurrentGuiController_mapsStep, setCurrentGuiController_valueTabs } from './slices/guiControllerSlice';
import { useGetProjectsForUserQuery, useGetProjectsQuery } from './slices/apiSlice';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
/* import { GUIController } from '../interfaces/interfaceGuiController';
import { resetCurrentMapDataController } from './slices/MapDataSlice'; */



export default function BasicMenu() {
    const { data, error, isLoading } = useGetProjectsQuery('');
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    /* const currentUser = useAppSelector((state) => state.userstatus.value); */
    const currentUser = useAppSelector((state) => state.guicontroller.value.currentUser);
    /* const currentLoginState = useAppSelector((state) => state.guicontroller.value.guiStatus); */
    const { data: dataProjectsUser, error: errorProjectsUser, isLoading: isLoadingProjectsUser } = useGetProjectsForUserQuery(currentUser.uuid);
    /* const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null); */
    /* const open = Boolean(anchorEl); */
    const [fill, setFill] = React.useState(false);
    const [apiReady, setApiReady] = React.useState(false);
    /* const [menuTitleProj, setMenuTitleProj] = React.useState(t('drpdwnmnproj.title')) */
    const [usedProjects, setUsedProjects] = React.useState([{
        active: 0,
        city: "",
        country: "",
        createdAt: "",
        demoGalleryEnabled: 0,
        demoGalleryPublic: 0,
        description: "",
        hasService: 0,
        languages: [""],
        mapUuid: null,
        market: "",
        modifiedAt: "",
        name: "",
        projectId: 0,
        shortName: "",
    } as ApiProject]);
    const [apiProjects, setApiProjects] = React.useState([{
        active: 0,
        city: "",
        country: "",
        createdAt: "",
        demoGalleryEnabled: 0,
        demoGalleryPublic: 0,
        description: "",
        hasService: 0,
        languages: [""],
        mapUuid: null,
        market: "",
        modifiedAt: "",
        name: "",
        projectId: 0,
        shortName: "",
    }])

    interface ApiProject {
        active: number;
        city: string;
        country: string;
        createdAt: string;
        demoGalleryEnabled: number;
        demoGalleryPublic: number;
        description: string;
        hasService: number;
        languages: string[];
        mapUuid: null;
        market: string;
        modifiedAt: string;
        name: string;
        projectId: number;
        shortName: string;
    }

    interface Project {
        id: number;
        kiosks: string,
        name: string,
        group: string,
        description: string;
        products: string;
        addons: string;
        userlist: string;
    }

    const projects = _projects as Project[];
    // const projectsJsonZero = projects[0];


    /* const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }; */
    /* const handleClose = (param: string) => {
        setAnchorEl(null);
        switch (param) {
            case "profile":
                console.log("menu select: " + param);
                break;
            case "account":
                console.log("menu select: " + param);
                break;
            case "logout":
                console.log("menu select: " + param);
                break;
            default:
                break;
        }
    }; */

    const handleProjectSelectNew = (param: string | null) => {
        if (param !== null) {
            const id: number = parseInt(param.substring(0, 6));
            const name: string = param.substring(9);
            console.log("id: ", id);
            console.log("name: ", name);

            dispatch(setCurrentGuiController_mapsGeoCoordinates_A([-1, -1]));
            dispatch(setCurrentGuiController_mapsGeoCoordinates_B([-1, -1]));

            /* setAnchorEl(null); */
            /* setMenuTitleProj(id + " - " + name); */

            dispatch(setCurrentGuiController_mapsStep(6));
            dispatch(setCurrentGuiController_valueTabs(0));

            dispatch(setCurrentGuiController_mapsPreviewRenew(true));
            dispatch(setCurrentGuiController_currentProject(id));
        }
        else {
            console.log("null selection clear Project!")
            // clear project
        }

    }

    const handleProjectSelect = (id: number, name: string) => {
        /* setAnchorEl(null); */
        /* setMenuTitleProj(id + " - " + name); */

        /* dispatch(setCurrentGuiController_valueTabs(0)); */
        /* dispatch(setCurrentGuiController_mapsManualSetup(false));
        dispatch(setCurrentGuiController_mapsAssistant(false)); */
        /* dispatch(setCurrentGuiController_mapsPreviewRenew(true)); */
        /* const gc: GUIController = {
            guiStatus: currentLoginState,
            currentProject: id,
            currentProjectTool: -1,
            currentAdminTool: -1,
            currentAdminMode: 0,
            currentUser: currentUser,
            mapsAssistant: false,
            mapsAssistant_uploads: 0,
            mapsAssistant_note: "none",
            mapsAssistant_note_read: false,
            mapsManualSetup: false,
            mapsAssistant_rotation: 0,
            editedLayerNames: [],
            editedLayerIds: [],
            mapsStep: 6,
            valueTabs: 0,
            mapsAssistant_layers: {
                images: []
            },
            mapsLayerPaths: [],
            mapsFiles: {
                files: []
            },
            mapsGeoCoordinates_A: [-1, -1],
            mapsGeoCoordinates_B: [-1, -1],
            mapsPixelCoordinates_A: [-1, -1],
            mapsPixelCoordinates_B: [-1, -1],
            mapsPreviewRenew: false,
            mapsRotation: 0,
            mapsAssistant_layers_index: 0,
            modalLoading: false,
            projectstable_page: 0
        }
        dispatch(resetCurrentMapDataController());
        dispatch(setCurrentGuiController_projectChange(gc)); */
        dispatch(setCurrentGuiController_mapsStep(6));
        dispatch(setCurrentGuiController_valueTabs(0));

        dispatch(setCurrentGuiController_mapsPreviewRenew(true));
        dispatch(setCurrentGuiController_currentProject(id));

        // dispatch(setCurrentGuiController_currentProject(id));
    }

    /* const handleApiProjectsList = () => {
        if(isLoading === false && error === undefined){
            const apiProjects = data.data as ApiProject[];
            setApiProjects(apiProjects);
            setApiReady(true);
        }
    } */

    React.useEffect(() => {

        /* console.log("################### isLoading: ", isLoading);
        console.log("################### error: ", error);
        console.log("################### data: ", data);
        console.log("####################### API getProducts"); */
        /*  handleApiProjectsList(); */
        // const { data: dataProjectsUser, error: errorProjectsUser, isLoading: isLoadingProjectsUser } = useGetRolesForUserQuery(props.user_uuid);
        if (isLoadingProjectsUser === false && errorProjectsUser === undefined && apiReady === true) {
            /* console.log("####################### API getRolesUser LOADED: dataProjectsUser.data ", dataProjectsUser.data); */
            const apiProjectsUser = dataProjectsUser.data as ApiProject[];
            const clone: ApiProject[] = structuredClone(apiProjectsUser);
            if (apiProjectsUser.length > 0) {
                clone.sort((a, b) => {
                    if (a.projectId < b.projectId) {
                        return -1;
                    }
                    if (a.projectId > b.projectId) {
                        return 1;
                    }
                    return 0;
                });
                const clone2: ApiProject[] = structuredClone(clone).reverse();
                setUsedProjects(clone2);
            }
            else {
                const clone3: ApiProject[] = structuredClone(apiProjects).reverse();
                setUsedProjects(clone3);
            }


        }
    }, [dataProjectsUser, errorProjectsUser, isLoadingProjectsUser, apiReady]);

    React.useEffect(() => {
        if (isLoading === false && error === undefined) {
            const apiProjects = data.data as ApiProject[];
            /* console.log("####################### API getProjects");
            console.log("################### LOADED!"); */
            /* console.log("apiProjects loaded: ", apiProjects); */
            setApiProjects(apiProjects);
            setApiReady(true);
        }
    }, [data, error, isLoading]);



    React.useEffect(() => {
        if (currentUser.uuid !== "0" && apiReady === true) {
            /* console.log(userStatus.projectlist);
            console.log(projectsJsonZero); */
            /* setMenuTitleProj(t('drpdwnmnproj.title')); */
            /* let userProjectList: ApiProject[] = []; */
            /* if (currentUser.projectlist.length === 0) { */
            // setUsedProjects(apiProjects);
            setFill(true);
            /* } */
            /* else {
                currentUser.projectlist.forEach(userProjectId => {
                    apiProjects.forEach(project => {
                        if (project.projectId === userProjectId) {
                            userProjectList.push(project)
                        }
                    });
                    if (userProjectList.length > 0) {
                        setUsedProjects(userProjectList);                        
                        setFill(true);
                    }
                    // setMenuTitleProj(t('drpdwnmnproj.title'));
                });
            } */

        }
    }, [currentUser, projects, t, apiReady, apiProjects]);

    return (
        <div>
            {fill
                ?
                <Stack spacing={2} sx={{ width: 800 }} divider={<Divider sx={{
                    borderColor: 'primary.contrastText',
                    /* marginTop: '0px !important',
                    marginBottom: '0px !important', */
                }}></Divider>}>
                    <Autocomplete
                        size="small"
                        disableClearable
                        sx={{
                            backgroundColor: '#000000',
                            padding: '0.3rem',
                            "& .MuiInputBase-input-MuiInput-input": {
                                fontWeight: 'bold !important'
                            },
                            "& .MuiOutlinedInput-root": {
                                // border: "1px solid yellow",
                                color: 'white',
                                /* fontWeight: 'bold !important' */
                                /* marginTop: '0.2em' */
                            },
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #eee",
                                /* fontWeight: 'bold !important' */
                            },
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline .Mui-focused": {
                                border: "1px solid #eee",
                                /* fontWeight: 'bold !important' */
                            },
                            "& label": {
                                color: "white",
                                /* fontWeight: 'bold',
                                fontSize: '1.3em', */
                                /* paddingTop: '0 !important',
                                paddingBottom: '0.5em !important' */
                            },
                            "& label.Mui-focused": {
                                color: "white",
                                /* fontWeight: 'normal', */
                                /* fontSize: '1.0em', */
                            }
                        }}
                        id="projects_autoComplete"
                        /* freeSolo */
                        onChange={(event: any, newValue: string | null) => {
                            handleProjectSelectNew(newValue);
                        }}
                        options={usedProjects.map((option) => (option.projectId + " - " + option.name))}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} style={{
                                    marginLeft: '0px',
                                    marginRight: '0',
                                    marginTop: '0',
                                    marginBottom: '0',
                                    padding: '0',
                                    borderBottom: '1px solid white'
                                }}>
                                    <Box
                                        sx={{
                                            marginLeft: '0px',
                                            padding: '0',
                                            paddingLeft: '1em',
                                            marginBottom: '7px !important',
                                            marginTop: '7px !important',
                                            color: 'primary.contrastText',
                                            textTransform: 'unset !important',
                                            /* fontSize: '1.3em', */
                                            fontWeight: 'normal',
                                            width: '100%'
                                        }}
                                    >
                                        {(option)}
                                    </Box>
                                </li>

                            );
                        }}
                        renderInput={(params) => <TextField {...params} sx={{
                            marginLeft: '0',
                            /* color: 'primary.contrastText', */
                            color: 'secondary.main !important',
                            textTransform: 'unset !important',
                            fontWeight: 'bold'
                        }} label={t('drpdwnmnproj.title')} variant="standard" />}
                        ListboxProps={
                            {
                                style: {
                                    maxHeight: '90vh',
                                    border: '1px solid white',
                                    backgroundColor: 'black',
                                    margin: '0',
                                    padding: '0'
                                }
                            }
                        }
                    />
                </Stack>
                : null
            }
            {/* <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    marginLeft: '0',
                    color: 'primary.contrastText',
                    textTransform: 'unset !important',
                    fontSize: '1.3em',
                    fontWeight: 'normal'
                }}
            >
                {menuTitleProj}
                {open ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        backgroundColor: 'info.main',
                        paddingBottom: '0px !important'
                    }
                }}
            >
                {fill
                    ?
                    usedProjects.map((element: ApiProject) => {

                        return <div key={element.projectId}>
                            <Divider sx={{
                                borderColor: 'primary.contrastText',
                                marginTop: '0px !important',
                                marginBottom: '0px !important',
                            }}></Divider>
                            <MenuItem onClick={() => handleProjectSelect(element.projectId, element.name)}>{element.projectId + " - " + element.name}</MenuItem>
                        </div>

                    })
                    : null
                }


            </Menu> */}
        </div>
    );
}


