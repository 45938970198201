import Box from "@mui/material/Box";
/* import Button from "@mui/material/Button"; */
import Typography from "@mui/material/Typography";
/* import { styled } from "@mui/material/styles"; */
import { useTranslation } from 'react-i18next';
/* import { useAppSelector } from '../../hooks'; */
import { useEffect, useState } from "react";
import LayersIcon from '@mui/icons-material/Layers';
import { ApiProject } from "../../../interfaces/commonInterfaces";
import Grid from "@mui/material/Grid";
import DeploymentItem from "./deployment_item";
import SettingsIcon from '@mui/icons-material/Settings';
import { LayerFile, LayerNames, MapData } from "../../../interfaces/interfaceGuiController";
import { useLazyGetMapQuery } from '../../slices/apiSlice';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setCurrentGuiController_mapsRefetch } from "../../slices/guiControllerSlice";


interface IDeployment_Map_Props {
    apiProject: ApiProject;
}



function Deployment_Maps(props: React.PropsWithChildren<IDeployment_Map_Props>) {
    const dispatch = useAppDispatch();
    const [apiProject, setApiProject] = useState<ApiProject>({
        "projectId": 0,
        "name": "",
        "shortName": "",
        "description": "",
        "comment": "",
        "city": "",
        "openingTime": "10:00:00",
        "closingTime": "20:00:00",
        "country": "DE",
        "active": true,
        "hasService": false,
        "market": "",
        "tags": [],
        "links": [],
        "products": [],
        "addons": [],
        "demoGalleryEnabled": false,
        "demoGalleryPublic": false,
        "mapUuid": "",
        "languages": [
            {
                "code": "de",
                "name": "German"
            },
        ],
        "createdAt": "2023-08-12T15:29:39.000000Z",
        "modifiedAt": "2023-08-12T15:29:39.000000Z",
        "timezone": null
    }); //layerFiles={mapLayerFiles as { [key: string]: LayerFile }}
    const [getMapCall] = useLazyGetMapQuery();
    const mapsRefetch = useAppSelector((state) => state.guicontroller.value.mapsRefetch);
    /* const [mapCalled, setMapCalled] = useState(false); */
    const [mapLayers, setMapLayers] = useState<LayerNames[]>();
    const [mapLayerFiles, setMapLayerFiles] = useState<{ [key: string]: LayerFile }>();
    let currentMapUuid: string = "";
    /* setApiProject(props.apiProject); */
    currentMapUuid = props.apiProject.mapUuid as string;
    const { t } = useTranslation();

    useEffect(() => {        
        currentMapUuid = props.apiProject.mapUuid as string;
        console.log("currentMapUuid in deployment_maps updated: ", currentMapUuid);
        console.log("currentMapUuid in deployment_maps updated: ", props.apiProject);
    }, [props.apiProject]);

    useEffect(() => {
        if(mapsRefetch === true){
            queryMap();
            dispatch(setCurrentGuiController_mapsRefetch(false));
        }        
    },[mapsRefetch]);


    const queryMap = async () => {

        await getMapCall(currentMapUuid)
            .then((response: any) => {

                let mapData: MapData = response.data.data.mapData as MapData;
                let filesData: { [key: string]: LayerFile } = response.data.data.files as { [key: string]: LayerFile };
                setMapLayerFiles(filesData);
                if (mapData.layerNames !== undefined) {
                    /* console.log("mapData.layerNames: ", mapData.layerNames);
                    console.log("response: ", response); */
                    setMapLayers(mapData.layerNames);
                }
                else {

                }

            }
            );
    }

    useEffect(() => {
        /* console.log("useEffect currentMapUuid: ", currentMapUuid); */
        /* setApiProject(props.apiProject); */
        if (currentMapUuid !== "") {
            console.log("map uuid found, call api!");
            queryMap();
        }

    }, [currentMapUuid]);

    /* if (apiProject.mapUuid !== null && apiProject.mapUuid !== undefined) {
        if (mapCalled === false) {
            setMapCalled(true);
            if (apiProject.mapUuid !== null && apiProject.mapUuid !== undefined) {
                queryMap();
            }
            else {
                console.log("no mapData for Project ", apiProject.projectId);
            }
        }
    } */

    return (
        <Box sx={{ display: "flex", flexDirection: "column", borderBottom: '3px solid #ffffff66', mt: '3vh', mb: '3vh' }}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <LayersIcon sx={{ ml: '1vh', mr: '2vh', width: '1.3em', height: '1.3em' }}></LayersIcon>
                    <Typography variant="subtitle1" gutterBottom align='left' sx={{
                        fontWeight: '400'
                    }}>Karten <br></br>(MapTiles)</Typography>
                </Box>
                <DeploymentItem
                    buttonTarget='sdfgsdf'
                    buttonTitle={t('tools.deployment.tiles_gen')}
                    date='2024-02-14T14:26:12'
                    linkTarget='createTiles'
                    user='Benjamin Wysk'
                    mainColor='green'
                    apiProject={props.apiProject}
                    layers={mapLayers as LayerNames[]}
                    layerFiles={mapLayerFiles as { [key: string]: LayerFile }}
                    mapUuid={currentMapUuid}
                >
                </DeploymentItem>
                <Box>
                    <DeploymentItem
                        buttonTarget='sdfgsdf'
                        buttonTitle={t('tools.deployment.beta_tiles')}
                        date='2024-02-15T11:17:59'
                        linkTarget='deployTilesBeta'
                        user='Benjamin Wysk'
                        mainColor='blue'
                        apiProject={props.apiProject}
                        layers={mapLayers as LayerNames[]}
                        layerFiles={mapLayerFiles as { [key: string]: LayerFile }}
                        mapUuid={currentMapUuid}
                    >
                    </DeploymentItem>
                </Box>
                <Box>
                    <DeploymentItem
                        buttonTarget='sdfgsdf'
                        buttonTitle={t('tools.deployment.live_tiles')}
                        date='2024-02-19T16:11:21'
                        linkTarget='deployTilesLive'
                        user='Benjamin Wysk'
                        mainColor='red'
                        apiProject={props.apiProject}
                        layers={mapLayers as LayerNames[]}
                        layerFiles={mapLayerFiles as { [key: string]: LayerFile }}
                        mapUuid={currentMapUuid}
                    >
                    </DeploymentItem>
                </Box>
                <Box>

                </Box>
            </Grid>
            <SettingsIcon sx={{ pl: '1vh', width: '1.0em', height: '1.0em' }}></SettingsIcon>
        </Box>
    );
}

export default Deployment_Maps;