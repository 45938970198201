import Typography from '@mui/material/Typography';
import { useAppSelector } from '../hooks';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import Container from '@mui/material/Container';
import ProjectsTable from './adminComponents/projectsTable';
import ProjectEdit from './adminComponents/projectEdit';
import ProjectAdd from './adminComponents/projectAdd';


export default function Tool_projects() {
  const currentAdminMode = useAppSelector((state) => state.guicontroller.value.currentAdminMode);
  const currentProjectTool = useAppSelector((state) => state.guicontroller.value.currentProjectTool);
  const currentProjectEdit = useAppSelector((state) => state.currentAdminEditProject.value);
  const { t } = useTranslation();

  /* React.useEffect(() => {
    console.log("currentProjectEdit.projectId: " + currentProjectEdit.projectId);
  }, [currentProjectEdit]);
 */
  if (currentAdminMode === 0) {
    return null;
  }

  if (currentProjectTool !== 1) {
    return null;
  }

  const getProjectsTitle = (): string => {
    let titleString: string = "";
    if (currentProjectEdit.projectId === 0) {
      titleString = t('admin_projects.title');
    }
    if (currentProjectEdit.projectId !== 0 && currentProjectEdit.projectId !== 9999999) {
      titleString = t('admin_projects.title_edit') + ":";
    }
    if (currentProjectEdit.projectId === 9999999) {
      titleString = t('admin_projects.add_project') + ":";
    }
    return titleString;
  }

  return (
    <Container sx={{
      /* position: 'absolute',
      top: '6.8%',
      left: '3.4%',
      width: '96.6%', */
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '3rem !important',
      height: '100%',
      /* background: 'linear-gradient(#08222c 0%, #12526A 100%) 0% 0% no-repeat padding-box', */
      backgroundColor: '#092935',
      maxWidth: 'unset !important'
    }}>

      <Typography variant="h6" gutterBottom align='left' sx={{
        paddingTop: '1rem'
      }}>{getProjectsTitle()}</Typography>

      {(currentProjectEdit.projectId === 0)
        ?
        <ProjectsTable />
        : null
      }

      {(currentProjectEdit.projectId !== 0 && currentProjectEdit.projectId !== 9999999)
        ?
        <ProjectEdit />
        : null
      }
      {currentProjectEdit.projectId === 9999999
        ?
        <ProjectAdd />
        : null
      }

    </Container>
  );
}