import Typography from '@mui/material/Typography';
import { useAppSelector } from '../hooks';
import * as React from 'react';
import Container from '@mui/material/Container';


export default function Tool_homescreen() {
  const currentProject = useAppSelector((state) => state.guicontroller.value.currentProject);
  const currentProjectTool = useAppSelector((state) => state.guicontroller.value.currentProjectTool);

  if (currentProject === 0) {
    return null;
  }

  if (currentProject !== 0 && currentProjectTool !== 5) {
    return null;
  }
  return (
    <Container sx={{
      /* position: 'absolute',
      top: '6.8%',
      left: '3.4%',
      width: '96.6%', */
      height: '100%',
      background: 'linear-gradient(#12526A 0%, #12526A 100%) 0% 0% no-repeat padding-box',
      maxWidth: 'unset !important'
    }}>
      <div>
        <Typography variant="h3" gutterBottom align='left' sx={{
          fontWeight: '600',
        }}>{"Homescreen Tool"}</Typography>
      </div>
    </Container>
  );
}