import Container from '@mui/material/Container';
/* import TextField from '@mui/material/TextField'; */
import Typography from '@mui/material/Typography';
/* import styled from '@mui/material/styles/styled'; */
import { useTranslation } from 'react-i18next';
import { /* useAppDispatch */ useAppSelector } from '../../hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import "./scrollDashboard.css";
import { useGetProjectsForMapQuery, useGetProtocolsQuery } from '../../slices/apiSlice';
import { useEffect, useState } from 'react';
import { ProjectAdminProtocolEntry } from '../../../interfaces/commonInterfaces';
import 'dayjs/locale/de';
import dayjs from 'dayjs';
import ProtocolTable from './protocolTable';

/* const CssTextField = styled(TextField)({
    marginTop: 0,
    '& label.Mui-focused': {
        color: '#ffffff',
    },
    '& .MuiInput-underline:hover': {
        borderBottomColor: '#ffffff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ffffff',
    },
}); */


export default function DashboardAdmin() {
    /* const currentProject = useAppSelector((state) => state.currentproject.value); */
    const currentProject = useAppSelector((state) => state.guicontroller.value.currentProject);
    const currentProjectTool = useAppSelector((state) => state.guicontroller.value.currentProjectTool);
    // const currentUser = useAppSelector((state) => state.userstatus.value);
    const currentUser = useAppSelector((state) => state.guicontroller.value.currentUser);
    const currentAdminMode = useAppSelector((state) => state.guicontroller.value.currentAdminMode);
    const { data, error, isLoading } = useGetProtocolsQuery('');
    /* const dispatch = useAppDispatch(); */
    const [apiProjectProtocols, setApiProjectProtocols] = useState<ProjectAdminProtocolEntry[]>([{ createdAt: "", message: "", id: 0, projects: [] }]);
    const { t } = useTranslation();

    const randomDate = (start: Date, end: Date): Date => {
        const rDate: Date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
        return rDate;
    }

    const randomIntFromInterval = (min: number, max: number) => { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    let dates: Date[] = [];
    for (let index = 0; index < randomIntFromInterval(3, 20); index++) {
        const element = randomDate(new Date(2022, 0, 1), new Date());
        dates.push(element);

    }
    const sortedDates = dates.slice().sort((a, b) => a.getTime() - b.getTime());

    useEffect(() => {
        if (isLoading === false && error === undefined) {
            const apiProjectProtocolsList = data.data as ProjectAdminProtocolEntry[];
            /* console.log("####################### API getProtocols");
            console.log("currentAdminMode API getProtocols: ", currentAdminMode); */
            /* console.log("################### LOADED!"); */
            console.log("apiProjectProtocolsList loaded: ", apiProjectProtocolsList);
            setApiProjectProtocols(apiProjectProtocolsList);
        }
    }, [data, error, isLoading]);

    if (currentAdminMode === 0) {
        return null;
    }

    const getUsedProjects = (entry: ProjectAdminProtocolEntry) => {
        let projectsString: string = "";
        if (entry.projects !== null) {
            if (entry.projects.length > 0) {
                entry.projects.forEach(proString => {
                    if (projectsString === "") {
                        projectsString = proString;
                    }
                    else {
                        projectsString += ", " + proString;
                    }
                });
            }

            if (projectsString !== "") {
                projectsString += " | "
            }
        }
        return projectsString;
    }

    /*       if (currentProjectTool !== -1) {
            return null;
          } */


    return (
        <Container key={"rootDashboardAdmin"} component="main" maxWidth="sm"
            sx={{
                /* position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)', */
                /* position: 'absolute', */
                marginRight: '2em',
                marginTop: '2rem',
                marginLeft: 'unset',
                top: '0%',
                /* left: '10%', */
            }}>

            <Typography variant="h3" gutterBottom align='left' sx={{
                fontWeight: '600',
            }}>
                {t('dashboard.greeting') + " " + currentUser.firstname}
            </Typography>
            <Container key={"rootDateList"} sx={{
                overflow: 'auto',
                maxHeight: '80%'
            }}>
                <Container key={"rootDateEntry_" + Math.random()} sx={{
                    maxHeight: '75vh',
                    paddingLeft: '0px !important'
                }}>
                    <Typography variant="subtitle1" gutterBottom align='left'>
                        {t('dashboard.changesAdmin')}
                    </Typography>
                    <div className="dashboard_scroll_area">
                        <PerfectScrollbar>
                            <div className="content">
                                {(apiProjectProtocols as ProjectAdminProtocolEntry[]).map((entry) => (
                                    <Container key={"DateEntry_" + Math.random()}>
                                        <Typography variant="subtitle1" gutterBottom align='left'>
                                            {getUsedProjects(entry) + dayjs(entry.createdAt).locale('de').format('LLL') + " Uhr:"}
                                        </Typography>
                                        <Typography variant="subtitle2" gutterBottom align='left' sx={{ ml: '1em' }}>
                                            {entry.message}
                                        </Typography>
                                    </Container>
                                ))
                                }
                            </div>
                        </PerfectScrollbar>
                    </div>
                </Container>
            </Container>
            {/* <ProtocolTable /> */}
        </Container>

    );
}