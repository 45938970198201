import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAppSelector, useAppDispatch } from './hooks';
import Divider from '@mui/material/Divider';
import { setCurrentGuiController_guistatus } from './slices/guiControllerSlice';
/* import { setGuiStatus } from './slices/guiStatusSlice'; */
import { useGetLogoutQuery } from './slices/apiSlice'
import { useTranslation } from 'react-i18next';

export default function BasicMenu() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [isLogin, setIsLogin] = React.useState(true);
    const currentUser = useAppSelector((state) => state.guicontroller.value.currentUser);
    /*  const currentUser = useAppSelector((state) => state.userstatus.value); */
    const { data, error, isLoading, isSuccess, status } = useGetLogoutQuery('', { skip: isLogin });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (param: string) => {
        setAnchorEl(null);
        switch (param) {
            case "profile":
                console.log("menu select: " + param);
                break;
            case "account":
                console.log("menu select: " + param);
                break;
            case "logout":
                console.log("menu select: " + param);
                /* dispatch(setGuiStatus("logout")); */
                setIsLogin(false);

                break;
            default:
                break;
        }
    };

    React.useEffect(() => {

        if (isLoading === false && error === undefined) {
            /* console.log("####################### API getLogout LOADED: ", data); */
        }
        if (isSuccess === true) {
            /* console.log("####################### API getLogout Success: ", data); */
            dispatch(setCurrentGuiController_guistatus("logout"));
        }
    }, [data, error, isLoading, isSuccess, status, dispatch]);

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    color: 'primary.contrastText',
                    textTransform: 'unset !important',
                    fontWeight: 'bold'
                }}
            >
                {currentUser.firstname}
                {open ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        backgroundColor: 'info.main',
                        paddingBottom: '0px !important'
                    }
                }}
            >
                <Divider sx={{
                    borderColor: 'primary.contrastText',
                    marginTop: '0px !important',
                    marginBottom: '0px !important',
                }}></Divider>
                <MenuItem disabled onClick={() => handleClose("profile")}>{t('drpdwnmn.profile')}</MenuItem>
                <Divider sx={{
                    borderColor: 'primary.contrastText',
                    marginTop: '0px !important',
                    marginBottom: '0px !important',
                }}></Divider>
                <MenuItem disabled onClick={() => handleClose("account")}>{t('drpdwnmn.account')}</MenuItem>
                <Divider sx={{
                    borderColor: 'primary.contrastText',
                    marginTop: '0px !important',
                    marginBottom: '0px !important',
                }}></Divider>
                <MenuItem onClick={() => handleClose("logout")}>{t('drpdwnmn.logout')}</MenuItem>
            </Menu>
        </div>
    );
}