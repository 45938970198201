import { LooseObject } from "../interfaces/commonInterfaces";

export class HelmertTransformation {

    private similarity: boolean = false;
    private hasControlPoints: boolean = false;
    private a_!: number;
    private sc_!: number[];
    private tr_!: number[];
    private matrix!: number[] | boolean;

    constructor(options?: LooseObject) {
        if (!options) options = {};
        this.similarity = options.similarity;
        this.matrix = [1, 0, 0, 0, 1, 0];
        this.hasControlPoints = false;
    }



    public setControlPoints(xy: number[][], XY: number[][]) {
        if (xy.length < 2) {
            this.matrix = [1, 0, 0, 0, 1, 0];
            this.hasControlPoints = false;
        }
        else {
            if (this.similarity || xy.length < 3) this.matrix = this._similarity(xy, XY);
            else this.matrix = this._helmert(xy, XY);
            this.hasControlPoints = true;
        }
        return this.hasControlPoints;
    }

    public reverse(xy: number[]) {
        var a = (this.matrix as number[])[0];
        var b = (this.matrix as number[])[1];
        var c = (this.matrix as number[])[3];
        var d = (this.matrix as number[])[4];
        var p = (this.matrix as number[])[2];
        var q = (this.matrix as number[])[5];
        return [
            (d * xy[0] - b * xy[1] + b * q - p * d) / (a * d - b * c),
            (-c * xy[0] + a * xy[1] + c * p - a * q) / (a * d - b * c),
        ];
    }

    /** Get the rotation of the transform
    * @return {Number}: angle
    */
    public getRotation(): number {
        return this.a_;
    }

    /** Get the scale of the transform
    * @return {ol.Coordinate}: scale along x and y axis
    */
    public getScale(): number[] {
        return this.sc_;
    }

    /** Get the rotation of the translation
    * @return {ol.Coordinate}: translation
    */
    public getTranslation(): number[] {
        return this.tr_;
    }

    public transform(xy: number[]) {
        var m = this.matrix as number[];
        return [m[0] * xy[0] + m[1] * xy[1] + m[2], m[3] * xy[0] + m[4] * xy[1] + m[5]];
    }


    private _similarity(xy: number[][], XY: number[][]) {
        if (!xy.length || xy.length !== XY.length) {
            console.log("Helmert :  points incompatible");
            return false;
        }
        var i;					// Variable de boucle
        var n = XY.length;		// nb points de calage
        var a = 1, b = 0, p = 0, q = 0;

        var mxy = { x: 0, y: 0 };
        var mXY = { x: 0, y: 0 };
        for (i = 0; i < n; i++) {
            mxy.x += xy[i][0];
            mxy.y += xy[i][1];
            mXY.x += XY[i][0];
            mXY.y += XY[i][1];
        }

        mxy.x /= n;
        mxy.y /= n;
        mXY.x /= n;
        mXY.y /= n;

        var xy0 = [], XY0 = [];
        for (i = 0; i < n; i++) {
            xy0.push({ x: xy[i][0] - mxy.x, y: xy[i][1] - mxy.y });
            XY0.push({ x: XY[i][0] - mXY.x, y: XY[i][1] - mXY.y });
        }

        var SxX, SxY, SyY, SyX, Sx2, Sy2;
        SxX = SxY = SyY = SyX = Sx2 = Sy2 = 0;
        for (i = 0; i < n; i++) {
            SxX += xy0[i].x * XY0[i].x;
            SxY += xy0[i].x * XY0[i].y;
            SyY += xy0[i].y * XY0[i].y;
            SyX += xy0[i].y * XY0[i].x;
            Sx2 += xy0[i].x * xy0[i].x;
            Sy2 += xy0[i].y * xy0[i].y;
        }

        a = (SxX + SyY) / (Sx2 + Sy2);
        b = (SxY - SyX) / (Sx2 + Sy2);

        p = mXY.x - a * mxy.x + b * mxy.y;
        q = mXY.y - b * mxy.x - a * mxy.y;

        this.matrix = [a, -b, p, b, a, q];

        var sc = Math.sqrt(a * a + b * b);

        this.a_ = Math.acos(a / sc);
        if (b > 0) this.a_ *= -1;
        this.sc_ = [sc, sc];
        this.tr_ = [p, q];

        return this.matrix;
    }

    private _helmert(xy: number[][], XY: number[][], poids?: number[], tol?: number) {
        if (!xy.length || xy.length !== XY.length) {
            console.log("Helmert : points incompatible");
            return false;
        }
        var i;					// Schleifenvariable
        var n = xy.length;		// nb Einstellungspunkte
        // Standardgewichte erstellen
        if (!poids) poids = [];
        if (poids.length === 0 /* || n != poids.iGetTaille() */) {
            for (i = 0; i < n; i++) poids.push(1.0);
        }

        var a, b, k, h, tx, ty;
        if (!tol) tol = 0.0001;

        // Initialisierung (auf einer Ähnlichkeit)
        var affine = this._similarity(xy, XY) as number[];
        a = affine[0];
        b = -affine[1];
        k = h = Math.sqrt(a * a + b * b);
        a /= k;
        b /= k;
        tx = affine[2];
        ty = affine[5];

        // Schwerpunkt
        var mxy = { x: 0, y: 0 };
        var mXY = { x: 0, y: 0 };
        for (i = 0; i < n; i++) {
            mxy.x += xy[i][0];
            mxy.y += xy[i][1];
            mXY.x += XY[i][0];
            mXY.y += XY[i][1];
        }
        mxy.x /= n;
        mxy.y /= n;
        mXY.x /= n;
        mXY.y /= n;

        // Abweichung vom Schwerpunkt
        var xy0 = [], XY0 = [];
        for (i = 0; i < n; i++) {
            xy0.push({ x: xy[i][0] - mxy.x, y: xy[i][1] - mxy.y });
            XY0.push({ x: XY[i][0] - mXY.x, y: XY[i][1] - mXY.y });
        }

        // Variablen
        var Sx, Sy, Sxy, SxX, SxY, SyX, SyY;
        Sx = Sy = Sxy = SxX = SxY = SyX = SyY = 0;
        for (i = 0; i < n; i++) {
            Sx += xy0[i].x * xy0[i].x * poids[i];
            Sxy += xy0[i].x * xy0[i].y * poids[i];
            Sy += xy0[i].y * xy0[i].y * poids[i];
            SxX += xy0[i].x * XY0[i].x * poids[i];
            SyX += xy0[i].y * XY0[i].x * poids[i];
            SxY += xy0[i].x * XY0[i].y * poids[i];
            SyY += xy0[i].y * XY0[i].y * poids[i];
        }

        // Iterations
        var dk, dh, dt;
        var A, B, C, D, E, F, G, H;
        var da, db;
        var div = 1e10;

        do {
            A = Sx;
            B = Sy;
            C = k * k * Sx + h * h * Sy;
            D = -h * Sxy;
            E = k * Sxy;
            F = a * SxX + b * SxY - k * Sx;
            G = -b * SyX + a * SyY - h * Sy;
            H = -k * b * SxX + k * a * SxY - h * a * SyX - h * b * SyY;

            // 
            dt = (A * B * H - B * D * F - A * E * G) / (A * B * C - B * D * D - A * E * E);
            dk = (F - D * dt) / A;
            dh = (G - E * dt) / A;

            // Numerisches Divergenzprob
            if (Math.abs(dk) + Math.abs(dh) > div) break;

            // Annäherung
            da = a * Math.cos(dt) - b * Math.sin(dt);
            db = b * Math.cos(dt) + a * Math.sin(dt);
            a = da;
            b = db;
            k += dk;
            h += dh;

            div = Math.abs(dk) + Math.abs(dh);
        } while (Math.abs(dk) + Math.abs(dh) > tol);

        // baryzentr. Rahmen
        tx = mXY.x - a * k * mxy.x + b * h * mxy.y;
        ty = mXY.y - b * k * mxy.x - a * h * mxy.y;

        this.a_ = Math.acos(a);
        if (b > 0) this.a_ *= -1;
        if (Math.abs(this.a_) < Math.PI / 8) {
            this.a_ = Math.asin(-b);
            if (a < 0) this.a_ = Math.PI - this.a_;
        }
        this.sc_ = [k, h];
        this.tr_ = [tx, ty];

        // Lösung
        this.matrix = [];
        this.matrix[0] = a * k;
        this.matrix[1] = -b * h;
        this.matrix[2] = tx;
        this.matrix[3] = b * k;
        this.matrix[4] = a * h;
        this.matrix[5] = ty;
        return this.matrix;
    }


}
