import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ApiProject, uuidObject } from '../../interfaces/commonInterfaces';

// Define a type for the slice state
interface CurrentAdminEditCopyProject {
  value: ApiProject
}

interface Language {
  code: string;
  name: string;
}

// Define the initial state using that type
const initialState: CurrentAdminEditCopyProject = {
  value: {
    "projectId": 0,
    "name": "",
    "shortName": "",
    "description": "",
    "comment": "",
    "city": "",
    "openingTime": "10:00:00",
    "closingTime": "20:00:00",
    "country": "DE",
    "active": true,
    "hasService": false,
    "market": "",
    "tags": [],
    "links": [],
    "products": [],
    "addons": [],
    "demoGalleryEnabled": false,
    "demoGalleryPublic": false,
    "mapUuid": "",
    "languages": [
      {
        "code": "de",
        "name": "German"
      },
    ],
    "createdAt": "2023-08-12T15:29:39.000000Z",
    "modifiedAt": "2023-08-12T15:29:39.000000Z",
    "timezone": null
  },
}

export const currentAdminEditCopyProjectSlice = createSlice({
  name: 'setCurrentAdminEditCopyProject',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentAdminEditCopyProject: (state, action: PayloadAction<ApiProject>) => {
      state.value = action.payload;
    },
    setCurrentAdminEditProject_market: (state, action: PayloadAction<string>) => {
      state.value.market = action.payload;
    },
    set_products: (state, action: PayloadAction<uuidObject[]>) => {
      state.value.products = action.payload;
    },
    set_addons: (state, action: PayloadAction<uuidObject[]>) => {
      state.value.addons = action.payload;
    },
    set_languages: (state, action: PayloadAction<Language[]>) => {
      state.value.languages = action.payload;
    },
    resetCurrentAdminEditProject: () => initialState
  },
})

export const { setCurrentAdminEditCopyProject } = currentAdminEditCopyProjectSlice.actions
export const { resetCurrentAdminEditProject } = currentAdminEditCopyProjectSlice.actions
export const { set_products } = currentAdminEditCopyProjectSlice.actions
export const { set_addons } = currentAdminEditCopyProjectSlice.actions
export const { set_languages } = currentAdminEditCopyProjectSlice.actions
export const { setCurrentAdminEditProject_market } = currentAdminEditCopyProjectSlice.actions

// Other code such as selectors can use the imported `RootState` type
/* export const selectCurrentProject = (state: RootState) => state.currentproject */


export default currentAdminEditCopyProjectSlice.reducer