import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import axios from 'axios'
import type { AxiosRequestConfig, AxiosError } from 'axios'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
    async ({ url, method, data, params }) => {
      try {
        const result = await axios({ url: baseUrl + url, method, data, params, withCredentials: true })
        return { data: result.data }
      } catch (axiosError) {
        let err = axiosError as AxiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }


const getBaseUrl = () => {
  let url;
  const envString: string = process.env.REACT_APP_PATH_TO_API as string;
  switch (envString) {
    case 'production ':
      url = 'https://creator.guide3d.com/';
      break;
    case 'development ':
      url = 'http://api.creator.local/';
      break;
    default:
      url = 'http://api.creator.local/';
  }

  /* console.log("url: ", url); */

  return url;
}

export const creatorApi = createApi({
  reducerPath: 'creatorApi',
  baseQuery: axiosBaseQuery({
    baseUrl: getBaseUrl(),
    // baseUrl: 'https://creator.guide3d.com/' | 'http://api.creator.local/' 
    /* prepareHeaders(headers) {
      return headers;
    }, */
    /* credentials: "include" */

  }),
  tagTypes: ['Projects', 'Project', 'Company', 'Addons', 'Products', 'Maps', 'Users', 'Role', 'Protocol'],
  endpoints: (builder) => ({
    // ###########################
    // ##### AUTHENTIFICATION
    getAuthCookie: builder.query({
      query: () => ({
        // url: `sanctum/csrf-cookie`
        url: `api/csrf-cookie`,
        method: "GET",
      }),
    }),
    getMe: builder.query({
      query: () => ({
        url: `api/me`,
        method: "GET",
      }),
    }),
    getLogin: builder.mutation({
      query: projectData => ({
        url: `api/login`,
        method: 'POST',
        data: projectData
      }),
    }),
    getLogout: builder.query({
      query: () => ({
        url: `api/logout`,
        method: "GET"
      }),
    }),
    // ###########################
    // ##### USER / USERS
    getUsers: builder.query({
      query: () => ({
        url: `api/users`,
        method: "GET"
      }),
      providesTags: ['Users']
    }),
    getUser: builder.query({
      query: (userUuid) => ({
        url: `api/user/${userUuid}`,
        method: "GET"
      }),
      providesTags: ['Users']
    }),
    getGroupsForUser: builder.query({
      query: (userUuid) => ({
        url: `api/user/${userUuid}/groups`,
        method: "GET"
      }),
      providesTags: ['Users']
    }),
    getRoles: builder.query({
      query: () => ({
        url: `api/roles`,
        method: "GET"
      }),
      providesTags: ['Role']
    }),
    getRolesForUser: builder.query({
      query: (userUuid: string) => ({
        url: `api/user/${userUuid}/roles`,
        method: "GET"
      }),
      providesTags: ['Role']
    }),
    addRoleToUser: builder.mutation({
      query: ({ roleUuid, userUuid }) => ({
        url: `api/role/${roleUuid}/user/${userUuid}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Role']
    }),
    removeRoleFromUser: builder.mutation({
      query: ({ roleUuid, userUuid }) => ({
        url: `api/role/${roleUuid}/user/${userUuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Role']
    }),
    getProjectsForUser: builder.query({
      query: (userUuid) => ({
        url: `api/user/${userUuid}/projects`,
        method: "GET"
      }),
      providesTags: ['Users']
    }),
    createUser: builder.mutation({
      query: userData => ({
        url: 'api/user',
        method: 'POST',
        data: userData
      }),
      invalidatesTags: ['Users']
    }),
    editUser: builder.mutation({
      query: userData => ({
        url: `api/user/${userData.userUuid}`,
        method: 'PUT',
        data: userData.bodydata
      }),
      invalidatesTags: ['Users']
    }),
    deleteUser: builder.mutation({
      query: (userUuid) => ({
        url: `api/user/${userUuid}`,
        method: "DELETE"
      }),
      invalidatesTags: ['Users']
    }),
    addProjectToUser: builder.mutation({
      query: ({ userUuid, projectId }) => ({
        url: `api/user/${userUuid}/project/${projectId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Users']
    }),
    removeProjectFromUser: builder.mutation({
      query: ({ userUuid, projectId }) => ({
        url: `api/user/${userUuid}/project/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users']
    }),
    // ###########################
    // ##### PROJECT / PROJECTS
    getProjects: builder.query({
      query: () => ({
        url: `api/projects`,
        method: "GET"
      }),
      providesTags: ['Projects']
    }),
    getProject: builder.query({
      query: (projectId) => ({
        url: `api/project/${projectId}`,
        method: "GET"
      }),
      providesTags: ['Project']
    }),
    createProject: builder.mutation({
      query: projectData => ({
        url: 'api/project',
        method: 'POST',
        data: projectData
      }),
      invalidatesTags: ['Projects', 'Project']
    }),
    editProject: builder.mutation({
      query: projectData => ({
        url: `api/project/${projectData.id}`,
        method: 'PUT',
        data: projectData.bodydata
      }),
      invalidatesTags: ['Projects', 'Project']
    }),
    // ###########################
    // ##### LANGUAGES
    getLanguages: builder.query({
      query: () => ({
        url: `api/languages`,
        method: "GET"
      }),
    }),
    // ###########################
    // ##### MARKETS
    getMarkets: builder.query({
      query: () => ({
        url: `api/markets`,
        method: "GET"
      }),
      providesTags: ['Projects']
    }),
    // ###########################
    // ##### PRODUCTS
    getProducts: builder.query({
      query: (projectId) => ({
        url: `api/project/${projectId}/products`,
        method: "GET"
      }),
      providesTags: ['Products'],
    }),
    addProductToProject: builder.mutation({
      query: ({ productUuid, projectId }) => ({
        url: `api/product/${productUuid}/project/${projectId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Products']
    }),
    removeProductFromProject: builder.mutation({
      query: ({ productUuid, projectId }) => ({
        url: `api/product/${productUuid}/project/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Products']
    }),
    // ###########################
    // ##### ADDONS
    getAddons: builder.query({
      query: (projectId) => ({
        url: `api/project/${projectId}/addons`,
        method: "GET"
      }),
      providesTags: ['Addons']
    }),
    addAddonToProject: builder.mutation({
      query: ({ productUuid, projectId }) => ({
        url: `api/addon/${productUuid}/project/${projectId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Addons']
    }),
    removeAddonFromProject: builder.mutation({
      query: ({ productUuid, projectId }) => ({
        url: `api/addon/${productUuid}/project/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Addons']
    }),
    // ###########################
    // ##### MAPS
    getMaps: builder.query({
      query: () => ({
        url: `api/maps`,
        method: "GET"
      }),
      providesTags: ['Maps']
    }),
    createMap: builder.mutation({
      query: mapData => ({
        url: 'api/map',
        method: 'POST',
        data: mapData
      }),
      invalidatesTags: ['Maps']
    }),
    getMap: builder.query({
      query: mapUuid => ({
        url: `api/map/${mapUuid}`,
        method: "GET"
      }),
      providesTags: ['Maps']
    }),
    editMap: builder.mutation({
      query: projectData => ({
        url: `api/map/${projectData.mapUuid}`,
        method: 'PUT',
        data: projectData.bodydata
      }),
      invalidatesTags: ['Maps']
    }),
    deleteMap: builder.mutation({
      query: (mapUuid) => ({
        url: `api/map/${mapUuid}`,
        method: "DELETE"
      }),
      invalidatesTags: ['Maps']
    }),
    getProjectsForMap: builder.query({
      query: (mapUuid) => ({
        url: `api/map/${mapUuid}/projects`,
        method: "GET"
      }),
      providesTags: ['Maps']
    }),
    addMapToProject: builder.mutation({
      query: ({ productUuid, projectId }) => ({
        url: `api/map/${productUuid}/project/${projectId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Maps', 'Project']
    }),
    removeMapFromProject: builder.mutation({
      query: ({ mapUuid, projectId }) => ({
        url: `api/map/${mapUuid}/project/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Maps', 'Project']
    }),
    uploadPngToMap: builder.mutation({
      query: ({ bodyFormData, mapUuid }) => ({
        url: `api/map/${mapUuid}/uploadPng`,
        method: 'POST',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }),
      invalidatesTags: ['Maps']
    }),
    deletePng: builder.mutation({
      query: ({ mapUuid, layerId }) => ({
        url: `api/map/${mapUuid}/png/${layerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Maps']
    }),
    uploadPgwToLayer: builder.mutation({
      query: ({ bodyFormData, mapUuid }) => ({
        url: `api/map/${mapUuid}/uploadPgw`,
        method: 'POST',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }),
      invalidatesTags: ['Maps']
    }),
    deletePgw: builder.mutation({
      query: ({ mapUuid, layerId }) => ({
        url: `api/map/${mapUuid}/pgw/${layerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Maps']
    }),
    // ###########################
    // ##### TILES
    createTiles: builder.mutation({
      query: projectData => ({
        url: `api/map/${projectData.mapUuid}/createTiles`,
        method: 'POST',
        data: projectData.bodydata
      }),
      invalidatesTags: ['Maps']
    }),
    deployTiles: builder.mutation({
      query: projectData => ({
        url: `api/process/jenkins/build/deployTiles/${projectData.projectId}`,
        method: 'POST',
        data: projectData.bodydata
      }),
    }),
    createTilesStatus: builder.query({
      query: batchUuid => ({
        url: `api/map/createTilesStatus/${batchUuid}`,
        method: "GET"
      }),
    }),
    createTilesStatusGlobal: builder.query({
      query: () => ({
        url: `api/map/createTilesStatus`,
        method: "GET"
      }),
    }),
    // ###########################
    // ##### JOBS
    getJobs: builder.query({
      query: projectId => ({
        url: `api/process/jenkins/jobs?projectId=${projectId}`,
        method: "GET"
      }),
    }),
    // ###########################
    // ##### COMPANY
    getCompanies: builder.query({
      query: () => ({
        url: `api/companies`,
        method: "GET"
      }),
      providesTags: ['Company']
    }),
    getCompany: builder.query({
      query: (userUuid) => ({
        url: `api/company/${userUuid}`,
        method: "GET"
      }),
      providesTags: ['Company']
    }),
    getCompaniesForUser: builder.query({
      query: (userUuid) => ({
        url: `api/user/${userUuid}/companies`,
        method: "GET"
      }),
      providesTags: ['Company']
    }),
    createCompany: builder.mutation({
      query: companyData => ({
        url: 'api/company',
        method: 'POST',
        data: companyData
      }),
      invalidatesTags: ['Company']
    }),
    addCompanyToUser: builder.mutation({
      query: ({ companyUuid, userUuid }) => ({
        url: `api/company/${companyUuid}/user/${userUuid}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Company', 'Users']
    }),
    removeCompanyFromUser: builder.mutation({
      query: ({ companyUuid, userUuid }) => ({
        url: `api/company/${companyUuid}/user/${userUuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company', 'Users']
    }),
    // ###########################
    // ##### PROTOCOLS   
    getProtocols: builder.query({
      query: () => ({
        url: `api/protocols?limit=250`,
        method: "GET"
      }),
      providesTags: ['Protocol']
    }),
    getProtocolForProject: builder.query({
      query: (projectId) => ({
        url: `api/protocol/${projectId}?limit=50`,
        method: "GET"
      }),
      providesTags: ['Protocol']
    }),
  }),
})

export const {
  useGetAuthCookieQuery,
  useGetProjectsQuery,
  useGetLanguagesQuery,
  useGetProjectQuery,
  useLazyGetProjectQuery,
  useGetMarketsQuery,
  useGetProductsQuery,
  useGetAddonsQuery,
  useCreateProjectMutation,
  useEditProjectMutation,
  useAddAddonToProjectMutation,
  useRemoveAddonFromProjectMutation,
  useAddProductToProjectMutation,
  useRemoveProductFromProjectMutation,
  useGetLoginMutation,
  useGetLogoutQuery,
  useGetMeQuery,
  useLazyGetMeQuery,
  useGetMapsQuery,
  useCreateMapMutation,
  useGetMapQuery,
  useLazyGetMapQuery,
  useEditMapMutation,
  useDeleteMapMutation,
  useGetProjectsForMapQuery,
  useAddMapToProjectMutation,
  useRemoveMapFromProjectMutation,
  useUploadPngToMapMutation,
  useUploadPgwToLayerMutation,
  useCreateTilesMutation,
  useDeployTilesMutation,
  useLazyCreateTilesStatusQuery,
  useLazyCreateTilesStatusGlobalQuery,
  useCreateUserMutation,
  useAddProjectToUserMutation,
  useRemoveProjectFromUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
  useGetGroupsForUserQuery,
  useGetProjectsForUserQuery,
  useGetRolesQuery,
  useGetRolesForUserQuery,
  useAddRoleToUserMutation,
  useRemoveRoleFromUserMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUsersQuery,
  useDeletePgwMutation,
  useDeletePngMutation,
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useGetCompaniesForUserQuery,
  useAddCompanyToUserMutation,
  useRemoveCompanyFromUserMutation,
  useCreateCompanyMutation,
  useLazyGetJobsQuery,
  useGetProtocolForProjectQuery,
  useLazyGetProtocolForProjectQuery,
  useGetProtocolsQuery
} = creatorApi
