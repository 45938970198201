import * as React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
/* import { useTranslation } from 'react-i18next'; */
import "./scrollList.css";
import { useGetLanguagesQuery } from '../slices/apiSlice';
import { useAppSelector, useAppDispatch } from '../hooks';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
/* import Chip from '@mui/material/Chip'; */
import { set_languages } from '../slices/currentAdminEditProjectSlice';

/* function not(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
} */

/* function intersection(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
} */

interface Language {
    code: string;
    name: string;
}


export default function LanguageTransferList() { 
    const currentAdminEditProject = useAppSelector((state) => state.currentAdminEditProject.value);
    const { data, error, isLoading } = useGetLanguagesQuery(currentAdminEditProject.projectId.toString());
    const [apiReady, setApiReady] = React.useState(false);
    /* const { t } = useTranslation(); */
    const dispatch = useAppDispatch();
    /* const [fill, setFill] = React.useState(false); */
    const [selectedLanguages, setSelectedLanguages] = React.useState<Language[]>([]);
    const [allLanguages, setAllLanguages] = React.useState([{
        code: "",
        name: "",
    } as Language]);

    /* React.useEffect(() => {
        if(currentAdminEditProject.projectId === 9999999){
            const productsUuidList: string[] = [];
            allProducts.forEach(productObject => {
                left.forEach(element => {
                    if(element === productObject.name){
                        productsUuidList.push(productObject.uuid);
                    }                    
                });
            });
            dispatch(set_products(productsUuidList));
            console.log("Products SET in AddProject context! => ", productsUuidList);
        }
    }, [left,currentAdminEditProject.projectId,dispatch]); */

    React.useEffect(() => {

        /* console.log("################### isLoading: ", isLoading);
        console.log("################### error: ", error);
        console.log("################### data: ", data);
        console.log("####################### API getProducts"); */
        /*  handleApiProjectsList(); */
        if (isLoading === false && error === undefined && apiReady === false) {
            console.log("####################### API getLanguages LOADED");
            const languages = data.data as Language[];
            /* console.log("languages loaded: ", languages); */
            setAllLanguages(languages);
            setApiReady(true);
            if (currentAdminEditProject.languages.length > 0) {
                const langs: Language[] = [];
                currentAdminEditProject.languages.forEach(element => {
                    langs.push(element);
                });
                setSelectedLanguages(langs);
            }
            else {
                setSelectedLanguages([]);
            }
            // setFill(true);
        }
    }, [data, error, isLoading, currentAdminEditProject.languages, apiReady]);

    React.useEffect(() => {
        if (apiReady === true) {
            if (allLanguages.length > 10) {
                /* console.log("selectedLanguages NOW: ", selectedLanguages); */
                dispatch(set_languages(selectedLanguages));
            }
        }
    }, [apiReady, selectedLanguages, allLanguages.length, dispatch]);


    /*     const sorter = (a: string, b: string) => {
            return prodList.indexOf(a) - prodList.indexOf(b);
        } */

    return (
        <Box sx={{ width: '100%' }}>
            {apiReady === true
                ?
                <Stack spacing={3} sx={{ width: '100%' }}>
                    <Autocomplete
                        /* sx={{ color: 'blue' }} */
                        sx={{ backgroundColor: 'primary.dark', padding: '0.3rem' }}
                        multiple
                        id="tags-standardL"
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                        options={allLanguages}
                        getOptionLabel={(option: Language) => (option.name + " (" + option.code + ")")}
                        /* defaultValue={[allLanguages[13]]} */
                        /* value={selectedLanguages} */
                        /* onChange={handleChange} */
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                            /*  placeholder="Favorites" */
                            />
                        )}
                        onChange={(event: any, newValue: Language[]) => {
                            setSelectedLanguages(newValue);
                            /* let newSelectedLang: Language[] = [];
                            selectedLanguages.forEach(element => {
                                newSelectedLang.push(element);
                            });
                            if(newSelectedLang.indexOf(newValue) === -1){
                                newSelectedLang.push(newValue);
                            } */

                        }}
                        value={selectedLanguages}
                    />
                </Stack>
                : null
            }
        </Box>


    );
}