import * as React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
/* import { useTranslation } from 'react-i18next'; */
import "./scrollList.css";
import { useGetProjectsQuery, useGetProjectsForUserQuery, useAddProjectToUserMutation, useRemoveProjectFromUserMutation } from '../slices/apiSlice';
/* import { useAppDispatch } from '../hooks'; */
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
/* import Chip from '@mui/material/Chip'; */
import { ApiProject } from '../../interfaces/commonInterfaces';


interface ProjectSelect {
    projectId: string;
    name: string;
}

interface IProjectsTransferListProps {
    user_uuid: string;
    saveChanges: boolean;
}


export default function ProjectsTransferList(props: React.PropsWithChildren<IProjectsTransferListProps>) { 
    const { data, error, isLoading } = useGetProjectsQuery('');
    const { data: dataProjectsUser, error: errorProjectsUser, isLoading: isLoadingProjectsUser } = useGetProjectsForUserQuery(props.user_uuid);
    const [apiAllReady, setApiAllReady] = React.useState(false);
    const [apiProjectUserReady, setApiProjectUserReady] = React.useState(false);
    /* const { t } = useTranslation(); */ 
    const [selectedLoadedProjects, setSelectedLoadedProjects] = React.useState<ProjectSelect[]>([]);
    const [selectedProjects, setSelectedProjects] = React.useState<ProjectSelect[]>([]);
    const [allProjects, setAllProjects] = React.useState([{
        projectId: "",
        name: "",
    } as ProjectSelect]);
    const [addProjectToUser] = useAddProjectToUserMutation();
    const [removeProjectFromUser] = useRemoveProjectFromUserMutation();


    React.useEffect(() => {        
        if(props.saveChanges === true){
            handleProjectsComparison();
        }
    }, [props.saveChanges]);


    React.useEffect(() => {

        /* console.log("################### isLoading: ", isLoading); 
        console.log("################### error: ", error);
        console.log("################### data: ", data);
        console.log("####################### API getProducts"); */
        /*  handleApiProjectsList(); */
        if (isLoading === false && error === undefined && apiAllReady === false) {
            /* console.log("####################### API getProjects LOADED"); */
            const apiProjects = data.data as ApiProject[];
            const projectsList: ProjectSelect[] = [];

            apiProjects.forEach(element => {
                const project: ProjectSelect = {
                    projectId: element.projectId.toString(),
                    name: element.name
                }
                projectsList.push(project);
            });
            /* console.log("languages loaded: ", languages); */
            setAllProjects(projectsList);
            setApiAllReady(true);
        }
    }, [data, error, isLoading, apiAllReady]);

    React.useEffect(() => {

        /* console.log("################### isLoading: ", isLoading);
        console.log("################### error: ", error);
        console.log("################### data: ", data);
        console.log("####################### API getProducts"); */
        /*  handleApiProjectsList(); */
        // const { data: dataProjectsUser, error: errorProjectsUser, isLoading: isLoadingProjectsUser } = useGetRolesForUserQuery(props.user_uuid);
        if (isLoadingProjectsUser === false && errorProjectsUser === undefined && apiProjectUserReady === false) {
            /* console.log("####################### API getRolesUser LOADED: dataProjectsUser.data ", dataProjectsUser.data); */
            const apiProjectsUser = dataProjectsUser.data as ProjectSelect[];
            const projectsList: ProjectSelect[] = [];

            const cloneProjectsList: ProjectSelect[] = structuredClone(apiProjectsUser);
            cloneProjectsList.sort((a, b) => {
                if (a.projectId < b.projectId) {
                    return -1;
                }
                if (a.projectId > b.projectId) {
                    return 1;
                }
                return 0;
            });

            cloneProjectsList.forEach(element => {
                const role: ProjectSelect = {
                    projectId: element.projectId.toString(),
                    name: element.name
                }
                projectsList.push(role);
            });
            /* console.log("languages loaded: ", languages); */
            setSelectedLoadedProjects(projectsList);
            setSelectedProjects(projectsList);
            setApiProjectUserReady(true);
            
        }
    }, [dataProjectsUser, errorProjectsUser, isLoadingProjectsUser, apiProjectUserReady]);


    const handleProjectsComparison = () => {
        if(selectedLoadedProjects !== selectedProjects){
            /* console.log("NEW ROLES mach was! *schluck*"); */
            // check first if any Roles were deleted:
            selectedLoadedProjects.forEach(element => {
                /* console.log(element); */
                if(selectedProjects.indexOf(element) === -1){
                    // call api to delete that role from user:
                    removeProjectFromUserCall(props.user_uuid,element.projectId.toString());
                }                
            });
            // check if new Roles have been added:
            selectedProjects.forEach(element => {
                /* console.log(element); */
                if(selectedLoadedProjects.indexOf(element) === -1){
                    // call api to add that role to the user:
                    addProjectToUserCall(props.user_uuid,element.projectId.toString());
                }
            });
        }
        else{
            /* console.log("Keine Roles Änderungen *schnarch*"); */
        }
    }

    const addProjectToUserCall = async (userUuid: string, projectId: string) => {
        try {
            await addProjectToUser({ userUuid, projectId });
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }

    const removeProjectFromUserCall = async (userUuid: string, projectId: string) => {
        try {
            await removeProjectFromUser({ userUuid, projectId });
        } catch (error) {
            console.error('Failed to save the post: ', error)
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            {apiAllReady === true
                ?
                <Stack spacing={3} sx={{ width: '100%' }}>
                    <Autocomplete
                        /* sx={{ color: 'blue' }} */
                        sx={{ backgroundColor: 'primary.dark', padding: '0.3rem', minHeight: '3em' }}
                        multiple
                        /* freeSolo={true} */
                        id="tags-standardP"
                        isOptionEqualToValue={(option, value) => option.projectId === value.projectId}
                        options={allProjects}
                        getOptionLabel={(option: ProjectSelect) => (option.projectId + " (" + option.name + ")")}
                        /* defaultValue={[allLanguages[13]]} */
                        /* value={selectedLanguages} */
                        /* onChange={handleChange} */
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                            /*  placeholder="Favorites" */
                            />
                        )}
                        onChange={(event: any, newValue: ProjectSelect[]) => {
                            setSelectedProjects(newValue);
                            /* let newSelectedLang: Language[] = [];
                            selectedLanguages.forEach(element => {
                                newSelectedLang.push(element);
                            });
                            if(newSelectedLang.indexOf(newValue) === -1){
                                newSelectedLang.push(newValue);
                            } */

                        }}
                        value={selectedProjects}
                    />
                </Stack>
                : null
            }
        </Box>


    );
}